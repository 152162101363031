import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Modal,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Divider,
} from "@mui/material";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import connect from "../../images/connect.png";
import axios from "axios";
import { reflectionService } from "../../service/index"; // Adjust path as needed

const httpClient = axios.create({
  baseURL: "https://your-api-url.com", // Replace with your API base URL
});

const getColor = (status) => (status === "Yes" ? "green" : "red");

const LogEntryCards = ({ form }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState(form);
  const [loading, setLoading] = useState(false);

  const handleEditClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await reflectionService.updateReflection(
        formData._id,
        formData
      );
      if (response.status) {
        // Handle successful save
        console.log("Updated Form Data:", formData);
        setModalOpen(false);
      } else {
        // Handle error case
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        }}
        elevation={0}
      >
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              bgcolor: "#FFEBF3",
              fontSize: "12px",
              color: "#EB568F",
              borderRadius: "6px",
              textTransform: "none",
              padding: "5px",
              mb: 1,
              width: "83px",
              cursor: "default",
              "&:hover": {
                bgcolor: "#FFEBF3",
                color: "#EB568F",
              },
            }}
          >
            Self entered
          </Box>

          <Typography variant="body2" color="textSecondary">
            Expire date
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "13px", mb: 1 }}
          >
            {form.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Type
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "13px", mb: 1 }}
          >
            {form.type}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                Date Of Modified
              </Typography>
              <Typography variant="body2">{form.modifiedDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                Date Of Activity
              </Typography>
              <Typography variant="body2">{form.activityDate}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                Shared?
              </Typography>
              <Typography
                variant="body2"
                style={{ color: getColor(form.isShared ? "Yes" : "No") }}
              >
                {form.isShared ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" marginTop={2} sx={{ mt: "auto" }}>
            <IconButton
              sx={{
                backgroundColor: "#E5E4FF",
                "&:hover": {
                  backgroundColor: "#D4D3FF",
                },
              }}
              onClick={handleEditClick}
            >
              <img src={connect} alt="Connect" style={{ color: "#2C2B83" }} />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: "#E5E4FF",
                "&:hover": {
                  backgroundColor: "#D4D3FF",
                },
                marginLeft: 1,
              }}
              onClick={handleEditClick}
            >
              <CiEdit style={{ color: "#2C2B83" }} />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: "#FFEBEB",
                "&:hover": {
                  backgroundColor: "#FFD4D4",
                },
                marginLeft: 1,
              }}
            >
              <MdDelete style={{ color: "#D32F2F" }} />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      {/* Modal for Editing */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "11%", // Adjust this value as needed
            left: "50%",
            transform: "translateX(-50%)",
            bgcolor: "background.paper",
            borderRadius: "8px",
            padding: "24px",
            width: "500px",
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Reflection
          </Typography>
          <Divider sx={{ my: 2 }} />
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Activity Date"
            name="activityDate"
            type="date"
            value={formData.activityDate.slice(0, 10)} // Format to YYYY-MM-DD
            onChange={handleChange}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Expiry Date"
            name="expiryDate"
            type="date"
            value={formData.expiryDate.slice(0, 10)} // Format to YYYY-MM-DD
            onChange={handleChange}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isShared"
                checked={formData.isShared}
                onChange={handleChange}
              />
            }
            label="Shared"
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            margin="normal"
            fullWidth
            multiline
            rows={2}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LogEntryCards;
