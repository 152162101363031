import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import footerlogo from '../../images/footerlogo.jpg';
import maplogo from '../../images/maplogo.png';
import follow1 from '../../images/follow1.png';
import follow2 from '../../images/follow2.png';
import follow3 from '../../images/follow3.png';
import follow4 from '../../images/follow4.png';
import follow5 from '../../images/follow5.png';
import footerphone from '../../images/footerphone.png';
import footermail from '../../images/footermail.png';
import { useNavigate } from 'react-router-dom';
import { getAuthStorage } from '../../utils/storage-utils';

function Footer() {

  const navigate = useNavigate();

  return (
    <>
      <Box sx={{
        backgroundColor: '#000020',
        color: '#F2F2F2',
        // height: '45vh',
        padding: {
          lg: "3% 9% 2% 9%",
          sm: "3% 2% 3% 2%",
          xs: "3% 8% 3% 8%",
          md: "3% 2% 3% 2%",
        },
        overflow: 'hidden',
      }}>
        <Box>
          <img src={footerlogo} alt="" style={{ width: '10rem', height: 'auto' }} />
        </Box>
        <Grid container sx={{
          mt: '1rem',
          justifyContent: 'space-between'
        }}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '80%',
                lg: '60%',
                xl: '60%',
              }
            }}>


              <Typography sx={{
                fontSize: '13.37px',
                fontFamily: 'Poppins',
                fontWeight: ' normal',
                color: '#F2F2F2',
              }}>
                Empowering Medical Excellence: Study Medic is a leading platform dedicated to supporting the educational and professional growth of medical professionals worldwide.
              </Typography>
              <Box sx={{
                mt: '1.5rem',
              }}>
                <Typography sx={{
                  fontSize: '18px',
                  fontFamily: 'Poppins',
                  fontWeight: ' 600',
                  color: '#fff',

                }}>
                  Locate Us
                </Typography>
                <Box sx={{
                  display: 'flex',
                  mt: '0.8rem',
                  width: { xs: '47%', lg: '70%' },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // border : '1px solid white'
                }}>
                  <img src={maplogo} alt="" />
                  <Typography sx={{
                    fontSize: '15.6px',
                    fontFamily: 'Poppins',
                    fontWeight: 'normal',
                    color: '#fff',

                  }}>INDIA</Typography>
                  <Typography sx={{
                    fontSize: '12.26px',
                    fontFamily: 'Poppins',
                    fontWeight: 'normal',
                    color: '#EB568F',
                    textDecoration: 'underline',
                    cursor: 'pointer',

                  }}>View Map</Typography>

                </Box>
                <Box sx={{
                  display: 'flex',
                  mt: '0.8rem',
                  width: { xs: '47%', lg: '70%' },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // border : '1px solid white'
                }}>
                  <img src={maplogo} alt="" />
                  <Typography sx={{
                    fontSize: '15.6px',
                    fontFamily: 'Poppins',
                    fontWeight: 'normal',
                    color: '#fff',

                  }}>KOCHI</Typography>
                  <Typography sx={{
                    fontSize: '12.26px',
                    fontFamily: 'Poppins',
                    fontWeight: 'normal',
                    color: '#EB568F',
                    textDecoration: 'underline',
                    cursor: 'pointer',

                  }}>View Map</Typography>

                </Box>

              </Box>
              <Box sx={{
                mt: '1.5rem',
              }}>
                <Typography sx={{
                  fontSize: '18px',
                  fontFamily: 'Poppins',
                  fontWeight: ' 600',
                  color: '#fff',

                }}>
                  Follow Us:
                </Typography>
                <Box sx={{
                  display: 'flex',
                  mt: '0.8rem',
                  width: { xs: '75%', lg: '115%', xl: '100%' },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // border : '1px solid white'
                }}>
                  <a href='https://www.youtube.com/channel/UC1KtPKHmiiztvz895BYQvTA'><img src={follow1} alt="" style={{ width: '2.5rem', height: 'auto' }} />
                  </a>
                  <a href='https://www.facebook.com/StudyMEDICacademy'><img src={follow2} alt="" style={{ width: '2.5rem', height: 'auto' }} />
                  </a>
                  <a href="https://www.instagram.com/studymedicacademy/"><img src={follow3} alt="" style={{ width: '2.5rem', height: 'auto' }} /></a>
                  <a href="https://qa.linkedin.com/company/studymedicacademy"><img src={follow4} alt="" style={{ width: '2.5rem', height: 'auto' }} /></a>
                  <a href='https://twitter.com/StudyMEDIClive'><img src={follow5} alt="" style={{ width: '2.5rem', height: 'auto' }} /></a>


                </Box>

              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={{
            mt: {
              xs: '1.5rem',
              sm: '1.5rem',
            }
          }}>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontWeight: '600',
              color: '#fff'
            }}>Quick Links:</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1.25rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => { navigate("/home") }}
            >Home</Typography>
            {/* <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}>About Us</Typography> */}
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => { navigate("/solutions") }}
            >Solutions</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => { navigate("/career") }}
            >Career Guidance</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => { navigate("/sign-in") }}

            >Login/Create Account</Typography>


          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={{
            mt: {
              xs: '1.5rem',
              sm: '1.5rem',
            }
          }}>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontWeight: '600',
              color: '#fff'
            }}

            >Useful Links:</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1.25rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => navigate(`/home`)}
            >Home</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => {
                const session = getAuthStorage();
                if (session) {
                  navigate(`/dashboardT/${session.userId}`);
                } else {
                  navigate(`/sign-in`);
                }
              }}
            >Profile</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => {
                const session = getAuthStorage();
                if (session) {
                  navigate(`/user/files/${session.userId}`);
                } else {
                  navigate(`/sign-in`);
                }
              }}
            >My Files</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => {
                const session = getAuthStorage();
                if (session) {
                  navigate(`/user/career/${session.userId}`);
                } else {
                  navigate(`/career`);
                }
              }}
            >Career Guidance</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}
              onClick={() => {
                const session = getAuthStorage();
                if (session) {
                  navigate(`/user/clinical/${session.userId}`);
                } else {
                  navigate(`/clinical`);
                }
              }}
            >Clinical Programs</Typography>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '15.6px',
              fontWeight: 'normal',
              color: '#F2F2F2',
              cursor: "pointer",
              mt: '1rem',
              '&:hover': {
                color: '#D20A62'
              }
            }}>Free Training Videos</Typography>

          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.2} xl={2.2} sx={{
            mt: {
              xs: '1.5rem',
              sm: '1.5rem',
            }
          }}>

            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontWeight: '600',
              color: '#fff'
            }}>Contact Us</Typography>

            <Box sx={{
              display: 'flex',
              mt: '1.25rem',
            }}>
              <img src={footerphone} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} />
              <Box>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: '15.6px',
                  fontWeight: 'normal',
                  color: '#F2F2F2'
                }}>
                  +91 9094200800
                </Typography>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: '15.6px',
                  fontWeight: 'normal',
                  color: '#F2F2F2'
                }}>
                  +447464245285
                </Typography>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: '15.6px',
                  fontWeight: 'normal',
                  color: '#F2F2F2'
                }}>
                  +447341981539
                </Typography>
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              mt: '1rem',
            }}>
              <img src={footermail} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} />
              <Box>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: '15.6px',
                  fontWeight: 'normal',
                  color: '#F2F2F2'
                }}>
                  sales@studymedic.com
                </Typography>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: '15.6px',
                  fontWeight: 'normal',
                  color: '#F2F2F2'
                }}>
                  info@studymedic.com
                </Typography>
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              mt: '1rem',
            }}>
              <img src={maplogo} alt="" style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} />
              <Box>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontSize: '15.6px',
                  fontWeight: 'normal',
                  color: '#F2F2F2'
                }}>
                  StudyMEDIC Academy Private Ltd. Door No 90 G, N.K.K Plaza Pannithadam Road, Kecheri Thrissur Pin: 680501
                </Typography>

              </Box>
            </Box>


          </Grid>

        </Grid>

      </Box>

    </>







    // <div style={{ position: 'relative', bottom: '-8px' }}>
    //   <div style={{ height: '100px' }}></div>

    //   <Box sx={{ height: { md: '1080px', xs: '1510px', lg: '650px' }, backgroundColor: '#000020', color: '#FFF', width: '100%', }}>
    //     <Box sx={{
    //       '@media (min-width: 1200px) and  (max-width: 1600px)': {
    //         ml: '100px'
    //       },



    //     }}>
    //       <Grid container justifyContent="space-between" alignItems="center" sx={{
    //         padding: { md: '40px 120px', xs: '20px 60px', lg: '40px 300px', },






    //       }} spacing={0}>

    //         {/* 1st */}

    //         <Grid item xs={12} md={12} lg={2} >
    //           <Box sx={{
    //             padding: '40px 0px',

    //             '@media (min-width: 1200px) and  (max-width: 1600px)': {
    //               position: 'relative',
    //               right: '250px'
    //             },






    //           }}>
    //             <img src={footerlogo} alt="logo" style={{ width: '155px', height: '44px' }} />
    //             <Typography sx={{ color: '#F2F2F2', fontSize: '13.373px', fontWeight: '400', width: '307.576px', textAlign: 'justify', marginTop: '10px' }}>
    //               Empowering Medical Excellence: Study Medic is a leading platform dedicated to supporting the educational and professional growth of medical professionals worldwide.
    //             </Typography>
    //             <Typography sx={{ fontSize: '17.831px', fontWeight: '600', color: '#FFF', marginTop: '30px' }}>Locate Us</Typography>
    //             <Stack direction={'row'} alignItems="center">
    //               <img src={maplogo} alt="maplogo" style={{ width: '22.29px', height: '22.29px', marginTop: '20px' }} />
    //               <Typography sx={{ fontSize: '15.602px', fontWeight: '400', marginTop: '20px', marginLeft: '15px' }}>INDIA</Typography>
    //               <Link href='#' sx={{ color: '#EB568F', fontSize: "12.258px", fontWeight: '500', textDecorationColor: '#EB568F', marginTop: '22px', marginLeft: '20px' }}>ViewMap</Link>
    //             </Stack>
    //             {/* More locations */}
    //             <Stack direction={'row'} alignItems="center">
    //               <img src={maplogo} alt="maplogo" style={{ width: '22.29px', height: '22.29px', marginTop: '20px' }} />
    //               <Typography sx={{ fontSize: '15.602px', fontWeight: '400', marginTop: '20px', marginLeft: '15px' }}>KOCHI</Typography>
    //               <Link href='#' sx={{ color: '#EB568F', fontSize: "12.258px", fontWeight: '500', textDecorationColor: '#EB568F', marginTop: '22px', marginLeft: '10px' }}>ViewMap</Link>
    //             </Stack>
    //             {/* Social media icons */}
    //             <Typography sx={{ fontSize: '17.831px', fontWeight: '600', color: '#FFF', marginTop: '40px' }}>Follow Us:</Typography>
    //             <Stack direction={'row'} spacing={2}>
    //               <img src={follow1} alt="ytbelink" style={{ width: '33px', height: '33px', marginTop: '20px' }} />
    //               <img src={follow2} alt="ytbelink" style={{ width: '33px', height: '33px', marginTop: '20px' }} />
    //               <img src={follow3} alt="ytbelink" style={{ width: '33px', height: '33px', marginTop: '20px' }} />
    //               <img src={follow4} alt="ytbelink" style={{ width: '33px', height: '33px', marginTop: '20px' }} />
    //               <img src={follow5} alt="ytbelink" style={{ width: '33px', height: '33px', marginTop: '20px' }} />
    //             </Stack>
    //           </Box>
    //         </Grid>



    //         {/* 2nd */}


    //         <Grid item xs={12} md={2} lg={3} sx={{

    //           '@media (min-width: 1200px) and  (max-width: 1600px)': {
    //             position: 'relative',
    //             left: '30px'
    //           },



    //         }}>
    //           <Box>
    //             <Typography sx={{ fontSize: '17.831px', fontWeight: '600', color: '#FFF' }}>Quick Links:</Typography>
    //             <Stack direction={'column'} spacing={1} marginBottom={10} marginTop={3}>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Home</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>About Us</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Solutions</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Career Guidance</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Login/Create Account</Link>
    //             </Stack>
    //           </Box>
    //         </Grid>



    //         {/* 3rd */}


    //         <Grid item xs={12} md={2} lg={3} sx={{
    //           '@media (min-width: 1200px) and  (max-width: 1600px)': {
    //             position: 'relative',
    //             left: '100px'
    //           },



    //         }}>
    //           <Box>
    //             <Typography sx={{ fontSize: '17.831px', fontWeight: '600', color: '#FFF' }}>Useful Links</Typography>
    //             <Stack direction={'column'} spacing={1} marginBottom={7} marginTop={3}>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Home</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Profile</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>My Files</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Career Guidance</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Clinical Programs</Link>
    //               <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>Free Training Videos</Link>
    //             </Stack>
    //           </Box>
    //         </Grid>



    //         {/* 4th */}



    //         <Grid item xs={12} md={3} mt={3} lg={4} sx={{

    //           '@media (min-width: 1200px) and  (max-width: 1600px)': {
    //             position: 'relative',
    //             left: '150px'
    //           },

    //           '@media (min-width: 1200px) and  (max-width: 1380px)': {
    //             position: 'relative',
    //             top: '20px'
    //           },


    //         }}>
    //           <Box>
    //             <Typography sx={{ fontSize: '17.831px', fontWeight: '600', color: '#FFF' }}>Contact Us</Typography>
    //             <Stack direction={'column'} spacing={2} marginTop={3}>
    //               <Stack direction={'row'} spacing={1}>
    //                 <img src={footerphone} alt='phonelogo' style={{ width: '21.174px', height: '21.174px' }} />
    //                 <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>
    //                   +91 9094200800 <br />
    //                   +447464245285 <br />
    //                   +447341981539
    //                 </Link>
    //               </Stack>

    //               <Stack direction={'row'} spacing={2}>
    //                 <img src={footermail} alt='phonelogo' style={{ width: '21.174px', height: '21.174px' }} />
    //                 <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>
    //                   sales@studymedic.com<br />
    //                   info@studymedic.com
    //                 </Link>
    //               </Stack>

    //               <Stack direction={'row'} spacing={2}>
    //                 <img src={maplogo} alt='phonelogo' style={{ width: '21.174px', height: '21.174px' }} />
    //                 <Link href='#' sx={{ textDecoration: 'none', color: '#F2F2F2', fontSize: '15.602px', fontWeight: '400' }}>
    //                   StudyMEDIC Academy Private Ltd.<br />
    //                   Door No 90 G, N.K.K Plaza <br />
    //                   Pannithadam Road, Kecheri<br />
    //                   Thrissur Pin: 680501
    //                 </Link>
    //               </Stack>
    //             </Stack>
    //           </Box>
    //         </Grid>
    //       </Grid>








    //       <Typography sx={{
    //         fontSize: '15.62px', fontWeight: '400', marginLeft: { md: '584px', xs: '60px' }, mt: { xs: '40px', md: '20px' },

    //         '@media (min-width: 1201px) and  (max-width: 1400px)': {
    //           marginLeft: '500px'


    //         }, '@media (min-width: 900px) and  (max-width: 1200px)': {
    //           marginLeft: '120px'


    //         },




    //       }}>© 2023 Study Medic. All rights reserved.</Typography>
    //     </Box></Box>
    //   <style>
    //     {`
    //       body {
    //         margin: 0;
    //         /* Add any other body-specific styles here */
    //       }
    //     `}
    //   </style>
    // </div>
  );
}

export default Footer;































