import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
import "./user-career.css";
import { Box, Typography, Grid } from "@mui/material/";
import scheduled from "../../images/calendartick.svg";
import doctor from "../../images/doctorsche.png";
import booked1 from "../../images/booked1.png";
import { subService } from "../../service/index";

const UserCareer = () => {
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [guidance, setGuidance] = useState(null);
  const [events, setEvents] = useState(null);
  const [loading, isLoading] = useState(false);

  // useEffect(() => {
  //   if (loading) {
  //   }
  // }, [loading]);

  if (loading) {
    <FileLoader />;
  }


  const fetchGuidance = useCallback(async () => {
    isLoading(true);
    try {
      const res = await subService.getBookedGuidance();
      if (res.status && res.data) {
        console.log("GuidRESPONSE", res);
        setGuidance(res.data);
      } else {
        console.log("Error_Guidance", res);
        snackbar("Failed to fetch data", { variant: "error" });
      }
    } catch (error) {
      console.error("Error_Guidance", error);
      snackbar("Failed to fetch data", { variant: "error" });
    } finally {
      isLoading(false);
    }
  }, [snackbar]);

  const fetchBookedEvents = useCallback(async () => {
    isLoading(true);
    try {
      const res = await subService.getBookedEvents();
      if (res.status && res.data) {
        console.log("EventResponse", res);
        setEvents(res.data.data);
      } else {
        console.log("Error_Events", res);
        snackbar("Failed to fetch data", { variant: "error" });
      }
    } catch (error) {
      console.error("Error_Events", error);
      snackbar("Failed to fetch data", { variant: "error" });
    } finally {
      isLoading(false);
    }
  }, [snackbar]);

  useEffect(() => {
    fetchGuidance();
    fetchBookedEvents();
  }, [fetchBookedEvents, fetchGuidance]);

  return (
    <>
      {guidance ? (
        <>
          <Grid
            container
            className="careerContainer"
            sx={{
              padding: "3.5% 5% 3.5% 5%",
              // border : '1px solid red',
              height: "max-content",
              overflow: "auto",
              backgroundColor: "#F4F4F4",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                mb: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Booked Career Guidance
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <img src={scheduled} alt="" />
                <Typography
                  sx={{
                    fontStyle: "Poppins",
                    fontSize: "14px",
                    color: "#EB568F",
                    mt: "0.1rem",
                    ml: "0.4rem",
                  }}
                >
                  Scheduled
                </Typography>
              </Box>
            </Grid>
            {guidance && (
              <Grid
                container
                sx={{
                  mt: "0.5rem",
                }}
              >
                {guidance.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    className="doctor-data"
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      height: "17vh",
                      borderRadius: "0.5rem",
                      padding: "0.8rem",
                      display: "flex",
                      mt: "1rem",
                    }}
                  >
                    {/* {console.log("Image\t\t", item)} */}
                    <Box
                      className="doctor-pic"
                      sx={{
                        height: "100%",
                        width: "12%",
                        borderRadius: "1rem",
                        background:
                          item.doctor.image === ""
                            ? `url(${doctor})`
                            : `url(${item.doctor.image})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        mr: "1.5rem",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "Poppins",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        {item?.doctor?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontStyle: "Poppins",
                          fontWeight: "600",
                          fontSize: "12px",
                          color: "#252525",
                        }}
                      >
                        {item?.doctor?.specialization.map((item) => `${item} `)}
                      </Typography>
                      <Typography
                        sx={{
                          fontStyle: "Poppins",
                          fontWeight: "600",
                          fontSize: "12px",
                          color: "#747474",
                          display: "flex",
                        }}
                      >
                        Hospital Name :
                        <Typography
                          sx={{
                            color: "#000",
                            fontStyle: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                            ml: "0.2rem",
                          }}
                        >
                          {item?.doctor?.hospital}
                        </Typography>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          sx={{
                            fontStyle: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#747474",
                            display: "flex",
                          }}
                        >
                          Date :{" "}
                          <Typography
                            sx={{
                              color: "#000",
                              fontStyle: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                              ml: "0.2rem",
                              mr: "0.5rem",
                            }}
                          >
                            {item.date}
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            fontStyle: "Poppins",
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#747474",
                            display: "flex",
                          }}
                        >
                          Time :
                          <Typography
                            sx={{
                              color: "#000",
                              fontStyle: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                              ml: "0.2rem",
                            }}
                          >
                            {item.time}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          <Box
            sx={{
              padding: "0% 5% 0rem 5%",
              backgroundColor: "#F4F4F4",
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              sx={{
                flexDirection: "column",
                backgroundColor: "#ffffff",
                padding: "1.5rem 1.5rem 3.5rem 1.5rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid
                item
                sx={{
                  width: "100%",
                  mb: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  Booked Events
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "Poppins",
                    fontWeight: "normal",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Grid>

              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <img src={events} alt="" />
                  <Typography
                    sx={{
                      fontStyle: "Poppins",
                      fontSize: "14px",
                      color: "#EB568F",
                      mt: "0.1rem",
                      ml: "0.4rem",
                    }}
                  >
                    Events
                  </Typography>
                </Box>
              </Grid>

              {events && events ? (
                <>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    {events.map((item, index) => (
                      <>
                        <Grid
                          container
                          xs={12}
                          sm={5.8}
                          sx={{
                            border: "1px solid #D7D7D7",
                            height: "7rem",
                            mt: "1.5rem",
                          }}
                        >
                          <Grid
                            item
                            className="events_box"
                            sx={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <Box
                              sx={{
                                width: "50%",
                                height: "100%",
                                background: item.image
                                  ? `url(${item.image})`
                                  : `url(${booked1})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                "@media (max-width: 576px)": {
                                  width: "100%",
                                },
                              }}
                            ></Box>
                            <Box
                              sx={{
                                padding: "1rem",
                                width: "100%",
                                // border: "1px solid red",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "8px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Medical
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "8px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  jan 22 2023
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontStyle: "Poppins",
                                  }}
                                >
                                  Lorem ipsum dolor sit amet, <br />
                                  consectetur adipiscing
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "#EB568F",
                                    fontWeight: "600",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Booked
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#252525",
                      }}
                    >
                      There are no booked events
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <FileLoader />
        </>
      )}
    </>
  );
};

export default UserCareer;
