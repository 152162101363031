import React, { useCallback, useEffect, useState } from "react";
import { Dialog, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import CustomButton from "../common/custom-buttons/CustomButton";
import PermissionListCard from "../common/permission-list-card/PermissionListCard";
import Loader from "../Loader";
import Autocomplete from "@mui/material/Autocomplete";
import { recordService, userService } from "../../service/index";
import {
  RECORD_PERMISSIONS_TYPES,
  RECORD_PERMISSIONS,
} from "../../utils/constants";

const ShareRecord = ({ data, open, onClose }) => {
  // const theme = useTheme();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [sharingData, setSharingData] = useState([]);
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);


  const shareRecord = () => {
    const match = (sharingData || []).find((d) => d.email === email);
    if (match) {
      snackbar("Email already exists", { variant: "error" });
      return;
    }

    const payload = {
      id: data._id,
      email,
      permission,
    };
    recordService.shareRecord(payload).then((res) => {
      if (!res.status) {
        snackbar(res.message || "Something went wrong", { variant: "error" });
        return;
      }

      snackbar("Resource shared", { variant: "success" });
      fetchSharingData();
    });
  };

  const fetchSharingData = useCallback(() => {
    setLoading(true);
    if (!data?._id) {
      return;
    }

    recordService.fetchSharingData(data._id).then((res) => {
      setLoading(true);
      if (!res.status || !res.data) {
        return;
      }

      setSharingData(res.data);
      setLoading(false);
    });
  }, [data?._id]);

  useEffect(() => {
    const fetchData = async () => {
      if (open && data?._id) {
        await fetchSharingData();
        setPermission(RECORD_PERMISSIONS_TYPES.EDIT);
      }
    };

    fetchData();
  }, [open, data?._id, fetchSharingData]);

  const fetchUsers = async () => {
    const resUsers = await userService.getAllUsers();
    if (!resUsers.status || !resUsers.data) {
      console.log(resUsers.message);
      return;
    }
    setUsersData(resUsers.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="share-record"
      aria-describedby="share-record"
      maxWidth="sm"
      fullWidth
    >
      <Box
        sx={{
          padding: "24px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "22px",
            marginBottom: "18px",
            // color: theme.colors?.primary.default,
          }}
        >
          Share {data.name}
        </Typography>
        {loading ? (
          <Loader isResponsive />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Autocomplete
                options={usersData}
                getOptionLabel={(option) => option.email}
                value={usersData.find((ud) => ud.email === email)}
                onChange={(event, newValue) => {
                  newValue && setEmail(newValue.email);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Email" />
                )}
                fullWidth
                noOptionsText={"Email not exist!"}
              />
              <Select
                labelId="permission-select-label"
                id="permission-select"
                value={permission}
                onChange={(e) => {
                  setPermission(e.target.value);
                }}
                sx={{
                  width: "165px",
                  marginLeft: "10px",
                  textTransform: "capitalize",
                }}
              >
                {RECORD_PERMISSIONS.map((permission, i) => (
                  <MenuItem
                    value={permission}
                    key={i}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {permission}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  // color: theme.colors?.darkGreyText.default,
                  margin: "20px 0",
                }}
              >
                People with access
              </Typography>

              <Box
                pb={1.5}
                sx={{
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {sharingData.map((data, i) => (
                  <PermissionListCard
                    data={data}
                    refresh={fetchSharingData}
                    key={i}
                  />
                ))}
              </Box>
            </Box>{" "}
          </>
        )}
        <Box
          sx={{
            textAlign: "right",
            marginTop: "10px",
          }}
        >
          <CustomButton onClick={shareRecord} disabled={!email || !permission}>
            Submit
          </CustomButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ShareRecord;
