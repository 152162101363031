const libraryService = (httpClient) => {
  return {
    getLibraries: async () => {
      try {
        const res = await httpClient.get("/get/library");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch libraries" };
      }
    },
    getLibraryById: async (pid) => {
      try {
        const res = await httpClient.get(`/get/lib/${pid}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch library" };
      }
    },
  };
};

export default libraryService;
