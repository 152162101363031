import axios from "axios";
import {
  clearSessionStorage,
  clearStorage,
  getAuthStorage,
} from "../utils/storage-utils";
import { apiConfig } from "../config/api-config";

// Importing services
import auth from "./auth-service";
import record from "./records-service";
import user from "./user-service";
import program from "./program-service";
import doctor from "./doctor-service";
import supervisor from "./supervisor-service";
import certificate from "./certificate-service";
import subscribe from "./subscripton-service"; // Corrected spelling from "subscripton-service"
import events from "./event-service";
import library from "./library-service";
import news from "./news-service";
import site from "./site-service";
import zoho from "./zoho-service";
import ticket from "./ticket-service";
import assessment from "./assessment-service";
import reflection from "./reflection-service";
import form from "./form-service";
import maincurriculum from "./maincurriculum-service";
import appraisal from "./appraisal-service";
// Create axios instance
const httpClient = axios.create(apiConfig);

// Request interceptor
httpClient.interceptors.request.use(async (config) => {
  if (config.headers) {
    config.headers["x-domain-host"] = window.location.hostname || "";
  }

  const authData = getAuthStorage();
  if (authData && config.headers) {
    config.headers.Authorization = `Bearer ${authData.token}`;
  }

  if (!config.params) {
    config.params = {};
  }

  config.params["cache-invalidate"] = Date.now();
  return config;
});

// Logout function
const logout = () => {
  clearStorage();
  clearSessionStorage();
  window.location.href = `/home`;
};

// Response interceptor
httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === "Network Error") {
      return Promise.resolve(); // Suppress the error by returning a resolved Promise.
    }

    if (error.request.responseURL.includes("/api/sign-in")) {
      return Promise.resolve(); // Suppress the error for sign-in requests.
    }

    if (
      error.response.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      logout();
      return Promise.resolve(); // Suppress the error for unauthorized requests.
    }

    if (
      error.response.status === 403 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      logout();
      return Promise.resolve(); // Suppress the error for forbidden requests.
    }

    return Promise.reject(error); // Re-throw the error if none of the conditions match.
  }
);

// Instantiate services
const authService = auth(httpClient);
const recordService = record(httpClient);
const userService = user(httpClient);
const programService = program(httpClient);
const doctorService = doctor(httpClient);
const supervisorService = supervisor(httpClient);
const certificateService = certificate(httpClient);
const ticketService = ticket(httpClient);
const formService = form(httpClient);
const subService = subscribe(httpClient);
const eventService = events(httpClient);
const libraryService = library(httpClient);
const newsService = news(httpClient);
const siteService = site(httpClient);
const zohoService = zoho(httpClient);
const maincurriculumService = maincurriculum(httpClient);
const assessmentService = assessment(httpClient);
const reflectionService = reflection(httpClient);
const appraisalService = appraisal(httpClient);

// Export services
export {
  authService,
  userService,
  recordService,
  programService,
  doctorService,
  subService,
  eventService,
  libraryService,
  newsService,
  siteService,
  zohoService,
  supervisorService,
  certificateService,
  ticketService,
  assessmentService,
  formService,
  maincurriculumService,
  reflectionService,
  appraisalService,
};
