import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import AddPostIco from "../../images/addPostIcon.svg";
import ticketIco from "../../images/ticketBtnIcon.svg";
import incompTicket from "../../images/incompleteTicket.svg";
import { FormControl, Select, MenuItem } from "@mui/material";
import ticketIcon from "../../images/ticketIcon.svg";
import RefreshIcon from "../../images/refreshIcon.svg";
import { MdDelete } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import { IoCopy } from "react-icons/io5";
import IncompleteICon from "../../images/incompleteIconsvg.svg";
import PropTypes from "prop-types";
import { ticketService } from "../../service/index";
import CreateTicket from "../../components/createticket/CreateTicket";
function Tickets() {
  const options = [
    { value: "post1", label: "Post 1" },
    { value: "post2", label: "Post 2" },
    { value: "year2022", label: "2022" },
    { value: "year2023", label: "2023" },
  ];
  const options2 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];

  const [selectedValue, setSelectedValue] = React.useState(""); // selection box
  const [selectedPage, setSelectedPage] = React.useState("1"); // selection box

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangePage = (event) => {
    setSelectedPage(event.target.value);
  };

  const [selected, setSelected] = useState("tickets");

  const ArrowIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "1rem", width: "3rem", cursor: "pointer" }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#3F3F3F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ArrowIconColored = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "0.5rem", width: "6rem", cursor: "pointer" }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#2C2B83"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  const handleOpen = () => {
    // console.log("open....",selectRef)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    console.log("refresh....");
  };

  //---------------------------------------------TICKETS-----------------------------------------------------------------//

  const TicketModalComponent = ({ selectedTicket, handleClose }) => {
    console.log("Modal Selected Ticket:", selectedTicket);
    return (
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "2rem",
          padding: "1.5rem",
          width: {
            lg: 480,
            md: "90vw",
          },
          height: {
            lg: 450,
            md: "90vh",
          },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box>
          <img
            src={selected === "tickets" ? ticketIcon : IncompleteICon}
            alt="no"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            mt: "1rem",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#525252",
                fontSize: "12px",
              }}
            >
              Created Date
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#101010",
                fontSize: "14px",
              }}
            >
              {selectedTicket.createdDate}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#525252",
                fontSize: "12px",
              }}
            >
              Expiry Date
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#101010",
                fontSize: "14px",
              }}
            >
              {selectedTicket.expiryDate}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: "1rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Assessor
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.assessorName}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            mt: "1rem",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#525252",
                fontSize: "12px",
              }}
            >
              Assessor Email
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#101010",
                fontSize: "14px",
              }}
            >
              {selectedTicket.assessorEmail}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#525252",
                fontSize: "12px",
              }}
            >
              Login Code
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                color: "#101010",
                fontSize: "14px",
              }}
            >
              {selectedTicket.loginCode}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "1rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Form / Procedure / Curriculum Item
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.fpci}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: "#E6E6FE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "18%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "600",
              color: "#2C2B83",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            Close
          </Typography>
        </Box>
      </Box>
    );
  };

  const TicketsComponent = () => {
    const [ticketModalOpen, setTicketModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [ticketData, setTicketData] = useState([]);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationType, setConfirmationType] = useState("");

    useEffect(() => {
      const fetchTickets = async () => {
        const response = await ticketService.fetchTickets();
        console.log("Fetch tickets response:", response);
        if (response.status) {
          let filteredTickets = response.data;
          if (selected === "tickets") {
            filteredTickets = filteredTickets.filter(
              (ticket) => ticket.expiry !== 0
            );
          } else if (selected === "incomplete") {
            filteredTickets = filteredTickets.filter(
              (ticket) => ticket.expiry === 0
            );
          }
          setTicketData(filteredTickets);
        } else {
          console.error(response.message);
        }
      };

      fetchTickets();
    }, [selected]);

    const handleSelectTicket = async (ticket) => {
      try {
        const response = await ticketService.fetchTicketDetails(ticket._id);
        console.log("Fetched ticket details:", response); // Check if response is correct
        if (response.status) {
          setSelectedTicket(response.data);
          setTicketModalOpen(true);
        } else {
          console.error(response.message);
          // You can show an error message to the user here
          alert("Failed to fetch ticket details. Please try again later.");
        }
      } catch (error) {
        console.error("Error fetching ticket details:", error);
        // You can show an error message to the user here
        alert("An unexpected error occurred. Please try again later.");
      }
    };

    const handleCloseModal = () => {
      setTicketModalOpen(false);
      setSelectedTicket(null);
    };

    const handleOpenConfirmationModal = (type, item) => {
      setConfirmationType(type);
      setConfirmationModalOpen(true);
    };

    const handleCloseConfirmationModal = () => {
      setConfirmationModalOpen(false);
    };

    const handleConfirmAction = async () => {
      if (confirmationType === "delete" && selectedTicket) {
        const response = await ticketService.deleteTicket(selectedTicket._id);
        console.log("Delete ticket response:", response);
        if (response.status) {
          setTicketData(
            ticketData.filter((ticket) => ticket._id !== selectedTicket._id)
          );
          setSelectedTicket(null);
        } else {
          console.error(response.message);
        }
      } else if (confirmationType === "sendReminder" && selectedTicket) {
        console.log("Sending reminder for ticket:", selectedTicket);
        // Implement send reminder functionality
        setSelectedTicket(null);
      }
      handleCloseConfirmationModal();
    };

    const handleDelete = (item) => {
      setSelectedTicket(item);
      handleOpenConfirmationModal("delete", item);
    };

    const handleSendReminder = (item) => {
      setSelectedTicket(item);
      handleOpenConfirmationModal("sendReminder", item);
    };

    const handleCopy = (item) => {
      console.log("Copying ticket:", item);
      // Implement copy functionality
    };

    return (
      <Grid
        container
        sx={{
          mt: "1rem",
          justifyContent: "space-between",
        }}
      >
        {ticketData &&
          ticketData.map((item) => (
            <Grid
              item
              key={item._id}
              lg={5.9}
              md={12}
              mt={2}
              sx={{
                border: "0.5px solid #AAAAAA",
                borderRadius: "0.5rem",
                backgroundColor: "#ffffff",
                padding: "1rem",
                cursor: "pointer",
                "&:hover": {
                  border: "1px solid #2C2B83",
                  boxShadow: "0px 4px 4px 0px rgba(44, 43, 131, 0.25)",
                },
              }}
              onClick={() => handleSelectTicket(item)}
            >
              <Box>
                <img src={ticketIcon} alt="Ticket Icon" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85%",
                  mt: "1rem",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      color: "#525252",
                      fontSize: "12px",
                    }}
                  >
                    Expiry
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      color: "#101010",
                      fontSize: "14px",
                    }}
                  >
                    {item.expiry}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      color: "#525252",
                      fontSize: "12px",
                    }}
                  >
                    Ticketed Items
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      color: "#101010",
                      fontSize: "14px",
                    }}
                  >
                    {item.ticketedItem || "No items available"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    color: "#525252",
                    fontSize: "12px",
                  }}
                >
                  Assessor
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    color: "#101010",
                    fontSize: "14px",
                  }}
                >
                  {item.assessor}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "1.5rem",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "12.52px",
                    fontStyle: "Poppins",
                    fontWeight: "600",
                    color: "#E32222",
                    backgroundColor: "#FFECEC",
                    borderRadius: "1.5rem",
                    mr: "0.5rem",
                    "&:hover": {
                      backgroundColor: "#E32222",
                      color: "#ffffff",
                    },
                  }}
                  onClick={(e) => {
                    handleDelete(item);
                    e.stopPropagation();
                  }}
                >
                  <MdDelete size={"16px"} style={{ marginRight: "0.2rem" }} />
                  Delete
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "12.52px",
                    fontStyle: "Poppins",
                    fontWeight: "600",
                    color: "#2C2B83",
                    backgroundColor: "#E5E4FF",
                    borderRadius: "1.5rem",
                    mr: "0.5rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                      color: "#ffffff",
                    },
                  }}
                  onClick={(e) => {
                    handleSendReminder(item);
                    e.stopPropagation();
                  }}
                >
                  <HiMail size={"16px"} style={{ marginRight: "0.2rem" }} />
                  Send Reminder
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "12.52px",
                    fontStyle: "Poppins",
                    fontWeight: "600",
                    color: "#2C2B83",
                    backgroundColor: "#E5E4FF",
                    borderRadius: "1.5rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                      color: "#ffffff",
                    },
                  }}
                  onClick={(e) => {
                    handleCopy(item);
                    e.stopPropagation();
                  }}
                >
                  <IoCopy size={"16px"} style={{ marginRight: "0.2rem" }} />
                  Copy
                </Button>
              </Box>
            </Grid>
          ))}
        {confirmationModalOpen && (
          <Modal
            open={confirmationModalOpen}
            onClose={handleCloseConfirmationModal}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
            sx={{
              display: "flex",
              zIndex: 99999,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: 400,
                p: 4,
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                id="confirmation-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2 }}
              >
                {confirmationType === "delete"
                  ? "Confirm Delete"
                  : "Confirm Send Reminder"}
              </Typography>
              <Typography id="confirmation-modal-description" sx={{ mb: 2 }}>
                {confirmationType === "delete"
                  ? "Are you sure you want to delete this ticket?"
                  : "Are you sure you want to send a reminder?"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCloseConfirmationModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmAction}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        )}
        {selectedTicket && (
          <Modal
            open={ticketModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              zIndex: 99999,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TicketModalComponent
              selectedTicket={selectedTicket}
              handleClose={handleCloseModal}
            />
          </Modal>
        )}
      </Grid>
    );
  };

  //-------------------------------------------------CREATE TICKET--------------------------------------------------------//

  const [createTicket, OpenCreateTicket] = useState(false);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        padding: "2.5rem",
      }}
    >
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#525252",
              letterSpacing: "0.8px",
            }}
          >
            Tickets
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#252525",
              letterSpacing: "0.8px",
            }}
          >
            {selected === "tickets" ? "Ticket Requests" : "Incomplete Tickets"}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Grid
        container
        sx={{
          mt: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#ffffff",
              backgroundColor: "#2C2B83",
              borderRadius: "1rem",
              transition: "scale 0.1s ease-in",
              mr: "0.5rem",
              "&:hover": {
                scale: "0.9",
                backgroundColor: "#2C2B83",
              },
            }}
            onClick={() => OpenCreateTicket(true)}
          >
            <img src={AddPostIco} alt="no" style={{ marginRight: "0.5rem" }} />
            Create Ticket
          </Button>
          <Button
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#2C2B83",
              backgroundColor: "#E5E4FF",
              borderRadius: "1rem",
              transition: "scale 0.1s ease-in",
              border: selected === "tickets" ? "1px solid #2C2B83" : "none",
              mr: "0.5rem",
              "&:hover": {
                scale: "0.9",
                backgroundColor: "#E5E4FF",
                color: "#2C2B83",
              },
            }}
            onClick={() => setSelected("tickets")}
          >
            <img src={ticketIco} alt="no" style={{ marginRight: "0.5rem" }} />
            Tickets
          </Button>
          <Button
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#2C2B83",
              backgroundColor: "#E5E4FF",
              borderRadius: "1rem",
              transition: "scale 0.1s ease-in",
              border: selected === "incomplete" ? "1px solid #2C2B83" : "none",
              // mr: '0.5rem',
              "&:hover": {
                scale: "0.9",
                backgroundColor: "#E5E4FF",
                color: "#2C2B83",
              },
            }}
            onClick={() => setSelected("incomplete")}
          >
            <img
              src={incompTicket}
              alt="no"
              style={{ marginRight: "0.5rem" }}
            />
            Incomplete Tickets
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <FormControl
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "1.5rem",
              overflow: "hidden",
              border: "none", // Remove border from the FormControl
              height: "2.4rem",
              width: "50%",
              mr: "0.5rem",
            }}
          >
            <Select
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              input={<OutlinedInput />}
              IconComponent={ArrowIcon}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: "1.5rem",
                    marginTop: "0.8rem", // Adjust as needed
                  },
                },
              }}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "1.5rem",
                "&:focus": {
                  borderRadius: "1.5rem", // Match the focus border radius
                  backgroundColor: "#ffffff",
                },
                "& .MuiSelect-icon": {
                  color: "currentColor", // Match the icon color with text color
                },
                "& .MuiInputBase-root": {
                  borderRadius: "1.5rem",
                  border: "none", // Remove border from the Select component
                  "&:hover": {
                    borderRadius: "1.5rem",
                    backgroundColor: "#f0f0f0", // Match hover background with MenuItem hover
                  },
                },
                height: "100%",
              }}
            >
              <MenuItem disabled value="">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    color: "#252525",
                    fontWeight: "normal",
                  }}
                >
                  Select Your Post (or) Year
                </span>
              </MenuItem>
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ borderRadius: "1.5rem" }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              backgroundColor: "#E5E4FF",
              borderRadius: "1.5rem",
              overflow: "hidden",
              border: "none", // Remove border from the FormControl
              height: "2.4rem",
              width: "6.5rem",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Select
              ref={selectRef}
              value={selectedPage}
              onChange={handleChangePage}
              displayEmpty
              input={<OutlinedInput />}
              IconComponent={ArrowIconColored}
              open={open}
              onClose={handleClose}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: "1.5rem",
                    marginTop: "0.8rem", // Adjust as needed
                  },
                },
              }}
              sx={{
                backgroundColor: "#E5E4FF",
                borderRadius: "1.5rem",
                "&:focus": {
                  borderRadius: "1.5rem", // Match the focus border radius
                  backgroundColor: "#E5E4FF",
                },
                "& .MuiSelect-icon": {
                  color: "#2C2B83", // Match the icon color with text color
                },
                "& .MuiInputBase-root": {
                  borderRadius: "1.5rem",
                  border: "none", // Remove border from the Select component
                  "&:hover": {
                    borderRadius: "1.5rem",
                    backgroundColor: "#f0f0f0", // Match hover background with MenuItem hover
                  },
                },
                height: "100%",
              }}
            >
              {options2.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ borderRadius: "1.5rem", justifyContent: "center" }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                position: "absolute",
                alignItems: "center",
              }}
              onClick={handleOpen}
            >
              <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  ml: "0.5rem",
                  mr: "0.5rem",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Poppins",
                  color: "#2C2B83",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {selectedPage}
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  color: "#2C2B83",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                Page
              </Typography>
            </Box>
          </FormControl>

          <Button
            sx={{
              borderRadius: "50%",
              width: 40,
              height: 40,
              minWidth: 0,
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E5E4FF",
              ml: "1rem",
              "&:hover": {
                border: "1px solid #2C2B83",
                backgroundColor: "#E5E4FF",
              },
            }}
            onClick={handleRefresh}
          >
            <img src={RefreshIcon} alt="no" />
          </Button>
        </Grid>
      </Grid>

      {selected === "tickets" && <TicketsComponent />}
      {selected === "incomplete" && <TicketsComponent />}

      <Modal
        open={createTicket}
        onClose={() => OpenCreateTicket(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CreateTicket handleClose={() => OpenCreateTicket(false)} />
      </Modal>
    </Box>
  );
}

export default Tickets;
