import dashinactive from "../images/dashboard-ico.svg";
import dashactive from "../images/dashboard-ico2.svg";
import profileactive from "../images/profile-ico2.svg";
import profileinactive from "../images/profile-ico.svg";
import fileactive from "../images/files-ico2.svg";
import fileinactive from "../images/files-ico.svg";
import careeractive from "../images/career-ico2.svg";
import careerinactive from "../images/career-ico.svg";
import clinicalactive from "../images/hospital-ico2.svg";
import clinicalinactive from "../images/hospital-ico.svg";
import libraryactive from "../images/archivebook-ico2.svg";
import libraryinactive from "../images/archivebook-ico.svg";
import ticketInactive from "../images/ticketInactive.svg";
import ticketActive from "../images/ticketActive.svg";
import myFormActive from "../images/myFormIcoInactive.svg";
import myFormInactive from "../images/myFormIco.svg";
import curriculumActive from "../images/curriculumIconInactive.svg";
import curriculumInactive from "../images/curriculumIcon.svg";
import assessmentActive from "../images/assessmentIconActive.svg";
import assessmentIconInactive from "../images/assessmentIcon.svg";
import reflectionActive from "../images/reflectionActive.svg";
import reflectionInactive from "../images/reflectionInactive.svg";
import appraisalActive from "../images/appraisalActive.svg";
import appraisalInactive from "../images/appraisalInactive.svg";
import progressLogActive from "../images/progressLogActive.svg"
import progressLogInactive from "../images/progressLogInactive.svg";
import elearningActive from "../images/eLearningIconInactive.svg";
import elearningInactive from "../images/eLearningIcon.svg"






const sideBarOptions = [
  {
    title: "Dashboard",
    inactive: dashinactive,
    active: dashactive,
    url: "/dashboardT",
    name: "dashboard",
  },
  {
    title: "Profile",
    url: "/user/profile",
    inactive: profileinactive,
    active: profileactive,
    name: "profile",
  },
  {
    title: "Profile",
    url: "/user/clinical/profile",
    inactive: profileinactive,
    active: profileactive,
    name: "clinical-profile",
  },
  {
    title: "My Files",
    url: "/user/files",
    inactive: fileinactive,
    active: fileactive,
    name: "files",
  },
  {
    title: "Career Guidance",
    inactive: careerinactive,
    active: careeractive,
    url: "/user/career",
    name: "career",
  },
  {
    title: "Clinical Programs",
    inactive: clinicalinactive,
    active: clinicalactive,
    url: "/user/clinical",
    name: "programs",
  },
  {
    title: "My Library",
    inactive: libraryinactive,
    active: libraryactive,
    url: "/user/library",
    name: "library",
  },
  {
    title: "Tickets",
    inactive: ticketInactive,
    active: ticketActive,
    url: "/user/clinical/tickets",
    name: "tickets",
  },
  {
    title: "My Forms",
    inactive: myFormInactive,
    active: myFormActive,
    url: "/user/clinical/myforms",
    name: "myforms",
  },
  {
    title: "Curriculum",
    inactive: curriculumInactive,
    active: curriculumActive,
    url: "/user/clinical/curriculum",
    name: "curriculum",
  },
  {
    title: "Assessment",
    active: assessmentActive,
    inactive: assessmentIconInactive,
    url: "/user/clinical/assessment",
    name: "assessment",
  },
  {
    title: "Reflection",
    active: reflectionActive,
    inactive: reflectionInactive,
    url: "/user/clinical/reflection",
    name: "reflection",
  },
  {
    title: "Appraisal",
    inactive: appraisalInactive,
    active: appraisalActive,
    url: "/user/clinical/appraisal",
    name: "appraisal",
  },
  {
    title: "Progresslog",
    active: progressLogActive,
    inactive: progressLogInactive,
    url: "/user/clinical/progresslog",
    name: "progresslog",
  },
  {
    title: "E-learning",
    inactive: elearningInactive,
    active: elearningActive,
    url: "/user/clinical/elearning",
    name: "elearning",
  },
];

const pages = [
  { title: "Home", url: "/home", name: "home" },
  // { title: "About", url: "/about", name: "about" },
  { title: "Solutions", url: "/solutions", name: "solutions" },
  { title: "Clinical Programs", url: "/clinical", name: "clinical" },
  { title: "Career Guidance", url: "/career", name: "career" },
];

const superAdminMenuItems = [""];
const adminMenuItems = [""];

const userMenuItems = [
  "dashboard",
  "profile",
  "clinical-profile",
  "tickets",
  "myforms",
  "curriculum",
  "assessment",
  "reflection",
  "appraisal",
  "progresslog",
  "elearning",
  "my-files",
  "career-duidance",
  "clinical-programs",
  "my-library",
  "logout",
];

const getTitle = (location) =>
  sideBarOptions.find((d) =>
    location.pathname.toLowerCase().includes(d.url.toLowerCase())
  )?.title || "dashboard";

const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
};
const ROLE_TYPES = {
  super_admin: "Super Admin",
  admin: "Admin",
  user: "User",
};

const SUPER_ADMIN_ROLE = "super_admin";
const ADMIN_ROLE = "admin";
const USER_ROLE = "user";

const RECORD_PERMISSIONS_TYPES = {
  VIEW: "view",
  EDIT: "edit",
  OWNER: "owner",
};
const RECORD_PERMISSIONS = [
  RECORD_PERMISSIONS_TYPES.VIEW,
  RECORD_PERMISSIONS_TYPES.EDIT,
];
const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];

const infoFormFields = [
  {
    label: "Name",
    name: "name",
    type: "text",
  },
  {
    label: "GMC Number",
    name: "gmcRefNumber",
    type: "text",
  },
  {
    label: "College Number",
    name: "collegeNumber",
    type: "text",
  },
  {
    label: "National Training Number",
    name: "nationalTrainingNumber",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },
  {
    label: "JRCPTB Enrolled",
    name: "jrcptbEnrolled",
    type: "selection",
    options: ["Yes", "No"],
  },
];

const addressFormFields = [
  {
    label: "Country",
    name: "address.country",
    type: "text",
  },
  {
    label: "City",
    name: "address.city",
    type: "text",
  },
  {
    label: "State / Province",
    name: "address.state",
    type: "text",
  },
  {
    label: "Postal Code",
    name: "address.postalCode",
    type: "text",
  },
];

export {
  sideBarOptions,
  superAdminMenuItems,
  adminMenuItems,
  userMenuItems,
  getTitle,
  ROLES,
  ROLE_TYPES,
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  USER_ROLE,
  RECORD_PERMISSIONS_TYPES,
  RECORD_PERMISSIONS,
  imageExtensions,
  pages,
  infoFormFields,
  addressFormFields,
};

export const convertToIST = (inputDate) => {
  const date = new Date(inputDate);
  date.setUTCHours(date.getUTCHours() + 5, date.getUTCMinutes() + 30);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
