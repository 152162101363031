import React, { useEffect, useState, useCallback } from "react";
import { userService } from "../../service/index";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
import { useParams } from "react-router-dom";
import "./profile.css";
import { Button } from "@mui/material";
import big from "../../images/bigprofile.jpg";
import close from "../../images/close-icon.svg";
import edit from "../../images/edit-ico.svg";
import { Modal, Box, Typography, TextField } from "@mui/material/";
import { useNavigate } from "react-router-dom";
// import Form from "../../components/form/form";
// import { infoFormFields, addressFormFields } from "../../utils/constants";
import ProfileUpload from "../../components/profile-upload/profile-upload";
import Divider from '@mui/material/Divider';

const Profile = () => {
  //   let file = null;

  // const [file, setFile] = useState(null);
  const { id } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [personal, openPersonal] = useState(true);
  const [communication, openCommunication] = useState(false);
  const [trigger, setTrigger] = useState(false);
  // const [editPic, openEditPic] = useState(false);
  // const [editInfo, openEditInfo] = useState(false);
  // const [editAddress, openEditAddress] = useState(false);
  const navigate = useNavigate();
  const [modal, openModal] = useState(false);
  // const [avatar, setAvatar] = useState(null);
  // const [submitting, setSubmitting] = useState(false);
  const [editCom, setEditCom] = useState(false);
  // const fileInputRef = useRef(null);

  const [facebookLink, setFacebookLink] = useState('');
  const [linkedinLink, setLinkedinLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');

  const handleEditCom = () => {
    setEditCom(!editCom);
  }

  const handleFileSelected = (event) => {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    // const selectedFile = event.target.files[0];
    // setFile(selectedFile); // Update the selected file using useState
    // setAvatar(URL.createObjectURL(selectedFile)); // Update the avatar image
  };

  // const handleFileButtonClick = () => {
  //   fileInputRef.current?.click();
  // };

  // const handleUpload = () => {
  //   setSubmitting(true);
  //   const data = new FormData();
  //   data.append("userImage", file);
  //   console.log("UploadData\t\t\t", file);

  //   userService.updateUser(data, id).then((res) => {
  //     setSubmitting(false);
  //     console.log("UploadREs\t\t\t", res);
  //     if (!res.status) {
  //       snackbar("Upload failed", { variant: "error" });
  //       return;
  //     }
  //     snackbar("Upload Successful", { variant: "success" });
  //     handleProfileChange();
  //   });
  // };

  // const handleProfileChange = async () => {
  //   // await userService.refreshToken(id).then((res) => {
  //   //   if (!res.status || !res.data) {
  //   //     setIsLoading(false);
  //   //     snackbar("Upload Failed, Please login again", { variant: "error" });
  //   //     return;
  //   //   }
  //   //   console.log("Profile_Data\t\t\t", res.data);
  //   //   setIsLoading(false);
  //   // });
  // };

  const handleClose = () => {
    // openEditAddress(false);
    // openEditInfo(false);
    // openEditPic(false);
    openModal(false);
    setTrigger(!trigger);
  };

  const getUserData = useCallback(async (userId) => {
    setIsLoading(true);
    await userService.getSingle(userId).then((res) => {
      if (!res.status || !res.data) {
        setIsLoading(false);
        snackbar("Could not load resources", { variant: "error" });
        return;
      }
      setUser(res.data);
      setFacebookLink(res.data.facebookUrl);
      setTwitterLink(res.data.twitterUrl);
      setLinkedinLink(res.data.linkedinUrl);
      console.log("CurrentUSer\t\t\t", res.data);
      setIsLoading(false);
    });
  }, [snackbar]);

  useEffect(() => {
    async function fetchData() {
      await getUserData(id);
    }
    fetchData();
    // const refreshPageOnBack = () => {
    //   console.log("refresh")
    //   window.location.reload();
    // };

    // window.addEventListener("popstate", refreshPageOnBack);

    // return () => {
    //   // Clean up the event listener when the component unmounts
    //   window.removeEventListener("popstate", refreshPageOnBack);
    // };
  }, [id, trigger, getUserData]);



  if (isLoading) {
    return <FileLoader />;
  }

  const handlePersonalClick = () => {
    openCommunication(false);
    openPersonal(true);
  };

  const handleComClick = () => {
    openPersonal(false);
    openCommunication(true);
  };

  // const handleSubmit = async (formData) => {
  //   handleClose();
  //   setIsLoading(true);
  //   await userService.updateUser(formData, id).then((res) => {
  //     console.log("Update\t\t\t", res);
  //     if (!res.status) {
  //       console.log("HERERERERE");
  //       setIsLoading(false);
  //       snackbar(res.message, { variant: "error" });
  //       return;
  //     }
  //     setTrigger(!trigger);
  //     snackbar("Update was successfull", { variant: "success" });
  //     setIsLoading(false);
  //   });
  // };

  const handleCommSubmit = async () => {
    setIsLoading(true);
    let data = {
      linkedinUrl: linkedinLink,
      facebookUrl: facebookLink,
      twitterUrl: twitterLink,
    }
    await userService.updateUser(data, id).then((res) => {
      console.log("Update\t\t\t", res);
      if (!res.status) {
        console.log("HERERERERE");
        setIsLoading(false);
        snackbar(res.message, { variant: "error" });
        return;
      }
      setTrigger(!trigger);
      handleEditCom();
      snackbar("Update was successfull", { variant: "success" });
      setIsLoading(false);
    });
  };

  const toggleModal = async () => {
    openModal(!modal);
  };

  return (
    <>
      <div className="container" style={{
        padding: '3.5rem 5rem 3.5rem 5rem',
        backgroundColor: '#f4f4fA',
      }}>
        <div className="profile-head">
          <h1>Profile - Personal Details</h1>
          <p>
            Below are the Personal Details on file for this account in
            eortfolio. To update these details, click on the "Edit Details"
            button below Personal Details
          </p>
        </div>
        <div className="profile-content">
          <div className="profile-btns">
            <Button
              sx={{
                border: "none",
                borderRadius: "2rem",
                backgroundColor: personal ? "#E5E4FF" : "#E3E3E3",
                mt: "0.8rem",
                width: "9rem",
                color: personal ? "#2C2B83" : "#434343",
                fontSize: "0.7rem",
                padding: "0.8rem",
                fontWeight: 600,
                textTransform: "none",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={handlePersonalClick}
            >
              Personal Details
            </Button>
            <Button
              sx={{
                border: "none",
                backgroundColor: communication ? "#E5E4FF" : "#E3E3E3",
                borderRadius: "2rem",
                mt: "0.8rem",
                width: "auto",
                color: communication ? "#2C2B83" : "#434343",
                fontSize: "0.7rem",
                padding: "0.8rem",
                fontWeight: 700,
                textTransform: "none",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={handleComClick}
            >
              Communication Preferences
            </Button>
          </div>
          {personal && (
            <div className="personal">
              <div className="profile-sec profile-sec-1">
                <div className="profile-user dflex">
                  <Box
                    sx={{
                      width: "4rem",
                      height: "4rem",
                      border: "1px solid #AFAFAF",
                      borderRadius: "50%",
                      // background: `url(${apiConfig.imageUrl}${user?.image})`,
                      background: user?.imageUrl ? `url(${user.imageUrl})` : `url(${big})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      mr: "1rem",
                      // mt: "-0.5rem",
                    }}
                  >
                    {/* <img src={big} alt="" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                  </Box>
                  <div className="user">
                    <h5>{user?.name}</h5>
                    <h6>{user?.status}</h6>
                    <h6>{user?.address?.city}</h6>
                  </div>
                </div>
                <div className="profile-edit">
                  <Button
                    sx={{
                      width: "6rem",
                      height: "2.5rem",
                      border: "1px solid #525252",
                      borderRadius: "1.5rem",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#E5E4FF",
                        border: "1px solid #2C2B83",
                        color: "#2C2B83",
                      },
                    }}
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    Edit{" "}
                    <img
                      src={edit}
                      alt=""
                      style={{
                        maxWidth: "1rem",
                        height: "auto",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </Button>
                </div>
              </div>
              <div className="profile-sec profile-sec-2">
                <div className="profile-sec-2-head">
                  <h5>Personal Information</h5>
                  <div className="profile-edit">
                    <Button
                      sx={{
                        width: "6rem",
                        height: "2.5rem",
                        border: "1px solid #525252",
                        borderRadius: "1.5rem",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#E5E4FF",
                          border: "1px solid #2C2B83",
                          color: "#2C2B83",
                        },
                      }}
                      onClick={() => {
                        navigate(`/user/edit/${id}`);
                      }}
                    >
                      Edit{" "}
                      <img
                        src={edit}
                        alt=""
                        style={{
                          maxWidth: "1rem",
                          height: "auto",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </Button>
                  </div>
                </div>
                <div className="profile-sec-2-contents dflex">
                  <div className="sec-2-content-1">
                    <div className="sec-2-content-element">
                      <h5>Name</h5>
                      <h5>{user?.name}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>College Number</h5>
                      <h5>{user?.collegeNumber}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>JRCPTB Enrolled</h5>
                      <h5>{user?.jrcptbEnrolled}</h5>
                    </div>
                  </div>
                  <div className="sec-2-content-1">
                    <div className="sec-2-content-element">
                      <h5>GMC Number</h5>
                      <h5>{user?.gmcRefNumber}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>National Training Number</h5>
                      <h5>{user?.nationalTrainingNumber}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>Email</h5>
                      <h5>{user?.email}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-sec profile-sec-3">
                <div className="profile-sec-2-head">
                  <h5>Address</h5>
                  <div className="profile-edit">
                    <Button
                      sx={{
                        width: "6rem",
                        height: "2.5rem",
                        border: "1px solid #525252",
                        borderRadius: "1.5rem",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#E5E4FF",
                          border: "1px solid #2C2B83",
                          color: "#2C2B83",
                        },
                      }}
                      onClick={() => {
                        navigate(`/user/edit/${id}`);
                      }}
                    >
                      Edit{" "}
                      <img
                        src={edit}
                        alt=""
                        style={{
                          maxWidth: "1rem",
                          height: "auto",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </Button>
                  </div>
                </div>
                <div className="profile-sec-3-contents dflex">
                  <div className="sec-2-content-1">
                    <div className="sec-2-content-element">
                      <h5>Country</h5>
                      <h5>{user?.address?.country}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>City</h5>
                      <h5>{user?.address?.city}</h5>
                    </div>
                  </div>
                  <div className="sec-2-content-1">
                    <div className="sec-2-content-element">
                      <h5>State / Province</h5>
                      <h5>{user?.address?.state}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>Postal Code</h5>
                      <h5>{user?.address?.postalCode}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {communication && (
            <div className="communication">
              <div className="profile-sec profile-sec-4">
                <div className="profile-sec-2-head">
                  <h5 style={{ color: "#2C2B83" }}>
                    Communication Preferences
                  </h5>
                  <div className="profile-edit">
                    <Button
                      sx={{
                        width: "6rem",
                        height: "2.5rem",
                        border: "1px solid #525252",
                        borderRadius: "1.5rem",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#E5E4FF",
                          border: "1px solid #2C2B83",
                          color: "#2C2B83",
                        },
                      }}
                      onClick={() => {
                        handleEditCom();
                      }}
                    >
                      Edit{" "}
                      <img
                        src={edit}
                        alt=""
                        style={{
                          maxWidth: "1rem",
                          height: "auto",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </Button>
                  </div>
                </div>
                <div className="profile-sec-3-contents dflex">
                  <div className="sec-2-content-1">
                    <div className="sec-2-content-element">
                      <h5>LinkedIn</h5>
                      <h5>{user?.linkedinUrl}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
                      <h5 style={{ marginRight: '0.5rem' }}>Facebook</h5>
                      {/* </Box> */}

                      <h5>{user?.facebookUrl}</h5>
                    </div>
                    <div className="sec-2-content-element">
                      <h5>Twitter</h5>
                      <h5>{user?.twitterUrl}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Modal
        open={editInfo}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          mt: "4.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "50vw",
            backgroundColor: "#ffffff",
            borderRadius: "1rem",
            padding: "2.5rem",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontFamily: "Poppins",
              fontSize: "1.1rem",
              mb: "1rem",
            }}
          >
            Personal Information
          </Typography>
          <Form
            fields={infoFormFields}
            onSubmit={handleSubmit}
            user={user}
            form="personal"
          />
        </Box>
      </Modal>
      <Modal
        open={editAddress}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          mt: "4.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "50vw",
            backgroundColor: "#ffffff",
            borderRadius: "1rem",
            padding: "2.5rem",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontFamily: "Poppins",
              fontSize: "1.1rem",
              mb: "1rem",
            }}
          >
            Address
          </Typography>
          <Form
            fields={addressFormFields}
            onSubmit={handleSubmit}
            user={user}
            form="address"
          />
        </Box>
      </Modal> */}
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          mt: "4.5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{
          width: '30vw',
          // height: 'auto',
          backgroundColor: '#fff',
          borderRadius: '1.5rem',
          padding: '1.5rem 5rem 2rem 1.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Box
            sx={{
              width: "100%",
              // border: "1px solid blue",
              display: "flex",
              justifyContent: "flex-end",
              transform: 'translate(4rem,-0.5rem)'
            }}
          >
            <img
              src={close}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
          <ProfileUpload handleClose={handleClose} isModal={true} handleFileSelected={handleFileSelected} />
        </Box>
      </Modal>
      {user && <Modal
        open={editCom}
        onClose={handleEditCom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: ' flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '1.5rem',
            height: 'auto',
            width: {
              xs: '85vw',
              md: '50vw',
            },
            padding: '2.5rem',
          }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}><Typography
            sx={{
              fontWeight: '600',
              color: '#4A4A4A',
              fontFamily: 'Poppins',
              fontSize: '18px',
              mb: '1rem'
            }}
          >
              Communication Preferences
            </Typography>
            <img
              src={close}
              alt=""
              style={{ cursor: "pointer", transform: 'translateY(-1rem)' }}
              onClick={() => {
                handleEditCom();
              }}
            />

          </Box>

          <Divider />
          <Typography
            sx={{
              fontWeight: '600',
              color: '#0a66c2',
              fontFamily: 'Poppins',
              fontSize: '18px',
              mt: '1rem',
            }}
          >
            linkedIn
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={linkedinLink}
            onChange={(e) => setLinkedinLink(e.target.value)}
          />
          <Typography
            sx={{
              fontWeight: '600',
              color: '#2C2B83',
              fontFamily: 'Poppins',
              fontSize: '18px',
              mt: '1rem',
            }}
          >
            facebook
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={facebookLink}
            onChange={(e) => setFacebookLink(e.target.value)}
          />
          <Typography
            sx={{
              fontWeight: '600',
              color: '#1DA1F2',
              fontFamily: 'Poppins',
              fontSize: '18px',
              mt: '1rem',
            }}
          >
            twitter
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={twitterLink}
            onChange={(e) => setTwitterLink(e.target.value)}
          // sx={{ mb: '1rem' }}
          />
          <Box sx={{
            display: 'flex',
            width: '50%',
            mt: '1.5rem',
          }}>  <Button
            sx={{
              backgroundColor: "#EBEBEB",
              fontFamily: "Poppins",
              fontSize: "14.3px",
              fontWeight: "600",
              color: "#000",
              mr: '1.5rem',
              width: "100%",
              borderRadius: "0.5rem",
              height: "3rem",
              "&:hover": {
                backgroundColor: "#2C2B83",
                color: '#fff'
              },
            }}
            onClick={() => {
              handleEditCom();
            }}
          >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#EB568F",
                fontFamily: "Poppins",
                fontSize: "14.3px",
                fontWeight: "600",
                color: "#fff",
                width: "100%",
                borderRadius: "0.5rem",
                height: "3rem",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={() => { handleCommSubmit() }}
            >
              Submit
            </Button></Box>

        </Box>
      </Modal>}
    </>
  );
};

export default Profile;
