
const doctorService = (httpClient) => {
    return {

        getDoctorsByUser: async (id) => {
            try {
                const res = await httpClient.get(`/user/doctors/${id}`);
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch doctors" };
            }
        },

        getAllDoctors: async () => {
            try {
                const res = await httpClient.get("/public/doctors");
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch doctors" };
            }
        },

        getSingleDoctor: async (id) => {
            try {
                const res = await httpClient.get(`/doctor/${id}`);
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch doctor" };
            }
        },
    };
};

export default doctorService;
