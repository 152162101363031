import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  Paper,
  FormControl,
  OutlinedInput,
  Card,
  Grid,
  IconButton,
  CardContent,
} from "@mui/material";
import { MdArrowDropDown, MdInfoOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaTicketAlt } from "react-icons/fa";
import DescriptionIcon from "@mui/icons-material/Description";
import { AiFillInfoCircle } from "react-icons/ai";
import SuperVisorsReport from "./SuperVisorsReport";

const forms = [
  { title: "Academic Supervisor’s Report", create: false, ticket: true },
  { title: "ACAT_IMT", create: true, ticket: true },
  { title: "ARCP", create: true, ticket: false },
  { title: "Attendance at organized teaching", create: true, ticket: false },
  { title: "Academic Supervisor’s Report", create: true, ticket: true },
  { title: "ACAT_IMT", create: true, ticket: true },
  { title: "ARCP", create: true, ticket: false },
  { title: "Attendance at organized teaching", create: true, ticket: false },
];

const existingCards = [
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "24/02/2023",
    dateActivity: "27/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "25/02/2023",
    dateActivity: "28/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "26/02/2023",
    dateActivity: "29/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "27/02/2023",
    dateActivity: "30/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
];

const getColor = (value) => {
  return value === "Yes" ? "green" : "red";
};

const ProgressLog = () => {
  const [activeButton, setActiveButton] = useState("Summary Overview");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [activeTab, setActiveTab] = useState("Available Forms");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  return (
    <Box sx={{ padding: "2.5rem" }}>
      <Box display="flex" gap={2} marginBottom={2}>
        <Button
          disableElevation
          variant="contained"
          onClick={() => handleButtonClick("Summary Overview")}
          style={{
            backgroundColor:
              activeButton === "Summary Overview" ? "#2C2B83" : "white",
            color: activeButton === "Summary Overview" ? "white" : "#525252",
            borderRadius: "30px",
            textTransform: "none",
          }}
        >
          Summary Overview
        </Button>
        <Button
          disableElevation
          variant="contained"
          onClick={() => handleButtonClick("Supervisor’s Report")}
          style={{
            backgroundColor:
              activeButton === "Supervisor’s Report" ? "#2C2B83" : "white",
            color: activeButton === "Supervisor’s Report" ? "white" : "#525252",
            borderRadius: "30px",
            textTransform: "none",
          }}
        >
          Supervisor’s Report
        </Button>
        <Button
          disableElevation
          variant="contained"
          onClick={() => handleButtonClick("ARCP")}
          style={{
            backgroundColor: activeButton === "ARCP" ? "#2C2B83" : "white",
            color: activeButton === "ARCP" ? "white" : "#525252",
            borderRadius: "30px",
            textTransform: "none",
          }}
        >
          ARCP
        </Button>
      </Box>
      {activeButton === "Summary Overview" && (
        <Box>
          <Paper
            elevation={0}
            style={{
              padding: "1rem",
              backgroundColor: "white",
              borderRadius: "10px",
              border: "1px solid #D5D5D5",
            }}
          >
            <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
              <Box flex={1}>
                <Typography
                  variant="subtitle2"
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#525252",
                  }}
                  gutterBottom
                >
                  Select Year (or) Post
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "4px",
                  }}
                >
                  <Select
                    value={selectedDate}
                    onChange={handleDateChange}
                    displayEmpty
                    input={<OutlinedInput />}
                    IconComponent={MdArrowDropDown}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        color: "#7E7E7E",
                        fontSize: "12px",
                        fontWeight: 400,
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Year (or) Post</em>
                    </MenuItem>
                    <MenuItem value="date1">
                      07 Aug, 2023 to 02 Aug, 2023
                    </MenuItem>
                    <MenuItem value="date2">
                      07 Aug, 2022 to 02 Aug, 2023
                    </MenuItem>
                    <MenuItem value="date3">
                      07 Aug, 2021 to 02 Aug, 2022
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box flex={1}>
                <Typography
                  variant="subtitle2"
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#525252",
                  }}
                  gutterBottom
                >
                  Form Groups
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "4px",
                  }}
                >
                  <Select
                    value={selectedGroup}
                    onChange={handleGroupChange}
                    displayEmpty
                    input={<OutlinedInput />}
                    IconComponent={MdArrowDropDown}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        color: "#7E7E7E",
                        fontSize: "12px",
                        fontWeight: 400,
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Form Groups</em>
                    </MenuItem>
                    <MenuItem value="group1">Group 1</MenuItem>
                    <MenuItem value="group2">Group 2</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Button
                disableElevation
                variant="contained"
                style={{
                  backgroundColor: "#2C2B83",
                  color: "white",
                  borderRadius: "30px",
                  textTransform: "none",
                  marginTop: "1rem",
                }}
              >
                Search
              </Button>
            </Box>
          </Paper>
          <Box display="flex" gap={2} marginBottom={2} marginTop={2}>
            <Button
              disableElevation
              variant="contained"
              onClick={() => setActiveTab("Available Forms")}
              sx={{
                backgroundColor:
                  activeTab === "Available Forms" ? "#E5E4FF" : "white",
                color: activeTab === "Available Forms" ? "#2C2B83" : "#525252",
                borderRadius: "1rem",
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "Poppins",
                transition: "scale 0.1s ease-in",
                border:
                  activeTab === "Available Forms"
                    ? "1px solid #2C2B83"
                    : "none",
                "&:hover": {
                  scale: "0.9",
                  backgroundColor: "#E5E4FF",
                  color: "#2C2B83",
                },
              }}
            >
              Available Forms
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={() => setActiveTab("Existing Forms")}
              sx={{
                backgroundColor:
                  activeTab === "Existing Forms" ? "#E5E4FF" : "white",
                color: activeTab === "Existing Forms" ? "#2C2B83" : "#525252",
                borderRadius: "1rem",
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "Poppins",
                transition: "scale 0.1s ease-in",
                border:
                  activeTab === "Existing Forms" ? "1px solid #2C2B83" : "none",
                "&:hover": {
                  scale: "0.9",
                  backgroundColor: "#E5E4FF",
                  color: "#2C2B83",
                },
              }}
            >
              Existing Forms
            </Button>
          </Box>
          {activeTab === "Available Forms" && (
            <Grid container spacing={2} marginTop={2}>
              {forms.map((form, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    elevation={0}
                    style={{
                      padding: "1.5rem",
                      backgroundColor: "white",
                      borderRadius: "20px",
                      border: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#E4E5FF",
                          borderRadius: "50%",
                          width: 32,
                          height: 32,
                          marginRight: 2,
                        }}
                      >
                        <DescriptionIcon sx={{ color: "#8787A3" }} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#E4E5FF",
                          borderRadius: "50%",
                          width: 22,
                          height: 22,
                          marginRight: 2,
                        }}
                      >
                        <AiFillInfoCircle sx={{ color: "#2C2B83" }} />
                      </Box>
                    </Box>
                    <Box marginTop={2}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#525252",
                          marginBottom: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          mb: 4,
                        }}
                      >
                        <span>Title:</span>
                        <span style={{ color: "black", fontSize: "14px" }}>
                          {form.title}
                        </span>
                      </Typography>

                      <Button
                        disableElevation
                        variant="contained"
                        startIcon={<FaTicketAlt />}
                        sx={{
                          backgroundColor: form.ticket ? "#E5E4FF" : "#F3F3F3",
                          color: form.ticket ? "#2C2B83" : "#525252",
                          textTransform: "none",
                          marginBottom: "0.5rem",
                          borderRadius: "20px",
                          width: "100%",
                          mb: 2,
                          "&:hover": {
                            backgroundColor: form.ticket
                              ? "#E5E4FF"
                              : "#F3F3F3",
                            color: form.ticket ? "#2C2B83" : "#525252",
                          },
                        }}
                      >
                        Ticket
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        startIcon={<MdInfoOutline />}
                        sx={{
                          backgroundColor: form.create ? "#2C2B83" : "#F3F3F3",
                          color: form.create ? "white" : "#525252",
                          textTransform: "none",
                          borderRadius: "20px",
                          width: "100%",
                          "&:hover": {
                            backgroundColor: form.create
                              ? "#2C2B83"
                              : "#F3F3F3",
                            color: form.create ? "white" : "#525252",
                          },
                        }}
                      >
                        Create
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {activeTab === "Existing Forms" && (
            <Grid container spacing={2} height={"80vh"}>
              {existingCards.map((form, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card sx={{ borderRadius: "16px" }} elevation={0}>
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        Title
                      </Typography>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontSize: "13px", mb: 1 }}
                      >
                        {form.title}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ color: "#525252", fontSize: "11px" }}
                          >
                            Date Of Modified
                          </Typography>
                          <Typography variant="body2">
                            {form.dateModified}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ color: "#525252", fontSize: "11px" }}
                          >
                            Date Of Activity
                          </Typography>
                          <Typography variant="body2">
                            {form.dateActivity}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ color: "#525252", fontSize: "11px" }}
                          >
                            Shared
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ color: getColor(form.shared) }}
                          >
                            {form.shared}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525252", fontSize: "11px" }}
                          >
                            Achieved
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ color: getColor(form.achieved) }}
                          >
                            {form.achieved}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ color: "#525252", fontSize: "11px" }}
                          >
                            Countersigned
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ color: getColor(form.countersigned) }}
                          >
                            {form.countersigned}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box display="flex" marginTop={2}>
                        <IconButton
                          sx={{
                            backgroundColor: "#FFEBEB",
                            "&:hover": {
                              backgroundColor: "#FFD4D4",
                            },
                          }}
                        >
                          <MdDelete style={{ color: "#D32F2F" }} />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
      {activeButton === "Supervisor’s Report" && <SuperVisorsReport />}
      {activeButton === "ARCP" && (
        <Box>
          <Paper
            elevation={0}
            style={{
              padding: "1rem",
              backgroundColor: "white",
              borderRadius: "10px",
              border: "1px solid #D5D5D5",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              ARCP
            </Typography>
            <Typography variant="body1" style={{ marginTop: "1rem" }}>
              This is a placeholder for the ARCP content.
            </Typography>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default ProgressLog;
