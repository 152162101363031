import React, { useCallback, useEffect, useState } from "react";
import { programService } from "../../service/index";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
import "./user-clinical.css";
import { Button } from "@mui/material";
import { Box, Typography, Grid } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import userClinical from "../../images/clinicaluser.png";

const UserClinical = () => {
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [initialLoadingComplete, setInitialLoadingComplete] = useState(false);
  const [programs, setPrograms] = useState([]);
  const navigate = useNavigate();

  const fetchPrograms = useCallback(() => {
    setInitialLoadingComplete(false);
    programService.getSubscribedPrograms().then((res) => {
      setInitialLoadingComplete(true);
      console.log("Libs:\t\t\t\t", res);
      if (!res.status || !res.data) {
        console.log("LibErr\t\t\t", res);
        snackbar("Library fetching failed", { variant: "error" });
        return;
      }
      setPrograms(res.data);
    });
  }, [snackbar]);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);


  if (!initialLoadingComplete) {
    <FileLoader />;
  }

  return (
    <>
      {!initialLoadingComplete && <FileLoader />}
      <Box
        sx={{
          padding: "3.5% 5% 3.5% 5%",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#000020",
              fontSize: "22px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Subscribed Clinical Programs
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            mt: "1.5rem",
            // display: "flex",
            // flexWrap: "wrap",
            // justifyContent: "flex-start",
          }}
        >
          {programs && (
            <>
              {console.log("Programs\t\t\t", programs)}
              {programs.map((program, index) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      xl={3}
                      sx={{
                        height: "25rem",
                        borderRadius: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          boder: "1px solid red",
                          backgroundColor: "#fff",
                          height: "100%",
                          width: "100%",
                          borderRadius: "1.5rem",
                          padding: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "50%",
                            borderRadius: "1.5rem",
                            // border : '2px solid blue',
                            background: program.programData[0].imageUrl
                              ? `url(${program.programData[0].imageUrl})`
                              : `url(${userClinical})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        ></Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            // border: '1px solid blue',
                            justifyContent: "space-evenly",
                            height: "50%",
                            overflow: "hidden",
                            padding: "0.2rem",
                            // paddingBottom : '1.5rem',
                            pt: '1.5rem'
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                            }}
                          >
                            {program?.programData[0].title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "#777777",
                              // mr: "1rem",
                              maxHeight: "80%",
                              overflow: 'auto',
                              // border : '1px solid red',
                              pb: '0.5rem'
                            }}
                          >
                            {program?.programData[0].about}
                          </Typography>

                          <Button
                            sx={{
                              backgroundColor: "#EB568F",
                              fontFamily: "Poppins",
                              fontSize: "14.3px",
                              fontWeight: "600",
                              color: "#fff",
                              width: "100%",
                              borderRadius: "2rem",
                              height: "3rem",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2C2B83",
                              },
                            }}
                            onClick={() =>
                              navigate(`/lib/files/${program.programId}`)
                            }
                          >
                            Continue
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </>
          )}
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={6}
          xl={2.5}
          sx={{
            backgroundColor: "#fff",
            height: "25rem",
            borderRadius: "1rem",
            // mb: "1rem",
            // mr: "1.5rem",
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "1rem",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  background: `url(${userClinical})`,
                  backgroundSize: "cover",
                  height: "50%",
                }}
              ></Box>
              <Box
                sx={{
                  height: "50%",
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontStyle: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  MRCPI Clinical program
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "Poppins",
                    mr: "1rem",
                  }}
                >
                  Royal College of Physicians of Ireland Clinical
                  Program
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#EB568F",
                    fontFamily: "Poppins",
                    fontSize: "14.3px",
                    fontWeight: "600",
                    color: "#fff",
                    width: "100%",
                    borderRadius: "2rem",
                    height: "3rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
};

export default UserClinical;
