import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem, Tooltip, Modal } from "@mui/material";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import axios from "axios";
import { recordService } from "../../service/index";
import { useSnackbar } from "notistack";
import FileLoader from "../FileLoader";
import { CardType } from "../../enum";
import ShareRecord from "../share-record/ShareRecord";

import { apiConfig } from "../../config/api-config";

const FolderCardComponent = ({
  id,
  record,
  title,
  dIcon,
  cardColor,
  sx,
  type,
  dense,
  clickHandler,
  clickSelection,
  fetchRecords,
  isDelete = true,
  isEdit = true,
  isSelected,
  onDelete,
  isRestore,
  onRestore,
  setInitialLoadingComplete,
  allowShare,
  allowRename,
  handleRenameTrigger,
  allowLocation,
  allowDownload,
}) => {
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSharingBox, setSharingBox] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileLoading, setfileLoading] = useState(false);
  const [rename, setRename] = useState(false);
  const [newName, setNewName] = useState(title);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!anchorEl) {
      setOpen(false);
      return;
    }
    setOpen(true);
  }, [anchorEl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [downloadModal, openDownloadModal] = useState(false);

  const handleCloseDownload = () => {
    setAnchorEl(null);
    openDownloadModal(!downloadModal);
  };

  const handleDownload = () => {
    openDownloadModal(true);
  };

  const downloadFile = (previewUrl, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", previewUrl);
    xhr.responseType = "blob";

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = ((event.loaded / event.total) * 100).toFixed(2);
        console.log(`Downloading... ${percent}%`);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        console.log("Download complete");
      } else {
        console.error("Error downloading file");
      }
    };

    xhr.onerror = () => {
      console.error("Error downloading file");
    };

    xhr.send();
  };

  const handleConfirm = async () => {
    setfileLoading(true);
    const file = record?.img;
    const fileName = file?.substring(file.lastIndexOf("/") + 1);
    console.log("imagename", fileName, record);
    const apiUrl =
      apiConfig.serverURL + `/api/app/get/signurl?param=${fileName}`;
    console.log("URL:", apiUrl);
    try {
      const response = await axios.get(apiUrl);
      const previewUrl = response.data.url;
      console.log("previewUrl", previewUrl);
      downloadFile(previewUrl, record?.name);
      return;
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setfileLoading(false);
      handleCloseDownload();
    }
  };

  const handleDelete = (id) => {
    setInitialLoadingComplete && setInitialLoadingComplete(false);
    recordService.deleteRecord(id).then((res) => {
      setInitialLoadingComplete && setInitialLoadingComplete(true);
      if (!res.status) {
        snackbar("Delete failed", { variant: "error" });
        return;
      }

      snackbar("Delete success", { variant: "success" });
      handleClose();
      fetchRecords && fetchRecords();
    });
  };

  const handleShare = (data) => {
    console.log("sharing:::::::::::::: ", id);
    snackbar("In progress", { variant: "info" });
    setSharingBox(false);
  };

  const handleRename = () => {
    setRename(!rename);
  };

  const saveRename = async () => {
    setfileLoading(true);
    let extension = "";
    const lastIndex = title.lastIndexOf(".");
    if (lastIndex !== -1) {
      extension = title.substring(lastIndex + 1);
    }
    if (newName.length === 0) {
      setfileLoading(false);
      handleRename();
      return;
    }
    const removeExt = newName.replace(/\.[^/.]+$/, "");
    const validateName = removeExt.replace(/[^a-zA-Z0-9-_]/g, "");
    console.log("VALIDATED\t\t\t\t", removeExt, validateName);
    if (id) {
      await recordService
        .updateRecord(id, `${validateName}.${extension}`)
        .then((res) => {
          if (!res.status) {
            setfileLoading(false);
            snackbar("Error renaming record", { variant: "error" });
            return;
          }
          snackbar("Successfully renamed record", { variant: "success" });
          if (handleRenameTrigger) {
            handleRenameTrigger();
          }
          setfileLoading(false);
        });
    }
    handleRename();
    setTrigger(!trigger);
  };

  if (fileLoading) {
    return <FileLoader />;
  }

  const handleEdit = async () => {
    const file = record?.img;
    const fileName = file?.substring(file.lastIndexOf("/") + 1);
    const fileType = fileName
      ?.substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    if (!file) {
      console.log("File Error");
      return;
    }

    console.log("type", fileType);
    console.log("fileName", fileName);

    try {
      let url;

      if (fileType === "docx" || fileType === "doc") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/doc",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else if (fileType === "pptx" || fileType === "ppt") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/slide",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else if (fileType === "xls" || fileType === "xlsx") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/sheet",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else {
        snackbar("Unsupported file type", { variant: "error" });
        console.log("Unsupported file type:", fileType);
        throw new Error("Unsupported file type");
      }

      console.log("documentUrl", url);
      return url;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const card = (
    <>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: dense ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          minHeight: dense ? 85 : "100%",
          padding: dense ? 2 : "0px",
          paddingBottom: dense ? `16px !important` : "8px !important",
          cursor: "pointer",
          position: "relative",
          "&:hover": {
            backgroundColor: "#E4ECFB",
          },
          backgroundColor: isSelected ? "#E4ECFB" : "none",
        }}
        onClickCapture={clickSelection}
        onDoubleClickCapture={clickHandler}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          width={dense ? "fit-content" : "100%"}
          height={dense ? "unset" : "140px"}
          alignItems="center"
          justifyContent={type === CardType.IMG ? "unset" : "center"}
        >
          {dIcon}
        </Box>
        {!rename && (
          <Tooltip title={title}>
            <Typography
              sx={{
                fontSize: "15px",
                paddingX: "14px",
                paddingBottom: dense ? "0px" : "5px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: dense ? "start" : "center",
              }}
              color="text.primary"
            >
              {title}
            </Typography>
          </Tooltip>
        )}
        {rename && (
          <>
            <Tooltip title={title}>
              <TextField
                id="outlined-multiline-flexible"
                label={title}
                multiline
                maxRows={5}
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    border: "#EB568F",
                  },
                }}
                variant="outlined"
                sx={{
                  marginLeft: "1rem",
                }}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            </Tooltip>
            <Button
              variant="contained"
              sx={{
                "border-radius": "0 5px 5px 0",
                height: "2.6rem",
                transform: "translateX(-3px)",
                backgroundColor: "#EB568F",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={() => saveRename()}
            >
              Save
            </Button>
          </>
        )}
        <Box
          sx={
            !dense
              ? {
                  position: "absolute",
                  right: 10,
                  top: 10,
                  ...(!isDelete || !isRestore ? { display: "none" } : {}),
                }
              : {}
          }
        >
          {(isDelete || allowShare || isRestore || isEdit) && (
            <Button
              onClick={handleClick}
              sx={{
                padding: "6px",
                minWidth: "unset",
                // color: theme.colors?.ternary.default,
                fontSize: "20px",
              }}
            >
              <BiDotsHorizontalRounded />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {allowRename && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  handleRename();
                }}
              >
                Rename
              </MenuItem>
            )}
            {allowShare && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  handleShare(id);
                  handleClose();
                }}
              >
                Share
              </MenuItem>
            )}
            {isEdit && (
              <MenuItem
                onClick={async () => {
                  if (!id) {
                    return;
                  }
                  try {
                    setfileLoading(true);
                    const documentUrl = await handleEdit();
                    if (documentUrl) {
                      window.open(documentUrl, "_blank");
                      setfileLoading(false);
                    } else {
                      console.log("Failed to retrieve document URL");
                      setfileLoading(false);
                    }
                  } catch (error) {
                    console.error("Error:", error);
                    setfileLoading(false);
                  }
                }}
              >
                Edit
              </MenuItem>
            )}
            {isRestore && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  handleClose();
                  onRestore && onRestore(id);
                }}
              >
                Restore
              </MenuItem>
            )}
            {isDelete && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  onDelete ? onDelete(id) : handleDelete(id);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            )}
            {allowDownload && (
              <MenuItem
                onClick={() => {
                  if (!record) {
                    return;
                  }
                  handleDownload();
                }}
              >
                Download
              </MenuItem>
            )}
            {allowLocation && (
              <MenuItem onClick={() => {}}>{record?.location}</MenuItem>
            )}
          </Menu>
        </Box>
        {record && (
          <ShareRecord
            open={openSharingBox}
            onClose={() => {
              setSharingBox(false);
              handleClose();
            }}
            data={record}
          />
        )}
      </CardContent>
    </>
  );

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          padding: "0px",
          background: cardColor || "white",
          minHeight: dense ? 64 : "100%",
          borderRadius: "10px",
          ...{ sx },
        }}
      >
        {card}
      </Card>
      <Modal
        open={downloadModal}
        onClose={handleCloseDownload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "0.5rem",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              color: "#0E1C3F",
            }}
          >
            Are you sure you want to download{" "}
            <span
              style={{
                fontWeight: "600",
                color: "#0E1C3F",
              }}
            >
              {record?.name}
            </span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#0E1C3F",
              mt: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                mr: "1rem",
              }}
              onClick={handleCloseDownload}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#EB5B91",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={handleConfirm}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FolderCardComponent;
