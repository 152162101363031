const zohoService = (httpClient) => {
  return {
    previewDoc: async (img) => {
      try {
        const res = await httpClient.post(`/files/view/doc/${img}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to preview doc" };
      }
    },
    previewSlide: async (img) => {
      try {
        const res = await httpClient.post(`/files/view/slide/${img}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to preview presentation" };
      }
    },
    previewSheet: async (img) => {
      try {
        const res = await httpClient.post(`/files/view/sheet/${img}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to preview sheet" };
      }
    },
  };
};

export default zohoService;
