import { clearSessionStorage } from "../utils/storage-utils";
import { clearStorage } from "../utils/storage-utils";
import { setAuthStorage, setSessionStorage } from "../utils/storage-utils";

const userService = (httpClient) => {
  const createUser = async (email, password, phoneNumber) => {
    try {
      const res = await httpClient.post("/user/create", {
        email,
        password,
        mobile: phoneNumber,
      });
      return res.data;
    } catch (error) {
      return { status: false, message: "User creation failed" };
    }
  };

  const getUserUsageService = async (userId) => {
    try {
      const res = await httpClient.post("/get/user/usage", {
        userId
      });
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch user usage" };
    }
  };

  const getUsers = async () => {
    try {
      const res = await httpClient.get("/users");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch users" };
    }
  };

  const getAllUsers = async () => {
    try {
      const res = await httpClient.get("/all-users");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch users" };
    }
  };

  const getAdmins = async () => {
    try {
      const res = await httpClient.get("/admins");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch admins" };
    }
  };

  const getSuperAdmins = async () => {
    try {
      const res = await httpClient.get("/super-admins");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch users" };
    }
  };

  const deleteUser = async (id) => {
    try {
      const res = await httpClient.delete(`/user/delete/${id}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "User deletion failed" };
    }
  };

  const getAdminsUnderSuperAdmins = async (id) => {
    try {
      const res = await httpClient.get(`/super-admins/${id}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch admins" };
    }
  };

  const getUsersUnderAdmins = async (id) => {
    try {
      const res = await httpClient.get(`/admins/${id}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch admins" };
    }
  };

  const getSingle = async (id) => {
    try {
      if (!id) {
        return { status: false, message: "Failed to fetch user" };
      }
      const res = await httpClient.get(`/user/${id}`);
      return res.data;
    } catch (error) {
      console.log("Error\t\t\t", error);
      return { status: false, message: "Failed to fetch user" };
    }
  };

  const updateUser = async (data, id) => {
    try {
      const res = await httpClient.put(`/user/update/${id}`, data);
      return res.data;
    } catch (error) {
      return { status: false, message: "User update failed" };
    }
  };

  const logout = () => {
    clearStorage();
    clearSessionStorage();
    window.location.href = `/home`;
  };

  const refreshToken = async (userId) => {
    try {
      const res = await httpClient.put(`/user/refresh/token/${userId}`, userId);
      if (res.data.data.expiresIn === "1h") {
        setSessionStorage(res.data.data);
        console.log("Session\t\t\t\t", res.data.data);
      } else {
        setAuthStorage(res.data.data);
      }
      return res.data;
    } catch (error) {
      return { status: false, message: "User update failed" };
    }
  };

  const joinProgram = async (data) => {
    try {
      console.log("JoinData\t\t\t", data);
      const res = await httpClient.put(`/user/sub/program`, data);

      return res.data;
    } catch (error) {
      return { status: false, message: "Program Subsciption Failed" };
    }
  };

  const getPresignedUrl = async (data) => {
    try {
      // console.log("DataPRE\t\t\t", data);
      const res = await httpClient.get(`/file/signed/${data}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch URL" };
    }
  };

  return {
    createUser,
    getUsers,
    getAllUsers,
    getAdmins,
    getSuperAdmins,
    deleteUser,
    getAdminsUnderSuperAdmins,
    getUsersUnderAdmins,
    getSingle,
    updateUser,
    logout,
    refreshToken,
    joinProgram,
    getPresignedUrl,
    getUserUsageService,
  };
};

export default userService;
