import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import AddPostIco from "../../images/addPostIcon.svg";
import { MdDelete } from "react-icons/md";
import { BiSolidEditAlt } from "react-icons/bi";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { MdDownload } from "react-icons/md";
import { supervisorService } from "../../service/index";
import { certificateService } from "../../service/index";

function ClinicalProfile() {
  const [supervisorData, setsupervisorData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({
    postId: "",
    supervisorId: "",
  });
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    year: "",
    grade: "",
    inProgramme: "",
    location: "",
    specialities: "",
    startDate: "",
    endDate: "",
    code: "",
    status: "",
    credits: "",
    postId: "",
    supervisorId: "",
  });

  const handleDeleteOpen = (postId, supervisorId) => {
    setDeleteData({ postId, supervisorId });
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleOpen = (data) => {
    setEditData(data);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };
  const handleEditSubmit = async () => {
    // Call your API to edit supervisor details
    const response = await supervisorService.editSupervisor(
      editData.postId,
      editData.supervisorId,
      editData
    );
    if (response.status) {
      // Update the local state with the edited data
      const updatedSupervisors = supervisorData.map((item) =>
        item._id === editData.postId
          ? {
              ...item,
              supervisors: item.supervisors.map((supervisor) =>
                supervisor._id === editData.supervisorId ? editData : supervisor
              ),
            }
          : item
      );
      setsupervisorData(updatedSupervisors);
      handleClose();
    } else {
      console.error("Failed to edit supervisor:", response.message);
    }
  };

  useEffect(() => {
    async function fetchSupervisorData() {
      const supervisor = await supervisorService.getSupervisors();
      if (supervisor.status) {
        console.log("Fetched supervisors Data:", supervisor.data);
        setsupervisorData(supervisor.data);
      }
    }
    fetchSupervisorData();
  }, []);
  const handleDelete = async (postId, supervisorId) => {
    console.log(
      "Initiating delete for postId:",
      postId,
      "supervisorId:",
      supervisorId
    );
    if (!postId) {
      console.error("postId is missing");
      return;
    }
    if (!supervisorId) {
      console.error("supervisorId is missing");
      return;
    }

    const response = await supervisorService.deleteSupervisor(
      postId,
      supervisorId
    );
    console.log("Delete response:", response);

    if (response.status) {
      console.log("Supervisor deleted successfully. Updating state.");
      const updatedSupervisors = supervisorData.map((item) => ({
        ...item,
        supervisors: item.supervisors.filter(
          (supervisor) => supervisor._id !== supervisorId
        ),
      }));
      setsupervisorData(updatedSupervisors);
    } else {
      console.error("Failed to delete supervisor:", response.message);
      console.error("Response data:", response.data);
    }
  };

  const [selected, setSelected] = useState("supervisor");

  //---------------------------------------CARDs-----------------------------------------------------------------------//
  const TrainingCard = ({
    year,
    grade,
    inProgramme,
    location,
    specialities,
    startDate,
    endDate,
    code,
    status,
    credits,
    postId,
    supervisorId,
    supervisors,
  }) => {
    const handleEditClick = () => {
      handleOpen({
        year,
        grade,
        inProgramme,
        location,
        specialities,
        startDate,
        endDate,
        code,
        status,
        credits,
        postId,
        supervisorId,
      });
    };

    console.log("TrainingCard props:", { postId, supervisorId });
    return (
      <Box>
        <Box
          sx={
            {
              // border: '1px solid red'
            }
          }
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Year
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {year}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Grade
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {grade}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              mt: "1rem",
            }}
          >
            <Box
              sx={{
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                In Programme
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {inProgramme}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Location
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {location}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              mt: "1rem",
            }}
          >
            <Box
              sx={{
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Specialties
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {specialities}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Dates
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {new Date(startDate).toLocaleDateString()} to{" "}
                {new Date(endDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              // display: 'flex',
              mt: "1rem",
            }}
          >
            <Box
              sx={{
                width: "90%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Supervisors
              </Typography>
              <Typography
                sx={{
                  fontSize: "11.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                  lineHeight: "16.1px",
                }}
              >
                {supervisors}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              mt: "1rem",
            }}
          >
            <Box
              sx={{
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Code
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {code}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Full Time Status %
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {status}%
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              mt: "1rem",
            }}
          >
            <Box
              sx={{
                width: "60%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#252525",
                  fontFamily: "Poppins",
                }}
              >
                Training Credits
              </Typography>
              <Typography
                sx={{
                  fontSize: "12.52px",
                  fontWeight: "600",
                  color: "#101010",
                  fontFamily: "Poppins",
                }}
              >
                {credits}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: "1.5rem",
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                fontSize: "12.52px",
                fontStyle: "Poppins",
                fontWeight: "600",
                color: "#E32222",
                backgroundColor: "#ffffff",
                borderRadius: "1.5rem",
                mr: "0.5rem",
                "&:hover": {
                  backgroundColor: "#E32222",
                  color: "#ffffff",
                },
              }}
              onClick={() => handleDeleteOpen(postId, supervisorId)}
            >
              <MdDelete size={"16px"} style={{ marginRight: "0.2rem" }} />
              Delete
            </Button>
            <Button
              sx={{
                textTransform: "none",
                fontSize: "12.52px",
                fontStyle: "Poppins",
                fontWeight: "600",
                color: "#2C2B83",
                backgroundColor: "#ffffff",
                borderRadius: "1.5rem",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                  color: "#ffffff",
                },
              }}
              onClick={handleEditClick}
            >
              <BiSolidEditAlt size={"16px"} style={{ marginRight: "0.2rem" }} />
              Edit
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  //------------------------------------------------------------------------ supervisor
  const colors = ["#DBEBFF", "#FFF9DB", "#FFDBF9", "#F5FFFA", "#FFFACD"];
  const SuperVisor = () => {
    return (
      <Box>
        {supervisorData.map((item) => (
          <Box key={item.id}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  color: "#525252",
                  letterSpacing: "0.8px",
                }}
              >
                Profile/
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  color: "#252525",
                  letterSpacing: "0.8px",
                }}
              >
                Post/
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  color: "#252525",
                  letterSpacing: "0.8px",
                }}
              >
                Supervisor Details
              </Typography>
            </Box>
            <Box
              sx={{
                mt: "0.5rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  color: "#252525",
                  lineHeight: "22px",
                }}
              >
                Below are the posts and supervisors listed for this account on
                ePortfolio. If you have permission to edit or delete a post,
                there will be links in the "Action" column. The trainee must be
                enrolled in a Training Programme first before you can maintain
                posts. To add a new training programme, click the "Add/Edit
                Training Programmes' button at the top of the page. To add a new
                post, click the 'Add Post' button.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "1.5rem",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  color: "#ffffff",
                  backgroundColor: "#2C2B83",
                  borderRadius: "1rem",
                  transition: "scale 0.1s ease-in",
                  "&:hover": {
                    scale: "0.9",
                    backgroundColor: "#2C2B83",
                  },
                }}
              >
                <img
                  src={AddPostIco}
                  alt="no"
                  style={{ marginRight: "0.5rem" }}
                />
                Add Post
              </Button>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  color: "#252525",
                  lineHeight: "auto",
                }}
              >
                {`Target Number Of Months: ${item.targetMonths}`}
              </Typography>
            </Box>
            <Box
              sx={{
                border: "0.5px solid #AAAAAA",
                borderRadius: "0.5rem",
                backgroundColor: "#ffffff",
                mt: "1.5rem",
                padding: "0.5rem",
              }}
            >
              <Box
                sx={{
                  p: "1rem",
                  backgroundColor: "#DBEBFF",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#252525",
                    lineHeight: "auto",
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  mt: "0.3rem",
                  p: "0 0rem 0.5rem 0rem",
                  "& .MuiTypography-root": {
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  },
                }}
              >
                {item.supervisors.map((supervisor, index) => (
                  <Grid
                    item
                    md={12}
                    lg={3.95}
                    sx={{
                      backgroundColor: colors[index % colors.length],
                      borderRadius: "0.5rem",
                      p: "1rem",
                    }}
                    key={supervisor._id}
                  >
                    <TrainingCard
                      supervisors={supervisor.supervisors}
                      year={supervisor.year}
                      grade={supervisor.grade}
                      inProgramme={supervisor.inProgramme}
                      location={supervisor.location}
                      specialities={supervisor.specialities}
                      startDate={supervisor.startDate}
                      endDate={supervisor.endDate}
                      supervisors={supervisor.supervisors}
                      code={supervisor.code}
                      status={supervisor.status}
                      credits={supervisor.credits}
                      postId={item._id}
                      supervisorId={supervisor._id}
                      onDelete={() => handleDelete(item._id, supervisor._id)}
                    />
                  </Grid>
                ))}
              </Grid>
              {/* -------------------------------------------modal for edit----------------------------------- */}
              <Modal open={open} onClose={handleClose}>
                <Paper
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "#f7f9fc",
                    p: 4,
                    borderRadius: "12px",
                    boxShadow: 24,
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ color: "#d32f2f", mb: 4 }}
                  >
                    Edit Supervisor
                  </Typography>
                  <Box component="form" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          name="year"
                          label="Year"
                          value={editData.year}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="grade"
                          label="Grade"
                          value={editData.grade}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="inProgramme"
                          label="In Programme"
                          value={editData.inProgramme}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="location"
                          label="Location"
                          value={editData.location}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="specialities"
                          label="Specialities"
                          value={editData.specialities}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="startDate"
                          label="Start Date"
                          type="date"
                          value={editData.startDate}
                          onChange={handleEditChange}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="endDate"
                          label="End Date"
                          type="date"
                          value={editData.endDate}
                          onChange={handleEditChange}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="code"
                          label="Code"
                          value={editData.code}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="status"
                          label="Status"
                          value={editData.status}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="credits"
                          label="Credits"
                          value={editData.credits}
                          onChange={handleEditChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{ mr: 2, color: "black", borderColor: "red" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleEditSubmit}
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Modal>
              <Modal open={deleteOpen} onClose={handleDeleteClose}>
                <Paper
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "#f7f9fc",
                    p: 4,
                    borderRadius: "12px",
                    boxShadow: 24,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#d32f2f", mb: 2 }}
                  >
                    Confirm Deletion
                  </Typography>
                  <Typography>
                    Are you sure you want to delete this supervisor?
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleDeleteClose}
                      sx={{ mr: 2 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleDelete(
                          deleteData.postId,
                          deleteData.supervisorId
                        );
                        handleDeleteClose();
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Paper>
              </Modal>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };
  //-----------------------CERTIFICATE------------------------------------------------balance code under-//
  const Certificate = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [certificateData, setsupervisorData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [certificateToDelete, setCertificateToDelete] = useState(null);
    const [certificateToEdit, setCertificateToEdit] = useState(null);
    const [editedCertificate, setEditedCertificate] = useState({});

    useEffect(() => {
      async function fetchCertificateData() {
        const certificate = await certificateService.getCertificates();
        if (certificate.status) {
          setsupervisorData(certificate.data);
        }
      }
      fetchCertificateData();
    }, []);

    const filteredCertificates = certificateData.filter((certificate) =>
      certificate.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDeleteCertificate = async (id) => {
      console.log("Attempting to delete certificate with ID:", id);
      try {
        const response = await certificateService.deleteCertificate(id);
        console.log("Delete API response:", response);
        if (response.status) {
          setsupervisorData((prevData) =>
            prevData.filter((certificate) => certificate._id !== id)
          );
          setOpenDeleteModal(false);
        } else {
          console.error("Delete response message:", response.message);
        }
      } catch (error) {
        console.error("Error deleting certificate:", error);
      }
    };

    const handleEditCertificate = async (id, updatedData) => {
      if (!id) {
        console.error("Certificate ID is undefined");
        return;
      }

      console.log("Editing certificate with ID:", id);
      console.log("Updated data:", updatedData);

      try {
        const payload = { certificateId: id, updateData: updatedData };
        console.log("Payload being sent:", payload);

        const response = await certificateService.editCertificate(payload);
        console.log("Edit API response:", response);

        if (response.status) {
          setsupervisorData((prevData) =>
            prevData.map((certificate) =>
              certificate._id === id
                ? { ...certificate, ...updatedData }
                : certificate
            )
          );
          setOpenEditModal(false);
        } else {
          console.error("Edit response message:", response.message);
        }
      } catch (error) {
        console.error("Error editing certificate:", error);
      }
    };

    const handleOpenEditModal = (certificate) => {
      setCertificateToEdit(certificate); // Store the entire certificate object
      setEditedCertificate(certificate);
      setOpenEditModal(true);
    };

    return (
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#525252",
              letterSpacing: "0.8px",
            }}
          >
            Profile/
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#252525",
              letterSpacing: "0.8px",
            }}
          >
            Certificates and Exams
          </Typography>
        </Box>
        <Box sx={{ mt: "0.5rem", width: "95%" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "normal",
              color: "#252525",
              lineHeight: "22px",
            }}
          >
            Below are the certificates and exams listed for this account on
            ePortfolio. To add a new certificate or exam to the list, select the
            "Add New Certificate or Exam" button. To confirm that a certificate
            is valid, a supervisor must view the paper certificate and Confirm
            it. If there is an electronic version of the certificate available,
            it can be uploaded using the "Upload" link.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "1.5rem",
            overflow: "none",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search Certificate / Exam"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
                backgroundColor: "#fff",
                "& input": {
                  paddingLeft: "10px",
                  color: "#ADADAD",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                },
                p: "1.2rem",
                height: "2.5rem",
              },
              width: "35%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: "normal",
              color: "#ffffff",
              backgroundColor: "#2C2B83",
              borderRadius: "1rem",
              transition: "scale 0.1s ease-in",
              "&:hover": {
                scale: "0.9",
                backgroundColor: "#2C2B83",
              },
            }}
          >
            <img src={AddPostIco} alt="Add" style={{ marginRight: "0.5rem" }} />
            Add New Certificate
          </Button>
        </Box>
        <Box>
          <Grid
            container
            sx={{
              mt: "1.5rem",
              justifyContent: "space-between",
            }}
          >
            {filteredCertificates.length > 0 &&
              filteredCertificates.map((certificate) => (
                <Grid
                  item
                  key={certificate._id}
                  md={12}
                  lg={3.95}
                  sx={{
                    border: "0.5px solid #AAAAAA",
                    borderRadius: "1rem",
                    backgroundColor: "#ffffff",
                    padding: "1rem",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#525252",
                      }}
                    >
                      Course Name
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#101010",
                        width: "80%",
                      }}
                    >
                      {certificate.name}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "1rem" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#525252",
                      }}
                    >
                      Duration
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#101010",
                        width: "80%",
                      }}
                    >
                      {certificate.duration || "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "1rem" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#525252",
                      }}
                    >
                      College Number
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#101010",
                        width: "80%",
                      }}
                    >
                      {certificate.collegeNumber || "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "2.5rem", display: "flex" }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        color: "#2C2B83",
                        fontWeight: "600",
                        borderRadius: "1.5rem",
                        backgroundColor: "#E5E4FF",
                        mr: "0.8rem",
                      }}
                    >
                      <MdDownload
                        size={"16px"}
                        style={{ marginRight: "0.5rem" }}
                      />
                      Download
                    </Button>
                    <Button
                      sx={{
                        width: "38px",
                        height: "38px",
                        p: 0,
                        borderRadius: "50%",
                        backgroundColor: "#E5E4FF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: "0.8rem",
                      }}
                      onClick={() => handleOpenEditModal(certificate)}
                    >
                      <BiSolidEditAlt color={"#2C2B83"} size={"16px"} />
                    </Button>
                    <Button
                      sx={{
                        width: "38px",
                        height: "38px",
                        p: 0,
                        borderRadius: "50%",
                        backgroundColor: "#FFECEC",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: "0.8rem",
                      }}
                      onClick={() => {
                        setCertificateToDelete(certificate._id);
                        setOpenDeleteModal(true);
                      }}
                    >
                      <MdDelete color={"#E32222"} size={"16px"} />
                    </Button>
                  </Box>
                </Grid>
              ))}
          </Grid>

          {/* Delete Confirmation Modal */}
          <Modal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" component="h2" gutterBottom>
                Are you sure you want to delete this certificate?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  onClick={() => setOpenDeleteModal(false)}
                  sx={{
                    mr: 1,
                    backgroundColor: "#E5E4FF",
                    color: "#2C2B83",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (certificateToDelete) {
                      handleDeleteCertificate(certificateToDelete);
                    }
                  }}
                  sx={{
                    backgroundColor: "#E32222",
                    color: "#ffffff",
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Edit Certificate Modal */}
          <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" component="h2" gutterBottom>
                Edit Certificate
              </Typography>
              <TextField
                fullWidth
                label="Course Name"
                variant="outlined"
                value={editedCertificate.name || ""}
                onChange={(e) =>
                  setEditedCertificate({
                    ...editedCertificate,
                    name: e.target.value,
                  })
                }
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                label="Duration"
                variant="outlined"
                value={editedCertificate.duration || ""}
                onChange={(e) =>
                  setEditedCertificate({
                    ...editedCertificate,
                    duration: e.target.value,
                  })
                }
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                label="College Number"
                variant="outlined"
                value={editedCertificate.collegeNumber || ""}
                onChange={(e) =>
                  setEditedCertificate({
                    ...editedCertificate,
                    collegeNumber: e.target.value,
                  })
                }
                sx={{ mt: 2 }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  onClick={() => setOpenEditModal(false)}
                  sx={{
                    mr: 1,
                    backgroundColor: "#E5E4FF",
                    color: "#2C2B83",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleEditCertificate(
                      certificateToEdit._id,
                      editedCertificate
                    )
                  }
                  sx={{
                    backgroundColor: "#2C2B83",
                    color: "#ffffff",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "2.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: {
            lg: "47%",
            md: "80%",
            sm: "100%",
          },
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: selected === "personal" ? "#2C2B83" : "#525252",
            borderRadius: "10px",
            backgroundColor: selected === "personal" ? "#E5E4FF" : "#ffffff",
            pl: "1rem",
            pr: "1rem",
          }}
          onClick={() => setSelected("personal")}
        >
          Personal Details
        </Button>
        <Button
          sx={{
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: selected === "supervisor" ? "#2C2B83" : "#525252",
            backgroundColor: selected === "supervisor" ? "#E5E4FF" : "#ffffff",
            pl: "1rem",
            pr: "1rem",
            borderRadius: "10px",
          }}
          onClick={() => setSelected("supervisor")}
        >
          Post/Supervisor Details
        </Button>
        <Button
          sx={{
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: selected === "certificate" ? "#2C2B83" : "#525252",
            backgroundColor: selected === "certificate" ? "#E5E4FF" : "#ffffff",
            pl: "1rem",
            pr: "1rem",
            borderRadius: "10px",
          }}
          onClick={() => setSelected("certificate")}
        >
          Certificates and Exams
        </Button>
      </Box>
      <Box
        sx={{
          mt: "1.5rem",
        }}
      >
        {selected === "supervisor" && <SuperVisor />}

        {selected === "certificate" && <Certificate />}
      </Box>
    </Box>
  );
}

export default ClinicalProfile;
