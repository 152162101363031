import { useState, useEffect, useCallback } from "react"; // Remove { React }
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { recordService } from "../../service";
import FileLoader from "../../components/FileLoader";
import { Box, Button, Grid, Modal } from "@mui/material";
import DropArea from "../../components/common/drop-zone/drop-Area";
import CustomDropZone from "../../components/common/drop-zone/custom-drop";
import FolderCardComponent from "../../components/card/FolderCardComponent";
import FolderIcon from "@mui/icons-material/Folder";
import {
  docFileExtensions,
  getFileExtension,
  getIcon,
  getRecordType,
  imageExtensions,
  videoExtensions,
} from "../../utils/core-utils";
// import { readAble } from "../../enum";
import ImageViewer from "./ImageViewer";
import VideoPlayer from "./Video";
import PDFViewer from "./Pdf";
import { handlePreviewDocuments } from "../../utils/doc-utils";

const FileNavigation = ({ trigger }) => {
  const { id, recordId } = useParams();
  const [records, setRecords] = useState(null);
  const [initialLoadingComplete, setInitialLoadingComplete] = useState(null);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const navigate = useNavigate();

  const fetchUserFiles = useCallback(async () => {
    try {
      setInitialLoadingComplete(false);
      const res = await recordService.getUserRecordService();
      if (!res.status) {
        snackbar(res.message, { variant: "error" });
        return;
      }
      console.log("User_Records\t\t", res);
      setRecords(res.data.data);
    } catch (err) {
      console.log("File_Fetching_Error_User", err);
      snackbar("Something went wrong", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
    }
  }, [snackbar]);

  const fetchFilesUnderRecord = useCallback(async () => {
    try {
      setInitialLoadingComplete(false);
      const res = await recordService.getFolderRecordService(recordId);
      if (!res.status) {
        snackbar(res.message, { variant: "error" });
        return;
      }
      console.log("Folder_Records\t\t", res);
      setRecords(res.data.data);
    } catch (err) {
      console.log("File_Fetching_Error_Folder", err);
      snackbar("Something went wrong", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
    }
  }, [recordId, snackbar]);

  useEffect(() => {
    if (id !== null && id !== undefined && recordId === undefined) {
      fetchUserFiles();
    } else if (recordId !== null && recordId !== undefined) {
      fetchFilesUnderRecord();
    }
  }, [id, recordId, trigger, fetchFilesUnderRecord, fetchUserFiles]);



  const [viewer, openViewer] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState(null);
  const [type, setType] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);
  const handleDoubleClick = async (record) => {
    try {
      setInitialLoadingComplete(false);
      console.log("Record\t\t\t", record);
      if (record.isFolder) {
        setRecords(null);
        navigate(`/user/files/${id}/${record._id}`);
      }
      if (!record.isFolder) {
        const recordType = getRecordType(record);
        console.log("RecordType\t", recordType);
        // if (!readAble.includes(recordType)) {
        //   snackbar("File can't be opened T", { variant: "error" });
        //   return;
        // } else if
        if (imageExtensions.includes(recordType.toLowerCase())) {
          const res = await recordService.generatePresignedUrl(record.img);
          console.log("UrlSigned\t\t\t", res?.url);
          if (res?.url) {
            setType("image");
            setSignedUrl(res.url);
            openViewer(true);
            return;
          }
        } else if (videoExtensions.includes(recordType.toLowerCase())) {
          const res = await recordService.generatePresignedUrl(record.img);
          console.log("UrlSigned\t\t\t", res?.url);
          if (res?.url) {
            setType("video");
            setSignedUrl(res.url);
            openViewer(true);
            return;
          }
        } else if (getFileExtension(record.name) === "pdf") {
          const res = await recordService.generatePresignedUrl(record.img);
          console.log("UrlSigned\t\t\t", res?.url);
          if (res?.url) {
            // setType("pdf");
            // setSignedUrl(res.url);
            // openViewer(true);
            // return;
            window.open(`${res.url}`, "_blank");
          }
        } else if (docFileExtensions.includes(recordType.toLowerCase())) {
          const response = await handlePreviewDocuments(record);
          console.log("Response_preview", response);
          if (!response.status) {
            snackbar("Something went wrong", { variant: "error" });
            console.log("Error\t\t", response);
            return;
          }
          window.open(`${response.url}`, "_blank");
        } else {
          console.log(
            "Record\t\t",
            record,
            recordType,
            docFileExtensions.includes(recordType.toLowerCase())
          );
          snackbar("File can't be opened", { variant: "error" });
          return;
        }
        return;
      }
    } catch (err) {
      console.log("Double_click_err\t\t", err);
      snackbar("Something went wrong", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
    }
  };

  const handleRecordClick = () => { };
  const handleRenameTrigger = () => { };

  return (
    <>
      {!initialLoadingComplete && <FileLoader />}
      {records && (
        <Box
          sx={
            {
              // border : '1px solid blue',
              // overflow : 'hidden',
            }
          }
        >
          {records.length > 0 ? (
            <Box
              sx={{
                overflowY: "hidden",
              }}
            >
              <Grid container spacing={1.5}>
                {records.map((record, i) => (
                  <Grid item md={3} key={i}>
                    <FolderCardComponent
                      id={record._id}
                      record={record}
                      title={record.name}
                      //   isSelected={selectedRecords.includes(record._id)}
                      dIcon={
                        record.isFolder ? (
                          // <BiFolder color="red" fontSize={36} />
                          <FolderIcon
                            sx={{
                              fontSize: "36px",
                              color: "#2C2B83",
                            }}
                          />
                        ) : (
                          getIcon(record.isFolder, false, record)
                        )
                      }
                      clickHandler={() => handleDoubleClick(record)}
                      clickSelection={() => handleRecordClick(record._id)}
                      fetchRecords={() => fetchFilesUnderRecord(recordId)}
                      setInitialLoadingComplete={(val) => {
                        setInitialLoadingComplete(val);
                      }}
                      allowShare
                      dense
                      isEdit={false}
                      isDelete={false}
                      allowDownload={true}
                      allowRename={false}
                      handleRenameTrigger={handleRenameTrigger}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                // overflowY : 'hidden',
                // border : '1px solid red',
              }}
            >
              {console.log("Length\t\t\t", records)}
              {/* <Typography> Uploaded files will show here </Typography> */}
              <CustomDropZone id={id}>
                <DropArea />
              </CustomDropZone>
            </Box>
          )}
        </Box>
      )}

      {signedUrl && (
        <Modal
          open={viewer}
          onClose={() => openViewer(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              //   border : '1px solid red'
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "1rem",
              }}
            >
              {type === "image" && (
                <ImageViewer
                  url={signedUrl}
                  onClose={() => openViewer(false)}
                />
              )}
              {type === "video" && (
                <VideoPlayer
                  url={signedUrl}
                  onClose={() => openViewer(false)}
                />
              )}
              {type === "pdf" && (
                <PDFViewer url={signedUrl} onClose={() => openViewer(false)} />
              )}
            </Box>
            {type === "video" && (
              <Button
                sx={{
                  display: "flex",
                  bgcolor: "#2C2B83",
                  color: "#ffffff",
                  textTransform: "none",
                  "&:hover": {
                    scale: "0.8",
                    bgcolor: "#2C2B83",
                  },
                }}
                onClick={() => openViewer(false)}
              >
                Close Player
              </Button>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FileNavigation;
