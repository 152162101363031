import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import bookicon from "../../images/bookicon.png"; // Import the book icon image
import carriculum1 from "../../images/carriculum1.png";
import carriculum2 from "../../images/carriculum2.png";
import carriculum3 from "../../images/carriculum3.png";
import ElearningProgram from "./ElearningProgram"; // Assuming you have a similar component for ELearning

const ELearningItems = [
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Continue",
    image: carriculum1,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Completed",
    image: carriculum2,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Start Now",
    image: carriculum3,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Start Now",
    image: carriculum1,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Continue",
    image: carriculum1,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Completed",
    image: carriculum2,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Start Now",
    image: carriculum3,
  },
  {
    title: "MRCP Clinical Program",
    subtitle: "Royal College of Physicians of Ireland Clinical Program",
    buttonText: "Start Now",
    image: carriculum1,
  },
];

const ELearning = () => {
  const [showNewComponent, setShowNewComponent] = useState(false);

  const handleButtonClick = () => {
    setShowNewComponent(true); // Function to show the new component
  };

  if (showNewComponent) {
    return <ElearningProgram />; // Render the new component if showNewComponent is true
  }

  return (
    <Box sx={{ padding: "2.5rem" }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }} component="h2">
          E - Learning For Health Care
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {ELearningItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                borderRadius: "19px",
                p: 1,
                pb: 0,
                border: "1px solid #D5D5D5",
              }}
              elevation={0}
            >
              <CardMedia
                component="img"
                height="auto"
                image={item.image}
                alt={item.title}
                sx={{ borderRadius: "16px" }}
              />
              <CardContent>
                <Typography
                  sx={{ fontSize: "15px", fontWeight: "500" }}
                  component="div"
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400" }}
                  color="text.secondary"
                >
                  {item.subtitle}
                </Typography>
                <Button
                  onClick={handleButtonClick} // Use onClick to handle button click
                  disableElevation
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    borderRadius: "28px",
                    bgcolor:
                      item.buttonText === "Completed" ? "#2C9230" : "#2C2B83",
                    height: "38px",
                    mt: 2,
                    textTransform: "none",
                  }}
                >
                  {item.buttonText}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ELearning;
