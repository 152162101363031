export const CardType = {
  Folder: "Folder",
  IMG: "IMAGE",
  PDF: "PDF",
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
  DOC: "DOC",
  File: "FILE",
  XLS: "XLS",
  PPT: "PPT",
};

export const readAble = ["IMAGE", "VIDEO", "AUDIO", "PDF", "DOC", "XLS", "PPT"]; // REQUIRE CHANGES IN SUCCEDDING PHASES

export const canEdit = ["pptx", "ppt", "doc", "docx", "xls", "xlsx"];

export const ACTIVITY_TYPE = {
  SHARE_RECORD: 1,
  UPLOAD_FILE: 2,
  DELETE_FILE: 3,
};

export * from "./auth";
