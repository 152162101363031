import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  MenuItem,
  Select,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Modal,
  IconButton,
  TextField,
} from "@mui/material";
import { IoDocumentTextSharp } from "react-icons/io5";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { IoIosArrowDown } from "react-icons/io";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import { reflectionService } from "../../service/index";
import share from "../../images/share.png";
const logs = [
  {
    id: 1,
    title: "Reflection On Management and Leadership Experience",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: 2,
    title: "Reflection On Management and Leadership Experience",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: 3,
    title: "Reflection On Management and Leadership Experience",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: 4,
    title: "Reflection On Management and Leadership Experience",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: 5,
    title: "Reflection On Management and Leadership Experience",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
  {
    id: 6,
    title: "Reflection On Management and Leadership Experience",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  },
];

const modalStyle = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pt: 1,
  pb: 1,
  borderRadius: "20px",
};

function ReflectionAddNewLog() {
  const [selectedPostId, setSelectedPostId] = useState("");
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [formData, setFormData] = useState({
    dateOfTeaching: "",
    title: "",
    time: "",
    venue: "",
    summary: "",
    learningOutcomes: "",
    post: "",
  });

  useEffect(() => {
    // Fetch the available posts on component mount
    const fetchPosts = async () => {
      try {
        console.log("Fetching posts...");
        const res = await reflectionService.fetchPosts();
        console.log("Fetch posts response:", res);
        if (res.status) {
          setPosts(res.data.posts);
          console.log("Posts set:", res.data.posts);
        } else {
          console.error("Failed to fetch posts:", res.message);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const handleChange = (event) => {
    setSelectedPostId(event.target.value);
    console.log("Selected post ID:", event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const {
      dateOfTeaching = "2024-01-01", // Default dummy date
      title = "Untitled Event", // Default dummy title
      time = "00:00", // Default dummy time
      venue = "Unknown Venue", // Default dummy venue
      summary = "No summary provided", // Default dummy summary
      learningOutcomes = "None specified", // Default dummy learning outcomes
      post = "General Post", // Default dummy post
    } = formData;

    if (
      selectedPostId &&
      dateOfTeaching &&
      title &&
      time &&
      venue &&
      summary &&
      learningOutcomes &&
      post
    ) {
      const data = {
        postId: selectedPostId,
        activityDate: dateOfTeaching, // Adjusting to the correct formData field
        title: title,
        time: time,
        venue: venue,
        summary: summary,
        learningOutcomes: learningOutcomes,
      };

      try {
        const response = await reflectionService.addNewReflection(data);
        if (response.status) {
          console.log("Log created successfully:", response.data);
          handleClose(); // Close the modal on success
        } else {
          console.error("Failed to create log:", response.message);
        }
      } catch (error) {
        console.error("Error creating log:", error);
      }
    } else {
      console.log("Please fill all required fields.");
    }
  };

  return (
    <Box>
      <Typography component="h1" gutterBottom fontSize={"14px"}>
        <span style={{ fontSize: "16px", color: "#525252" }}>Reflection</span>
        &gt; Add New Log
      </Typography>
      <Typography fontSize={"16px"} component="h2" gutterBottom mt={2}>
        Select Form:
      </Typography>
      <Typography fontSize={"14px"} component="h3" gutterBottom>
        Please select the type of Reflective Practice Log you would like to add
        from the list below.
      </Typography>
      <Box mb={2} mt={2}>
        <Typography fontSize={"12px"} component="h4" gutterBottom>
          Post
        </Typography>
        <Select
          value={selectedPostId}
          onChange={handleChange}
          displayEmpty
          variant="outlined"
          margin="dense"
          sx={{
            borderRadius: "4px",
            border: "none",
            bgcolor: "white",
            minWidth: 500,
            height: "34px", // Adjust the height as needed
            color: "#7E7E7E",
            fontSize: "12px",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-icon": {
              fontSize: "16px",
              color: "#3F3F3F",
            },
            ".MuiSelect-select": {
              paddingTop: "0px",
              paddingBottom: "0px",
              display: "flex",
              alignItems: "center",
            },
          }}
          IconComponent={IoIosArrowDown}
        >
          <MenuItem value="" disabled>
            Select Post
          </MenuItem>
          {posts.map((post) => (
            <MenuItem key={post._id} value={post._id}>
              {post.title}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Grid container spacing={3}>
        {logs.map((log) => (
          <Grid item xs={12} sm={6} md={3} key={log.id}>
            <Card sx={{ borderRadius: "20px", p: 2 }} elevation={0}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "#E5E4FF",
                      "&:hover": {
                        backgroundColor: "#D4D3FF",
                      },
                    }}
                  >
                    <IoDocumentTextSharp
                      style={{ color: "#8787A3", fontSize: "24px" }}
                    />
                  </IconButton>
                </Box>
                <Typography
                  component="h3"
                  gutterBottom
                  sx={{ color: "#525252", fontSize: "12px" }}
                >
                  Type Of Log
                </Typography>
                <Typography
                  component="h3"
                  sx={{ fontSize: "13px", fontWeight: "500" }}
                >
                  {log.title}
                </Typography>
                <Typography
                  sx={{ color: "#525252", fontSize: "12px", mt: 1 }}
                  component="h4"
                  gutterBottom
                >
                  Description
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                  {log.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  startIcon={<AddCircleIcon />}
                  disableElevation
                  size="large"
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleOpen}
                  sx={{
                    borderRadius: "30px",
                    bgcolor: "#2C2B83",
                    textTransform: "none",
                    transition: "scale 0.1s ease-in",
                    "&:hover": {
                      scale: "0.9",
                      backgroundColor: "#2C2B83",
                    },
                  }}
                >
                  Create Log
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography fontSize={"14px"} component="h2">
              Attendance at Organised Teaching
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <Box flex={1}>
                <Typography
                  sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                  component="h3"
                >
                  Date Of Organised Teaching
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  placeholder="DD/MM/YYYY"
                  InputProps={{
                    style: { backgroundColor: "#f0f0f0" },
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        color: "#7E7E7E",
                      },
                    },
                  }}
                  value={formData.dateOfTeaching}
                  onChange={(e) =>
                    setFormData({ ...formData, dateOfTeaching: e.target.value })
                  }
                />
              </Box>
              <Box flex={1}>
                <Typography
                  sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                  component="h3"
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter The Title"
                  InputProps={{
                    style: { backgroundColor: "#f0f0f0", color: "#7E7E7E" },
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        color: "#7E7E7E",
                      },
                    },
                  }}
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
              </Box>
            </Box>

            <Box display="flex" gap={2}>
              <Box flex={1}>
                <Typography
                  sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                  component="h3"
                >
                  Time (Hours)
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter The Time"
                  InputProps={{
                    style: { backgroundColor: "#f0f0f0" },
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        color: "#7E7E7E",
                      },
                    },
                  }}
                  value={formData.time}
                  onChange={(e) =>
                    setFormData({ ...formData, time: e.target.value })
                  }
                />
              </Box>
              <Box flex={1}>
                <Typography
                  sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                  component="h3"
                >
                  Venue
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter The Venue"
                  InputProps={{
                    style: { backgroundColor: "#f0f0f0" },
                    disableUnderline: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        color: "#7E7E7E",
                      },
                    },
                  }}
                  value={formData.venue}
                  onChange={(e) =>
                    setFormData({ ...formData, venue: e.target.value })
                  }
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                component="h3"
              >
                Summary Of The Event
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="Summary..."
                InputProps={{
                  style: { backgroundColor: "#f0f0f0" },
                  disableUnderline: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      color: "#7E7E7E",
                    },
                  },
                }}
                value={formData.summary}
                onChange={(e) =>
                  setFormData({ ...formData, summary: e.target.value })
                }
              />
            </Box>
            <Box>
              <Typography
                sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                component="h3"
              >
                What Were The Principal Learning Outcomes?
              </Typography>
              <TextField
                fullWidth
                type="date"
                InputProps={{
                  style: { backgroundColor: "#f0f0f0" },
                  disableUnderline: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      color: "#7E7E7E",
                    },
                  },
                }}
                value={formData.learningOutcomes} // This line
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    learningOutcomes: e.target.value,
                  })
                }
              />
            </Box>
            <Box>
              <Typography
                sx={{ color: "#525252", fontWeight: "500", fontSize: "12px" }}
                component="h3"
              >
                Select The Post This Entry Applies To
              </Typography>
              <TextField
                fullWidth
                placeholder="Post..."
                value={formData.post}
                onChange={(e) =>
                  setFormData({ ...formData, post: e.target.value })
                }
                InputProps={{
                  style: { backgroundColor: "#f0f0f0" },
                  disableUnderline: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      color: "#7E7E7E",
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Box mt={1}>
            <img src={share} alt="share"></img>
          </Box>
          <Box mt={2} display="flex" gap={2} justifyContent="flex-start">
            <Button
              disableElevation
              onClick={handleClose}
              variant="contained"
              sx={{
                bgcolor: "#EFEFEF",
                color: "black",
                borderRadius: "30px",
                textTransform: "none",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              variant="contained"
              sx={{
                bgcolor: "#E5E4FF",
                color: "#2C2B83",
                borderRadius: "30px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                  color: "white",
                },
              }}
            >
              Save To Draft
            </Button>
            <Button
              onClick={() => handleSubmit(formData)}
              disableElevation
              variant="contained"
              sx={{
                bgcolor: "#2C2B83",
                borderRadius: "30px",
                textTransform: "none",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ReflectionAddNewLog;
