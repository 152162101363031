const reflectionService = (httpClient) => {
  return {
    fetchReflections: async (page = 1, pageSize = 10) => {
      try {
        const res = await httpClient.post("/fetch/reflection/", {
          page,
          pageSize,
        });
        console.log("Fetched reflections response:", res);
        return res.data;
      } catch (error) {
        console.error(
          "Error fetching reflections:",
          error.response?.data || error
        );
        return { status: false, message: "Failed to fetch reflections" };
      }
    },
    fetchPosts: async () => {
      try {
        const res = await httpClient.get("/fetch/selection/posts/");
        return res.data;
      } catch (error) {
        console.error("Error fetching posts:", error.response?.data || error);
        return { status: false, message: "Failed to fetch posts" };
      }
    },
    AddNewReflection: async () => {
      try {
        const res = await httpClient.post("/add/new/reflection/");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to add new reflection" };
      }
    },
    updateReflection: async (reflectionId, data) => {
      try {
        const res = await httpClient.post("/update/reflection/", {
          reflectionId,
          ...data,
        });
        return res.data;
      } catch (error) {
        console.error(
          "Error updating reflection:",
          error.response?.data || error
        );
        return { status: false, message: "Failed to update reflection" };
      }
    },
  };
};

export default reflectionService;
