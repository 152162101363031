import React from "react";
import { SvgIcon} from "@mui/material";
import { ReactComponent as svg } from "./pdf.svg";

const PDFIcon = (props) => {

  return (
    <SvgIcon
      component={svg}
      viewBox="0 0 59 58"
      sx={{
        fontSize: "30px",
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default PDFIcon;
