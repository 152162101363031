import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Box,
  Modal,
  Backdrop,
  Fade,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { MdDelete } from "react-icons/md";
import { FaFileSignature, FaFileLines } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { formService } from "../../service/index"; // Import formService correctly

const MyForms = () => {
  const [showFilledForms, setShowFilledForms] = useState(false);
  const [forms, setForms] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [formToEdit, setFormToEdit] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: "",
    dateOfActivity: "", // New field
    isShared: false,
    isAchieved: false,
    isCounterSigned: false,
    isFilled: false,
  });

  const handleShowFilledForms = () => {
    setShowFilledForms(true);
  };

  const handleShowDraftForms = () => {
    setShowFilledForms(false);
  };

  const getColor = (status) => (status === "Yes" ? "green" : "red");

  const fetchForms = async (type) => {
    const response = await formService.fetchForms();
    console.log(`Fetch ${type} forms response:`, response);
    if (response.status) {
      setForms(
        response.data.filter((form) => form.isFilled === (type === "filled"))
      );
    } else {
      console.error(response.message);
    }
  };

  const handleDeleteForm = async (formId) => {
    const response = await formService.deleteForm(formId);
    console.log("Delete form response:", response);
    if (response.status) {
      setForms(forms.filter((form) => form._id !== formId));
    } else {
      console.error(response.message);
    }
  };

  const handleEditForm = async (formId, updatedData) => {
    const response = await formService.editForm({ formId, ...updatedData });
    console.log("Edit form response:", response);
    if (response.status) {
      setForms(
        forms.map((form) => (form._id === formId ? response.data : form))
      );
      handleCloseEditModal();
    } else {
      console.error(response.message);
    }
  };

  const handleOpenDeleteModal = (formId) => {
    setFormToDelete(formId);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setFormToDelete(null);
  };

  const confirmDeleteForm = () => {
    if (formToDelete) {
      handleDeleteForm(formToDelete);
      handleCloseDeleteModal();
    }
  };

  const handleOpenEditModal = (form) => {
    setFormToEdit(form);
    setEditFormData({
      title: form.title,
      dateOfActivity: form.activityDate.split("T")[0], // Set initial value without time
      isShared: form.isShared === "Yes",
      isAchieved: form.isAchieved === "Yes",
      isCounterSigned: form.isCounterSigned === "Yes",
      isFilled: form.isFilled === "Yes",
    });
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setFormToEdit(null);
    setEditFormData({
      title: "",
      dateOfActivity: "", // Reset field
      isShared: false,
      isAchieved: false,
      isCounterSigned: false,
      isFilled: false,
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value, checked, type } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const confirmEditForm = () => {
    if (formToEdit) {
      handleEditForm(formToEdit._id, {
        ...editFormData,
        isShared: editFormData.isShared ? true : false,
        isAchieved: editFormData.isAchieved ? true : false,
        isCounterSigned: editFormData.isCounterSigned ? true : false,
        isFilled: editFormData.isFilled ? true : false,
      });
    }
  };

  useEffect(() => {
    fetchForms(showFilledForms ? "filled" : "draft");
  }, [showFilledForms]);

  return (
    <Box sx={{ padding: "2.5rem", height: "100vh" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: "16px", mb: 2, fontWeight: "500" }}
      >
        My Forms
      </Typography>
      <Box display="flex" gap={2} marginBottom={2}>
        <Button
          disableElevation
          variant="contained"
          startIcon={<FaFileSignature />}
          onClick={handleShowDraftForms}
          style={{
            backgroundColor: !showFilledForms ? "#2C2B83" : "#E5E4FF",
            color: !showFilledForms ? "white" : "black",
            borderRadius: "30px",
            textTransform: "none",
          }}
        >
          Draft forms
        </Button>
        <Button
          disableElevation
          variant="contained"
          startIcon={<FaFileLines />}
          onClick={handleShowFilledForms}
          style={{
            backgroundColor: showFilledForms ? "#2C2B83" : "#E5E4FF",
            color: showFilledForms ? "white" : "black",
            borderRadius: "30px",
            textTransform: "none",
          }}
        >
          Filled Forms
        </Button>
      </Box>
      <Grid container spacing={2}>
        {forms.map((form) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={form._id}>
            <Card sx={{ borderRadius: "16px" }} elevation={0}>
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  Title
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontSize: "13px", mb: 1 }}
                >
                  {form.title}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                      Date Of Modified
                    </Typography>
                    <Typography variant="body2">{form.modifiedDate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                      Date Of Activity
                    </Typography>
                    <Typography variant="body2">{form.activityDate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                      Shared
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: getColor(form.isShared) }}
                    >
                      {form.isShared}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#525252", fontSize: "11px" }}
                    >
                      Achieved
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: getColor(form.isAchieved) }}
                    >
                      {form.isAchieved}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                      Countersigned
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: getColor(form.isCounterSigned) }}
                    >
                      {form.isCounterSigned}
                    </Typography>
                  </Grid>
                </Grid>
                <Box display="flex" marginTop={2}>
                  {!showFilledForms && (
                    <IconButton
                      sx={{
                        backgroundColor: "#E5E4FF",
                        "&:hover": {
                          backgroundColor: "#D4D3FF",
                        },
                      }}
                      onClick={() => handleOpenEditModal(form)}
                    >
                      <CiEdit style={{ color: "#2C2B83" }} />
                    </IconButton>
                  )}
                  <IconButton
                    sx={{
                      backgroundColor: "#FFEBEB",
                      "&:hover": {
                        backgroundColor: "#FFD4D4",
                      },
                      marginLeft: 1,
                    }}
                    onClick={() => handleOpenDeleteModal(form._id)}
                  >
                    <MdDelete style={{ color: "#D32F2F" }} />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDeleteModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Confirm Delete
            </Typography>
            <Typography variant="body1">
              Are you sure you want to delete this form?
            </Typography>
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              <Button onClick={handleCloseDeleteModal} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button onClick={confirmDeleteForm} color="error">
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEditModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Edit Form
            </Typography>
            <TextField
              name="title"
              label="Title"
              fullWidth
              margin="normal"
              value={editFormData.title}
              onChange={handleEditFormChange}
            />
            <TextField
              name="dateOfActivity"
              label="Date Of Activity"
              type="date"
              fullWidth
              margin="normal"
              value={editFormData.dateOfActivity}
              onChange={handleEditFormChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isShared"
                  checked={editFormData.isShared}
                  onChange={handleEditFormChange}
                />
              }
              label="Shared"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isAchieved"
                  checked={editFormData.isAchieved}
                  onChange={handleEditFormChange}
                />
              }
              label="Achieved"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isCounterSigned"
                  checked={editFormData.isCounterSigned}
                  onChange={handleEditFormChange}
                />
              }
              label="Countersigned"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isFilled"
                  checked={editFormData.isFilled}
                  onChange={handleEditFormChange}
                />
              }
              label="Filled"
            />
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              <Button onClick={handleCloseEditModal} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button onClick={confirmEditForm} variant="contained">
                Save
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default MyForms;
