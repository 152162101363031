import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Stack,
  Button,
  Grid,
  Modal,
} from "@mui/material";
import { subService, userService } from "../../service/index";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import FileLoader from "../../components/FileLoader/FileLoader";
import "./DashBoard.css";
import big from "../../images/bigprofile.jpg";
import premium from "../../images/premium.png";
import phone from "../../images/phone.jpg";
import mail from "../../images/mail.png";
import tank from "../../images/tankfile.jpg";
import tank2 from "../../images/filetank2.jpg";
import new1 from "../../images/news1.jpg";
import news from "../../images/news-ico.svg";
import arrow from "../../images/arrow.jpg";
import Close from "../../images/close-icon.svg";
import CircularWithValueLabel from "../../components/usage-graph/usage";
import { useNavigate } from "react-router-dom";
import { setProfileImage } from "../../utils/storage-utils";
import Events from "../../components/events/Events";
import { newsService } from "../../service/index"; //
import { AiOutlineClose } from "react-icons/ai";
import { convertToIST } from "../../utils/constants";
import CourseStatus from "../../components/course-status/CourseStatus";

function formatDate(dateStr) {
  const date = new Date(dateStr);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate().toString().padStart(2, "0");

  return `${month} ${day}, ${year}`;
}

const Dashboard = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [eventModal, openEventModal] = useState(false);
  const { id } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [sessions, openSessions] = useState(true);
  // const [mocks, openMocks] = useState(false);
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleArrowClick = (newsItem) => {
    setSelectedNews(newsItem);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    async function fetchNewsData() {
      const news = await newsService.getAllNews();
      if (news.status) {
        setNewsData(news.data);
      }
    }
    fetchNewsData();
  }, []);

  const getUserData = useCallback(async (userId) => {
    setIsLoading(true);
    await userService.getSingle(userId).then((res) => {
      if (!res.status || !res.data) {
        console.log("UserRes\t\t\t", res);
        setIsLoading(false);
        snackbar("Could not load resources", { variant: "error" });
        return;
      }
      setUser(res.data);
      setProfileImage(res.data.imageUrl);
      console.log("User_Data\t\t\t", res.data);
      setIsLoading(false);
    });
  }, [snackbar]);

  useEffect(() => {
    async function fetchData() {
      await getUserData(id);
    }
    fetchData();
  }, [id, getUserData]);



  if (isLoading) {
    return <FileLoader />;
  }

  // const handleSessionClick = () => {
  //   openMocks(false);
  //   openSessions(true);
  // };

  // const handleMocksClick = () => {
  //   openSessions(false);
  //   openMocks(true);
  // };
  console.log("newsdataaaaaa", newsData);

  const handleEventOpen = (event) => {
    console.log("Sele\t\t\t", event);
    setSelectedEvent(event);
    openEventModal(true);
  };

  const handleEventBooking = async () => {
    setIsLoading(true);
    try {
      if (!user) {
        setSelectedEvent(null);
        snackbar("Please login to book events", { variant: "warning" });
        navigate("/sign-in");
        return;
      }
      const res = await subService.bookEvent(selectedEvent._id);
      console.log("BookingResponse\t\t", res);
      if (res.exists) {
        snackbar("Booking already exists", { variant: "success" });
        return;
      }
      if (res.status && res.data) {
        if (res.status) {
          snackbar("Booking Successful", { variant: "success" });
        }
      } else {
        console.log("Event_Booking_Error_X", res);
        snackbar("Booking failed", { variant: "error" });
      }
    } catch (error) {
      console.error("Event_Booking_Error", error);
      snackbar("Booking failed", { variant: "error" });
    } finally {
      setIsLoading(false);
      setSelectedEvent(null);
      openEventModal(false);
      setTrigger(!trigger);
    }
  };

  return (
    <>
      <div class="container">
        <Grid
          container
          className="sec-1"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="profile"
            sx={{
              mt: {
                md: 0,
                sm: "1.5rem",
                xs: "1.5rem",
              },
            }}
          >
            <Grid
              container
              sx={{
                height: {
                  md: "12rem",
                  sm: "20rem",
                  xs: "20rem",
                },
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4.08}
                sx={{
                  display: "flex",
                  background: localStorage.getItem("ePortImage")
                    ? `url(${localStorage.getItem("ePortImage")})`
                    : `url(${big})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  borderRadius: "0.5rem",
                  height: {
                    md: "100%",
                    sm: "45%",
                    xs: "45%",
                  },
                  width: "100%",
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7.5}
                sx={{
                  // border: "1px solid red",
                  flexGrow: 1,
                  padding: {
                    md: "0",
                    sm: "2% 35% 0 35%",
                    xs: "2% 35% 0 35%",
                  },
                  height: {
                    md: "100%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: {
                    md: "space-between",
                  },
                }}
              >
                {/* <Box sx={{border : '1px solid blue' ,height : '100%'}}></Box> */}
                {user?.isPremium && (
                  <Box>
                    <img src={premium} alt="" style={{ width: "5rem" }} />
                  </Box>
                )}
                <Typography
                  sx={{
                    fontSize: "26.8px",
                    // color: "#4A4A4A",
                    fontWeight: "600",
                    color: "#232323",
                    // border : '1px solid red',
                    lineHeight: "100%",
                  }}
                >
                  {user?.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "10.5px",
                      color: "#232323",
                      fontWeight: "600",
                      display: "flex",
                    }}
                  >
                    Joined Date :{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {convertToIST(user?.createdAt)}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10.5px",
                      color: "#232323",
                      fontWeight: "600",
                    }}
                  >
                    Status :{" "}
                    <span style={{ fontWeight: "bold" }}>{user?.status}</span>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "10.5px",
                      color: "#252525",
                      fontWeight: "600",
                    }}
                  >
                    Hospital Name :{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {user?.hospitalName}
                    </span>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img style={{ height: "1.5rem" }} src={phone} alt="" />
                    <Typography
                      sx={{
                        margin: "0 0.5rem 0 0",
                        fontSize: "10.34px",
                        fontWeight: "600",
                      }}
                    >
                      {user?.address?.countryCode}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10.34px",
                        margin: "0",
                        fontWeight: "600",
                      }}
                    >
                      {user?.mobile}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex" }}
                    style={{ alignItems: "center" }}
                  >
                    <img
                      style={{ height: "1.5rem", marginRight: "0.5rem" }}
                      src={mail}
                      alt=""
                    />
                    <Typography
                      sx={{
                        margin: "0",
                        fontSize: "10.34px",
                        fontWeight: "600",
                      }}
                    >
                      {user?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    sx={{
                      border: "2px solid #EB568F",
                      borderRadius: "2rem",
                      mt: "0.8rem",
                      color: "#EB568F",
                      fontSize: "11.48px",
                      width: {
                        md: "50%",
                        xs: "100%",
                        sm: "100%",
                      },
                      padding: "0.5rem",
                      fontWeight: 600,
                      textTransform: "none",
                      "&:hover": {
                        color: "#fff",
                        backgroundColor: "#EB568F",
                      },
                    }}
                    onClick={() => {
                      navigate(`/user/profile/${id}`);
                    }}
                  >
                    View full profile
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            item
            sx={{
              mt: {
                md: 0,
                sm: "1.5rem",
                xs: "1.5rem",
                backgroundColor: '#fff',
                borderRadius: '0.5rem',
                padding: '1rem',
                // border: '1px solid red',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'

              },
            }}
          >
            <Typography sx={{
              fontWeight: '600',
              fontSize: '14.5px',
              fontFamily: 'Poppins',
              color: '#252525'
            }}>Total Size Used</Typography>
            <Box className="graph">
              <Box
                sx={{
                  display: "flex",
                  width: '100%',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                  // border: '1px solid blue'
                }}
              >
                <CircularWithValueLabel></CircularWithValueLabel>
                <Box>
                  <Box sx={{ display: "flex", alignItems: 'center', mb: '0.5rem' }}>
                    <Box
                      sx={{
                        width: "1.3rem",
                        height: "1.3rem",
                        bgcolor: "#EB568F",
                        mr: '0.5rem'
                      }}
                    ></Box>
                    <Typography sx={{
                      fontWeight: '600',
                      fontSize: '8.64px',
                      fontFamily: 'Poppins',
                      color: '#252525'
                    }}>storage used</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: 'center' }}>
                    <Box
                      sx={{
                        width: "1.3rem",
                        height: "1.3rem",
                        bgcolor: "#D8D6D6",
                        mr: '0.5rem'
                      }}
                    ></Box>
                    <Typography sx={{
                      fontWeight: '600',
                      fontSize: '8.64px',
                      fontFamily: 'Poppins',
                      color: '#252525'
                    }}>storage Free space</Typography>
                  </Box>

                </Box>

              </Box>

            </Box>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '8.64px',
              fontFamily: 'Poppins',
              color: '#525252'
            }}>*For Free users, a content limit of 20 GBx</Typography>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={2.1}
            item
            className="files main-box"
            sx={{
              mt: {
                md: 0,
                sm: "1.5rem",
                xs: "1.5rem",
              },
            }}
          >
            <div className="file-ico">
              <img
                src={tank}
                style={{ maxWidth: "100%", height: "auto" }}
                alt=""
              />
            </div>
            <h5>Created Files</h5>
            <div className="file-count">
              <div className="page-ico dflex">
                <img
                  src={tank2}
                  style={{ maxWidth: "100%", height: "auto" }}
                  alt=""
                />
                <h6>{`${user?.fileCount} Files`}</h6>
              </div>
              <div className="file-small">
                <div className="link">
                  <Typography
                    sx={{
                      color: "#EB568F",
                      whiteSpace: "nowrap",
                      fontSize: "12.04px",
                      fontWeight: "600",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/user/files/${id}`);
                    }}
                  >
                    View files
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="sec-2 dflex">
          <div className="events main-box">
            <Events userId={id} handleEventOpen={handleEventOpen} />
          </div>
          <div className="news main-box">
            <div className="news-head dflex">
              <img
                src={news}
                style={{
                  width: "1.8rem",
                  height: "auto",
                  marginRight: "0.5rem",
                }}
                alt=""
              />
              <h4>News</h4>
            </div>
            <Box>
              {newsData.map((newsItem) => (
                <Box key={newsItem.id} marginTop={2}>
                  <Stack direction={"row"} spacing={3}>
                    <Box
                      sx={{
                        width: "6.5rem",
                        height: "4.5rem",
                        background: newsItem.image
                          ? `url(${newsItem.image})`
                          : `url(${new1})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    />
                    <Box>
                      <Typography>{newsItem.title}</Typography>

                      <Stack direction={"row"} mt={1.5}>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#525252",
                          }}
                        >
                          <span style={{ fontSize: "30px" }}></span>{" "}
                          {formatDate(newsItem.date)}
                        </Typography>

                        <Box
                          onClick={() => handleArrowClick(newsItem)}
                          sx={{
                            mt: "15px",
                            ml: "16rem",
                            width: "1.5rem",
                            height: "1.5rem",
                            background: `url(${arrow})`,
                            backgroundSize: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </Stack>
                    </Box>
                  </Stack>

                  <hr color="#D9D9D9" />
                </Box>
              ))}
            </Box>
            <Modal open={isModalOpen} onClose={handleCloseModal}>
              <Box>
                {selectedNews && (
                  <div>
                    {console.log("selectdataaa", selectedNews)}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        mt: "10rem",
                      }}
                    >
                      <Paper
                        sx={{
                          width: "60%",
                          height: "65vh",
                          padding: "3% 5% 3% 5%",
                          borderRadius: "15px",
                        }}
                      >
                        <div className="news-head dflex">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={news}
                              style={{
                                width: "1.5rem",
                                height: "auto",
                                marginRight: "0.5rem",
                              }}
                              alt=""
                            />
                            <Typography sx={{ fontSize: "15px", ml: "10px" }}>
                              News
                            </Typography>
                          </div>
                          <div
                            style={{ marginLeft: "auto", cursor: "pointer" }}
                            onClick={handleCloseModal}
                          >
                            <AiOutlineClose />
                          </div>
                        </div>
                        <Stack direction={"row"}>
                          <Typography variant="h6" sx={{ mt: "10px" }}>
                            {selectedNews.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#525252",
                              bottom: "1.5rem",
                              ml: "40rem",
                            }}
                          >
                            <span
                              style={{ fontSize: "30px", marginRight: "5px" }}
                            >
                              .
                            </span>{" "}
                            {formatDate(selectedNews.date)}
                          </Typography>
                        </Stack>
                        <Box
                          sx={{
                            width: "100%",
                            height: "12rem",
                            background: selectedNews.image
                              ? `url(${selectedNews.image})`
                              : `url(${new1})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            mt: "20px",
                          }}
                        />
                        <Typography variant="body1" mt={3}>
                          {selectedNews.content}
                        </Typography>
                      </Paper>
                    </Box>
                  </div>
                )}
              </Box>
            </Modal>

            <Modal
              open={eventModal}
              onClose={() => openEventModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "25%",
                    height: "25%",
                    borderRadius: "0.5rem",
                    padding: "3%",
                    position: "relative",
                  }}
                >
                  <img
                    src={Close}
                    alt=""
                    style={{
                      height: "1.5rem",
                      cursor: "pointer",
                      position: "absolute",
                      right: "2.5%",
                      top: "4%",
                    }}
                    onClick={() => openEventModal(false)}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "",
                      color: "",
                      fontWeight: "",
                    }}
                  >
                    Are you sure you want to book <br />
                    <span style={{ fontWeight: "600" }}>
                      {selectedEvent?.title}
                    </span>{" "}
                    ?
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifySelf: "flex-end",
                      justifyContent: "space-between",
                      mt: "1.5rem",
                    }}
                  >
                    <Button
                      sx={{
                        width: "45%",
                        bgcolor: "#f4f4f4",
                        border: "1px solid #EB568F",
                        "&:hover": { bgcolor: "#2C2B83", color: "#ffffff" },
                      }}
                      onClick={() => openEventModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        width: "45%",
                        bgcolor: "#EB568F",
                        color: "#ffffff",
                        "&:hover": { bgcolor: "#2C2B83" },
                      }}
                      onClick={() => {
                        handleEventBooking();
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </div>
        </div>
        <Box sx={{
          // border: '1px solid red',
          mt: '1rem',
        }}>
          <CourseStatus />
        </Box>
      </div>
    </>
  );
};

export default Dashboard;
