const assessmentService = (httpClient) => {
  return {
    fetchTickets: async () => {
      try {
        const res = await httpClient.get("/fetch/assessment/");
        console.log("Fetched tickets response:", res);
        return res.data;
      } catch (error) {
        console.error("Error fetching tickets:", error);
        return { status: false, message: "Failed to fetch tickets" };
      }
    },
    addTicket: async (data) => {
      try {
        const res = await httpClient.post("/add/assessment/main", data);
        console.log("Add ticket response:", res);
        return res.data;
      } catch (error) {
        console.error("Error adding ticket:", error);
        return { status: false, message: "Failed to add ticket" };
      }
    },
    updateTicket: async (data) => {
      try {
        const res = await httpClient.post("/edit/assessment/main", data);
        console.log("Update ticket response:", res);
        return res.data;
      } catch (error) {
        console.error("Error updating ticket:", error); 
        return { status: false, message: "Failed to update ticket" };
      }
    },
    deleteTicket: async (assessmentId) => {
      try {
        const res = await httpClient.post("/remove/assessment/", {
          assessmentId,
        });
        console.log("Delete ticket response:", res);
        return res.data;
      } catch (error) {
        console.error("Error deleting ticket:", error);
        return { status: false, message: "Failed to delete ticket" };
      }
    },
    fetchTicketDetails: async (assessmentId) => {
      try {
        // Validate ticketId before making the request
        if (!assessmentId) {
          throw new Error("Invalid ticketId provided");
        }

        const res = await httpClient.post("/fetch/assessment/details", {
          assessmentId,
        });
        console.log("Fetched ticket details response:", res);
        return res.data;
      } catch (error) {
        // Log detailed error information
        console.error(
          "Error fetching ticket details:",
          error.response?.data || error.message
        );
        return { status: false, message: "Failed to fetch ticket details" };
      }
    },
  };
};

export default assessmentService;
