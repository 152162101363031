import { CssBaseline } from "@mui/material";
import React, { Suspense, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader/Loader";
import Themes from "./components/themes/index";
import Routers from "./routes/index";
import { store } from "./state/store/index";
import { loaderContext } from "./context/index";

const App = () => {
  const [trigger, setTrigger] = useState(false);
  return (
    <div className="App full-height">
      <loaderContext.Provider value={{ trigger, setTrigger }}>
        <Provider store={store}>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Themes>
                <CssBaseline />
                <Routers />
              </Themes>
            </Suspense>
          </BrowserRouter>
        </Provider>
      </loaderContext.Provider>
    </div>
  );
};

export default App;