import { Route, Routes } from "react-router-dom";
import { apiConfig } from "../config/api-config";
import Template from "../components/template/template";
import UserSignIn from "../pages/sign-in/Login";
import UserRegister from "../pages/Register/Register";
import FourNotFour from "../pages/FourNotFour";
import Dashboard from "../pages/dashboard/Dashboard";
import DashboardTwo from "../pages/dashboard/DasboardTwo";

import Home from "../pages/home/Home";
import Solutions from "../pages/solutions/Solutions";
import Clinicalprograms from "../pages/clinicalprograms/ClinicalPrograms";
import Careerguidance from "../pages/careerguidance/CareerGuidance";
import Profile from "../pages/profile/profile";
import ClinicalProfile from "../pages/clinical-profile/ClinicalProfile";
import EditProfile from "../pages/edit-profile/edit-profile";
import UserCareer from "../pages/user-career/user-career";
import UserClinical from "../pages/user-clinical/user-clinical";
import UserFiles from "../pages/user-files/user-files";
import UserLibrary from "../pages/user-library/user-library";
import LibFiles from "../pages/lib-files/lib-files";
import Tickets from "../pages/tickets/Tickets";
import MyForms from "../pages/myforms/MyForms";
import Curriculum from "../pages/curriculum/Curriculum";
import Assessment from "../pages/assessment/Assessment";
import Reflection from "../pages/reflection/Reflection";
import Appraisal from "../pages/appraisal/Appraisal";
import ProgressLog from "../pages/progresslog/ProgressLog";
import ELearning from "../pages/elearning/E-Learning";
import ExternalAssessment from "../pages/progresslog/ExternalAssessment";
import FileListTable from "../pages/curriculum/FileListTable";

const Routers = () => {
  // const auth = useSelector(({ auth }) => auth.value);
  // console.log("Auth\t\t\t", auth)

  return (
    <>
      <Routes>
        <Route
          path={`${apiConfig.basePath}/sign-in`}
          element={<UserSignIn />}
        />
        <Route
          path={`${apiConfig.basePath}/register`}
          element={<UserRegister />}
        />
        <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Template>
              <Home />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/home/`}
          element={
            <Template>
              <Home />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/solutions`}
          element={
            <Template>
              <Solutions />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/clinical`}
          element={
            <Template>
              <Clinicalprograms />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/career`}
          element={
            <Template>
              <Careerguidance />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/dashboard/:id`}
          element={
            <Template>
              <Dashboard />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/dashboardT/:id`}
          element={
            <Template>
              <DashboardTwo />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/profile/:id`}
          element={
            <Template>
              <Profile />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/profile/:id`}
          element={
            <Template>
              <ClinicalProfile />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/edit/:userID`}
          element={
            <Template>
              <EditProfile />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/career/:id`}
          element={
            <Template>
              <UserCareer />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/:id`}
          element={
            <Template>
              <UserClinical />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/files/:id`}
          element={
            <Template>
              <UserFiles />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/files/:id/:recordId`}
          element={
            <Template>
              <UserFiles />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/library/:id`}
          element={
            <Template>
              <UserLibrary />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/lib/files/:pid`}
          element={
            <Template>
              <LibFiles />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/lib/files/:pid/:id`}
          element={
            <Template>
              <LibFiles />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/tickets/:id`}
          element={
            <Template>
              <Tickets />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/myforms/:id`}
          element={
            <Template>
              <MyForms />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/curriculum/:id`}
          element={
            <Template>
              <Curriculum />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/assessment/:id`}
          element={
            <Template>
              <Assessment />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/reflection/:id`}
          element={
            <Template>
              <Reflection />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/appraisal/:id`}
          element={
            <Template>
              <Appraisal />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/progresslog/:id`}
          element={
            <Template>
              <ProgressLog />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/user/clinical/elearning/:id`}
          element={
            <Template>
              <ELearning />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/user/progresslog/external-assessment/:id`}
          element={
            <Template>
              <ExternalAssessment />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/file-list/:fileType`}
          element={
            <Template>
              <FileListTable />
            </Template>
          }
        />
        <Route path="*" element={<FourNotFour />} />
      </Routes>
    </>
  );
};

export default Routers;
