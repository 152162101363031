import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
// import { useParams } from "react-router-dom";
import "./program-component.css";
import {
  Modal,
  Box,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { programService } from "../../service/index";
import { userService } from "../../service/index";
import NavBar from "../../components/navbar/NavBarTwo";
import backIcon from "../../images/back-icon.svg";
import SampleVideo from "../../images/sampleClinical.mp4";
import ReviewDemo from "../../images/review-demo.png";
import Badge from "../../images/badge-icon.svg";
import { getAuth } from "../../utils/storage-utils";
import AudioIco from "../../images/audio-icon.svg";
import QBIcon from "../../images/qbIcon.svg";
import VidIcon from "../../images/video-icon.svg";
import podIcon from "../../images/pod-ico.svg";
import docIcon from "../../images/doc-ico.svg";
import ImageIcon from "../../images/image-icon.svg";
import chapterDefault from "../../images/thumbanilVid.png";
import king2 from "../../images/king2.png";
import books from "../../images/books.png";

const Program = ({ programID, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [programData, setProgramData] = useState(null);
  const [session, setSession] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formError, setFormError] = useState("");

  const [selectedProgram, setSelectedProgram] = useState(null);

  const [program, setProgram] = useState();
  const fetchProgram = useCallback(() => {
    setIsLoading(true);
    programService.getLibraryById(programID).then((res) => {
      console.log("ProgramLIB:\t\t\t\t", res);
      if (!res.status || !res.data) {
        console.log("ProgERR\t\t\t", res);
        enqueueSnackbar("Program fetching failed", { variant: "error" });
        return;
      }
      setProgram(res.data);
      setIsLoading(false);
    });
  }, [programID, enqueueSnackbar]);

  useEffect(() => {
    const session = getAuth();
    setSession(session);

    async function fetchData() {
      try {
        setIsLoading(true);
        console.log("ID\t\t\t\t", programID);
        const response = await programService.getSingleProgram(programID);
        if (!response.status || !response.data) {
          enqueueSnackbar("Could not load resources", { variant: "error" });
          return;
        }
        console.log("Response\t\t\t\t", response.data);
        setProgramData(response.data);

        // Extract unique location and hospital names from hospitalData
        const locations = [
          ...new Set(response.data.hospitalData.map((item) => item.location)),
        ];
        const hospitals = [
          ...new Set(response.data.hospitalData.map((item) => item.name)),
        ];

        setLocationOptions(locations);
        setHospitalOptions(hospitals);
      } catch (error) {
        console.error("Error fetching program data:", error);
        enqueueSnackbar("An error occurred while fetching data", { variant: "error" });
      } finally {
        // setIsLoading(false);
      }
    }

    fetchData();
    fetchProgram();
  }, [programID, enqueueSnackbar, fetchProgram]);



  // Filter hospital options based on selected location
  // const filteredHospitals = programData?.hospitalData?.filter(
  //   (hospital) => hospital.location === selectedLocation
  // );

  // Filter location options based on selected hospital
  // const filteredLocations = programData?.hospitalData
  //   ?.filter((hospital) => hospital.name === selectedHospital)
  //   .map((hospital) => hospital.location);

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setSelectedLocation(newLocation);

    // Filter hospital options based on the selected location
    const hospitalsForLocation = programData?.hospitalData
      ?.filter((hospital) => hospital.location === newLocation)
      .map((hospital) => hospital.name);

    setHospitalOptions(hospitalsForLocation);
    // setSelectedHospital(''); // Reset selected hospital
  };

  const handleHospitalChange = (event) => {
    const newHospital = event.target.value;
    setSelectedHospital(newHospital);
    // Find the selected hospital object in hospitalData
    const selectedHospitalObject = programData?.hospitalData?.find(
      (hospital) =>
        hospital.location === selectedLocation && hospital.name === newHospital
    );

    if (selectedHospitalObject) {
      // Now you have the selected hospital object with all its information
      // const hospitalId = selectedHospitalObject._id;
      // You can access other properties as needed
      setSelectedProgram(selectedHospitalObject);
      console.log("Selected Hospital ID:", selectedHospitalObject);
    }
  };

  const handleSubmit = () => {
    if (!selectedLocation || !selectedHospital) {
      setFormError("Please select both location and hospital.");
    } else {
      setIsModalOpen(true); // Show confirmation modal
    }
  };

  const handleConfirm = async () => {
    try {
      const data = {
        userId: session.userId,
        hospitalId: selectedProgram._id,
        programId: programData._id,
      };
      setIsLoading(true);
      const response = await userService.joinProgram(data);
      if (!response.status || !response.data) {
        console.log("RESPONSEJOIN\t\t\t", response);
        enqueueSnackbar("Subsciption Failed", { variant: "error" });
        return;
      }
      console.log("Response\t\t\t\t", response.data);
      enqueueSnackbar("Subsciption was successful", { variant: "success" });
    } catch (error) {
      console.log("Error joining program :", error);
      enqueueSnackbar("Subscription Failed", { variant: "error" });
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <FileLoader />;
  }

  return (
    <>
      {programData && (
        <Grid
          container
          sx={{
            height: "auto",
            width: "100vw",
            backgroundColor: "#F9F9FB",
            padding: "7%",
            justifyContent: "space-between",
          }}
        >
          <NavBar />
          <Grid
            item
            sx={{
              width: "100%",
              height: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={() => {
                onClose();
              }}
            >
              <img src={backIcon} alt="" />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "#7A7A7A",
                  ml: "0.5rem",
                }}
              >
                Back
              </Typography>
            </Box>
          </Grid>

          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5.5}
                xl={5.8}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "3rem",
                  padding: "2rem",
                  mt: "2.5rem",
                  height: "100%",
                  //   width : '100%',
                  // border: "1px solid blue",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <video
                    controls
                    style={{
                      minWidth: "100%",
                      maxWidth: "100%",
                      borderRadius: "1rem",
                    }}
                    onLoadedData={() => setIsLoading(false)}
                  >
                    <source
                      src={
                        programData?.videoUrl !== undefined &&
                          programData?.videoUrl !== null
                          ? programData.videoUrl
                          : SampleVideo
                      }
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
                <Box
                  sx={{
                    mt: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "25px",
                      fontWeight: "600",
                      color: "#000020",
                    }}
                  >
                    {programData?.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "60%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      mb: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "#000020",
                      }}
                    >
                      Reviews & Rating
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        width: "70%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "32px",
                          fontWeight: "600",
                          color: "#252525",
                          fontFamily: "Poppins",
                          mr: "1rem",
                        }}
                      >
                        4.5
                      </Typography>
                      <Box>
                        <img
                          src={ReviewDemo}
                          alt=""
                          style={{ maxWidth: "100%" }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "8px",
                            color: "#252525",
                          }}
                        >
                          Based on 1123 ratings
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "18.55px",
                      color: "#000020",
                      fontWeight: "600",
                    }}
                  >
                    About Course
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      color: "#252525",
                    }}
                  >
                    {programData?.about}
                  </Typography>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "18.55px",
                        color: "#000020",
                        fontWeight: "600",
                      }}
                    >
                      Hospitals :
                    </Typography>
                  </Box>
                  {programData && (
                    <Box sx={{ padding: "0 1rem 0 1rem" }}>
                      {console.log("HospitalData\t\t\t", programData)}
                      {programData.hospitalData.map((hospital) => (
                        <Box
                          sx={{
                            m: "1rem 0 1rem 0",
                          }}
                          key={hospital.name}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "600",
                              display: "flex",
                            }}
                          >
                            {hospital.name}
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "600",
                                display: "flex",
                                ml: "0.5rem",
                              }}
                            >
                              - {hospital.location}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "noraml",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            }}
                          >{`${hospital.state}, ${hospital.country}`}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "18.55px",
                      color: "#000020",
                      fontWeight: "600",
                    }}
                  >
                    What you'll learn
                  </Typography>
                  {programData?.uses?.map((use) => (
                    <Box
                      sx={{
                        display: "flex",
                        mt: "1rem",
                      }}
                      key={use}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          mr: "1rem",
                        }}
                      >
                        <img src={Badge} alt="" />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Poppins",
                            color: "#252525",
                          }}
                        >
                          {use}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "18.55px",
                      color: "#000020",
                      fontWeight: "600",
                    }}
                  >
                    Requirements
                  </Typography>
                  {programData?.requirements?.map((requirement) => (
                    <Box
                      sx={{
                        display: "flex",
                        mt: "1rem",
                      }}
                      key={requirement}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Poppins",
                          color: "#252525",
                        }}
                      >
                        {requirement}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "18.55px",
                      color: "#000020",
                      fontWeight: "600",
                    }}
                  >
                    Skills You Will Gain
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: "1rem",
                      flex: "wrap",
                    }}
                  >
                    {programData?.skills?.map((skill) => (
                      <Box
                        sx={{
                          backgroundColor: "#EBECED",
                          borderRadius: "2rem",
                          height: "3rem",
                          padding: "1.5rem",
                          width: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mr: "1rem",
                        }}
                        key={skill}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {skill}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={5.5} xl={5.8}>
                {program && (
                  <Grid
                    sx={{
                      backgroundColor: "#F9F9FB",
                      borderRadius: "3rem",
                      mt: "2.5rem",
                      height: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <Grid
                      container
                      gap={2}
                      sx={{
                        backgroundColor: "#fff",
                        padding: "2rem",
                        borderRadius: "3rem",
                      }}
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          //   border: "1px solid red",
                          height: "6rem",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "25%",
                            height: "100%",
                            borderRadius: "1rem",
                            overflow: "hidden",
                            background: `url(${chapterDefault})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "75%",
                            height: "100%",
                            // border: "1px solid red",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "1rem",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#000020",
                              }}
                            >
                              Indroduction
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#252525",
                              }}
                            >
                              3 Lectures.10 min
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              height: "100%",
                              flexGrow: 0.8,
                              justifyContent: "space-between",
                              mt: "1rem",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#252525",
                                textDecoration: "underline",
                              }}
                            >
                              Preview
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#252525",
                              }}
                            >
                              0:35
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          //   border: "1px solid red",
                          height: "6rem",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "25%",
                            height: "100%",
                            borderRadius: "1rem",
                            overflow: "hidden",
                            background: `url(${chapterDefault})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            width: "75%",
                            height: "100%",
                            // border: "1px solid red",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "1rem",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#000020",
                              }}
                            >
                              Indroduction
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#252525",
                              }}
                            >
                              3 Lectures.10 min
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              height: "100%",
                              flexGrow: 0.8,
                              justifyContent: "space-between",
                              mt: "1rem",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#252525",
                                textDecoration: "underline",
                              }}
                            >
                              Preview
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                color: "#252525",
                              }}
                            >
                              0:35
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {program && (
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#F9F9FB",
                      borderRadius: "3rem",
                      mt: "2.5rem",
                      height: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        backgroundColor: "#fff",
                        padding: "2rem",
                        borderRadius: "3rem",
                      }}
                    >
                      <Grid
                        item
                        xs={4}
                        sm={3.8}
                        md={3.8}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: "1rem",
                        }}
                      >
                        <img src={AudioIco} alt="" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000020",
                          }}
                        >
                          Audio
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12.75px",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {program.audio?.length}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3.8}
                        md={3.8}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: "1rem",
                        }}
                      >
                        <img src={QBIcon} alt="" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000020",
                          }}
                        >
                          Question Bank
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12.75px",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {program.questionBank?.length}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3.8}
                        md={3.8}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: "1rem",
                        }}
                      >
                        <img src={podIcon} alt="" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000020",
                          }}
                        >
                          Podcasts
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12.75px",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {program.podcasts?.length}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3.8}
                        md={3.8}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: "1rem",
                        }}
                      >
                        <img src={docIcon} alt="" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000020",
                          }}
                        >
                          Document
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12.75px",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {program.documents?.length}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3.8}
                        md={3.8}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: "1rem",
                        }}
                      >
                        <img src={ImageIcon} alt="" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000020",
                          }}
                        >
                          Images
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12.75px",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {program.images?.length}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3.8}
                        md={3.8}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: "1rem",
                        }}
                      >
                        <img src={VidIcon} alt="" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000020",
                          }}
                        >
                          Video
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12.75px",
                            fontWeight: "600",
                            color: "#252525",
                          }}
                        >
                          {program.video?.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {session?.userId && session.isPremium && programData && (
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#F9F9FB",
                      borderRadius: "3rem",
                      mt: "2.5rem",
                      height: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        padding: "2rem",
                        borderRadius: "3rem",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            mb: "1.5rem",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              mb: "1.25rem",
                            }}
                          >
                            Location
                          </Typography>
                          <FormControl
                            sx={{ width: "100%", backgroundColor: "#fff" }}
                          >
                            <InputLabel htmlFor="location-select-label">
                              Select Your Location
                            </InputLabel>
                            <Select
                              labelId="location-select-label"
                              id="location-select"
                              value={selectedLocation}
                              label="Choose Location"
                              onChange={handleLocationChange}
                            >
                              {locationOptions.map((locationOption, index) => (
                                <MenuItem key={index} value={locationOption}>
                                  {locationOption}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              mb: "1.25rem",
                            }}
                          >
                            Hospital
                          </Typography>
                          <FormControl
                            sx={{ width: "100%", backgroundColor: "#fff" }}
                          >
                            <InputLabel htmlFor="hospital-select-label">
                              Select Your Hospital
                            </InputLabel>
                            <Select
                              labelId="hospital-select-label"
                              id="hospital-select"
                              value={selectedHospital}
                              label="Choose Hospital"
                              onChange={handleHospitalChange}
                            >
                              {hospitalOptions.map((hospitalOption, index) => (
                                <MenuItem key={index} value={hospitalOption}>
                                  {hospitalOption}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            mt: "1.5rem",
                          }}
                        >
                          <Button
                            sx={{
                              backgroundColor: "#EB568F",
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              color: "#fff",
                              width: "25%",
                              borderRadius: "2rem",
                              height: "3rem",
                              "&:hover": {
                                backgroundColor: "#2C2B83",
                              },
                              textTransform: "none",
                            }}
                            onClick={handleSubmit}
                          >
                            Start Now
                          </Button>
                          {formError && (
                            <Typography
                              sx={{
                                color: "red",
                                marginTop: "0.5rem",
                              }}
                            >
                              {formError}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )}

                {(!session?.userId || !session.isPremium) && (
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "3rem",
                      mt: "2.5rem",
                      height: "12rem",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        padding: "2rem",
                        borderRadius: "3rem",
                        // border: "1px solid red",
                        background: `url(${books})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center right",
                        backgroundRepeat: "no-repeat",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#2C2B83",
                        }}
                      >
                        Go to Premium
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "13.91px",
                          color: "#252525",
                        }}
                      >
                        with purchases, program is unlocked.
                      </Typography>
                      <Button
                        sx={{
                          bgcolor: "#2C2B83",
                          padding: "0.8rem",
                          textTransform: "none",
                          color: "#fff",
                          borderRadius: "1.5rem",
                          width: "40%",
                          "&:hover": {
                            bgcolor: "#F25D9C",
                          },
                        }}
                        onClick={() => {
                          navigate("/sign-in/");
                        }}
                      >
                        <img
                          src={king2}
                          alt=""
                          style={{
                            height: "1.5rem",
                            width: "auto",
                            marginRight: "0.5rem",
                            transform: "translateY(-0.2rem)",
                          }}
                        />
                        Get Premium
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Modal open={isModalOpen} onClose={handleCancel}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: "0.5rem",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "600" }}
              >
                Confirm Action
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Are you sure you want to start this program at{" "}
                {selectedLocation} - {selectedHospital}?
              </Typography>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={handleCancel}
                  sx={{
                    backgroundColor: "#EBEBEB",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#000",
                    width: "25%",
                    borderRadius: "0.5rem",
                    height: "3rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                      color: "#fff",
                    },
                    mr: "1rem",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  sx={{
                    backgroundColor: "#EB568F",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#fff",
                    width: "25%",
                    borderRadius: "0.5rem",
                    height: "3rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                    },
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      )}
    </>
  );
};

export default Program;
