import { Box } from "@mui/material";
import React from "react";
import NavBarTwo from "../navbar/NavBarTwo";
import Footer from "../footer/Footer";
// import Drawer from "./Drawer";
// import Loader from "../Loader";

const Public = ({ children }) => {
    // const [loading, setLoading] = useState(true);

    // if (loading) {
    //     return <Loader />;
    // }
    return (
        <Box className="PPP">
            <NavBarTwo />
            <Box className="">
                {children}
            </Box>
            <Footer />
        </Box>
    );
};

export default Public;
