import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { BiCloudUpload } from "react-icons/bi";
import DropZone from "./drop-Zone";

const DropArea = ({ updateParent = () => {} }) => {
  const theme = useTheme();

  return (
    <Box
      className="full-height"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <DropZone
        updateParent={() => {
          updateParent();
        }}
      >
        <Box
          sx={{
            background: theme.colors?.quaternary.default,
            borderRadius: "50%",
            height: "400px",
            width: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BiCloudUpload
              fontSize={85}
              color={theme.colors?.primary.default}
            />
            <Typography
              variant="h4"
              fontSize={22}
              sx={{ color: theme.colors?.greyText.default }}
            >
              Drop files here
            </Typography>
            <Typography
              variant="h5"
              fontSize={16}
              sx={{ paddingTop: "18px", color: theme.colors?.greyText.default }}
            >
              or use the "create new" button
            </Typography>
          </Box>
        </Box>
      </DropZone>
    </Box>
  );
};

export default DropArea;
