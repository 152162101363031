import * as React from "react";
import "./drawer.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NavBarTwo from "../navbar/NavBarTwo";
import { getSideBarOptions } from "../../config/app-config";
import logouticon from "../../images/logout-ico.svg";
import { userService } from "../../service";
import { useNavigate } from "react-router-dom";
import { getAuthStorage } from "../../utils/storage-utils";
import { useEffect } from "react";

const drawerWidth = 270;

function ResponsiveDrawer({ children }) {
  // const { id } = useParams();
  const { window } = { children };
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentOption, setCurrentOption] = React.useState();
  const [sideBarOptions, setSideBarOptions] = React.useState([]);

  const setOptions = React.useCallback(() => {
    const options = getSideBarOptions();
    console.log("options", options[0]);
    setCurrentOption(options[0]);
    setSideBarOptions(options)
  }, [])
  useEffect(() => {
    setOptions();
  }, [setOptions])

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    userService.logout();
  };

  const [id, setUser] = React.useState(null);

  useEffect(() => {
    const session = getAuthStorage();
    setUser(session.userId);
  }, [id]);




  const handleDrawerClick = (option) => {
    console.log("Drawer\t\t\t\t", option);
    // if (option.name === "dashboard") {
    navigate(`${option.url}/${id}`);
    setCurrentOption(option);
    // } else if (option.name === "profile") {
    //   navigate("/home/");
    // }
  };
  useEffect(() => {
    console.log("current", currentOption);
  }, [currentOption])

  const drawer = (
    <div className="drawer">
      <Toolbar />
      {/* <Divider /> */}
      <Typography
        sx={{
          ml: "2.5rem",
          mb: "1rem",
          color: "#A1ACB8",
        }}
      >
        MAIN MENU
      </Typography>
      <List
        sx={{
          ml: "1rem",
          mr: "1rem",
        }}
      >
        {sideBarOptions.map((options, index) => (
          <ListItem
            key={options.name}
            disablePadding
            sx={{
              borderRadius: "0.5rem",
              overflow: "hidden",
              color: "#697A8D",
              backgroundColor: currentOption?.title === options.title ? "#E9E9FF" : "",
              "&:hover": {
                backgroundColor: "#E9E9FF",
              },
            }}
            onClick={() => {
              handleDrawerClick(options);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <img alt="" src={currentOption?.title === options.title ? options.active : options.inactive}></img>
              </ListItemIcon>
              <ListItemText primary={options.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List
        sx={{
          mt: "20%",
          ml: "1.45rem",
          mr: "1.45rem",
        }}
      >
        <ListItem
          key={"logout"}
          disablePadding
          sx={{
            borderRadius: "0.5rem",
            overflow: "hidden",
            color: "#697A8D",
            "&:hover": {
              backgroundColor: "#E9E9FF",
            },
          }}
          onClick={handleLogout}
        >
          <ListItemButton>
            <ListItemIcon>
              <img src={logouticon} alt="" />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          zIndex: "9999",
        }}
      >
        <NavBarTwo />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              "-ms-overflow-style": "none",
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-track": {
                visibility: "hidden",
              },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "15 0vh",
          backgroundColor: "#F4F4FA",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
