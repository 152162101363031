import React, { useCallback, useState } from 'react'
import { Box, Grid, Button, Typography, Link, Stack } from '@mui/material'
import event from "../../images/events.jpg";
import { useEffect } from 'react';
import { eventService } from "../../service/index";
import defaultImage from "../../images/eventimg1.jpg"
import { useSnackbar } from 'notistack';
function formatDate(dateStr) {
  const date = new Date(dateStr);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');

  return `${month} ${day}, ${year}`;
}

const Events = ({ userId, handleEventOpen }) => {

  const [sessions, openSessions] = useState(true);
  const [mocks, openMocks] = useState(false);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  // const [isLoading, setIsLoading] = useState(false);

  const [sessionEvents, setSessionEvents] = useState(null);
  const [mockEvents, setMockEvents] = useState(null);
  // const [eventList, setEventList] = useState(null);
  const fetchEvents = useCallback(async () => {
    // setIsLoading(true);
    try {
      if (!userId) {
        snackbar("Something went wrong,\n,No user data was provided", { variant: "error" });
        return
      }
      const res = await eventService.getAllEvents(userId);
      if (res.status && res.data) {
        console.log("EventResponse\t\t", res);
        if (res.status) {
          // setEventList(res.data);
          setSessionEvents(() => res.data.filter((event) => event.isSession && event.isFree));
          setMockEvents(() => res.data.filter((event) => event.isMock && event.isFree));
        }
      } else {
        console.log("Error_Guidance_Event_X", res);
        snackbar("Failed to fetch data", { variant: "error" });
      }
    } catch (error) {
      console.error("Error_Guidance_Event", error);
      snackbar("Failed to fetch data", { variant: "error" });
    } finally {
      // setIsLoading(false);
    }
  }, [snackbar, userId]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);





  const handleSessionClick = () => {
    openMocks(false);
    openSessions(true);
  };

  const handleMocksClick = () => {
    openSessions(false);
    openMocks(true);
  };

  return (
    <>
      <Box sx={{}}>
        <Box sx={{
          display: 'flex',
        }}>
          <img src={event} alt="" style={{
            width: "1.5rem",
            height: "auto",
            marginRight: "0.5rem",
          }} />
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            color: '#2C2B83',
            fontWeight: '600',
          }}>Events</Typography>
        </Box>


        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: {
            md: '45%',
            sm: '100%',
            xs: '100%'
          },
          // border : '1px solid red     '
        }}>
          <Button
            sx={{
              border: "none",
              borderRadius: "2rem",
              backgroundColor: sessions ? "#E5E4FF" : "#F4F4F4",
              mt: "0.8rem",
              textTransform: 'none',

              width: "9rem",
              color: "#2C2B83",
              fontSize: "0.7rem",
              padding: "0.8rem",
              fontWeight: 700,
              "&:hover": {
                color: "#fff",
                backgroundColor: "#2C2B83",
              },
            }}
            onClick={handleSessionClick}
          >
            Free Sessions
          </Button>
          <Button
            sx={{
              border: "none",
              backgroundColor: mocks ? "#E5E4FF" : "#F4F4F4",
              borderRadius: "2rem",
              textTransform: 'none',
              mt: "0.8rem",
              ml: '0.4rem',
              width: "9rem",
              color: "#434343",
              fontSize: "0.7rem",
              padding: "0.8rem",
              fontWeight: 700,
              "&:hover": {
                color: "#fff",
                backgroundColor: "#2C2B83",
              },
            }}
            onClick={handleMocksClick}
          >
            Free Mocks
          </Button>

        </Box>


        {sessions && sessionEvents && (
          <Box>
            {/* Display the session data that meets the conditions */}
            {sessionEvents.map((event) => (
              <Grid container key={event._id}>
                <Grid item xs={12} sm={12} md={5}>
                  <Box
                    sx={{
                      width: {
                        md: '32vw',
                        sm: '100%',
                        xs: '100%'
                      },
                      border: '0.725px solid #D7D7D7',
                      borderRadius: '4.502px',
                      height: '20vh',
                      mt: '20px',
                      overflow: 'hidden'
                    }}
                  >

                    <Stack direction={'row'}>

                      <Box
                        sx={{
                          width: '35%',
                          height: '9.2rem',
                          background: event.image ? `url(${event.image})` : `url(${defaultImage})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                        }}
                      />
                      <Box padding={'17px'}>
                        <Stack direction={'row'}>
                          <Typography
                            sx={{

                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#525252',
                            }}
                          >
                            {event.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#525252',
                            }}
                          >
                            {event.category}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#525252',
                              position: 'relative',
                              left: '10rem',
                              bottom: '1.5rem'


                            }}
                          >
                            <span style={{ fontSize: '30px', marginRight: '5px' }}>.</span> {formatDate(event.date)}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: '14.408px',
                            fontWeight: '400',
                            marginBottom: '50px',
                            color: '#252525',
                            lineHeight: '21.611px',
                            wordSpacing: '5px',


                          }}
                        >
                          {event.description}
                        </Typography>
                        {!event.booked ? (<Typography
                          sx={{
                            color: '#EB568F',
                            fontSize: '13.659px',
                            fontWeight: '500',
                            position: 'relative',
                            bottom: '38px',
                            textDecoration: 'underline #EB568F',
                            cursor: 'pointer',
                          }}
                          onClick={() => { handleEventOpen(event) }}
                        >
                          Book Now
                        </Typography>) : (<Link
                          sx={{
                            color: '#EB568F',
                            fontSize: '13.659px',
                            fontWeight: '500',
                            position: 'relative',
                            bottom: '38px',
                            textDecoration: 'underline #EB568F',

                          }}
                        >
                          Booked
                        </Link>)}
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}

        {mocks && mockEvents && (
          <Box>
            {/* Display the mock data that meets the conditions */}
            {mockEvents.map((event) => (
              <Grid container key={event._id}>
                <Grid item xs={12} sm={12} md={5}>
                  <Box
                    sx={{
                      width: {
                        md: '32vw',
                        sm: '100%',
                        xs: '100%'
                      },
                      border: '0.725px solid #D7D7D7',
                      borderRadius: '4.502px',
                      height: '20vh',
                      mt: '20px',
                      overflow: 'hidden'
                    }}
                  >

                    <Stack direction={'row'}>

                      <Box
                        sx={{
                          width: '35%',
                          height: '9.2rem',
                          background: event.image ? `url(${event.image})` : `url(${defaultImage})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                        }}
                      />
                      <Box padding={'17px'}>
                        <Stack direction={'row'}>
                          <Typography
                            sx={{

                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#525252',
                            }}
                          >
                            {event.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#525252',
                            }}
                          >
                            {event.category}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#525252',
                              position: 'relative',
                              left: '10rem',
                              bottom: '1.5rem'


                            }}
                          >
                            <span style={{ fontSize: '30px', marginRight: '5px' }}>.</span> {formatDate(event.date)}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: '14.408px',
                            fontWeight: '400',
                            marginBottom: '50px',
                            color: '#252525',
                            lineHeight: '21.611px',
                            wordSpacing: '5px',


                          }}
                        >
                          {event.description}
                        </Typography>
                        {!event.booked ? (<Link
                          sx={{
                            color: '#EB568F',
                            fontSize: '13.659px',
                            fontWeight: '500',
                            position: 'relative',
                            bottom: '38px',
                            textDecoration: 'underline #EB568F',
                            cursor: 'pointer',
                          }}
                          onClick={() => { handleEventOpen(event) }}

                        >
                          Book Now
                        </Link>) : (<Link
                          sx={{
                            color: '#EB568F',
                            fontSize: '13.659px',
                            fontWeight: '500',
                            position: 'relative',
                            bottom: '38px',
                            textDecoration: 'underline #EB568F',

                          }}
                        >
                          Booked
                        </Link>)}
                      </Box>
                    </Stack>
                  </Box>

                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      </Box>
    </>
  )
}

export default Events;
