import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import hat from "../../images/yellowHat.svg";
import progress1 from "../../images/progress1.png";
import progress2 from "../../images/progress2.png";
import progress3 from "../../images/progress3.png";
import CircularProgress from "../circular-progress/CircularProgress";
import ProgressBar from "../../images/progressBar.png";
import CurrentICo from "../../images/currentPost.svg";
import LinearProgress from "@mui/material/LinearProgress"; 

const ProgressBarComponent = ({ startDate, endDate }) => {
  const convertToDateObject = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  // Calculate total days between start and end dates
  const start = new Date(convertToDateObject(startDate));
  const end = new Date(convertToDateObject(endDate));
  const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

  const currentDate = new Date();
  const daysPassed = Math.ceil((currentDate - start) / (1000 * 60 * 60 * 24));
  const progress = (daysPassed / totalDays) * 100;

  return (
    <Box
      sx={{
        height: "4.375rem",
        width: "100%",
        backgroundColor: "#F4F4FA",
        borderRadius: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1.1rem",
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{
          height: "8px",
          backgroundColor: "#FFFFFF",
          borderRadius: "0.5rem",
        }}
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: progress >= 100 ? "#FFFFFF" : "#2C2B83",
          },
        }}
      />
      <Typography
        variant="body1"
        style={{
          color: "#252525",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: "600",
          marginTop: "0.5rem",
          letterSpacing: "0.9px",
        }}
      >
        {daysPassed} out of {totalDays} days
      </Typography>
    </Box>
  );
};

function CourseStatus() {
  const program = {
    _id: "1",
    name: "MRCOG Clinical Program",
    chapter: [
      {
        _id: "1",
        name: "Introduction",
        lectures: 3,
        duration: "10min",
        image: progress1,
      },
      {
        _id: "2",
        name: "Biochemistry and Nutrition",
        lectures: 3,
        duration: "10min",
        image: progress2,
      },
      {
        _id: "3",
        name: "Herbal Medicine",
        lectures: 3,
        duration: "10min",
        image: progress3,
      },
    ],
    progress: 50,
    hoursSpend: 18,
    increment: 12.5,
  };

  const currentPost = {
    grade: "IMY2",
    startDate: "05/08/2022",
    endDate: "02/12/2022",
    location: "Derrifod Hospital",
    specialty: "Cardiology",
    duration: "4 months",
    status: "",
  };

  return (
    <div>
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          //   alignItems: "center",
        }}
      >
        <Grid
          item
          md={12}
          lg={7.9}
          sx={{
            border: "0.5px solid #AAAAAA",
            borderRadius: "0.5rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            height: "auto",
            padding: "1.5rem",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "2px",
              height: "63%",
              background: "#AAAAAA",
              position: "absolute",
              right: "47%",
              top: "25%",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              alignContent: "center",
            }}
          >
            <img src={hat} alt="no" />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                mt: "0.3rem",
                ml: "1rem",
              }}
            >
              Course Status : Ongoing
            </Typography>
          </Box>
          <Grid
            container
            sx={{
              height: "100%",
              mt: "1rem",
            }}
          >
            <Grid item sx={12} md={12} lg={6}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    color: "#525252",
                    fontSize: "16px",
                    mr: "1rem",
                  }}
                >
                  Course Title:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "Medium",
                    color: "#101010",
                    fontSize: "16px",
                  }}
                >
                  {program?.name}
                </Typography>
              </Box>
              {program?.chapter?.map((item, index) => (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: "1rem",
                    }}
                  >
                    <img src={item.image} alt="" />
                    <Box
                      sx={{
                        ml: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "regular",
                          fontSize: "14px",
                          color: "#252525",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", mr: "1rem" }}>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: "medium",
                              fontSize: "12px",
                              color: "#525252",
                              mr: "0.5rem",
                            }}
                          >
                            {item.lectures}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontWeight: "medium",
                              fontSize: "12px",
                              color: "#525252",
                            }}
                          >
                            lectures
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: "medium",
                            fontSize: "12px",
                            color: "#525252",
                          }}
                        >
                          {item.duration}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))}
              <Box
                sx={{
                  mt: "1.5rem",
                }}
              >
                <Button
                  sx={{
                    border: "1px solid #EB568F",
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "medium",
                    fontFamily: "Poppins",
                    color: "#EB568F",
                    borderRadius: "2.5rem",
                    transition: "scale 0.1s ease-in",
                    p: "0.5rem",
                    width: "50%",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#2C2B83",
                      border: "1px solid #2C2B83",
                      color: "#FFF",
                      scale: "0.9",
                    },
                  }}
                >
                  Continue Learning
                </Button>
              </Box>
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={12}
              lg={1}
              sx={{
                height: "100%",
                width: "100%",
                // border: "1px solid #AAAAAA",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #AAAAAA",
                  height: "100%",
                  width: "1px",
                }}
              ></Box>
            </Grid> */}
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{
                pl: "2.5rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "#252525",
                    fontWeight: "medium",
                  }}
                >
                  Progression
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: "1rem",
                  display: "flex",
                  width: "90%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CircularProgress progress={75} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        height: "1rem",
                        width: "1rem",
                        background: "#F4F4FA",
                        borderRadius: "2.9px",
                        mr: "0.5rem",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        color: "#101010",
                        fontWeight: "medium",
                      }}
                    >
                      Not Completed
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "1rem",
                        width: "1rem",
                        background: "#7171FF",
                        borderRadius: "2.9px",
                        mr: "0.5rem",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        color: "#101010",
                        fontWeight: "medium",
                      }}
                    >
                      Completed
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      fontSize: "14px",
                      color: "#525252",
                    }}
                  >
                    Hours Spend
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      fontSize: "22px",
                      color: "#101010",
                      letterSpacing: "1.5px",
                      mt: "0.5rem",
                    }}
                  >
                    {program?.hoursSpend}Hr
                  </Typography>
                  <Box
                    sx={{
                      p: "0.5rem",
                      background: "#7171FF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "6px",
                      mt: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "medium",
                        fontSize: "14px",
                        color: "#fff",
                        letterSpacing: "1px",
                      }}
                    >
                      +{program?.increment}%
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "10rem",
                    height: "6.5rem",
                    background: `url(${ProgressBar})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    transform: "translateY(0.4rem)",
                  }}
                >
                  {/* <img
                    src={ProgressBar}
                    alt="no"
                    style={{ width: "100%", height: "100%" }}
                  /> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          lg={4}
          sx={{
            border: "0.5px solid #AAAAAA",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "0.5rem",
            height: "auto",
            padding: "1rem",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              alignContent: "center",
            }}
          >
            <img src={CurrentICo} alt="no" />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "medium",
                fontSize: "16px",
                color: "#101010",
                mt: "0.3rem",
                ml: "1rem",
              }}
            >
              Current Post
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "35%",
              display: "flex",
              mt: "1.5rem",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#525252",
                    mr: "0.5rem",
                  }}
                >
                  Grade :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#252525",
                  }}
                >
                  {currentPost?.grade}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#525252",
                    mr: "0.5rem",
                  }}
                >
                  Date :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#252525",
                  }}
                >
                  {currentPost?.startDate} {"-"} {currentPost?.endDate}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "medium",
                  fontSize: "13px",
                  color: "#525252",
                  mr: "0.5rem",
                }}
              >
                Location :
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "medium",
                  fontSize: "13px",
                  color: "#252525",
                }}
              >
                {currentPost?.location}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "medium",
                  fontSize: "13px",
                  color: "#525252",
                  mr: "0.5rem",
                }}
              >
                Speciality :
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "medium",
                  fontSize: "13px",
                  color: "#252525",
                }}
              >
                {currentPost?.specialty}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#525252",
                    mr: "0.5rem",
                  }}
                >
                  Duration :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#252525",
                  }}
                >
                  {currentPost?.duration}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#525252",
                    mr: "0.5rem",
                  }}
                >
                  Status :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "medium",
                    fontSize: "13px",
                    color: "#252525",
                  }}
                >
                  {currentPost?.status === "" ? "_____" : currentPost?.status}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: "25%",
            }}
          >
            <ProgressBarComponent
              startDate={"01/05/2024"}
              endDate={"02/12/2024"}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CourseStatus;
