const certificateService = (httpClient) => {
  return {
    getCertificates: async () => {
      try {
        const res = await httpClient.get("/fetch/certificates");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch certificates" };
      }
    },
    deleteCertificate: async (id) => {
      console.log("Sending delete request with ID:", id); // Added for debugging
      try {
        const response = await httpClient.post("/remove/certificate", { id });
        console.log("Delete response data:", response.data); // Added for debugging
        return response.data;
      } catch (error) {
        console.error("Error in deleteCertificate:", error);
        return { status: false, message: "Failed to delete certificate" };
      }
    },
    addCertificate: async (data) => {
      try {
        const res = await httpClient.post("/add/new/certificate", data);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to add certificate" };
      }
    },
    editCertificate: async (data) => {
      try {
        console.log("Data being sent to backend:", data); // Added for debugging
        const res = await httpClient.post("/edit/certificate", data);
        return res.data;
      } catch (error) {
        console.error("Error in editCertificate:", error); // Added for debugging
        return { status: false, message: "Failed to edit certificate" };
      }
    },
  };
};

export default certificateService;
