import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import bookicon from "../../images/bookicon.png";
import CurriculumProgram from "./CurriculumProgram";
import { maincurriculumService } from "../../service/index";

const Curriculum = () => {
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [selectedCurriculumId, setSelectedCurriculumId] = useState(null);
  const [curriculumItems, setCurriculumItems] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurriculumData = async () => {
      try {
        const result = await maincurriculumService.fetchUserCurriculumList();
        console.log("API response:", result); // Log the entire response

        if (result.status) {
          console.log("Fetched curriculum data:", result.data); // Log the fetched data
          setCurriculumItems(result.data);
        } else {
          setError(result.message);
          console.error("Error fetching curriculum data:", result.message); // Log the error message
        }
      } catch (error) {
        console.error("Error during fetchCurriculumData:", error); // Log any potential exceptions
        setError("Failed to fetch data");
      } finally {
        setLoading(false); // Set loading to false after the data is fetched
      }
    };

    fetchCurriculumData();
  }, []);

  const handleButtonClick = (curriculumId) => {
    setSelectedCurriculumId(curriculumId);
    setShowNewComponent(true);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{`Error: ${error}`}</Typography>;
  }

  if (showNewComponent) {
    return <CurriculumProgram curriculumId={selectedCurriculumId} />;
  }

  return (
    <Box sx={{ padding: "2.5rem", height: "100vh" }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
        <img src={bookicon} style={{ marginRight: "10px" }} alt="book icon" />
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }} component="h2">
          Curriculum
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {curriculumItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                borderRadius: "19px",
                p: 1,
                pb: 0,
                border: "1px solid #D5D5D5",
                position: "relative",
              }}
              elevation={0}
            >
              <CardMedia
                component="img"
                height="auto"
                image={item.image}
                alt={item.name} // Use the name as the alt text
                sx={{ borderRadius: "16px", position: "relative" }}
              />

              {item.progress > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 16,
                    left: 16,
                    padding: "8px",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={48}
                    thickness={5}
                    sx={{ color: "#D8D6D6", position: "absolute" }}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={item.progress}
                    size={48}
                    thickness={5}
                    sx={{ color: "#EB568F" }}
                  />
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    sx={{
                      position: "absolute",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {`${item.progress}%`}
                  </Typography>
                </Box>
              )}
              <CardContent>
                <Typography
                  sx={{ fontSize: "15px", fontWeight: "500" }}
                  component="div"
                >
                  {item.name} {/* Render the curriculum name */}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400" }}
                  color="text.secondary"
                >
                  {item.description} {/* Render the curriculum description */}
                </Typography>
                <Button
                  onClick={() => handleButtonClick(item.curriculumId)}
                  disableElevation
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    borderRadius: "28px",
                    bgcolor: "#2C2B83",
                    height: "38px",
                    mt: 2,
                    textTransform: "none",
                  }}
                >
                  {item.progress > 0 ? "Continue" : "Start Now"}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Curriculum;
