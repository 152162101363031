import React, { Component } from "react";
import Slider from "react-slick";
import { Card, Typography, Box, Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import home12 from "../../images/home12.png"
import home13 from "../../images/home13.png"
import home14 from "../../images/home14.png"
import home15 from "../../images/home15.png"
import home16 from "../../images/home16.png"
import plus from "../../images/plus-circle.svg"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const customSlides = [
  {
    imageSrc: home12,
    title: 'Sunraise Hospital',
    imageSrc2: home16,
    leftValue: '80px'
  },
  {
    imageSrc: home13,
    title: 'EDU Zone',
    imageSrc2: home16,
    leftValue: '145px'
  },
  {
    imageSrc: home14,
    title: 'Study MEDIC',
    imageSrc2: home16,
    leftValue: '125px'
  },
  {
    imageSrc: home15,
    title: 'isuog',
    imageSrc2: home16,
    leftValue: '175px'
  },
];

export default class Responsive extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef(); // Create a ref for the Slider component
  }

  next() {
    this.slider.current.slickNext();
  }

  previous() {
    this.slider.current.slickPrev();
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3.23,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider ref={this.slider} {...settings}>
          {customSlides.map((slide, index) => (
            <div key={index}>
              <Card
                sx={{
                  width: "17rem",
                  height: "20rem",
                  padding : '1.5rem',
                }}
              >
                <Box sx={{
                  // border :'1px solid blue',
                  height : '100%',
                }}>
                  <Box sx={{
                    height : '90%',
                    // border : '1px solid red',
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems :'center',
                  }}>
                    <img src={slide.imageSrc} alt="" style={{maxWidth : '80%' ,height :'auto'}}/>
                  </Box>
                  <Box sx={{
                    display : 'flex',
                    justifyContent :'space-between'
                  }}>
                    <Typography sx={{
                      fontFamily :'Poppins',
                      fontSize :'14.75px',
                      color :"#252525",
                      fontWeight : '600'
                    }}>{slide.title}</Typography>
                    <img src={plus} alt="" style={{cursor : "pointer"}}/>
                  </Box>

                </Box>

              </Card>
            </div>
          ))}
        </Slider>
        <Box sx={{ mt: "1.5rem" }}>
          <Button
            className="button"
            sx={{
              height: "3rem",
              width: "3rem",
              borderRadius: "50%",
              backgroundColor: "#fff",
              mr: "1rem",
              "&:hover": {
                backgroundColor: "#EB568F",
                color : '#fff'
              },
            }}
            onClick={() => this.previous()}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            className="button"
            sx={{
              height: "3rem",
              width: "3rem",
              borderRadius: "50%",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#EB568F",
                color : '#fff'
              },
            }}
            onClick={() => this.next()}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </div>
    );
  }
}



// import React, { useState, useRef } from 'react';
// import { Box, Card, Typography, Grid ,Stack} from '@mui/material';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import home12 from "../../images/home12.png"
// import home13 from "../../images/home13.png"
// import home14 from "../../images/home14.png"
// import home15 from "../../images/home15.png"
// import home16 from "../../images/home16.png"
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


// const Carousels2 = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const slider = useRef(null);

  
    
// const customSlides = [
//   {
//     imageSrc: home12,
//     title: 'Sunraise Hospital',
//     imageSrc2: home16,
//     leftValue: '80px'
//   },
//   {
//     imageSrc: home13,
//     title: 'EDU Zone',
//     imageSrc2: home16,
//     leftValue: '145px'
//   },
//   {
//     imageSrc: home14,
//     title: 'Study MEDIC',
//     imageSrc2: home16,
//     leftValue: '125px'
//   },
//   {
//     imageSrc: home15,
//     title: 'isuog',
//     imageSrc2: home16,
//     leftValue: '175px'
//   },
// ];


// const sliderSettings = {
//   slidesToScroll: 1,
//   infinite: false,
//   arrows: false,
//   afterChange: (index) => setCurrentSlide(index),
//   responsive: [
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 1,
//       },
//     },
//     {
//       breakpoint: 1000,
//       settings: {
//         slidesToShow: 1.2,
//       },
//     },
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 2.3,
//       },
//     },
//     {
//       breakpoint: 1380,
//       settings: {
//         slidesToShow: 2.5,
//       },
//     },
//     {
//       breakpoint: 2000,
//       settings: {
//         slidesToShow: 3.3,
//       },
//     },
//   ],
// };

// const slideTo = (index) => {
//   slider.current.slickGoTo(index);
// };

// return (
//   <Box
//     sx={{
    
     
//       width: '1113px',
//       display: 'block',
//       margin: '10px auto',
//       backgroundColor: '#F2F5FF',
//       padding: '40px',
//       marginTop:'100px',
//       textAlign: 'center',
//       height: '590px',
//       position:'relative',
//       '@media (min-width: 1400px) and (max-width: 1600px)': {
//         width: '1200px',
//         right:'60px',
        
//       },
    
//       '@media (min-width: 1123px) and (max-width: 1400px)': {
//         width: '900px',
//        left:''
//       },
//       '@media (min-width: 1000px) and (max-width: 1123px)': {
//         width: '750px',
   
//       },
//       '@media (min-width: 800px) and (max-width: 1000px)': {
//         width: '650px',
  
//       },
//       '@media (min-width: 800px) and (max-width: 899.5px)': {
     
//         left:'40px'
//       },
//       '@media (min-width: 618px) and (max-width: 800px)': {
//         width: '510px',
//         left:'10px'
//       },
//       '@media (max-width: 618px)': {
//         width: '452px',
       
//       },
//       '@media (max-width: 548px)': {
//         width: '402px',
       
//       },
//     }}
//   >
//     <style>
//       {`
//         .gap {
//           margin-right: 8px; // Add margin to create a gap between cards
//         }
//         @media (min-width: 600px) and (max-width: 618px) {
//           .gap {
//             margin-right:48px;
//           }
//       }
//         @media (min-width: 618px) and (max-width: 800.5px) {
//           .gap {
//             margin-right:108px;
//           }
//       }
//         @media (min-width: 800.5px) and (max-width: 900px) {
//           .gap {
//             margin-right:208px;
//           }
//       }
//       @media (min-width: 900px) and (max-width: 1000px) {
//         .gap {
//           margin-right:208px;
//         }
//     }
//       `}
//     </style>
//     <Typography sx={{fontSize:'26px',fontWeight:'600',textAlign:'left'}}>Our Partners</Typography>
//     <Slider {...sliderSettings} ref={slider} className='gap'>
//       {customSlides.map((slide, index) => (
//         <div key={index}>
//           <Card
//             sx={{
//               width: '316.72px',
//               height: '366.65px',
//               marginTop:'20px'
//             }}
//           >
//             <Grid ml={'25px'} mt={'25px'}>
//               <img
//                 src={slide.imageSrc}
//                 alt='Custom Slide'
//                 style={{ 
//                 width: '216.667px', 
//                 height: '64.484px',
//                 marginTop:'120px' ,
//                 marginLeft:'20px'
//               }}
//               />
//               <Stack direction={'row'}  mt={13} ml={1}> 
//               <Typography
//                 sx={{
//                   fontSize: '14.731px',
//                   fontWeight: '600',
//                   mt: '10px',
//                   textAlign: 'left',
//                 }}
//               >
//                 {slide.title}
//               </Typography>
//               <img
//                 src={slide.imageSrc2}
//                 alt='Custom Slide'
//                 style={{ width: ' 32.736px', height: ' 32.736px',   position: 'relative',left: slide.leftValue,top:'3px'}}
//               /></Stack>
//             </Grid>
//           </Card>
//         </div>
//       ))}
//     </Slider>
//     <Grid ml={'10px'}>
//       <Box
//         onClick={() => slideTo(currentSlide - 1)}
//         sx={{
//           width: '52.377px',
//           height: '52.377px',
//           backgroundColor: 'white',
//           borderRadius: '50%',
//           position: 'relative',
//         top:"33px",
//           cursor: 'pointer',
         
//           '&:hover':{bgcolor:"#EB568F",color:'#fff'}
//         }}
//       >
//         <ArrowBackIosIcon sx={{ marginLeft: '5px', mt: '13px' }} />
//       </Box>

//       <Box
//         onClick={() => slideTo(currentSlide + 1)}
//         sx={{
//           width: '52.377px',
//           height: '52.377px',
//           backgroundColor: 'white',
//           borderRadius: '50%',
//           position: 'relative',
//           bottom: '20px',
//           left: '70px',
//           cursor: 'pointer',
       
//           '&:hover':{bgcolor:"#EB568F",color:'#fff'}
//         }}
//       >
//         <ArrowForwardIosIcon sx={{ marginLeft: '5px', mt: '13px' }} />
//       </Box>
//     </Grid>
//   </Box>
// );
// };

// export default Carousels2;