import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  Box,
  Paper,
  OutlinedInput,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IoIosArrowDown } from "react-icons/io";
import RefreshIcon from "../../images/refreshIcon.svg";
import shorticon from "../../images/sorticon.png";
import LogEntryCards from "../../components/reflectioncard/ReflectionCard";
import ReflectionAddNewLog from "../../components/reflectionaddnewlog/ReflectionAddNewLog";
import { reflectionService } from "../../service/index";

const ReflectionLog = () => {
  const [showAddLog, setShowAddLog] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(""); // selection box
  const [selectedPage, setSelectedPage] = React.useState("1"); // selection box
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reflectionData, setReflectiontData] = useState([]);
  const pageSize = 10; // Define your page size
  const selectRef = useRef(null);

  useEffect(() => {
    const fetchReflection = async () => {
      try {
        const response = await reflectionService.fetchReflections(
          currentPage,
          pageSize
        );
        console.log("Fetch reflections response:", response);

        if (response.status) {
          setReflectiontData(response.data); // Directly set the fetched data without filtering
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching reflections:", error);
      }
    };

    fetchReflection();
  }, [currentPage, pageSize]);

  const handleOpen = () => {
    // console.log("open....",selectRef)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    console.log("refresh....");
  };

  const options = [
    { value: "post1", label: "Post 1" },
    { value: "post2", label: "Post 2" },
    { value: "year2022", label: "2022" },
    { value: "year2023", label: "2023" },
  ];

  const options2 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangePage = (event) => {
    setSelectedPage(event.target.value);
  };

  const ArrowIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginRight: "1rem",
        width: "3rem",
        cursor: "pointer",
        color: "#2C2B83",
      }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#3F3F3F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ArrowIconColored = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "0.5rem", width: "6rem", cursor: "pointer" }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#2C2B83"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const handleAddLog = () => {
    setShowAddLog(true); // Step 2: Toggle to show add log form
  };

  const handleSubmit = async () => {
    const selectedPost = options.find(
      (option) => option.value === selectedValue
    );
    const dataToSend = {
      isDraft: false,
      activityDate: new Date().toISOString(),
      expiryDate: new Date().toISOString(),
      title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
      type: "Reflection On Management and Leadership Experience",
      isShared: true,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      grade: "B",
      time: 2,
      venue: "Test",
      summary:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      outcomes:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      postId: selectedPost?.value || "",
      postName: selectedPost?.label || "",
    };

    try {
      const response = await reflectionService.AddNewReflection(dataToSend);
      if (response.status) {
        // Handle success
        console.log("Log added successfully");
      } else {
        // Handle error
        console.error("Failed to add log:", response.message);
      }
    } catch (error) {
      console.error("Error adding new log:", error);
    }
  };

  return (
    <Box p={"2.5rem"}>
      {!showAddLog ? (
        <>
          <Typography gutterBottom sx={{ fontSize: "16px", color: "#101010" }}>
            Reflection:
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#252525" }} paragraph>
            Reflection can be recorded in your portfolio and used as example of
            learning development. You can decide if a reflective record is to
            remain private or you can share it with supervisors. Be mindful of
            the need to ensure confidentiality of individuals involved in the
            experience.
            <br /> You can use these templates to record a variety of learning
            experiences, using the list of questions to aid your reflective
            writing. Shared entries are available for supervisor(s) to review
            and, if they wish, add comments or signatures to.{" "}
            <Typography sx={{ color: "#252525", fontSize: "14px" }}>
              <span style={{ color: "#101010", fontSize: "16px" }}>
                Supervisors:
              </span>{" "}
              To add a Comment to a specific record, click on the 'Links' item
              in the Action column.
            </Typography>
          </Typography>

          <Button
            startIcon={<AddCircleIcon />}
            onClick={handleAddLog}
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "#ffffff",
              backgroundColor: "#2C2B83",
              borderRadius: "1rem",
              transition: "scale 0.1s ease-in",
              mr: "0.5rem",
              "&:hover": {
                scale: "0.9",
                backgroundColor: "#2C2B83",
              },
            }}
          >
            Add New Log
          </Button>

          {/* //-----------------------------------------paper---------------------------------------------------------// */}
          <Paper
            elevation={0}
            sx={{
              p: 2,
              bgcolor: "white",
              mt: 2,
              border: "1px solid #D5D5D5",
              borderRadius: "10px",
            }}
          >
            <Typography sx={{ mb: 2, fontSize: "14px" }}>
              Find A Log:
            </Typography>
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Show log For
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none" sx={{ color: "#7E7E7E" }}>
                      <em>07 Aug, 2023 to 02 Aug, 2023</em>
                    </MenuItem>
                    <MenuItem value={10}>07 Aug, 2023 to 02 Aug, 2023</MenuItem>
                    <MenuItem value={20}>07 Aug, 2023 to 02 Aug, 2023</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Select Training Year
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none" sx={{ color: "#7E7E7E" }}>
                      <em>select</em>
                    </MenuItem>
                    <MenuItem value={10}>2023</MenuItem>
                    <MenuItem value={20}>2024</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Select Grade
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none" sx={{ color: "#7E7E7E" }}>
                      <em>Grade</em>
                    </MenuItem>
                    <MenuItem value={10}>A</MenuItem>
                    <MenuItem value={20}>B</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Restrict Types To
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none" sx={{ color: "#7E7E7E" }}>
                      <em>See All</em>
                    </MenuItem>
                    <MenuItem value={10}>Option 1</MenuItem>
                    <MenuItem value={20}>Option 2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Period Cover From
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  placeholder="DD-MM-YY"
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 36,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      "& input": {
                        color: "#7E7E7E",
                      },
                    },
                    // "& input::-webkit-calendar-picker-indicator": {
                    //   display: "none",
                    // },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  To
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  placeholder="DD-MM-YY"
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 36,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      "& input": {
                        color: "#7E7E7E",
                      },
                    },
                    // "& input::-webkit-calendar-picker-indicator": {
                    //   display: "none",
                    // },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleSubmit}
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#ffffff",
                    backgroundColor: "#2C2B83",
                    borderRadius: "1rem",
                    transition: "scale 0.1s ease-in",
                    mr: "0.5rem",
                    "&:hover": {
                      scale: "0.9",
                      backgroundColor: "#2C2B83",
                    },
                  }}
                >
                  Find Log
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {/* //--------------------------------------------selectbox------------------------------------------------------// */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mt: 4,
              gap: 0,
            }}
          >
            <FormControl
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "1.5rem",
                overflow: "hidden",
                border: "none",
                height: "2.4rem",
                width: "50%",
                mr: "0.5rem",
                borderColor: "white",
              }}
            >
              <Select
                value={selectedValue}
                onChange={handleChange}
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                  />
                }
                IconComponent={ArrowIcon}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: "1.5rem",
                      marginTop: "0.8rem",
                    },
                  },
                }}
                sx={{
                  backgroundColor: "#E5E4FF",
                  color: "#2C2B83",
                  borderRadius: "1.5rem",
                  "&:focus": {
                    borderRadius: "1.5rem",
                    backgroundColor: "#ffffff",
                  },
                  "& .MuiSelect-icon": {
                    color: "currentColor",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "1.5rem",
                    border: "none",
                    "&:hover": {
                      borderRadius: "1.5rem",
                      backgroundColor: "#f0f0f0",
                    },
                  },
                  height: "100%",
                }}
              >
                <MenuItem disabled value="">
                  {" "}
                  <span style={{ position: "relative", top: "3px" }}>
                    <img src={shorticon} alt="short" />
                  </span>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      color: "#2C2B83",
                      fontWeight: "normal",
                      marginLeft: "6px",
                    }}
                  >
                    {" "}
                    Short By
                  </span>
                </MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ borderRadius: "1.5rem" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl
                sx={{
                  backgroundColor: "#E5E4FF",
                  borderRadius: "1.5rem",
                  overflow: "hidden",
                  border: "none",
                  height: "2.4rem",
                  width: "6.5rem",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Select
                  ref={selectRef}
                  value={selectedPage}
                  onChange={handleChangePage}
                  displayEmpty
                  input={<OutlinedInput />}
                  IconComponent={ArrowIconColored}
                  open={open}
                  onClose={handleClose}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: "1.5rem",
                        marginTop: "0.8rem",
                      },
                    },
                  }}
                  sx={{
                    backgroundColor: "#E5E4FF",
                    borderRadius: "1.5rem",
                    "&:focus": {
                      borderRadius: "1.5rem",
                      backgroundColor: "#E5E4FF",
                    },
                    "& .MuiSelect-icon": {
                      color: "#2C2B83",
                    },
                    "& .MuiInputBase-root": {
                      borderRadius: "1.5rem",
                      border: "none",
                      "&:hover": {
                        borderRadius: "1.5rem",
                        backgroundColor: "#f0f0f0",
                      },
                    },
                    height: "100%",
                  }}
                >
                  {options2.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ borderRadius: "1.5rem", justifyContent: "center" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    alignItems: "center",
                  }}
                  onClick={handleOpen}
                >
                  <Box
                    sx={{
                      width: "1.5rem",
                      height: "1.5rem",
                      ml: "0.5rem",
                      mr: "0.5rem",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Poppins",
                      color: "#2C2B83",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {selectedPage}
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      color: "#2C2B83",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Page
                  </Typography>
                </Box>
              </FormControl>
              <Button
                sx={{
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  minWidth: 0,
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#E5E4FF",
                  ml: "1rem",
                  "&:hover": {
                    border: "1px solid #2C2B83",
                    backgroundColor: "#E5E4FF",
                  },
                }}
                onClick={handleRefresh}
              >
                <img src={RefreshIcon} alt="Refresh" />
              </Button>
            </Box>
          </Box>

          {/* //--------------------------------------------cards------------------------------------------------------// */}
          <Grid container spacing={2} mt={4}>
            {reflectionData.map((form, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <LogEntryCards form={form} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <>
            <ReflectionAddNewLog />
          </>
        </>
      )}
    </Box>
  );
};

export default ReflectionLog;
