const appraisalService = (httpClient) => {
  return {
    getAppraisal: async () => {
      try {
        const res = await httpClient.get("/fetch/appraisals");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch appraisals" };
      }
    },
    getAppraisalDetails: async (appraisalId) => {
      try {
        const res = await httpClient.post("/fetch/appraisal/details", {
          appraisalId,
        }); // Correct payload
        return res.data;
      } catch (error) {
        console.error("Error fetching appraisal details:", error); // Log error
        return { status: false, message: "Failed to fetch appraisal details" };
      }
    },
  };
};

export default appraisalService;
