import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  Modal,
  Paper,
} from "@mui/material";
import IncompleteICon from "../../images/incompleteIconsvg.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import ReminderIcon from "@mui/icons-material/Email";
import CopyIcon from "@mui/icons-material/FileCopy";
import AssessmentIcon from "@mui/icons-material/Assignment";
import TicketIcon from "@mui/icons-material/ConfirmationNumber";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ticketIcon from "../../images/ticketIcon.svg";
import PropTypes from "prop-types";
import RefreshIcon from "../../images/refreshIcon.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import { IoIosArrowDown } from "react-icons/io";
import CreateTicket from "../../components/createticket/CreateTicket";
import { assessmentService } from "../../service/index";

//---------------------------------------------TICKET Modal-----------------------------------------------------------------//

const TicketModalComponent = ({ selectedTicket, handleClose }) => {
  const [selected, setSelected] = useState("tickets");

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        padding: "1.5rem",
        width: {
          lg: 480,
          md: "90vw",
        },
        height: {
          lg: 450,
          md: "90vh",
        },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box>
        <img
          src={selected === "tickets" ? ticketIcon : IncompleteICon}
          alt="no"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "85%",
          mt: "1rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Created Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.expiryDate}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Expiry Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.expiryDate}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "1rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#525252",
            fontSize: "12px",
          }}
        >
          Assessor
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#101010",
            fontSize: "14px",
          }}
        >
          {selectedTicket.assessorName}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "85%",
          mt: "1rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Assessor Email
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.assessorEmail}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Login Code
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.loginCode}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "1rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#525252",
            fontSize: "12px",
          }}
        >
          Form / Procedure / Curriculum Item
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#101010",
            fontSize: "14px",
          }}
        >
          {selectedTicket.fpci}
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "#E6E6FE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "18%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "600",
            color: "#2C2B83",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          Close
        </Typography>
      </Box>
    </Box>
  );
};

//---------------------------------------------Assessment-----------------------------------------------------------------//
const Assessment = () => {
  const [view, setView] = useState("tickets");
  const [selected, setSelected] = useState("tickets");
  const items = [
    { label: "MiniCEX" },
    { label: "DOPS" },
    { label: "MSF" },
    { label: "ACAT" },
    { label: "MSF Self" },
  ];
  const options = [
    { value: "post1", label: "Post 1" },
    { value: "post2", label: "Post 2" },
    { value: "year2022", label: "2022" },
    { value: "year2023", label: "2023" },
  ];
  const options2 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangePage = (event) => {
    setSelectedPage(event.target.value);
  };
  const ArrowIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "1rem", width: "3rem", cursor: "pointer" }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#3F3F3F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const ArrowIconColored = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "0.5rem", width: "6rem", cursor: "pointer" }}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#2C2B83"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const [selectedValue, setSelectedValue] = React.useState(""); // selection box
  const [selectedPage, setSelectedPage] = React.useState("1"); // selection box
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [createTicket, OpenCreateTicket] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");

  const handleOpenConfirmationModal = (type, item) => {
    setConfirmationType(type);
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleViewChange = (view) => {
    setView(view);
    setSelected(view);
  };

  useEffect(() => {
    const fetchTickets = async () => {
      const response = await assessmentService.fetchTickets();
      console.log("Fetch tickets response:", response);
      if (response.status) {
        setTicketData(response.data); // Directly set the fetched data without filtering
      } else {
        console.error(response.message);
      }
    };

    fetchTickets();
  }, [selected]);

  const handleCopy = (item) => {
    // Copy logic here
    console.log("Copy", item);
  };

  const handleSelectTicket = async (ticket) => {
    try {
      const response = await assessmentService.fetchTicketDetails(ticket._id);
      console.log("Fetched ticket details:", response); // Check if response is correct
      if (response.status) {
        setSelectedTicket(response.data);
        setTicketModalOpen(true);
      } else {
        console.error(response.message);
        // You can show an error message to the user here
        alert("Failed to fetch ticket details. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching ticket details:", error);
      // You can show an error message to the user here
      alert("An unexpected error occurred. Please try again later.");
    }
  };
  const handleCloseModal = () => {
    setTicketModalOpen(false); // Close the modal
    setSelectedTicket(null); // Clear selected ticket data
  };
  const handleConfirmAction = async () => {
    if (confirmationType === "delete" && selectedTicket) {
      const response = await assessmentService.deleteTicket(selectedTicket._id);
      console.log("Delete ticket response:", response);
      if (response.status) {
        setTicketData(
          ticketData.filter((ticket) => ticket._id !== selectedTicket._id)
        );
        setSelectedTicket(null);
      } else {
        console.error(response.message);
      }
    } else if (confirmationType === "sendReminder" && selectedTicket) {
      console.log("Sending reminder for ticket:", selectedTicket);
      // Implement send reminder functionality
      setSelectedTicket(null);
    }
    handleCloseConfirmationModal();
  };

  const handleDelete = (item) => {
    setSelectedTicket(item);
    handleOpenConfirmationModal("delete", item);
  };

  const handleSendReminder = (item) => {
    setSelectedTicket(item);
    handleOpenConfirmationModal("sendReminder", item);
  };
  const handleOpen = () => {
    // console.log("open....",selectRef)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    console.log("refresh....");
  };

  return (
    <Box sx={{ padding: "2.5rem", height: "100vh" }}>
      <Typography sx={{ fontSize: "16px", mb: 2 }} gutterBottom>
        {view === "tickets" ? "Ticket Request" : "Assessment Form"}
      </Typography>

      <Box
        sx={{ display: "flex", gap: 2, marginBottom: 2, alignItems: "center" }}
      >
        <Button
          startIcon={<TicketIcon />}
          color="primary"
          onClick={() => handleViewChange("tickets")}
          sx={{
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            color: "#2C2B83",
            backgroundColor: "#E5E4FF",
            borderRadius: "1rem",
            transition: "scale 0.1s ease-in",
            border: selected === "tickets" ? "1px solid #2C2B83" : "none",
            "&:hover": {
              scale: "0.9",
              backgroundColor: "#E5E4FF",
              color: "#2C2B83",
            },
          }}
        >
          Tickets
        </Button>
        <Button
          startIcon={<AssessmentIcon />}
          color="primary"
          onClick={() => handleViewChange("assessments")}
          sx={{
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            color: "#2C2B83",
            backgroundColor: "#E5E4FF",
            borderRadius: "1rem",
            transition: "scale 0.1s ease-in",
            border: selected === "assessments" ? "1px solid #2C2B83" : "none",
            "&:hover": {
              scale: "0.9",
              backgroundColor: "#E5E4FF",
              color: "#2C2B83",
            },
          }}
        >
          Assessment Forms
        </Button>
        {selected === "tickets" && (
          <Button
            startIcon={<AddCircleIcon />}
            onClick={() => OpenCreateTicket(true)}
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "#ffffff",
              backgroundColor: "#2C2B83",
              borderRadius: "1rem",
              transition: "scale 0.1s ease-in",
              mr: "0.5rem",
              "&:hover": {
                scale: "0.9",
                backgroundColor: "#2C2B83",
              },
            }}
          >
            Create Ticket
          </Button>
        )}

        {/* ------------------------------------------------modal for createticket----------------------------------------- */}
        <Modal
          open={createTicket}
          onClose={() => OpenCreateTicket(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,
          }}
        >
          <CreateTicket />
        </Modal>
        {/* ------------------------------------------------assesment content----------------------------------------- */}

        {view === "assessments" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "auto",
              pr: "1.5%",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", color: "#525252" }}
            >
              Post
            </Typography>
            <Select
              defaultValue={10}
              IconComponent={(props) => (
                <IoIosArrowDown
                  {...props}
                  style={{ color: "#3F3F3F", fontSize: "20px" }}
                />
              )}
              sx={{
                minWidth: "150px",
                fontSize: "12px",
                fontFamily: "Poppins",
                color: "#7E7E7E",
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                height: "32px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
            >
              <MenuItem value={10}>
                IMY2 - Derriford Hospital (05 Aug 2020 to 02 Dec 2020)
              </MenuItem>
              <MenuItem value={20}>Option 2</MenuItem>
              <MenuItem value={30}>Option 3</MenuItem>
            </Select>
          </Box>
        )}
      </Box>

      {/* //---------------------------------------------Assessment form contentt-----------------------------------------// */}

      {view === "assessments" && (
        <Box>
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "20px", // Border radius for Paper
                  }}
                >
                  <Grid container alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#E4E5FF",
                        borderRadius: "50%",
                        width: 32,
                        height: 32,
                        marginRight: 2,
                      }}
                    >
                      <DescriptionIcon sx={{ color: "#8787A3" }} />
                    </Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {item.label}
                    </Typography>
                  </Grid>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{
                      backgroundColor: "#2c2B83",
                      color: "white",
                      borderRadius: "30px", // Border radius for Button
                    }}
                  >
                    Create
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* //---------------------------------------------ticket content-----------------------------------------// */}
      {view === "tickets" && (
        <Box>
          <Typography variant="body1" gutterBottom sx={{ fontSize: "14px" }}>
            To request an assessment from a person who does not have log in
            access to this account on ePortfolio, select the "Request New
            Assessment" button below. This will generate a unique code that the
            Assessor can use to login to ePortfolio and submit the assessment.
          </Typography>
          <ul>
            <li style={{ fontSize: "14px", fontWeight: "400" }}>
              <span style={{ fontWeight: "500" }}>
                A ticket will expire 30 days
              </span>{" "}
              after it was created and the Assessor will no longer be able to
              complete the form
            </li>
            <li style={{ fontSize: "14px" }}>
              If ticket assessment has not been completed after 7 days, you will
              be able to send a reminder. The "Send Reminder" link automatically
              appears next to any tickets that have not been completed within
              this time frame. Please note, you cannot send the same reminder
              more than once per 24 hour period To view a list of all of your
              forms including tickets you have completed for others.
            </li>
          </ul>
          {/* //---------------------------------------------Select box-----------------------------------------------------------------// */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <FormControl
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "1.5rem",
                overflow: "hidden",
                border: "none",
                height: "2.4rem",
                width: "50%",
                mr: "0.5rem",
                borderColor: "white",
              }}
            >
              <Select
                value={selectedValue}
                onChange={handleChange}
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                  />
                }
                IconComponent={ArrowIcon}
                MenuProps={{
                  PaperProps: {
                    style: {
                      borderRadius: "1.5rem",
                      marginTop: "0.8rem",
                    },
                  },
                }}
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "1.5rem",
                  "&:focus": {
                    borderRadius: "1.5rem",
                    backgroundColor: "#ffffff",
                  },
                  "& .MuiSelect-icon": {
                    color: "currentColor",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "1.5rem",
                    border: "none",
                    "&:hover": {
                      borderRadius: "1.5rem",
                      backgroundColor: "#f0f0f0",
                    },
                  },
                  height: "100%",
                }}
              >
                <MenuItem disabled value="">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      color: "#252525",
                      fontWeight: "normal",
                    }}
                  >
                    Select Your Post (or) Year
                  </span>
                </MenuItem>
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ borderRadius: "1.5rem" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl
                sx={{
                  backgroundColor: "#E5E4FF",
                  borderRadius: "1.5rem",
                  overflow: "hidden",
                  border: "none",
                  height: "2.4rem",
                  width: "6.5rem",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Select
                  ref={selectRef}
                  value={selectedPage}
                  onChange={handleChangePage}
                  displayEmpty
                  input={<OutlinedInput />}
                  IconComponent={ArrowIconColored}
                  open={open}
                  onClose={handleClose}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: "1.5rem",
                        marginTop: "0.8rem",
                      },
                    },
                  }}
                  sx={{
                    backgroundColor: "#E5E4FF",
                    borderRadius: "1.5rem",
                    "&:focus": {
                      borderRadius: "1.5rem",
                      backgroundColor: "#E5E4FF",
                    },
                    "& .MuiSelect-icon": {
                      color: "#2C2B83",
                    },
                    "& .MuiInputBase-root": {
                      borderRadius: "1.5rem",
                      border: "none",
                      "&:hover": {
                        borderRadius: "1.5rem",
                        backgroundColor: "#f0f0f0",
                      },
                    },
                    height: "100%",
                  }}
                >
                  {options2.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ borderRadius: "1.5rem", justifyContent: "center" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    alignItems: "center",
                  }}
                  onClick={handleOpen}
                >
                  <Box
                    sx={{
                      width: "1.5rem",
                      height: "1.5rem",
                      ml: "0.5rem",
                      mr: "0.5rem",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Poppins",
                      color: "#2C2B83",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    {selectedPage}
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      color: "#2C2B83",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Page
                  </Typography>
                </Box>
              </FormControl>
              <Button
                sx={{
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  minWidth: 0,
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#E5E4FF",
                  ml: "1rem",
                  "&:hover": {
                    border: "1px solid #2C2B83",
                    backgroundColor: "#E5E4FF",
                  },
                }}
                onClick={handleRefresh}
              >
                <img src={RefreshIcon} alt="no" />
              </Button>
            </Box>
          </Box>

          {/* //---------------------------------------------Ticket box-----------------------------------------------------------------// */}

          <Grid container sx={{ mt: "1rem", justifyContent: "space-between" }}>
            {ticketData &&
              ticketData.map((item) => (
                <Grid
                  key={item.id}
                  item
                  lg={5.9}
                  md={12}
                  sx={{
                    border: "0.5px solid #AAAAAA",
                    borderRadius: "0.5rem",
                    backgroundColor: "#ffffff",
                    padding: "1rem",
                    cursor: "pointer",
                    "&:hover": {
                      border: "1px solid #2C2B83",
                      boxShadow: "0px 4px 4px 0px rgba(44, 43, 131, 0.25)",
                    },
                  }}
                  onClick={(e) => {
                    handleSelectTicket(item);
                  }}
                >
                  <Box>
                    <img src={ticketIcon} alt="ticket icon" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "85%",
                      mt: "1rem",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "medium",
                          color: "#525252",
                          fontSize: "12px",
                        }}
                      >
                        Expiry
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "medium",
                          color: "#101010",
                          fontSize: "14px",
                        }}
                      >
                        {item.expiry}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "medium",
                          color: "#525252",
                          fontSize: "12px",
                        }}
                      >
                        Ticketed Items
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "medium",
                          color: "#101010",
                          fontSize: "14px",
                        }}
                      >
                        {item.ticketedItem}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "85%",
                      mt: "1rem",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "medium",
                          color: "#101010",
                          fontSize: "14px",
                        }}
                      >
                        Assessor
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "medium",
                          color: "#525252",
                          fontSize: "12px",
                        }}
                      >
                        {item.assessor}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "1rem" }}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          startIcon={<DeleteIcon />}
                          sx={{
                            textTransform: "none",
                            fontSize: "12.52px",
                            fontStyle: "Poppins",
                            fontWeight: "600",
                            color: "#E32222",
                            backgroundColor: "#FFECEC",
                            borderRadius: "1.5rem",
                            mr: "0.5rem",
                            "&:hover": {
                              backgroundColor: "#E32222",
                              color: "#ffffff",
                            },
                          }}
                          onClick={(e) => {
                            handleDelete(item);
                            e.stopPropagation();
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          startIcon={<ReminderIcon />}
                          sx={{
                            textTransform: "none",
                            fontSize: "12.52px",
                            fontStyle: "Poppins",
                            fontWeight: "600",
                            color: "#2C2B83",
                            backgroundColor: "#E5E4FF",
                            borderRadius: "1.5rem",
                            mr: "0.5rem",
                            "&:hover": {
                              backgroundColor: "#2C2B83",
                              color: "#ffffff",
                            },
                          }}
                          onClick={(e) => {
                            handleSendReminder(item);
                            e.stopPropagation();
                          }}
                        >
                          Send Reminder
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          startIcon={<CopyIcon />}
                          sx={{
                            textTransform: "none",
                            fontSize: "12.52px",
                            fontStyle: "Poppins",
                            fontWeight: "600",
                            color: "#2C2B83",
                            backgroundColor: "#E5E4FF",
                            borderRadius: "1.5rem",
                            "&:hover": {
                              backgroundColor: "#2C2B83",
                              color: "#ffffff",
                            },
                          }}
                          onClick={() => handleCopy(item)}
                        >
                          Copy
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            {confirmationModalOpen && (
              <Modal
                open={confirmationModalOpen}
                onClose={handleCloseConfirmationModal}
                aria-labelledby="confirmation-modal-title"
                aria-describedby="confirmation-modal-description"
                sx={{
                  display: "flex",
                  zIndex: 99999,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 400,
                    p: 4,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                    id="confirmation-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mb: 2 }}
                  >
                    {confirmationType === "delete"
                      ? "Confirm Delete"
                      : "Confirm Send Reminder"}
                  </Typography>
                  <Typography
                    id="confirmation-modal-description"
                    sx={{ mb: 2 }}
                  >
                    {confirmationType === "delete"
                      ? "Are you sure you want to delete this ticket?"
                      : "Are you sure you want to send a reminder?"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleCloseConfirmationModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleConfirmAction}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}

            {selectedTicket && (
              <Modal
                open={ticketModalOpen}
                onClose={() => setTicketModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  display: "flex",
                  zIndex: 99999,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TicketModalComponent
                  selectedTicket={selectedTicket}
                  handleClose={handleCloseModal}
                />
              </Modal>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

Assessment.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

export default Assessment;
