import React, { useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import doctorDefault from "../../images/doctorDp_def.png";
import calender from "../../images/calendar.svg";
import clock from "../../images/clock.svg";
import close from "../../images/close-icon.svg";
import { subService } from "../../service";
import { useSnackbar } from "notistack";
import FileLoader from "../FileLoader/FileLoader";


function Appointment({ onClose, doctor }) {
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const abbreviateDay = (dayName) => {
    const dayAbbreviations = {
      Monday: "MON",
      Tuesday: "TUE",
      Wednesday: "WED",
      Thursday: "THU",
      Friday: "FRI",
      Saturday: "SAT",
      Sunday: "SUN",
    };

    if (dayName in dayAbbreviations) {
      return dayAbbreviations[dayName];
    }

    for (const fullDay in dayAbbreviations) {
      if (dayAbbreviations[fullDay] === dayName) {
        return true;
      }
    }

    return false;
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeOptions, setTimeOptions] = useState([]);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
    let timeSlots = generateTimeSlots(date.start, date.end);
    console.log("selectedDate\t\t", date);
    console.log("selectedTime\t\t", timeSlots);
    setTimeOptions(timeSlots);
  };

  const generateTimeSlots = (start, end) => {
    const startTime = new Date(`01/01/2023 ${start}`);
    const endTime = new Date(`01/01/2023 ${end}`);

    const timeSlots = [];
    let currentTime = startTime;

    while (currentTime <= endTime) {
      let hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";

      if (hours > 12) {
        hours -= 12;
      }

      const timeString = `${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm}`;

      timeSlots.push(timeString);

      // Add 30 minutes to the current time
      currentTime = new Date(currentTime.getTime() + 30 * 60 * 1000);
    }

    return timeSlots;
  };

  if (isLoading) {
    <FileLoader />;
  }

  const handleSubmit = () => {
    setIsLoading(true);

    if(!selectedDate || !selectedTime){
      snackbar("Please select time and date", { variant: "error" });
      setIsLoading(false);
      return
    }

    let bookingData = {
      date: selectedDate,
      time: selectedTime,
    };
    console.log("BookingData\t\t\t", bookingData);
    subService.addAppointment(doctor._id, bookingData).then((res) => {
      setIsLoading(false);
      console.log("ResponseXX\t\t\t", res);

      if (!res.status || !res.data) {
        snackbar("Booking failed", { variant: "error" });
        return;
      }
      if (res.data.exists) {
        snackbar("Booking already exists", { variant: "success" });
        setDisableButton(true);
        return;
      }
      snackbar("Booking was successfull", { variant: "success" });
      setDisableButton(true);
      console.log("Response:\n", res.data);
      onClose();
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
          }}
        >
          <FileLoader />
        </Box>
      )}
      <Grid
        container
        sx={{
          height: "80%",
          width: "50%",
          backgroundColor: "#fff",
          borderRadius: "1.5rem",
          padding: "2rem 2.5rem 2rem 2.5rem",
          position: "relative",
          //   overflowY: "auto",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-track": {
            visibility: "hidden",
          },
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            overflowY: "auto",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-track": {
              visibility: "hidden",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontStyle: "Poppins",
                fontSize: "23px",
                color: "#000020",
                m: "0",
              }}
            >
              Book an Appointment
            </Typography>
            <img
              src={close}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </Box>
          {doctor && (
            <Box
              sx={{
                display: "flex",
                mt: "1.25rem",
              }}
            >
              <Grid
                container
                sx={{
                  flexDirection: "column",
                }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: "50%",
                      height: "5rem",
                      width: "5rem",
                      background: doctor.image
                        ? `url{${doctor.image}}`
                        : `url(${doctorDefault})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                  <Box sx={{ ml: "2rem" }}>
                    <Typography
                      sx={{
                        fontStyle: "Poppins",
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#000020",
                        letterSpacing: "0.5px",
                        m: 0,
                      }}
                    >
                      {doctor.name}
                    </Typography>
                    <Box>
                      <Typography
                        style={{
                          color: "#6F6F6F",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {doctor.education.map((item, index) => (
                          <span key={index} style={{ marginRight: "0.25rem" }}>
                            {item}
                          </span>
                        ))}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontStyle: "Poppins",
                          fontWeight: "regular",
                          fontSize: "14px",
                          color: "#6F6F6F",
                        }}
                      >
                        {doctor.location}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: "1.5rem",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontStyle: "Poppins",
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#000020",
                      }}
                    >
                      About
                    </Typography>
                  </Box>
                  <Box sx={{ maxHeight : '5rem' ,overflowY : 'auto'}}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontStyle: "Poppins",
                        color: "#252525",
                      }}
                    >
                      {doctor.about}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: "1.5rem",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontStyle: "Poppins",
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#000020",
                        mb: "0.5rem",
                      }}
                    >
                      Availability
                    </Typography>
                  </Box>
                  <Grid
                    container
                    sx={
                      {
                        // border : '2px solid red',
                      }
                    }
                  >
                    <Grid item xs={12} md={5.8}>
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={calender}
                            style={{ width: "1.5rem", height: "auto" }}
                            alt=""
                          />
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontStyle: "Poppins",
                              color: "#000020",
                              fontWeight: "600",
                            }}
                          >
                            Select Date
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          mt: "1rem",
                          maxHeight: "30%",
                          flexWrap: "nowrap",
                        }}
                      >
                        {doctor.availability.map((date, index) => (
                          <Box
                            key={index}
                            // key={date.id} // You might need a unique identifier for each date
                            sx={{
                              backgroundColor:
                                date === selectedDate ? "#2C2B83" : "#F5F5F5",
                              borderRadius: "0.9rem",
                              width: "14.2%",
                              height: "5rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer", // Add cursor pointer for interaction
                              mr : '0.5rem'
                            }}
                            onClick={() => handleSelectDate(date)}
                          >
                            {console.log("availabilit\t\t\t", doctor)}

                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                color:
                                  date === selectedDate ? "#FFFFFF" : "#000020",
                                textTransform: "uppercase",
                              }}
                            >
                              {abbreviateDay(date.day)}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5.8}>
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={clock}
                            style={{ width: "1.5rem", height: "auto" }}
                            alt=""
                          />
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontStyle: "Poppins",
                              color: "#000020",
                              fontWeight: "600",
                            }}
                          >
                            Time
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          //   justifyContent: "space-between",
                          flexWrap: "wrap",
                          mt: "1rem",
                          //   border: "1px solid blue",
                          overflowY: "auto",
                          height: "5rem",
                          p: "0",
                          "-ms-overflow-style": "none",
                          "&::-webkit-scrollbar-thumb": {
                            // backgroundColor: "transparent",
                          },
                          "&::-webkit-scrollbar-track": {
                            visibility: "hidden",
                          },
                        }}
                      >
                        {timeOptions.map((time, index) => (
                          <Box
                            // key={time}
                            key={index}
                            sx={{
                              backgroundColor:
                                time === selectedTime ? "#2C2B83" : "#F5F5F5",
                              borderRadius: "0.5rem",
                              width: "30%",
                              height: "2rem",
                              display: "flex",
                              justifyContent: "center",
                              //   border : '1px solid red',
                              alignItems: "center",
                              cursor: "pointer",
                              m: "0 0.5rem 0.5rem 0",
                            }}
                            onClick={() => setSelectedTime(time)}
                          >
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                color:
                                  time === selectedTime ? "#FFFFFF" : "#000020",
                                textTransform: "uppercase",
                              }}
                            >
                              {time}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    mt: "1rem",
                  }}
                >
                  {!disableButton ? (
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "2rem",
                          color: "#fff",
                          borderColor: "#EB568F",
                          textTransform: "none",
                          bgcolor: "#EB568F",
                          width: "25%",
                          fontSize: "14px",
                          fontWeight: "600",
                          height: "3rem",
                          position: "absolute",
                          bottom: "2.5rem",

                          "&:hover": {
                            borderColor: "#2C2B83",
                            bgcolor: "#2C2B83",
                            color: "white",
                          },
                        }}
                        onClick={handleSubmit}
                      >
                        Book Now
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "2rem",
                          color: "#fff",
                          borderColor: "#EB568F",
                          textTransform: "none",
                          bgcolor: "#2C2B83",
                          width: "25%",
                          fontSize: "14px",
                          fontWeight: "600",
                          height: "3rem",
                          position: "absolute",
                          bottom: "2.5rem",

                          "&:hover": {
                            borderColor: "#2C2B83",
                            bgcolor: "#2C2B83",
                            color: "white",
                          },
                        }}
                      >
                        Booked
                      </Button>
                    </Box>
                  )}
                </Grid>
                {/* <Grid item>
                <Box>
                  <Typography
                    sx={{
                      fontStyle: "Poppins",
                      fontWeight: "regular",
                      fontSize: "14px",
                      color: "#6F6F6F",
                    }}
                  >
                    Kochi
                  </Typography>
                </Box>
              </Grid> */}
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Appointment;
