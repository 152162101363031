import React, { useCallback, useRef } from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import dr1 from "../../images/dr1.png";
import { useSnackbar } from "notistack";
import landing from "../../images/guidance.png";
import { useState, useEffect } from "react";
// import { Link as RouterLink } from "react-router-dom";
import { doctorService, eventService, subService } from "../../service";
// import Appointment from "../../components/book-appointment/book-appointment";
// import Event from "../../components/book-event/book-event";
import FileLoader from "../../components/FileLoader/FileLoader";
import { getAuth } from "../../utils/storage-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../../components/book-appointment/book-appointment";
import EventIcon from "../../images/eventIcon.svg";
import defaultEvent from "../../images/defaultevent_thumb.png";
import Close from "../../images/close.png";
import { useNavigate } from "react-router-dom";

function CareerGuidance() {
  // const [isHovered1, setIsHovered1] = useState(false);

  const [eventModal, openEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const programListRef = useRef(null);

  const handleButtonClick = () => {
    if (programListRef.current) {
      programListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleHover1 = () => {
  //   setIsHovered1(true);
  // };

  // const handleEventClose = () => {
  //   openEventModal(false);
  // };

  // const handleMouseLeaves1 = () => {
  //   setIsHovered1(false);
  // };

  // const [isHovered2, setIsHovered2] = useState(false);

  // const handleHover2 = () => {
  //   setIsHovered2(true);
  // };

  // const handleMouseLeaves2 = () => {
  //   setIsHovered2(false);
  // };

  // const [isHovered3, setIsHovered3] = useState(false);

  // const handleHover3 = () => {
  //   setIsHovered3(true);
  // };

  // const handleMouseLeaves3 = () => {
  //   setIsHovered3(false);
  // // };

  // const formatDate = (dateStr) => {
  //   const date = new Date(dateStr);
  //   const options = { year: "numeric", month: "short", day: "2-digit" };
  //   return date.toLocaleDateString("en-US", options);
  // };

  // const [isHovered4, setIsHovered4] = useState(false);

  // const handleHover4 = () => {
  //   setIsHovered4(true);
  // };

  // const handleMouseLeaves4 = () => {
  //   setIsHovered4(false);
  // };

  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [initialLoadingComplete, setInitialLoadingComplete] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [doctorList, setDoctorList] = useState(null);
  const [eventList, setEventList] = useState(null);
  // const [session, setSession] = useState(null);

  // const openModal = () => {
  //   console.log("Counter");
  //   setModalOpen(true);
  // };

  const closeModal = () => {
    setTrigger(!trigger);
    setModalOpen(false);
  };

  if (initialLoadingComplete) {
    <FileLoader />;
  }

  const fetchDoctors = useCallback((session) => {
    setInitialLoadingComplete(false);
    if (session && session.userId) {
      doctorService.getDoctorsByUser(session.userId).then((res) => {
        // setInitialLoadingComplete(true);
        console.log("Response\t\t\t", res);
        if (!res.status || !res.data) {
          snackbar("Doctor fetching failed", { variant: "error" });
          return;
        }
        setDoctorList(res.data);
        console.log("doctors:\n", res.data);
      });
    } else {
      doctorService.getAllDoctors().then((res) => {
        // setInitialLoadingComplete(true);
        if (!res.status || !res.data) {
          snackbar("Doctor fetching failed", { variant: "error" });
          return;
        }
        setDoctorList(res.data);
        console.log("doctors:\n", res.data);
      });
    }
  }, [snackbar]);

  const fetchEvents = useCallback(async (data) => {
    setInitialLoadingComplete(false);
    try {
      let uid = "";
      if (data && data.userId) {
        uid = data.userId;
      }

      const res = await eventService.getAllEvents(uid);
      if (res.status && res.data) {
        console.log("EventResponse\t\t", res);
        if (res.status) setEventList(res.data);
      } else {
        console.log("Error_Guidance_Event_X", res);
        snackbar("Failed to fetch data", { variant: "error" });
      }
    } catch (error) {
      console.error("Error_Guidance_Event", error);
      snackbar("Failed to fetch data", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
    }
  }, [snackbar]);

  const [user, setUser] = useState(null);
  useEffect(() => {
    const session = getAuth();
    if (session) {
      setUser(session);
    }
    fetchDoctors(session);
    fetchEvents(session);
  }, [trigger, fetchDoctors, fetchEvents]);



  const handleEventOpen = (event) => {
    console.log("Sele\t\t\t", event);
    setSelectedEvent(event);
    openEventModal(true);
  };

  const handleEventBooking = async () => {
    setInitialLoadingComplete(false);
    try {
      if (!user) {
        setSelectedEvent(null);
        snackbar("Please login to book events", { variant: "warning" });
        navigate("/sign-in");
        return;
      }
      const res = await subService.bookEvent(selectedEvent._id);
      console.log("BookingResponse\t\t", res);
      if (res.exists) {
        snackbar("Booking already exists", { variant: "success" });
        return;
      }
      if (res.status && res.data) {
        if (res.status) {
          snackbar("Booking Successful", { variant: "success" });
        }
      } else {
        console.log("Event_Booking_Error_X", res);
        snackbar("Booking failed", { variant: "error" });
      }
    } catch (error) {
      console.error("Event_Booking_Error", error);
      snackbar("Booking failed", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
      setSelectedEvent(null);
      openEventModal(false);
      setTrigger(!trigger);
    }
  };

  // const anchorRef = React.useRef(null);

  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState();

  // const handlePopoverOpen = (event) => {
  //   setIsPopoverOpen(true);
  //   fetchSelectedDoctor(event._id);
  //   console.log("Docotr\t\t\t", event);
  // };

  // const fetchSelectedDoctor = (id) => {
  //   setInitialLoadingComplete(false);
  //   doctorService.getSingleDoctor(id).then((res) => {
  //     setInitialLoadingComplete(true);
  //     if (!res.status || !res.data) {
  //       snackbar("Doctor fetching failed", { variant: "error" });
  //       return;
  //     }
  //     setSelectedDoctor(res.data);
  //     console.log("SelectedDoctor:\n", res.data);
  //   });
  // };

  // const handlePopoverClose = () => {
  //   setIsPopoverOpen(false);
  // };

  const handleOpenModal = (doctor) => {
    setSelectedDoctor(doctor);
    setModalOpen(true);
  };

  return (
    <>
      {/* LANDING */}
      <Box
        sx={{
          mt: "3.6%",
          padding: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "0",
          },
          height: {
            xs: "100vh",
            sm: "100vh",
            md: "93vh",
          },
          width: "100%",
          background: `url(${landing})`,
          backgroundPosition: "center center",
          backgroundSize: "contain",
          // backgroundRepeat : "no-repeat"
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            // border: '2px solid red',
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            padding: {
              xs: "1.5rem",
              sm: "1.5rem",
              md: "0",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: " flex",
              justifyContent: "center",
              alignItems: "center",
              // border: '1px solid red',
              width: "30%",
              left: "8.5%",
              mt: "2rem",
              position: "absolute",
            }}
          >
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    fontSize: "36px",
                    fontWeight: "700",
                    color: "#13112D",
                    // width : "50%"
                    // border: "1px solid red",
                    // position : 'absolute',
                  }}
                >
                  {/* Lorem ipsum dolor sit */}
                  Plan Your UK Medical Career
                  <br />
                  {/* amet, consectetur{" "} */}
                  with Guidance From Experts!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "normal",
                    color: "#252525",
                  }}
                >
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do <br /> eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. */}
                  At StudyMEDIC, we assist medical aspirants in finding their
                  dream medical career in the UK. Through this dedicated
                  platform, aspirants get a unique opportunity to talk, discuss,
                  and engage directly with expert medical professionals who have
                  years of experience working with the UK healthcare system. Our
                  experts panel will guide aspirants, providing valuable
                  insights and helping them build their dream careers in
                  specialties like Obstetrics and Gynaecology, Internal
                  Medicine, Emergency Medicine, General Surgery, Clinical
                  Radiology, and more. Connect with us and plan your UK career
                  path with confidence.
                </Typography>

                <Button
                  sx={{
                    mt: "2rem",
                    textTransform: "none",
                    backgroundColor: "#EB568F",
                    borderRadius: "1.5rem",
                    height: "3rem",
                    fontSize: "13.31px",
                    color: "#FFF",
                    mr: "1rem",
                    padding: "0.8rem 2rem 0.8rem 2rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                    },
                  }}
                  onClick={() => handleButtonClick()}
                >
                  Join Events
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {initialLoadingComplete ? (
        <>
          {/* SECTION 2 */}
          <Box
            sx={{
              // CONTAINER
              // border : '1px solid red',
              overflow: "hidden",
              width: "100%",
              padding: "5% 14% 5% 14%",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "23px",
                  fontWeight: "600",
                  color: "#000020",
                }}
              >
                Book Your Career Guidance
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: "noraml",
                  color: "#252525",
                  mt: "0.25rem",
                  width: { md: "50%", sm: "80%", xs: "80%" },
                }}
              >
                {/* Our career guidance services are available to assist you in
                booking an appointment and exploring your career options. Please
                contact us to schedule a consultation with one of our experts. */}
                StudyMEDIC's career guidance services are available to assist
                you in booking appointments with experts and exploring your
                career options. Contact us to schedule a talk with one of our
                experts.
              </Typography>
            </Box>

            <Box>
              <Grid container gap={3} sx={{ justifyContent: "flex-start" }}>
                {doctorList &&
                  doctorList.map((doctor, index) => (
                    <>
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={2.8}
                        sx={{
                          backgroundColor: "#F4F4F4",
                          borderRadius: "0.5rem",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                          padding: "1.5rem",
                          mt: "2rem",
                        }}
                      >
                        {console.log("Doctor Data:", doctor.availability)}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "5rem",
                              height: "5rem",
                              // border: '1px solid red',
                              borderRadius: "50%",
                              background: doctor?.image
                                ? `url(${doctor.image})`
                                : `url(${dr1})`,
                              backgroundSize: "contain",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              mr: "1rem",
                            }}
                          ></Box>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "15.6px",
                                fontWeight: "600",
                                color: "#000020",
                              }}
                            >
                              {doctor?.name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12.67px",
                                  fontWeight: "600",
                                  color: "#6F6F6F",
                                  mr: "0.2rem",
                                }}
                              >
                                {doctor?.education[0]}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12.67px",
                                  fontWeight: "600",
                                  color: "#6F6F6F",
                                }}
                              >
                                |
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12.67px",
                                  fontWeight: "600",
                                  color: "#6F6F6F",
                                  ml: "0.2rem",
                                }}
                              >
                                {doctor?.specialization[0]}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "center",

                            justifyContent: "center",
                          }}
                        >
                          <Box sx={{ display: "flex", mt: "1rem" }}>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <Typography
                              sx={{
                                ml: "1rem",
                                fontSize: "12.67px",
                                fontWeight: "600",
                                color: "#6F6F6F",
                              }}
                            >
                              {doctor?.location[0]}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mt: "1rem",
                              height: "1.2rem",
                              overflowY: "hidden",
                            }}
                          >
                            {doctor.availability.map((item, index) => (
                              <Box
                                key={index}
                                sx={{ display: "flex", mt: "0.1rem" }}
                              >
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <Typography
                                  sx={{
                                    ml: "0.8rem",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "#6F6F6F",
                                  }}
                                >
                                  {`${item.day}: ${item.start} - ${item.end}`}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>

                        {!doctor.booked ? (
                          <>
                            {console.log("Booked\t\t", doctor)}
                            <Button
                              sx={{
                                border: "1px solid #EB568F",
                                fontFamily: "Poppins",
                                color: "#EB568F",
                                width: "100%",
                                mt: "2rem",
                                padding: "0.5rem",
                                borderRadius: "1.5rem",
                                textTransform: "none",
                                "&:hover": {
                                  color: "#fff",
                                  backgroundColor: "#EB568F",
                                },
                              }}
                              onClick={() => {
                                if (!user) {
                                  navigate("/sign-in");
                                  return;
                                }
                                handleOpenModal(doctor);
                              }}
                            >
                              Book Appointment
                            </Button>
                          </>
                        ) : (
                          <Button
                            sx={{
                              border: "1px solid #2C2B83",
                              fontFamily: "Poppins",
                              color: "#ffffff",
                              bgcolor: "#2C2B83",
                              width: "100%",
                              mt: "2rem",
                              padding: "0.5rem",
                              borderRadius: "1.5rem",
                              textTransform: "none",
                              "&:hover": {
                                color: "#fff",
                                backgroundColor: "#2C2B83",
                                cursor: "none",
                              },
                            }}
                            onClick={() => {
                              handleOpenModal(doctor);
                            }}
                          >
                            Booked
                          </Button>
                        )}
                      </Grid>
                      <Modal
                        open={isModalOpen}
                        onClose={() => {
                          closeModal();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                            width: "100vw",
                          }}
                        >
                          <BookAppointment
                            onClose={closeModal}
                            doctor={selectedDoctor}
                          />
                        </Box>
                      </Modal>
                    </>
                  ))}
              </Grid>
            </Box>
          </Box>

          {/* SECTION 3 */}
          {eventList && (
            <Box
              sx={{
                // CONTAINER
                // border : '1px solid red',
                overflow: "hidden",
                width: "100%",
                padding: "5% 14% 5% 14%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "23px",
                    fontWeight: "600",
                    color: "#000020",
                  }}
                  ref={programListRef}
                >
                  Book Your Event
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "noraml",
                    color: "#252525",
                    mt: "0.25rem",
                    width: { md: "80%", sm: "80%", xs: "80%" },
                  }}
                >
                  Our team will help you stay updated with career-guiding
                  webinars led by experts . Reach <br />
                  out to us and book your event slots.
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "0.8rem",
                  width: "100%",
                }}
              >
                <img src={EventIcon} style={{}} alt="" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "17px",
                    color: "#EB568F",
                    fontWeight: "500",
                    ml: "0.5rem",
                  }}
                >
                  Events
                </Typography>
              </Box>
              <Grid container sx={{ justifyContent: "space-between", mt: "0" }}>
                {eventList.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5.93}
                    sx={{
                      border: "1px solid #D7D7D7",
                      mt: "1rem",
                      display: "flex",
                      height: "8rem",
                      borderRadius: "0.3rem",
                      justifyContent: "flex-start",
                      overflow: "hidden",
                    }}
                  >
                    {console.log("Image\t\t\t", item)}
                    <Box
                      sx={{
                        width: "35%",
                        background: item?.image
                          ? `url(${item.image})`
                          : `url(${defaultEvent})`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></Box>

                    <Box
                      sx={{
                        // border: "1px solid blue",
                        maxWidth: "65%",
                        flexGrow: 1,
                        padding: "1rem 0.8rem 1rem 1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "10px",
                            color: "525252",
                          }}
                        >
                          Medical
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "10px",
                            color: "525252",
                          }}
                        >
                          {item.date}
                        </Typography>
                      </Box>

                      {/* <Box
                    sx={{
                      border: "1px solid red",
                      display : 'flex',
                      width : '100%',
                      height: "65%",
                      mt : '0.2rem'
                    }}
                  > */}
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14.41px",
                          color: "#252525",
                          fontWeight: "normal",
                          // border: "1px solid red",
                          display: "flex",
                          width: "100%",
                          height: "60%",
                          mt: "0.2rem",
                          overflowY: "auto",
                        }}
                      >
                        {!item.description || item.description.length === 0
                          ? "default Text"
                          : item.description}
                      </Typography>
                      {/* </Box> */}

                      {!item.booked ? (
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "13.66px",
                            color: "#EB568F",
                            fontWeight: "500",
                            textDecoration: "underline",
                            mt: "0.2rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (!user) {
                              navigate("/sign-in");
                              return;
                            }
                            handleEventOpen(item);
                            // handleEventBooking(item);
                          }}
                        >
                          Book Now
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "13.66px",
                            color: "#EB568F",
                            fontWeight: "500",
                            textDecoration: "underline",
                            mt: "0.2rem",
                          }}
                        >
                          Booked
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </>
      ) : (
        <>
          <FileLoader />
        </>
      )}

      <Modal
        open={eventModal}
        onClose={() => openEventModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              width: "25%",
              height: "25%",
              borderRadius: "0.5rem",
              padding: "3%",
              position: "relative",
            }}
          >
            <img
              src={Close}
              alt=""
              style={{
                height: "1.5rem",
                cursor: "pointer",
                position: "absolute",
                right: "2.5%",
                top: "4%",
              }}
              onClick={() => openEventModal(false)}
            />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "",
                color: "",
                fontWeight: "",
              }}
            >
              Are you sure you want to book <br />
              <span style={{ fontWeight: "600" }}>
                {selectedEvent?.title}
              </span>{" "}
              ?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-end",
                justifyContent: "space-between",
                mt: "1.5rem",
              }}
            >
              <Button
                sx={{
                  width: "45%",
                  bgcolor: "#f4f4f4",
                  border: "1px solid #EB568F",
                  "&:hover": { bgcolor: "#2C2B83", color: "#ffffff" },
                }}
                onClick={() => openEventModal(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "45%",
                  bgcolor: "#EB568F",
                  color: "#ffffff",
                  "&:hover": { bgcolor: "#2C2B83" },
                }}
                onClick={() => {
                  handleEventBooking();
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default CareerGuidance;
