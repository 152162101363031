

const recordService = (httpClient) => {
  return {
    addFile: async (data, config) => {
      try {
        const res = await httpClient.post("/files", data, config);
        return res.data;
      } catch (err) {
        console.log("err", err);
        return { status: false, message: "File upload failed" };
      }
    },

    fetchRecord: async (id) => {
      try {
        const res = await httpClient.get(`/fetch-record/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchRecords: async (id) => {
      try {
        const res = await httpClient.get(`/record/${id ? id : "0"}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    createFolder: async (data) => {
      try {
        const res = await httpClient.post(`/record`, data);
        return res.data;
      } catch (err) {
        return { status: false, message: "Folder creation failed" };
      }
    },

    updateRecord: async (id, name) => {
      try {
        const res = await httpClient.put(`/record/${id}`, { name });
        return res.data;
      } catch (err) {
        return { status: false, message: "Update failed" };
      }
    },

    deleteRecord: async (id) => {
      try {
        const res = await httpClient.delete(`/record/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Delete failed" };
      }
    },

    fetchTrash: async (id) => {
      try {
        const res = await httpClient.get(`/trash/${id}`);
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: "Failed to get trash files.",
          data: [],
        };
      }
    },

    restoreTrash: async (id) => {
      try {
        const res = await httpClient.put(`/restore/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to restore.", data: [] };
      }
    },

    deleteRecordFromTrash: async (id) => {
      try {
        const res = await httpClient.delete(`/record-hard/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Delete failed" };
      }
    },

    deleteMultipleRecordsFromTrash: async (ids) => {
      try {
        const res = await httpClient.post('/trash/bulk-delete', { data: { docIds: ids } });

        if (!res || !res.data) {
          return { status: false, message: "Delete failed" };
        }

        const responses = Array.isArray(res.data) ? res.data : [res.data];

        const allSuccessful = responses.every(response => response === 'Record deleted successfully');

        if (allSuccessful) {
          return { status: true, message: "All records deleted successfully" };
        } else {
          return { status: false, message: "Some records could not be deleted" };
        }
      } catch (err) {
        return { status: false, message: "Delete failed" };
      }
    },

    fetchSharingData: async (id) => {
      try {
        const res = await httpClient.get(`/record-share-users/${id}`);
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: "Failed to get sharing data.",
          data: [],
        };
      }
    },

    shareRecord: async (data) => {
      try {
        const res = await httpClient.put(`/record-share/${data.id}`, {
          email: data.email,
          permission: data.permission,
        });
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to share.", data: [] };
      }
    },

    fetchSharedRecords: async () => {
      try {
        const res = await httpClient.get(`/record-share`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchSharedRecordsUnderUser: async (id) => {
      try {
        const res = await httpClient.get(`/user-record/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    fetchDashboardData: async () => {
      try {
        const res = await httpClient.get(`/dashboard`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Failed to fetch dashboard data" };
      }
    },

    fetchRecentRecords: async (limit = 5) => {
      try {
        const res = await httpClient.get(`/recent/${limit}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },

    searchRecords: async (key) => {
      try {
        const res = await httpClient.get(`/search/${key}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Search failed" };
      }
    },

    fetchParents: async (id) => {
      try {
        const res = await httpClient.get(`/parents/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Location fetching failed" };
      }
    },

    fetchTotalFilesUnderUser: async (id) => {
      try {
        const res = await httpClient.get(`/user/records/total/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Total file count fetching failed" };
      }
    },

    getUserRecordService: async () => {
      try {
        const res = await httpClient.get('/get/records/user');
        return res.data;
      } catch (err) {
        return { status: false, message: "User files fetching failed" };
      }
    },

    getFolderRecordService: async (id) => {
      try {
        const res = await httpClient.get(`/get/records/folder/${id}`);
        return res.data;
      } catch (err) {
        return { status: false, message: "Records fetching failed" };
      }
    },

    generatePresignedUrl: async (data) => {
      try {
        console.log("DataPRES\t\t\t", data);
        const res = await httpClient.get(`/file/signed/${data}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch URL" };
      }
    },
  };
};

export default recordService;
