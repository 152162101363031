import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { recordService } from "../../../service/index";
import FileUploadProgress from "../../dialogue/FIleUploadProgress";

const DropZone = (props) => {
  const { id } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length || props.disable) {
      return;
    }

    const data = new FormData();
    data.append("parentId", `${id ? id : ""}`);
    acceptedFiles.forEach((file) => {
      data.append("img", file);
    });
    if (id) {
      data.append("parentId", id);
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentComplete = Math.round(
          (progressEvent.loaded / (progressEvent.total || 0)) * 100
        );

        setProgress(percentComplete);
      },
    };

    setShowUploadProgress(true);
    recordService.addFile(data, config).then((res) => {
      setProgress(0);
      setShowUploadProgress(false);
      if (!res.status) {
        snackbar("Upload error", { variant: "error" });
        return;
      }

      snackbar("Upload success", { variant: "success" });
      props.updateParent && props.updateParent();
    });
  }, [id, props, snackbar]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <Box {...getRootProps()} sx={{
      height: "100%",
    }}>
      <input {...getInputProps()} />
      {props.children}
      <FileUploadProgress
        open={showUploadProgress}
        onClose={() => {
          setShowUploadProgress(false);
        }}
        progress={progress}
      />
    </Box>
  );
};

export default DropZone;
