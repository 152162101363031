import React, { useState, useRef } from 'react';
import { Box, Card, Typography, Grid } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import home18 from "../../images/home18.png"
import home19 from "../../images/home19.png"




const Carousels3 = () => {
  const [, setCurrentSlide] = useState(0);
  const slider = useRef(null);

  
    
const customSlides = [
  {
    imageSrc: home18,
    title: 'I cant express enough how Study Medic has transformed my medical journey. The Clinical Program provided me with invaluable hands-on experience, and the mentorship I received was exceptional. I feel more confident and prepared for my future as a healthcare professional.',
    description:'- Mark Johnson, Medical Student',
   
    leftValue: '80px'
  },
  {
    imageSrc: home19,
    title: 'I cant express enough how Study Medic has transformed my medical journey. The Clinical Program provided me with invaluable hands-on experience, and the mentorship I received was exceptional. I feel more confident and prepared for my future as a healthcare professional.',
    description:'- Mark Johnson, Medical Student',
    leftValue: '145px'
  },
  {
    imageSrc: home18,
    title: 'I cant express enough how Study Medic has transformed my medical journey. The Clinical Program provided me with invaluable hands-on experience, and the mentorship I received was exceptional. I feel more confident and prepared for my future as a healthcare professional.',
    description:'- Mark Johnson, Medical Student',
    leftValue: '125px'
  },

];

  


const sliderSettings = {
  slidesToScroll: 1,
  infinite: false,
  dots:true,
  arrows: false,
  afterChange: (index) => setCurrentSlide(index),
  responsive: [

    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1.1,
      },
    },
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


return (
  <Box
    sx={{
      // width: '775px',
      display: 'block',
      padding: '0px',
      // marginTop:'100px',
      textAlign: 'center',
      height: '453px',
      position:'relative',
    
   
      '@media (min-width: 1000px) and (max-width: 1200px)': {
      
        width:'450px'
        
      },
      '@media (min-width: 900px) and (max-width: 1000px)': {
       
        width:'387px'
        
      },
      '@media (min-width: 600px) and (max-width: 899px)': {
      
        width:'487px'
        
      },
      '@media (max-width: 600px)': {
    
         width:'400px'
         
       },
 

  
   
  
    }}
  >
    <style>
  {`
     .custom-carousel .slick-slide {
      margin-right: -15px; /* Add margin here to create a gap between cards */
    }
  
    @media  (max-width: 899.5px) {
      .custom-carousel .slick-slide {
        margin-right: -105px; /* Add margin here to create a gap between cards */
      }
      @media  (max-width: 600px) {
        .custom-carousel .slick-slide {
          margin-right: -15px; /* Add margin here to create a gap between cards */
        }
       
        
  `}
</style>
   
  
    <Slider {...sliderSettings} ref={slider} className="custom-carousel">
      {customSlides.map((slide, index) => (
        <div key={index} >
          <Card
            sx={{
              width: '316.581px',
              height: '388.987px',
              marginTop:'20px',
            
              
            }}
          >
            <Grid ml={'30px'} mt={'25px'}>
              <img
                src={slide.imageSrc}
                alt='Custom Slide'
                style={{ width: '94.953px', height: '92.953px',marginTop:'20px' }}
              />
           
              <Typography
                sx={{
                  fontSize: '13.373px',
                  fontWeight: '400',
                  mt: '25px',
                  textAlign: 'justify',
                  paddingRight:'35px'
                }}
              >
                {slide.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '15.602px',
                  fontWeight: '500',
                  mt: '40px',
                  textAlign: 'justify',
                  paddingRight: '30px',
                }}
              >
                {slide.description}
              </Typography>
            
            </Grid>
          </Card>
        </div>
      ))}
    </Slider>
  
  </Box>
);
};

export default Carousels3;