const siteService = (httpClient) => {
  return {
    getBanner: async () => {
      try {
        const res = await httpClient.get("get/banner/image");
        return res.data;
      } catch (err) {
        return { status: false, message: "File upload failed" };
      }
    },
  };
};

export default siteService;
