import React, { useCallback, useEffect, useState } from "react";
// import NavBar from '../../components/navbar/NavBar';
import { Button, Grid, Typography, Box, Modal } from "@mui/material";
import home1x from "../../images/home1x.png";
import home2 from "../../images/home2.png";
import home5 from "../../images/home5.png";
import home11 from "../../images/home11.png";
import home17 from "../../images/home17.png";
import homeSample from "../../images/sampleHome.mp4";
import SliderComponent from "../../components/carousels/Carousels";
import Carousels2 from "../../components/carousels/Carousels2";
import Carousels3 from "../../components/carousels/Carousels3";
import { useNavigate } from "react-router-dom";
// import Footer from '../../components/footer/Footer';
import { getAuthStorage } from "../../utils/storage-utils";
import { siteService } from "../../service";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader";

function Home({ userId }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = useCallback(() => {
    try {
      setLoading(true);
      siteService.getBanner().then((res) => {
        setLoading(false);
        console.log("res_banner\t\t", res);
        if (!res) {
          snackbar("Banner fetching failed", { variant: "error" });
          return;
        }
        setBanner(res.imageUrl);
        console.log("Banner:\n", res.data);
      });
    } catch (err) {
      console.log("Error_fetching_Banner\t", err);
      snackbar("Banner fetching failed", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [snackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [openImage, setOpenImage] = useState(false);
  return (
    <div
      style={{
        overflowX: "hidden",
      }}
    >
      {loading && <FileLoader />}
      <>
        {/* LANDING */}
        <Grid
          container
          sx={{
            // mt : '2rem',
            width: "100vw",
            height: "100vh",
            backgroundColor: "#F2EBFF",
            position: "relative",
            overflow: "hidden",
            padding: {
              sm: "1.5rem",
            },
          }}
        >
          <Grid
            item
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              // mt: "2rem",
              background: `url(${home1x})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "repeat-y",
              overflow: "none",
            }}
          >
            <Grid
              container
              sx={{
                // height: "calc(100% - 2rem)",
                height: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  // border: "1px solid red",
                  mt: "0.5rem",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#362283",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Unlock Your Medical Journey{" "}
                  </Typography>

                  <Typography
                    sx={{
                      mt: "1rem",
                      fontWeight: "600",
                      fontSize: "54px",
                      lineHeight: "60px",
                    }}
                  >
                    With StudyMEDIC <br />E Portfolio{" "}
                  </Typography>

                  <Typography
                    sx={{
                      mt: "1rem",
                      fontWeight: "400",
                      color: "#000000",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                    }}
                  >
                    A Space Where Your Medical Dreams Go Digital!
                    {/* StudyMEDIC E Portfolio equips you with a powerful platform
                      to manage<br></br>
                      your learning, track your progress, and showcase your
                      achievements. */}
                  </Typography>
                  <Button
                    sx={{
                      mt: "2rem",
                      width: "161px",
                      height: "51px",
                      bgcolor: " #EB568F",
                      borderRadius: "30px",
                      color: "white",
                      textTransform: "none",
                      "&:hover": {
                        bgcolor: " #2C2B83",
                      },
                    }}
                    onClick={() => {
                      const session = getAuthStorage();
                      if (session) {
                        navigate(`/dashboardT/${session.userId}`);
                        return;
                      }
                      navigate("/sign-in/");
                    }}
                  >
                    Start Now
                  </Button>
                  <Box
                    sx={{
                      mt: "2.5rem",
                      display: "flex",
                      // border : '1px solid red',
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "80%",
                      },
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // border : '1px solid red'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "30.78px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          color: "#13112D",
                        }}
                      >
                        93<span style={{ color: "#2C2B83" }}>%</span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#000000",
                          fontFamily: "Poppins",
                        }}
                      >
                        Overall Success Rate
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "30.78px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          color: "#13112D",
                        }}
                      >
                        170<span style={{ color: "#2C2B83" }}>+</span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#000000",
                          fontFamily: "Poppins",
                        }}
                      >
                        Passionate Mentors
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "30.78px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          color: "#13112D",
                        }}
                      >
                        18<span style={{ color: "#2C2B83" }}>+</span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#000000",
                          fontFamily: "Poppins",
                        }}
                      >
                        Partners/Association
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={0}
                sm={0}
                md={6}
                sx={{
                  // border: "1px solid blue",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    mt: "10%",
                    height: "75%",
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                    },
                    // border: '1px solid red'
                  }}
                >
                  <img
                    src={banner?.imageUrl ? `${banner.imageUrl}` : `${home2}`}
                    // src="https://studymedic-e-portfolio.s3.amazonaws.com/public/images/b262285d-b1b2-45d2-a298-c253ce137065.png"
                    alt=""
                    style={{ height: "100%", width: "auto" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Button onClick={() => setOpenImage(true)}>Open modal</Button> */}
        <Modal
          open={openImage}
          onClose={() => { setOpenImage(false) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box sx={{ border: '1px solid red', width: '50vw', height: '50vh', backgroundColor: '#fff' }}>
            <img alt="" src="https://studymedic-e-portfolio.s3.amazonaws.com/public/images/b262285d-b1b2-45d2-a298-c253ce137065.png" />
          </Box>
        </Modal>
        {/* SECOND */}
        <Box
          sx={{
            margin: "5% 9% 5% 9%",
            // border: '1px solid blue',
          }}
        >
          <Grid
            container
            sx={{
              padding: {
                sm: "1rem",
                alignItems: "center",
                justifyContent: "space-between",
              },
            }}
          >
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                display: "flex",
                alignItems: "center",

                height: "100%",
              }}
            >
              <img
                src={home5}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid
              item
              md={12}
              lg={4}
              sx={{
                height: "100%",
                mr: {
                  md: "3.5rem",
                },
                // display: "flex",
                // flexDirection : 'column',
                // border : '1px solid blue'
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "26px",
                  color: "#090914",
                }}
              >
                What is an e-portfolio?
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.5rem",
                }}
              >
                An E-Portfolio is a web-based tool for medical aspirants to
                curate, showcase, and reflect upon their academic and
                professional achievements digitally. Here, aspirants can store
                and record all evidence of their experience, competencies, and
                courses throughout their entire medical training period using
                elements such as images, videos, documents, and other
                interactive features.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.5rem",
                }}
              >
                At StudyMEDIC, we ensure that your E-Portfolio is not just a
                collection of achievements but a celebration of your growth, a
                reflection of your unique path, and a declaration of your
                medical aspirations.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* THIRD */}
        <Box
          sx={{
            margin: {
              lg: "5% 9% 5% 9%",
              sm: "5% 0% 5% 0%",
            },
            backgroundColor: "#F2F5FF",
            overflow: "hidden",
            // border: '1px solid red',
          }}
        >
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              overflow: "hidden",
              margin: {
                md: "2.5rem 3.5rem 2.5rem 3.5rem",
                lg: "2.5rem 3.5rem 2.5rem 3.5rem",
                xl: "2.5rem 3.5rem 2.5rem 3.5rem",
                sm: "2.5rem 2rem 2.5rem 2rem",
                xs: "2.5rem 2rem 2.5rem 2rem",
              },
            }}
          >
            <Grid item xs={10} sm={11} md={11} lg={4} sx={{}}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "26px",
                  fontWeight: "600",
                  color: "#090914",
                }}
              >
                Why choose an e-portfolio?
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.5rem",
                }}
              >
                If you are a medical aspirant seeking International Memberships
                and Fellowships, it is mandatory for you to maintain a record of
                evidence showing your continuous development of knowledge and
                skills.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.5rem",
                }}
              >
                StudyMEDIC E-Portfolio is a unique space where you can manage
                your learning, track your progress, and showcase your
                achievements. Whether you are a medical student, resident, or
                seasoned practitioner, the way our e-portfolios are designed can
                significantly uplift your career.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                // mr: {
                //   lg: '3.5rem',
                // },
                mt: {
                  md: "1.5rem",
                  xs: "1.5rem",
                  sm: "1.5rem",
                },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  // border: '1px solid red',
                  maxHeight: {
                    md: "13.34rem",
                    sm: "13.34rem",
                    xs: "13.34rem",
                    lg: "20rem",
                    xl: "20rem",
                  },
                  maxWidth: {
                    md: "20rem",
                    sm: "20rem",
                    xs: "20rem",
                    lg: "33.75rem",
                    xl: "33.75rem",
                  },
                  transform: {
                    sm: "translateX(-1.5rem)",
                    xs: "translateX(-1.5rem)",
                  },
                  // maxHeight: '13.34rem',
                  // maxWidth: '20rem',
                  borderRadius: "1rem",
                  overflow: "hidden",
                }}
              >
                <video controls>
                  <source src={homeSample} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ padding: "1.5rem" }}>
            <SliderComponent />
          </Box>
        </Box>

        {/* FOURTH */}
        <Box
          sx={{
            margin: {
              lg: "5% 9% 5% 9%",
              sm: "5% 0% 5% 0%",
              xs: "5% 0% 5% 0%",
              md: "5% 0% 5% 0%",
            },
            overflow: "hidden",
            // border: '1px solid red',
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "space-between",
                xl: "space-between",
              },
            }}
          >
            <Grid item xs={11} sm={11} md={11} lg={4} xl={4}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "26px",
                  fontWeight: "600",
                  color: "#090914",
                }}
              >
                E-Portfolio for Clinical Program
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.5rem",
                }}
              >
                At StudyMEDIC, we understand the importance of practical,
                hands-on training in your medical career. StudyMEDIC offers a
                Two Years Advanced Clinical Training Program to bridge the gap
                between theoretical knowledge and practical application,
                equipping you with the skills and confidence necessary to excel
                in your medical career.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.5rem",
                }}
              >
                StudyMEDIC’s E-Portfolio is a valuable repository of aspirants'
                practical learning journey, capturing the skills they develop,
                the patients they interact with, and the procedures they assist
                or perform. By maintaining a comprehensive E portfolio, students
                can not only document their activities but also engage in
                reflection, identifying areas for improvement and setting goals
                for growth.
                <br />
                <br />
                Join with us and make use of the endless clinical learning
                opportunities
                <br /> Start Your E-Portfolio Journey Today!
              </Typography>
              <Button
                sx={{
                  mt: "2rem",
                  width: "161px",
                  height: "51px",
                  bgcolor: " #EB568F",
                  borderRadius: "30px",
                  color: "white",
                  textTransform: "none",
                  "&:hover": {
                    bgcolor: " #2C2B83",
                  },
                }}
                onClick={() => {
                  navigate("/clinical");
                }}
              >
                Join Program
              </Button>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              <img
                src={home11}
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* FIFTH */}
        <Box
          sx={{
            margin: {
              lg: "5% 9% 5% 9%",
              sm: "5% 0% 5% 0%",
            },
            backgroundColor: "#F2F5FF",
            overflow: "hidden",
            // border: '1px solid red',
          }}
        >
          <Box sx={{
            // border : '2px solid red',
            pl: '1.5rem',
            pt: '1.5rem'
          }}>
            <Typography sx={{
              fontSize: "26px",
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#090914"
            }}>Our Partners</Typography></Box>

          <Box sx={{ padding: "1.5rem" }}>
            <Carousels2 />
          </Box>
        </Box>

        {/* SIXTH */}
        <Box
          sx={{
            margin: {
              lg: "5% 9% 5% 9%",
              sm: "5% 0% 5% 0%",
              xs: "5% 0% 5% 0%",
              md: "5% 0% 5% 0%",
            },
            overflow: "hidden",
            // border: '1px solid red',
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "space-between",
                xl: "space-between",
              },
            }}
          >
            <Grid item xs={11} sm={11} md={11} lg={2.9} xl={2.9} sx={{}}>
              <Box sx={{}}>
                <img
                  src={home17}
                  alt=""
                  style={{ maxHeight: "8.3rem", width: "auto" }}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#090914",
                }}
              >
                Testimonials from
                <br />
                Satisfied Study Medic Users
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#252525",
                  mt: "1.25rem",
                }}
              >
                Study Medic has been a game-changer for me. The e-portfolio
                platform is incredibly intuitive and allowed me to showcase my
                achievements in a visually impressive way.
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={8} xl={8} sx={{}}>
              <Carousels3 />
            </Grid>
          </Grid>
        </Box>
      </>
    </div >
  );
}

export default Home;
