import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from "react-icons/md";
import { ticketService } from "../../service/index";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateTicket = ({ handleClose }) => {
  const [value, setValue] = useState(0);
  const [stepOneData, setStepOneData] = useState({
    post: "",
    form: "",
    relatedUser: "",
    assessorEmail: "",
  });
  const [stepTwoData, setStepTwoData] = useState({
    assessorName: "",
    assessorPosition: "",
    assessorReg: "",
    assessorLocation: "",
    assessorComment: "",
  });

  const postOptions = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  const relatedUserOptions = [
    { value: "User 1", label: "User 1" },
    { value: "User 2", label: "User 2" },
    { value: "User 3", label: "User 3" },
  ];

  const handleChangeStepOne = (event) => {
    const { name, value } = event.target;
    setStepOneData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeStepTwo = (event) => {
    const { name, value } = event.target;
    setStepTwoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const combinedData = { ...stepOneData, ...stepTwoData };
    try {
      const response = await ticketService.addTicket(combinedData);
      console.log("Add ticket response:", response);
      if (response.status) {
        // Handle success scenario, e.g., show a success message or close the modal
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error adding ticket:", error);
    }
  };

  const FormStepOne = () => {
    const fieldStyle = {
      backgroundColor: "#f5f5f5",
      mb: 1,
      borderRadius: "4px",
      "& .MuiOutlinedInput-root": {
        height: 34,
        "& input": {
          textAlign: "left",
          padding: 0,
          fontSize: "12px",
          marginLeft: "10px",
        },
        "& fieldset": {
          border: "none",
        },
        "& .MuiSelect-select": {
          textAlign: "left",
          padding: 0,
          fontSize: "12px",
          marginLeft: "10px",
        },
      },
      "& .MuiInputLabel-root": {
        fontSize: "12px",
        fontWeight: "400",
        top: "-8px",
      },
    };

    const labelStyle = {
      fontSize: "12px",
      fontWeight: "500",
    };

    return (
      <Box component="form">
        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Post
        </Typography>
        <FormControl fullWidth sx={fieldStyle}>
          <InputLabel
            id="post-select-label"
            shrink={Boolean(stepOneData?.post)} // Shrinks label when value is selected
            sx={{ display: stepOneData?.post ? 'none' : 'block' }} // Hides label when value is selected
          >
            Select Post
          </InputLabel>
          <Select
            labelId="post-select-label"
            name="post"
            value={stepOneData.post}
            onChange={handleChangeStepOne}
            required
            IconComponent={MdKeyboardArrowDown}
            label="Select Post" // Set label to properly link it to InputLabel
          >
            {postOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Forms
        </Typography>
        <TextField
          label={stepOneData?.form ? "" : "Type form name here..."}
          name="form"
          value={stepOneData.form}
          onChange={handleChangeStepOne}
          fullWidth
          required
          sx={fieldStyle}
        />

        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Select Related User /
          Previously Ticketed Assessor
        </Typography>
        <FormControl fullWidth sx={fieldStyle}>
          <InputLabel
            id="related-user-select-label"
            shrink={Boolean(stepOneData?.relatedUser)} // Shrinks label when value is selected
            sx={{ display: stepOneData?.relatedUser ? 'none' : 'block' }} // Hides label when value is selected
          >
            Select Post
          </InputLabel>
          <Select
            labelId="related-user-select-label"
            name="relatedUser"
            value={stepOneData.relatedUser}
            onChange={handleChangeStepOne}
            IconComponent={() => (
              <MdKeyboardArrowDown style={{ fontSize: "24px" }} />
            )}
          >
            {relatedUserOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="subtitle1" sx={labelStyle}>
          (Or)
        </Typography>
        <Typography variant="subtitle1" sx={labelStyle}>
          Provide Assessor Email Address
        </Typography>
        <TextField
          label={stepOneData?.assessorEmail ? "" : "Enter assessor email address"}
          name="assessorEmail"
          value={stepOneData.assessorEmail}
          onChange={handleChangeStepOne}
          fullWidth
          sx={fieldStyle}
        />
      </Box>
    );
  };

  const FormStepTwo = () => {
    const fieldStyle = {
      backgroundColor: "#f5f5f5",
      mb: 1,
      borderRadius: "4px",
      "& .MuiOutlinedInput-root": {
        height: 34,
        "& input": {
          textAlign: "left",
          padding: 0,
          marginLeft: "10px",
          fontSize: "12px",
        },
        "& fieldset": {
          border: "none",
        },
      },
      "& .MuiInputLabel-root": {
        fontSize: "12px",
        fontWeight: "400",
        top: "-8px",
      },
    };

    const labelStyle = {
      fontSize: "12px",
      fontWeight: "500",
    };

    return (
      <Box component="form">
        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Assessor Name
        </Typography>
        <TextField
          label="Enter assessor name"
          name="assessorName"
          value={stepTwoData.assessorName}
          onChange={handleChangeStepTwo}
          fullWidth
          required
          sx={fieldStyle}
        />

        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Assessor Position (e.g.
          Consultant, Trainee)
        </Typography>
        <TextField
          label="Enter assessor position"
          name="assessorPosition"
          value={stepTwoData.assessorPosition}
          onChange={handleChangeStepTwo}
          fullWidth
          required
          sx={fieldStyle}
        />

        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Assessor Register Number (e.g.
          GMC, NMC, GDC)
        </Typography>
        <TextField
          label="Enter assessor reg number"
          name="assessorReg"
          value={stepTwoData.assessorReg}
          onChange={handleChangeStepTwo}
          fullWidth
          required
          sx={fieldStyle}
        />

        <Typography variant="subtitle1" sx={labelStyle}>
          <span style={{ color: "red" }}>*</span> Assessor Location
        </Typography>
        <TextField
          label="Enter assessor location"
          name="assessorLocation"
          value={stepTwoData.assessorLocation}
          onChange={handleChangeStepTwo}
          fullWidth
          required
          sx={fieldStyle}
        />

        <Typography variant="subtitle1" sx={labelStyle}>
          Comment For Assessor
        </Typography>
        <TextField
          name="assessorComment"
          value={stepTwoData.assessorComment}
          onChange={handleChangeStepTwo}
          fullWidth
          multiline
          rows={2} // Adjust the number of rows as needed
          sx={{
            backgroundColor: "#f5f5f5",
            mb: 1,
            borderRadius: "4px",
            "& .MuiOutlinedInput-root": {
              "& textarea": {
                textAlign: "left",
                fontSize: "12px",
              },
              "& fieldset": {
                border: "none",
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "12px",
              fontWeight: "400",
            },
          }}
        />

        <Box sx={{ display: "flex", gap: "10px", mt: 2 }}>
          <Button
            disableElevation
            variant="contained"
            sx={{
              borderRadius: "22px",
              textTransform: "none",
              backgroundColor: "#EFEFEF",
              color: "#252525",
              "&:hover": {
                backgroundColor: "#EFEFEF",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            onClick={() => setValue(0)}
            sx={{
              borderRadius: "22px",
              textTransform: "none",
              backgroundColor: "#E5E4FF",
              color: "#2C2B83",
              "&:hover": {
                backgroundColor: "#E5E4FF",
                color: "#2C2B83",
              },
            }}
          >
            <MdKeyboardArrowLeft
              style={{
                color: "#2C2B83",
                fontSize: "20px",
              }}
            />
            Previous
          </Button>
          <Button
            disableElevation
            variant="contained"
            sx={{
              borderRadius: "22px",
              textTransform: "none",
              backgroundColor: "#2C2B83",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#2C2B83",
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        width: 510,
        borderRadius: "1.5rem",
        padding: "1.5rem",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#252525",
            marginBottom: "1rem",
          }}
        >
          Create Tickets
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              sx={{
                width: "100%",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#EB568F",
                  height: "5px",
                  borderRadius: "0.5rem",
                  opacity: 1,
                },
              }}
            >
              <Tab
                label="Step 1"
                {...a11yProps(0)}
                sx={{
                  width: "50%",
                }}
              />
              <Tab
                label="Step 2"
                {...a11yProps(1)}
                sx={{
                  width: "50%",
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <FormStepOne />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FormStepTwo />
          </CustomTabPanel>
        </Box>
        {value === 0 && (
          <Box sx={{ display: "flex", gap: "10px", mt: 5.1 }}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                borderRadius: "22px",
                textTransform: "none",
                backgroundColor: "#EFEFEF",
                color: "#252525",
                "&:hover": {
                  backgroundColor: "#EFEFEF",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={() => setValue(1)}
              sx={{
                textTransform: "none",
                borderRadius: "22px",
                backgroundColor: "#2C2B83",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              Next
              <MdKeyboardArrowRight
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                }}
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CreateTicket;
