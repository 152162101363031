import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
import "./clinical-program-list.css";
import {
    Modal,
    Box,
    Typography,
    Grid,
    TextField,
    Autocomplete,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { programService, subService } from "../../service/index";
import king from "../../images/king.svg";
import programDefault from "../../images/programDefault.png";
import searchIcon from "../../images/search-icon.svg";
import Program from "../program-component/program-component";
import { getAuth } from "../../utils/storage-utils";
import { LuRefreshCcw } from "react-icons/lu";

const ProgramList = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [programData, setProgramData] = useState(null);
    const [courses, setCourses] = useState(null);
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [session, setSession] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");

    const [filteredPrograms, setFilteredPrograms] = useState([]);

    const handleState = (event) => {
        setSelectedState(event.target.value);
    };

    const handleCountry = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleCourseChange = (event, newValue) => {
        setSelectedCourse(newValue);
    };

    const handleProgramClick = (id) => {
        setSelectedProgram(id);
    };

    const handleClose = () => {
        setSelectedProgram(null);
    };

    const handleRefreshClick = () => {
        setSelectedCourse("");
        setSelectedCountry("");
        setSelectedState("");
    };

    const [programIds, setProgramIds] = useState('');
    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const sessionData = getAuth();
                setSession(sessionData);

                const response = await programService.getPrograms();
                if (!response || !response.data) {
                    throw new Error("Could not load resources");
                }

                setProgramData(response.data);
                const stateValues = response.data.map((item) => item.state);
                const countryValues = response.data.map((item) => item.country);
                const coursesValues = response.data.map((item) => item.title);

                setState(stateValues);
                setCountry(countryValues);
                setCourses(coursesValues);

                if (sessionData) {
                    const res = await subService.getBookedProgramIds();
                    if (!res || !res.data) {
                        throw new Error("Could not fetch booked program IDs");
                    }
                    setProgramIds(res.data);
                }
            } catch (error) {
                console.error("Error fetching program data:", error);
                enqueueSnackbar("An error occurred while fetching data", { variant: "error" });
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [enqueueSnackbar]);

    const filterPrograms = useCallback(() => {
        let filtered = programData;
        console.log("Program\t\t\t\t", programData);
        if (selectedCourse) {
            filtered = filtered.filter((program) => program.title === selectedCourse);
        }

        if (selectedCountry) {
            filtered = filtered.filter((program) => program.country === selectedCountry);
        }

        if (selectedState) {
            filtered = filtered.filter((program) => program.state === selectedState);
        }

        // Check if no filters are applied, then include all programs.
        if (!selectedCourse && !selectedCountry && !selectedState) {
            filtered = programData;
        }
        console.log("FILTERESD\t\t\t", filtered);
        setFilteredPrograms(filtered);
    }, [selectedCourse, selectedCountry, selectedState, programData]);

    useEffect(() => {
        filterPrograms();
    }, [filterPrograms]);

    const checkJoined = (program) => {
        // console.log("CheckJoined\t\t\t", program, programIds);
        if (programIds.length !== '') {
            const check = programIds.includes(program._id);
            // console.log("Check\t",check)
            return check
        } else {
            return false
        }
    }



    if (isLoading) {
        return <FileLoader />;
    }

    return (
        <Grid container sx={{ pt: "1.5rem", pb: "1.5rem" }}>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <img src={king} alt="" />
                <Typography
                    sx={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "22px",
                        color: "#000020",
                        ml: "1rem",
                    }}
                >
                    Clinical Programs
                </Typography>
            </Grid>

            {programData && programData.length > 0 && (
                <Grid
                    item
                    container
                    sx={{
                        width: "100%",
                        display: "flex",
                        mt: "1rem",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        // border : '1px solid red'
                    }}
                >
                    <Grid item xs={12} sm={6} md={4.5} sx={{ mt: { xs: '1rem', sm: '1rem', md: '0' } }}>
                        <Autocomplete
                            sx={{ width: "100%", backgroundColor: "#fff", position: "relative" }}
                            freeSolo
                            id="courses"
                            disableClearable
                            options={courses?.map((option) => option)}
                            value={selectedCourse}
                            onChange={handleCourseChange}
                            renderInput={(params) => (
                                <>
                                    <TextField
                                        {...params}
                                        placeholder="Search Course"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                            style: {
                                                paddingLeft: '12%',
                                                '@media (max-width: 400px)': {
                                                    paddingLeft: '30%',
                                                },
                                            }
                                        }}
                                    />
                                    <img
                                        src={searchIcon}
                                        alt=""
                                        style={{
                                            position: "absolute",
                                            zIndex: "999",
                                            left: "1rem",
                                            top: "1rem",
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ mt: { xs: '1rem', sm: '1rem', md: '0' } }}>
                        <FormControl sx={{ width: "100%", backgroundColor: "#fff" }}>
                            <InputLabel id="country-select-label">Country</InputLabel>
                            <Select
                                labelId="country-select-label"
                                id="country-select"
                                value={selectedCountry}
                                label="Country"
                                onChange={handleCountry}
                            >
                                {country?.map((countryOption, index) => (
                                    <MenuItem key={index} value={countryOption}>
                                        {countryOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{ mt: { xs: '1rem', sm: '1rem', md: '0' } }}>
                        <FormControl sx={{ width: "100%", backgroundColor: "#fff" }}>
                            <InputLabel id="state-select-label">State</InputLabel>
                            <Select
                                labelId="state-select-label"
                                id="state-select"
                                value={selectedState}
                                label="State"
                                onChange={handleState}
                            >
                                {state?.map((stateOption, index) => (
                                    <MenuItem key={index} value={stateOption}>
                                        {stateOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} sx={{ mt: { xs: '1.5rem', sm: '1.5rem', md: '0' } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={() => { handleRefreshClick() }}
                        >
                            <LuRefreshCcw size={30} style={{
                                cursor: 'pointer',
                            }} />
                        </Box>
                    </Grid>
                </Grid>

            )}
            {filteredPrograms && (
                <Grid
                    container
                    sx={{ justifyContent: { xl: "flex-start", md: "space-between", sm: 'space-between' } }}
                >
                    {filteredPrograms.map((program) => (
                        <Grid
                            key={program.id}
                            item
                            xs={12}
                            sm={5.5}
                            md={3.5}
                            xl={2.5}
                            sx={{
                                width: "100%",
                                padding: "1rem",
                                backgroundColor: "#fff",
                                mt: "2.5rem",
                                borderRadius: "1rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "20rem",
                                overflow: "hidden",
                                marginRight: { xl: "1.5rem", md: 0 },
                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: "100%",
                                    borderRadius: "1rem",
                                    height: "50%",
                                    background: program.imageUrl
                                        ? `url(${program.imageUrl})`
                                        : `url(${programDefault})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                }}
                            ></Box>
                            <Box sx={{ mt: "1rem", }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontSize: "15.24px",
                                        fontWeight: "600",
                                        color: "#212128",
                                    }}
                                >
                                    {program.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontSize: "15.24px",
                                        fontWeight: "normal",
                                        color: "#777777",
                                        maxHeight: "4rem",
                                        overflowY: "scroll",
                                        "&::-webkit-scrollbar": {
                                            width: "0.4rem", // Adjust the scrollbar width as needed
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "transparent", // Make the scrollbar thumb transparent
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            backgroundColor: "transparent", // Make the scrollbar thumb transparent
                                        },
                                    }}
                                >
                                    {program.about}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: "1rem",
                                }}
                            >
                                <Button
                                    sx={{
                                        backgroundColor: "#EBEBEB",
                                        fontFamily: "Poppins",
                                        fontSize: "11px",
                                        fontWeight: "600",
                                        color: "#000",
                                        width: "48%",
                                        borderRadius: "2rem",
                                        height: "3rem",
                                        "&:hover": {
                                            backgroundColor: "#2C2B83",
                                            color: "#fff",
                                        },
                                    }}
                                    onClick={() => {
                                        handleProgramClick(program._id);
                                    }}
                                >
                                    View Details
                                </Button>
                                {checkJoined(program) ? (
                                    <Button
                                        sx={{
                                            backgroundColor: "green",
                                            fontFamily: "Poppins",
                                            fontSize: "11px",
                                            fontWeight: "600",
                                            color: "#fff",
                                            width: "48%",
                                            borderRadius: "2rem",
                                            height: "3rem",
                                            "&:hover": {
                                                backgroundColor: "#2C2B83",
                                            },
                                        }}
                                    >
                                        Joined
                                    </Button>
                                ) : (
                                    <Button
                                        sx={{
                                            backgroundColor: "#EB568F",
                                            fontFamily: "Poppins",
                                            fontSize: "11px",
                                            fontWeight: "600",
                                            color: "#fff",
                                            width: "48%",
                                            borderRadius: "2rem",
                                            height: "3rem",
                                            "&:hover": {
                                                backgroundColor: "#2C2B83",
                                            },
                                        }}
                                        onClick={() => {
                                            if (!session) {
                                                navigate('/sign-in/');
                                                return
                                            }
                                            handleProgramClick(program._id);
                                        }}
                                    >
                                        Join Now
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}

            <Modal
                open={selectedProgram}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
            >
                <Box>
                    <Program programID={selectedProgram} onClose={handleClose} />
                </Box>
            </Modal>
        </Grid>
    );
};

export default ProgramList;
