import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "./Viewer.css";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import FileLoader from "../../components/FileLoader";
// import { useSnackbar } from "notistack";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const PDFViewer = ({ url, onClose }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [resolvedUrl, setResolvedUrl] = useState(null);
  // const { enqueueSnackbar: snackbar } = useSnackbar();

  useEffect(() => {
    const resolveUrl = async () => {
      try {
        const resolved = await url;
        setResolvedUrl(resolved);
      } catch (error) {
        console.error("Error resolving URL:", error);
      }
    };

    resolveUrl();
  }, [url]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClose = () => {
    onClose();
  };

  if (!resolvedUrl) {
    // Show loading indicator or return null when the URL is not resolved yet
    return <FileLoader />;
  }

  return (
    <div className="pdf-wrapper">
      <Document
        className={"reader"}
        file={resolvedUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div>
            <FileLoader />
          </div>
        }
        renderMode="canvas"
      >
        <Page pageNumber={pageNumber} width={1500} />
      </Document>

      <div className="page-controls">
        <div className="pdf-navigation">
          <button
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            ‹
          </button>
          <span>
            {pageNumber} of {numPages}
          </span>
          <button
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            ›
          </button>
        </div>
      </div>
      <button className="closeButton-pdf" onClick={handleClose}>
        X
      </button>
    </div>
  );
};

export default PDFViewer;
