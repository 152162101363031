import React, { useCallback, useEffect, useState } from "react";
import { userService } from "../../service/index";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
import { useParams } from "react-router-dom";
import "./edit-profile.css";
import { Button } from "@mui/material";
import logo from "../../images/sm_logo.png";
import doctor from "../../images/editProfile-img.png";
// import big from "../../images/bigprofile.jpg";
// import upload from "../../images/uploadpic-ico.svg";
// import DropZone from "../../components/common/drop-zone/drop-Zone";
import {
  // Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  Divider,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";
import ProfileUpload from "../../components/profile-upload/profile-upload";

const EditProfile = () => {
  const { userID } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  const navigate = useNavigate();


  const getUserData = useCallback(async (userId) => {
    setIsLoading(true);
    try {
      const res = await userService.getSingle(userId);
      if (!res.status || !res.data) {
        setIsLoading(false);
        snackbar("Could not load resources", { variant: "error" });
        return;
      }
      setUser(res.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user data:", error);
      snackbar("An error occurred while fetching user data", {
        variant: "error",
      });
    }
  }, [snackbar]);

  useEffect(() => {
    async function fetchData() {
      await getUserData(userID);
    }
    fetchData();
  }, [userID, getUserData]);

  const initialFormState = {
    name: "",
    hospitalName: "",
    gmcRefNumber: "",
    collegeNumber: "",
    areaOfInterest: "",
    linkedinUrl: "",
    // country: "",
    // countryCode: "",
    // state: "",
    // city: "",
    // status: "", // You may set an appropriate default value here.
    // addresslineOne: "",
    address: {
      addresslineOne: "",
      state: "",
      city: "",
      country: "",
      countryCode: "",
    },
    status: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  const initializeForm = useCallback(() => {
    console.log("Intial\t\t\t", user);
    setFormData({
      name: user?.name || "",
      mobile: user?.mobile || "",
      hospitalName: user?.hospitalName || "",
      gmcRefNumber: user?.gmcRefNumber || "",
      collegeNumber: user?.collegeNumber || "",
      areaOfInterest: user?.areaOfInterest || "",
      linkedinUrl: user?.linkedinUrl || "",
      jrcptbEnrolled: user?.jrcptbEnrolled || "",
      address: {
        country: user?.address?.country || "",
        countryCode: user?.address?.countryCode || "",
        state: user?.address?.state || "",
        city: user?.address?.city || "",
        status: user?.status || "", // Set an appropriate default value here.
        addresslineOne: user?.address?.addresslineOne || "",
        postalCode: user?.address?.postalCode || "",
      },
      status: user?.status || "",
    });
    console.log("InitialFormData\t\t\t", formData);
  }, [formData, user]);

  useEffect(() => {
    console.log("User_Data\t\t\t", user);
    initializeForm();
    setIsLoading(false);
  }, [user, initializeForm]);





  if (isLoading) {
    return <FileLoader />;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const addressFields = ["addresslineOne", "country", "city", "state", "postalCode"];
    if (addressFields.includes(name)) {
      const nestedFieldName = name.replace(`formData.${name}`, "");
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [nestedFieldName]: value,
        },
      }));
    } else {
      console.log("FormAOI\t\t", name, value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // let { consulant, ug_student, pg_student, dean, professor, other } = false;

  const handleCheckboxChange = (event) => {
    console.log("EVENT\t\t\t", event);
    const { name } = event.target;
    const status = "status";
    setFormData((prevData) => ({
      ...prevData,
      [status]: name,
    }));
    console.log("FormData\t\t\t", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("SubmitttedData\t\t\t", formData);

    await userService.updateUser(formData, userID).then((res) => {
      console.log("Update\t\t\t", res);
      if (!res.status) {
        console.log("HERERERERE");
        setIsLoading(false);
        snackbar(res.message, { variant: "error" });
        return;
      }
      snackbar("Update was successfull", { variant: "success" });
      setIsLoading(false);
      navigate(`/user/profile/${userID}`);
    });
  };

  return (
    <>
      <div className="container">
        <div className="editProfile">
          <Grid
            container
            className="editProfile-head dflex"
            sx={{
              justifyContent: "space-between",
            }}
            sm={11}
            xs={12}
          >
            <Grid item className="head-text" xs={12} sm={6}>
              <img src={logo} alt="" style={{ width: "10rem" }} />
              <Typography
                sx={{
                  fontStyle: "Poppins",
                  fontSize: "24px",
                  color: "#000020",
                  fontWeight: "600",
                  // position: "absolute",
                  // top: "4rem",
                }}
              >
                Profile Setup
              </Typography>
              <div className="editprofile-desc">
                <Typography
                  sx={{
                    fontStyle: "Poppins",
                    fontSize: "12px",
                    color: "#252525",
                    fontWeight: "normal",
                  }}
                >
                  Create a complete and personalized user profile by providing
                  essential information with <br />
                  our Profile Setup section. Fill out mandatory details to get
                  started on our platform today!
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                className="edit-doctor"
                src={doctor}
                alt=""
                style={{ height: "12rem", width: "auto" }}
              />
            </Grid>
          </Grid>

          <div className="editForm">
            <form className="editprofile-form">

              <Grid container sx={{
                // border : '2px solid red'
              }}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{
                  // border: '1px solid red'

                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#252525'
                  }}>
                    Profile Photo
                  </Typography>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#000020'
                  }}>
                    This will be displayed on your profile.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} sx={{
                  // border: '1px solid blue',
                  // height : '15vh',
                  overflow: 'hidden',
                  mb: '1.5rem'
                }}>
                  <ProfileUpload isModal={false} />

                </Grid>

              </Grid>




              <Grid
                item
                sx={{
                  mb: "4%",
                }}
              >
                <Divider
                  variant="middle"
                  style={{
                    backgroundColor: "#D9D9D9",
                    height: "1px",
                    margin: "0",
                  }}
                />
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-sec-2"
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={12} sm={5.5} sx={{}}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Name{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.name ? (
                    <TextField
                      label=""
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Hospital Name{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.hospitalName ? (
                    <TextField
                      label=""
                      name="hospitalName"
                      value={formData.hospitalName}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your hospital name"
                      name="hospitalName"
                      value={formData.hospitalName}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    GMC Reference Number{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.hospitalName ? (
                    <TextField
                      label=""
                      name="gmcRefNumber"
                      value={formData.gmcRefNumber}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your GMC Reference Number"
                      name="gmcRefNumber"
                      value={formData.gmcRefNumber}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    College Number{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.collegeNumber ? (
                    <TextField
                      label=""
                      name="collegeNumber"
                      value={formData.collegeNumber}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your College Number"
                      name="collegeNumber"
                      value={formData.collegeNumber}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5} sx={{}}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    JRCPTB Enrolled{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.jrcptbEnrolled !== "" ? (
                    <Select
                      fullWidth
                      // labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="jrcptbEnrolled"
                      value={formData.jrcptbEnrolled}
                      // label=""
                      onChange={handleChange}
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      fullWidth
                      // labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="jrcptbEnrolled"
                      value={formData.jrcptbEnrolled}
                      // label="Area of interests"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    mb: "2%",
                    mt: "2%",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                >
                  <Divider
                    variant="middle"
                    style={{
                      backgroundColor: "#D9D9D9",
                      height: "1px",
                      margin: "0",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5.5} sx={{}}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Area of Interests{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.areaOfInterest ? (
                    <Select
                      fullWidth
                      // labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="areaOfInterest"
                      value={formData.areaOfInterest}
                      // label=""
                      onChange={handleChange}
                    >
                      <MenuItem value={"Kochi"}>Kochi</MenuItem>
                      <MenuItem value={"KL"}>KL</MenuItem>
                      <MenuItem value={"KY"}>KY</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      fullWidth
                      // labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="areaOfInterest"
                      value={formData.areaOfInterest}
                      // label="Area of interests"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Kochi"}>Kochi</MenuItem>
                      <MenuItem value={"KL"}>KL</MenuItem>
                      <MenuItem value={"KY"}>KY</MenuItem>
                    </Select>
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    LinkedIn URL{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.linkedinUrl ? (
                    <TextField
                      label=""
                      name="linkedinUrl"
                      value={formData.linkedinUrl}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your linkedIn url"
                      name="linkedinUrl"
                      value={formData.linkedinUrl}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    mb: "2%",
                    mt: "2%",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                >
                  <Divider
                    variant="middle"
                    style={{
                      backgroundColor: "#D9D9D9",
                      height: "1px",
                      margin: "0",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Address{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.addresslineOne ? (
                    <TextField
                      label=""
                      name="addresslineOne"
                      value={formData.address.addresslineOne}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your address detail"
                      name="addresslineOne"
                      value={formData.address.addresslineOne}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Country{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.country ? (
                    <TextField
                      label=""
                      name="country"
                      value={formData.address.country}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your Country"
                      name="country"
                      value={formData.address.country}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    State/Province{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.state ? (
                    <TextField
                      label=""
                      name="state"
                      value={formData.address.state}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your State/Province"
                      name="state"
                      value={formData.address.state}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    City{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.city ? (
                    <TextField
                      label=""
                      name="city"
                      value={formData.address.city}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      label="Enter your City"
                      name="city"
                      value={formData.address.city}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Postal Code{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.postalCode ? (
                    <TextField
                      label=""
                      name="postalCode"
                      value={formData.address.postalCode}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      // label="Enter your Postal Code"
                      name="postalCode"
                      value={formData.address.postalCode}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5.5} sx={{}}>
                  <Typography
                    sx={{
                      display: "flex",
                      fontSize: "14px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      height: "2rem",
                    }}
                  >
                    Mobile Number{" "}
                    <h5
                      style={{
                        color: "red",
                        transform: "translateY(-1.5rem)",
                      }}
                    >
                      *
                    </h5>
                  </Typography>
                  {formData.mobile ? (
                    <TextField
                      label=""
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    <TextField
                      // label="Enter your name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    mb: "2%",
                    mt: "2%",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                >
                  <Divider
                    variant="middle"
                    style={{
                      backgroundColor: "#D9D9D9",
                      height: "1px",
                      margin: "0",
                    }}
                  />
                </Grid>

                <Grid item sm={9} xs={12}>
                  <Typography
                    sx={{
                      fontStyle: "Poppins",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000020",
                    }}
                  >
                    Select Status
                  </Typography>
                  <Grid container className="check-container">
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={2}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={formData?.status === "Consultant"}
                        name="Consultant"
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#EB568F",
                          "&.Mui-checked": {
                            color: "#EB568F",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "0.8rem",
                          fontSize: "14px",
                          fontStyel: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Consulant
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={2}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={formData?.status === "UG Student"}
                        name="UG Student"
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#EB568F",
                          "&.Mui-checked": {
                            color: "#EB568F",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "0.8rem",
                          fontSize: "14px",
                          fontStyel: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        UG Student
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={2}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={formData?.status === "PG Student"}
                        name="PG Student"
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#EB568F",
                          "&.Mui-checked": {
                            color: "#EB568F",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "0.8rem",
                          fontSize: "14px",
                          fontStyel: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        PG Student
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={2}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={formData?.status === "Professor"}
                        name="Professor"
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#EB568F",
                          "&.Mui-checked": {
                            color: "#EB568F",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "0.8rem",
                          fontSize: "14px",
                          fontStyel: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Professor
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={2}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={formData?.status === "Dean"}
                        name="Dean"
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#EB568F",
                          "&.Mui-checked": {
                            color: "#EB568F",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "0.8rem",
                          fontSize: "14px",
                          fontStyel: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Dean
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={2}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Checkbox
                        checked={formData?.status === "Other"}
                        name="Other"
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#EB568F",
                          "&.Mui-checked": {
                            color: "#EB568F",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          mt: "0.8rem",
                          fontSize: "14px",
                          fontStyel: "Poppins",
                          fontWeight: "500",
                        }}
                      >
                        Other
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                xs={100}
                sm={12}
                md={12}
                sx={{
                  mt: "5%",
                  justifyContent: 'flex-start',
                  alignItems: 'center',

                }}
              >
                <Grid
                  item
                  sx={{
                    width: "40%",
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#EBEBEB",
                      fontFamily: "Poppins",
                      fontSize: "14.3px",
                      fontWeight: "600",
                      color: "#000",
                      mr: '1.5rem',
                      width: "100%",
                      borderRadius: "2rem",
                      height: "3rem",
                      "&:hover": {
                        backgroundColor: "#2C2B83",
                        color: '#fff'
                      },
                    }}
                    onClick={() => { navigate(`/user/profile/${userID}`) }}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#EB568F",
                      fontFamily: "Poppins",
                      fontSize: "14.3px",
                      fontWeight: "600",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "2rem",
                      height: "3rem",
                      "&:hover": {
                        backgroundColor: "#2C2B83",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
