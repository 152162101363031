import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import { Info, Link as LinkIcon } from "@mui/icons-material";
import { FaCirclePlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const SupervisorReportCard = ({
  status,
  title,
  submissions,
  supervisor,
  isSummary,
  showInfo,
}) => (
  <Card
    variant="outlined"
    sx={{
      bgcolor: "#EEEEFF",
      height: "226px", // Ensures all cards have equal height
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "none",
      borderBottomLeftRadius: "13px",
      borderBottomRightRadius: "13px",
    }}
  >
    <CardContent>
      {status === "Draft" && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            disableElevation
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              p: "6px",
              borderRadius: "5px",
              bgcolor: "white",
              color: "black",
            }}
          >
            Draft
          </Button>
          <IconButton size="small" sx={{ color: "#2C2B83" }}>
            <Info />
          </IconButton>
          {showInfo && (
            <IconButton size="small" sx={{ color: "#2C2B83" }}>
              <Info />
            </IconButton>
          )}
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            sx={{ color: "#525252", fontWeight: "500", fontSize: "11px" }}
          >
            Supervisor's Report
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: "14px" }} gutterBottom>
            {title}
          </Typography>
        </Box>
        {showInfo && (
          <IconButton size="small" sx={{ color: "#2C2B83" }}>
            <Info />
          </IconButton>
        )}
      </Box>
      <Box>
        <Typography
          sx={{ color: "#525252", fontWeight: "500", fontSize: "11px" }}
        >
          View Submissions
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
          {submissions}
        </Typography>
      </Box>
      {isSummary && (
        <Box display="flex" justifyContent="flex-start" mt={8}>
          <Button
            disableElevation
            variant="contained"
            sx={{
              color: "white",
              bgcolor: "#2C2B83",
              textTransform: "none",
              borderRadius: "30px",
              fontSize: "12px",
              px: "16px",
              py: "6px",
              mr: 1,
            }}
          >
            Summary
          </Button>
          <IconButton size="small" sx={{ color: "#2C2B83" }}>
            <LinkIcon />
          </IconButton>
        </Box>
      )}
    </CardContent>
  </Card>
);

const cardData = [
  {
    title: "Guidance notes for Educational Supervisors Report (IMT)",
  },
  {
    title: "Guidance notes for Multiple Consultant Report (IMT)",
  },
];

const SuperVisorsReport = () => {
  const [activeTab, setActiveTab] = useState("Add Supervisor's Report");
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <Box>
      <Box>
        <Typography fontSize={"16px"} fontWeight={"500"} gutterBottom>
          Supervisor's Report
        </Typography>
        <Typography gutterBottom fontSize={"14px"} fontWeight={"400"} mb={2}>
          This report is key to informing the Training Programme Director (or
          deputy) about the trainee's progress. The form should be completed by
          the trainee's Educational Supervisor, after a thorough ePortfolio
          review. Where the Educational Supervisor is not the current clinical
          supervisor, it is mandatory that clinical supervisors' reports
          (including the mid and/or end of attachment appraisals) and relevant
          information from other health professionals about the trainee's
          performance is reviewed prior to completion of this report. The report
          needs to be honest, constructive, accurate and defensible.
        </Typography>
        <Button
          disableElevation
          variant="contained"
          sx={{
            mb: 2,
            backgroundColor:
              activeTab === "Add Supervisor's Report" ? "#E5E4FF" : "white",
            color:
              activeTab === "Add Supervisor's Report" ? "#2C2B83" : "#525252",
            borderRadius: "1rem",
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            transition: "scale 0.1s ease-in",
            border:
              activeTab === "Add Supervisor's Report"
                ? "1px solid #2C2B83"
                : "none",
            "&:hover": {
              scale: "0.9",
              backgroundColor: "#E5E4FF",
              color: "#2C2B83",
            },
          }}
          startIcon={<FaCirclePlus />}
          onClick={() => setActiveTab("Add Supervisor's Report")}
        >
          Add Supervisor's Report
        </Button>
        <Button
          disableElevation
          variant="outlined"
          sx={{
            mb: 2,
            ml: 2,
            backgroundColor:
              activeTab === "Request External Assessment" ? "#E5E4FF" : "white",
            color:
              activeTab === "Request External Assessment"
                ? "#2C2B83"
                : "#525252",
            borderRadius: "1rem",
            textTransform: "none",
            fontSize: "14px",
            fontFamily: "Poppins",
            transition: "scale 0.1s ease-in",
            border:
              activeTab === "Request External Assessment"
                ? "1px solid #2C2B83"
                : "none",
            "&:hover": {
              scale: "0.9",
              backgroundColor: "#E5E4FF",
              color: "#2C2B83",
            },
          }}
          onClick={() => navigate("/user/progresslog/external-assessment/:id")}
        >
          Request External Assessment
        </Button>
      </Box>
      <Grid container spacing={3}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <Card
              variant="outlined"
              sx={{ border: "none", borderRadius: "20px" }}
            >
              <CardContent>
                <Button
                  sx={{
                    bgcolor: "#FFEBF3",
                    p: "6px",
                    borderRadius: "5px",
                    textTransform: "none",
                    mb: 2,
                    fontSize: "12px",
                    color: "#EB568F",
                    fontWeight: "500",
                  }}
                >
                  Detailed Instructions
                </Button>
                <Typography
                  sx={{ color: "#525252", fontWeight: "500", fontSize: "11px" }}
                >
                  Title
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                  {card.title}
                </Typography>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    borderRadius: "30px",
                    bgcolor: "#E5E4FF",
                    color: "#2C2B83",
                    textTransform: "none",
                  }}
                >
                  Read
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Paper sx={{ mt: 4, borderRadius: "20px", p: 2 }} elevation={0}>
        <Box
          sx={{
            bgcolor: "#DBEBFF",
            borderTopLeftRadius: "13px",
            borderTopRightRadius: "13px",
            p: "8px",
            pt: "10px",
            mb: 1,
          }}
        >
          <Typography gutterBottom sx={{ fontSize: "14px", fontWeight: "500" }}>
            IMY1-Royal London Hospital-Internal Medicine Training-Internal
            Medicine Training (01 Feb 2019 to 31 Aug 2019)
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SupervisorReportCard
              status="Draft"
              title="Educational Supervisors Report (MT)"
              submissions="19 Jun 2019 Dr Internal Medicine Supervisor"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SupervisorReportCard
              status="Submitted"
              title="Multiple Consultant Report (IMT)"
              submissions="09 Jun 2019 Dr Internal Medicine Supervisor"
              isSummary={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SupervisorReportCard
              status="Submitted"
              title="Multiple Consultant Report (IMT)"
              submissions="10 Jun 2019 Dr Internal Medicine Supervisor"
              showInfo={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SupervisorReportCard
              status="Submitted"
              title="Multiple Consultant Report (IMT)"
              submissions="09 Jun 2019 Dr Internal Medicine Supervisor"
              showInfo={true}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SuperVisorsReport;
