import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  Modal,
  TextField,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

const appraisalItems = [
  { label: "Induction Appraisal" },
  { label: "Mid - Point Review" },
  { label: "Educational Meeting" },
  { label: "End Of Attachment Appraisal" },
  { label: "ARCP" },
];

const AddAppraisal = ({ onBack }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ p: "2.5rem", height: "100vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ fontSize: "16px" }}>
            <span
              style={{
                color: "#525252",
                fontSize: "16px",
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={onBack}
            >
              Appraisal
            </span>
            >Appraisal Form
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            fontSize={"14px"}
            fontWeight={"400"}
          >
            Select the post this appraisal applies to, and then click the
            ‘Create’ link next to the appropriate appraisal form.
          </Typography>
        </Grid>
        {appraisalItems.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            >
              <Grid container alignItems="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#E4E5FF",
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    marginRight: 2,
                  }}
                >
                  <DescriptionIcon sx={{ color: "#8787A3" }} />
                </Box>
                <Typography>{item.label}</Typography>
              </Grid>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: "#2c2B83",
                  color: "white",
                  borderRadius: "30px",
                }}
              >
                Create
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700, // Increased modal width
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "14px", fontWeight: "500" }}
          >
            Induction Appraisal
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "500", color: "#525252" }}
              >
                <span style={{ color: "red" }}>*</span> Meeting Date
              </Typography>
              <TextField
                type="date"
                InputProps={{
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    textAlign: "center",
                    height: "34px",
                  },
                }}
                fullWidth
                sx={{
                  bgcolor: "#EFEFEF",
                  fontSize: "12px",
                  height: "34px",
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "500", color: "#525252" }}
              >
                Out Of Hours Work
              </Typography>
              <TextField
                InputProps={{
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    textAlign: "center",
                    height: "34px",
                    borderRadius: "4px",
                  },
                }}
                fullWidth
                sx={{
                  bgcolor: "#EFEFEF",
                  fontSize: "12px",
                  height: "34px",
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                Time Table For Regular Weekly Fixed Communications
              </Typography>
            </Grid>
            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map(
              (day) => (
                <React.Fragment key={day}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#525252",
                      }}
                    >
                      {day}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          textAlign: "center",
                          height: "34px",
                          borderRadius: "4px",
                        },
                      }}
                      fullWidth
                      sx={{
                        bgcolor: "#EFEFEF",
                        fontSize: "12px",
                        height: "34px",
                        borderRadius: "4px",
                      }}
                      placeholder="AM"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          textAlign: "center",
                          height: "34px",
                          borderRadius: "4px",
                        },
                      }}
                      fullWidth
                      sx={{
                        bgcolor: "#EFEFEF",
                        fontSize: "12px",
                        height: "34px",
                        borderRadius: "4px",
                      }}
                      placeholder="PM"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputProps={{
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          textAlign: "center",
                          height: "34px",
                          borderRadius: "4px",
                        },
                      }}
                      fullWidth
                      sx={{
                        bgcolor: "#EFEFEF",
                        fontSize: "12px",
                        height: "34px",
                        borderRadius: "4px",
                      }}
                      placeholder="Teaching etc."
                    />
                  </Grid>
                </React.Fragment>
              )
            )}
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: "12px", color: "#525252", fontWeight: "500" }}
              >
                Arrangements For Senior Review Of Admission
              </Typography>
              <TextField
                multiline
                rows={4}
                InputProps={{
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  },
                }}
                fullWidth
                sx={{
                  bgcolor: "#EFEFEF",
                  fontSize: "12px",
                  borderRadius: "4px",
                }}
                placeholder="Summary"
              />
            </Grid>
            <Box display="flex" gap={2} mt={2}>
              <Button
                disableElevation
                onClick={handleClose}
                variant="contained"
                sx={{
                  bgcolor: "#EFEFEF",
                  color: "black",
                  borderRadius: "30px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  bgcolor: "#E5E4FF",
                  color: "#2C2B83",
                  borderRadius: "30px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#2C2B83",
                    color: "white",
                  },
                }}
              >
                Save To Draft
              </Button>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  bgcolor: "#2C2B83",
                  borderRadius: "30px",
                  textTransform: "none",
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddAppraisal;
