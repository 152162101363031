import { CardType } from "../enum";
import { BiFile, BiPlayCircle } from "react-icons/bi";
import PDFIcon from "../components/common/icons/pdf-icon/PDFIcon";
import AUDIOIcon from "../components/common/icons/audio-icon/AUDIOIcon";
import DOCIcon from "../components/common/icons/doc-icon/DOCIcon";
import IMAGEIcon from "../components/common/icons/image-icon/IMAGEIcon";
import EXCELIcon from "../components/common/icons/excel-icon/EXCELIcon";
import PPTIcon from "../components/common/icons/ppt-icon/PPTIcon";
import FolderUserIcon from "../components/common/icons/folder-user-icon/FolderUserIcon";
import FolderIcon from "@mui/icons-material/Folder";


export const AudioExtensions = ["mp3", "M4A", "WMA", "AAC", "FLAC", "audio"];
export const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg", "image"];
export const videoExtensions = [
    "mp4",
    "mov",
    "wmv",
    "flv",
    "avi",
    "avchd",
    "webm",
    "mkv",
    "3gp",
    "video",
];

export const docFileExtensions = [
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
];

export const getRecordType = (record) => {
    if (!record.name) {
        return CardType.File;
    }

    const lastindexOfDot = record.name.lastIndexOf(".");
    if (!lastindexOfDot) {
        return CardType.File;
    }

    const extension = record.name.substring(lastindexOfDot + 1);
    if (imageExtensions.includes(extension.toLowerCase())) {
        return CardType.IMG;
    }
    if (AudioExtensions.includes(extension.toLowerCase())) {
        return CardType.AUDIO;
    }
    if (extension.toLowerCase() === "pdf") {
        return CardType.PDF;
    }

    if (videoExtensions.includes(extension.toLowerCase())) {
        return CardType.VIDEO;
    }

    if (
        extension.toLowerCase() === "doc" ||
        extension.toLowerCase() === "docx"
    ) {
        return CardType.DOC;
    }

    if (
        extension.toLowerCase() === "ppt" ||
        extension.toLowerCase() === "pptx"
    ) {
        return CardType.PPT;
    }

    if (
        extension.toLowerCase() === "xls" ||
        extension.toLowerCase() === "xlsx"
    ) {
        return CardType.XLS;
    }

    return CardType.File;
};


export const getIcon = (isFolder = false, isUser = false, data = {}) => {
    if (isFolder) {
        return (
            <FolderIcon
                sx={{
                    fontSize: "36px",
                    color: '#2C2B83'
                }}
            />
        );
    }

    if (isUser) {
        return <FolderUserIcon sx={{ fontSize: 30 }} />;
    }

    const type = getRecordType(data);

    switch (type) {
        case CardType.PDF:
            return <PDFIcon />;
        case CardType.AUDIO:
            return <AUDIOIcon />;
        case CardType.DOC:
            return <DOCIcon />;
        case CardType.IMG:
            return <IMAGEIcon />;
        case CardType.VIDEO:
            return <BiPlayCircle fontSize={36} color={"#F67A74"} />;
        case CardType.XLS:
            return <EXCELIcon />;
        case CardType.PPT:
            return <PPTIcon />;
        default:
            return <BiFile fontSize={30} />;
    }
};


export const getFileExtension = (name) => {
    const filename = name.toLowerCase();
    const lastIndex = filename.lastIndexOf('.');

    if (lastIndex === -1) {
        return '';
    }
    const extension = filename.substring(lastIndex + 1);

    return extension;
};