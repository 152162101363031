// form-service.js
const formService = (httpClient) => {
  return {
    fetchForms: async (type) => {
      try {
        const res = await httpClient.get(`/fetch/form`, { params: { type } });
        console.log(`Fetched ${type} forms response:`, res);
        return res.data;
      } catch (error) {
        console.error(`Error fetching ${type} forms:`, error);
        return { status: false, message: `Failed to fetch ${type} forms` };
      }
    },
    deleteForm: async (formId) => {
      try {
        const res = await httpClient.post("/remove/form", { formId });
        console.log("Delete form response:", res);
        return res.data;
      } catch (error) {
        console.error("Error deleting form:", error);
        return { status: false, message: "Failed to delete form" };
      }
    },
    editForm: async (data) => {
      try {
        const res = await httpClient.post("/edit/form", data);
        console.log("Edit form response:", res);
        return res.data;
      } catch (error) {
        console.error("Error editing form:", error);
        return { status: false, message: "Failed to edit form" };
      }
    },
  };
};

export default formService;
