import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { getAuthStorage } from "../../utils/storage-utils";
import { userService } from "../../service";

function CircularProgressWithLabel(props) {
  return (
    <>
      {props && (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            backgroundColor: "#D8D6D6",
            borderRadius: "50%",
          }}
        >
          <CircularProgress
            style={{ color: "#EB568F" }} // Set the color to #EB568F
            size={120}
            variant="determinate"
            {...props}
            thickness={6}
            sx={{
              "& .MuiCircularProgress-colorSecondary": {
                color: "#D8D6D6",
              },
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
                width: "5.5rem",
                height: "5.5rem",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                sx={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                }}
              >
                {`${Math.round(props.value)}%`}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  // const [progress, setProgress] = React.useState(10);
  const [usage, setUsage] = React.useState(0);
  // const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    fetchUsage();
  }, []);

  const fetchUsage = async () => {
    // setLoading(true);
    try {
      const session = getAuthStorage();
      if (!session.userId) {
        return;
      }
      const res = await userService.getUserUsageService(session.userId);
      console.log("UsageResponse\t\t", res);
      if (res.status && res.data) {
        const default_limit = 20 * 1024 * 1024;
        const usagePercentage = (res.data / default_limit) * 100;
        setUsage(usagePercentage);
      } else {
        setUsage(0);
      }
    } catch (error) {
      console.error("Failed to fetch User Record Usage_2", error);
    } finally {
      // setLoading(false);
    }
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      // setProgress((prevProgress) =>
      //   prevProgress >= 100 ? 0 : prevProgress + 10
      // );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return <CircularProgressWithLabel value={usage} />;
}
