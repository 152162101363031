import React, { useEffect, useState } from "react";
import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { apiConfig } from "../../../config/api-config";
import { recordService } from "../../../service/index";
import { RECORD_PERMISSIONS, RECORD_PERMISSIONS_TYPES } from "../../../utils/constants";
import { useSnackbar } from "notistack";

const PermissionListCard = ({ data, refresh = () => {} }) => {
  const theme = useTheme();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [currentPermission, setCurrentPermission] = useState("");

  const shareRecord = (permission) => {
    const payload = {
      id: data._id,
      email: data.email,
      permission,
    };
    recordService.shareRecord(payload).then((res) => {
      if (!res.status) {
        snackbar(res.message || "Something went wrong", { variant: 'error' });
        return;
      }

      snackbar("Sharing updated", { variant: 'success' });
      refresh();
    });
  };

  useEffect(() => {
    if (data.permission) {
      setCurrentPermission(data.permission);
    }
  }, [data]);

  return (
    <Box pb={1.5} pt={1.5} sx={{
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "solid thin #e5e5e5",
    }}>
      <Box sx={{
        display: "flex",
      }}>
        <Box>
          {data?.image ? (
            <img
              style={{
                height: "55px",
                width: "55px",
                objectFit: "cover",
                borderRadius: "13px",
              }}
              src={`${apiConfig.s3Url}${data.image}`}
              alt="profile"
            />
          ) : (
            <Typography sx={{
              height: "45px",
              width: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: theme.colors?.colorSix.default,
              borderRadius: "10px",
              textTransform: "capitalize",
              fontWeight: "bold",
              fontSize: "18px",
              color: theme.colors?.primary.default,
            }}>
              {data?.name?.charAt(0)}
            </Typography>
          )}
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          marginLeft: "8px",
        }}>
          <Box>
            <Typography sx={{
              fontSize: "16px",
            }}>
              {data.name}
            </Typography>
            <Typography sx={{
              fontSize: "14px",
              color: theme.colors?.lightGreyText.default,
            }}>
              {data.email}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        alignItems: "center",
      }}>
        {data.permission === RECORD_PERMISSIONS_TYPES.OWNER ? (
          <Typography sx={{
            textTransform: "capitalize",
            fontSize: "14px",
          }}>
            {RECORD_PERMISSIONS_TYPES.OWNER}
          </Typography>
        ) : (
          <Select
            labelId="permission-select-label"
            id="permission-select"
            value={currentPermission}
            onChange={(e) => {
              setCurrentPermission(e.target.value);
              shareRecord(e.target.value);
            }}
            sx={{
              width: "70px",
              textTransform: "capitalize",
            }}
            variant="standard"
            disableUnderline
          >
            {RECORD_PERMISSIONS.map((permission, i) => (
              <MenuItem value={permission} key={i} sx={{
                textTransform: "capitalize",
              }}>
                {permission}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    </Box>
  );
};

export default PermissionListCard;
