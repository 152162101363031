import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// import FileLoader from "../../components/FileLoader";
// import SubTopBar from "../../components/template/SubTopBar";
import { libraryService } from "../../service/index";
import LibIcon from "../../images/lib-icon.svg";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Modal, Grid } from "@mui/material";

const Library = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  // const [initialLoadingComplete, setInitialLoadingComplete] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  // const [fileLoading, setfileLoading] = useState(false);
  const [library, setLibrary] = useState(null); // Step 1: Add state variable
  const navigate = useNavigate();
  
  const fetchLibraries = useCallback(() => {
    // setInitialLoadingComplete(false);
    libraryService.getLibraries().then((res) => {
      // setInitialLoadingComplete(true);
      console.log("Libs:\t\t\t\t", res);
      if (!res.status || !res.data) {
        console.log("LibErr\t\t\t", res);
        snackbar("Library fetching failed", { variant: "error" });
        return;
      }
      setLibrary(res.data);
    });
  }, [snackbar]);

  useEffect(() => {
    fetchLibraries();
  }, [fetchLibraries]);



  // const openModal = () => {
  //   setModalOpen(true);
  // };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div style={{ padding: "3.5% 4% 1% 4%" }}>
      <>
        {/* <SubTopBar
                title="Library"
                onButtonClick={openModal}
            /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              color: "#000020",
              fontFamily: "Poppins",
              mb: "2rem",
            }}
          >
            My Library
          </Typography>
        </Box>

        <Modal
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              height: "90vh",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "5px",
              overflowY: "scroll",
            }}
          >
            {/* <AddNews closeModal={closeModal}></AddNews> */}
          </Box>
        </Modal>

        <Box
          sx={{
            overflow: "scroll",
            // border: '1px solid red'
          }}
        >
          {library ? (
            <Grid
              container
              gap={2}
              sx={{
                overflow: "scroll",
                // pl: "1rem",
              }}
            >
              {library.map((item, index) => (
                // <Stack key={index} direction={'row'} mt={5} sx={{ position: 'relative', right: '125px' }}>

                <Grid
                  item
                  key={index}
                  sx={{
                    // border: '1px solid red',
                    height: "11rem",
                    width: "11rem",
                    borderRadius: "1.5rem",
                    backgroundColor: "#fff",
                    padding: "1.5rem",
                    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    boxShadow: "rgba(100, 100, 111, 0.5) 0px 0px 5px 0px",
                    margin: '0.2rem',
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "rgba(235, 86, 143, 1) 0px 0px 5px 0px",
                    }
                  }}
                  onClick={() => navigate(`/lib/files/${item.programId}`)}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "40%",
                      // border: '1px solid blue',
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={LibIcon}
                      alt=""
                      style={{ width: "auto", height: "70%" }}
                    />
                    {/* <img src={Dots} alt="" style={{ width: 'auto', height: '35%' }} /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "60%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        color: "#101010",
                      }}
                    >
                      {item?.programName}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No data</Typography>
          )}
        </Box>
      </>
    </div>
  );
};

export default Library;
