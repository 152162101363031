import React, { useEffect, useRef } from "react";
import bgimg from "../../images/bgimg.jpg";
// import FileLoader from "../../components/FileLoader/FileLoader";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
} from "@mui/material";
import clinical from "../../images/clinicalImage.png";
import ProgramList from "../../components/clinical-programs-list/clinical-program-list";

function ClinicalPrograms() {
  // const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const join = searchParams.get("join_programs");

  const programListRef = useRef(null);

  useEffect(() => {
    if (join === "true") {
      if (programListRef.current) {
        programListRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [join]);

  // if (isLoading) {
  //   return <FileLoader />;
  // }

  return (
    <>
      {/* LANDING */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: "3.6%",
          padding: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "0",
          },
          height: {
            xs: "100vh",
            sm: "100vh",
            md: "93vh",
          },
          width: "100%",
          background: `url(${bgimg})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          // border :' 1px solid red'
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            // border: '1px solid red',
          }}
        >
          <Typography
            sx={{
              fontSize: "36px",
              color: "#ffffff",
              fontWeight: "700",
              lineHeight: "52px",
            }}
          >
            StudyMEDIC Two-Years Advanced
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              color: "#ffffff",
              fontWeight: "700",
              lineHeight: "52px",
            }}
          >
            <span style={{ color: "#EB568F" }}>Clinical Training Program </span>
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: {
                sm: "100%",
                xs: "100%",
                md: "70%",
              },
              textAlign: {
                sm: "left",
                xs: "left",
                md: "center",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                color: "#F2F2F2",
                fontWeight: "normal",
              }}
            >
              One-Stop Destination for Empowering Medical Aspirants with
              Unparalleled Clinical Skills and Knowledge.
            </Typography>
          </Box>

          {/* <Typography sx={{
            fontSize: '13px',
            color: '#F2F2F2',
            fontWeight: 'normal',
          }}></Typography> */}
        </Box>
      </Box>

      {/* SECTION 2 */}
      <Box
        sx={{
          // CONTAINER
          overflow: "hidden",
          width: "100%",
          padding: "5% 14% 5% 14%",
        }}
      >
        <Grid
          container
          gap={4}
          sx={{
            // border : '1px solid red'
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={4.1}>
            {/* <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "600",
                color: "#000020",
              }}
            >
              Lorem ipsum dolor sit
            </Typography> */}

            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "normal",
                color: "#252525",
                mt: "1rem",
              }}
            >
              StudyMEDIC offers a Two-Year Advanced Clinical Training Program
              for aspiring medical professionals, providing invaluable
              real-clinical experience. Our E-Portfolio for the clinical
              training program aims to offer seamless access to learning
              resources, ensuring aspirants' progress and success at the end.
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "normal",
                color: "#252525",
                mt: "1rem",
              }}
            >
              By maintaining a comprehensive E-portfolio, students can not only
              document their activities but also engage in identifying areas for
              improvement and setting goals for growth. During clinical
              rotations, students actively participate in hands-on patient care
              under the guidance of experienced healthcare professionals.
              <br />
              <br/>
              Take advantage of this opportunity to reflect upon learning
              experiences, fostering a deeper understanding of growth and
              development.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <img
              src={clinical}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* SECTION 3 */}
      <div id="join_programs" ref={programListRef}></div>
      <Box
        sx={{
          background: "#F2F5FF",
          mb: "4%",
        }}
      >
        <Box
          sx={{
            // CONTAINER
            overflow: "hidden",
            width: "100%",
            padding: "5% 14% 5% 14%",
          }}
        >
          <ProgramList />
        </Box>
      </Box>
    </>
  );
}

export default ClinicalPrograms;
