import { AES, enc } from "crypto-js";
import { KEY_STRING } from "../config/api-config";

const KEY = "session";

const setAuthStorage = (auth) => {
  try {
    localStorage.setItem(
      KEY,
      AES.encrypt(JSON.stringify(auth), KEY_STRING).toString()
    );
  } catch (err) {
    // Handle any errors here
  }
};

const setProfileImage = (url) => {
  try {
    localStorage.setItem("ePortImage",url);
  } catch (err) {
    // Handle any errors here
  }
};

const getAuth = () => {
  let result = getAuthStorage();
  if (result === null) {
    result = getSessionStorage();
  }
  return result;
};

const getAuthStorage = () => {
  try {
    const auth = localStorage.getItem(KEY);
    if (!auth) {
      return null;
    }

    const result = JSON.parse(AES.decrypt(auth, KEY_STRING).toString(enc.Utf8));
    return result;
  } catch (err) {
    // Handle any errors here
    return null;
  }
};

const clearStorage = (key = KEY) => {
  localStorage.removeItem(key);
};

const setSessionStorage = (userData) => {
  try {
    sessionStorage.setItem(KEY, JSON.stringify(userData));
  } catch (err) {
    // Handle any errors here
  }
};

const getSessionStorage = () => {
  try {
    const data = sessionStorage.getItem(KEY);
    const result = JSON.parse(typeof data === "string" ? data : "");
    return result;
  } catch (err) {
    // Handle any errors here
    return null;
  }
};

const clearSessionStorage = () => {
  sessionStorage.removeItem(KEY);
};

const listViewState = {
  set: () => {
    try {
      sessionStorage.setItem("listView", JSON.stringify(true));
    } catch (error) {
      // Handle any errors here
    }
  },

  get: () => {
    try {
      const data = sessionStorage.getItem("listView");
      const result = JSON.parse(typeof data === "string" ? data : "");
      return result;
    } catch (error) {
      // Handle any errors here
    }
  },

  clear: () => {
    try {
      sessionStorage.removeItem("listView");
    } catch (error) {
      // Handle any errors here
    }
  },
};

const breadCrumbState = {
  set: (data) => {
    try {
      sessionStorage.setItem("breadCrump", JSON.stringify(data));
    } catch (error) {
      // Handle any errors here
    }
  },

  get: () => {
    try {
      const data = sessionStorage.getItem("breadCrump");
      const result = typeof data === "string" ? JSON.parse(data) : [];
      return result;
    } catch (error) {
      // Handle any errors here
      return [];
    }
  },

  clear: () => {
    try {
      sessionStorage.removeItem("breadCrump");
    } catch (error) {
      // Handle any errors here
    }
  },
};

export {
  setAuthStorage,
  getAuthStorage,
  clearStorage,
  setSessionStorage,
  getSessionStorage,
  clearSessionStorage,
  getAuth,
  listViewState,
  breadCrumbState,
  setProfileImage,
};
