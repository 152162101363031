import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  IconButton,
  Paper,
  Modal,
  Backdrop,
  Fade,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Card,
  CardContent,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { AddCircle } from "@mui/icons-material";
import { IoIosArrowDown } from "react-icons/io";
import ShareIcon from "@mui/icons-material/Share";
import AddAppraisal from "./AddAppraisal";
import { appraisalService } from "../../service/index";

const PersonalForms = [
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "24/02/2023",
    dateActivity: "27/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "25/02/2023",
    dateActivity: "28/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "26/02/2023",
    dateActivity: "29/03/2023",
    shared: "Yes",
    achieved: "Yes",
    countersigned: "Yes",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "27/02/2023",
    dateActivity: "30/03/2023",
    shared: "No",
    achieved: "No",
    countersigned: "No",
  },
  {
    title: "Teaching have you delivered (29 Jul 2023) IMG Introductory Day",
    dateModified: "27/02/2023",
    dateActivity: "30/03/2023",
    shared: "No",
    achieved: "No",
    countersigned: "No",
  },
];

const getColor = (status) => (status === "Yes" ? "green" : "red");
const Appraisal = () => {
  const [showAppraisal, setShowAppraisal] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [appraisal, setAppraisal] = useState([]);
  const [appraisaldetails, setAppraisalDetails] = useState([]);
  const [showAddAppraisal, setShowAddAppraisal] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const handleOpen = async (item) => {
    setSelectedItem(item);
    console.log("Selected item:", item); // Check the selected item
    try {
      const res = await appraisalService.getAppraisalDetails(item._id);
      console.log("Fetch appraisal details response:", res);
      if (res.status) {
        setAppraisalDetails(res.data);
        setModalOpen(true);
      } else {
        console.error("Failed to fetch appraisal details:", res.message);
      }
    } catch (error) {
      console.error("Error fetching appraisal details:", error);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };
  const handleAddAppraisalClick = () => {
    setShowAddAppraisal(true);
  };

  const handleBackToAppraisal = () => {
    setShowAddAppraisal(false);
  };

  useEffect(() => {
    // Fetch the available appraisals on component mount
    const fetchAppraisal = async () => {
      try {
        console.log("Fetching appraisals...");
        const res = await appraisalService.getAppraisal();
        console.log("Fetch appraisal response:", res);
        if (res.status) {
          setAppraisal(res.data);
          console.log("Appraisals set:", res.data);
        } else {
          console.error("Failed to fetch appraisals:", res.message);
        }
      } catch (error) {
        console.error("Error fetching appraisals:", error);
      }
    };

    fetchAppraisal();
  }, []);

  if (showAddAppraisal) {
    return <AddAppraisal onBack={handleBackToAppraisal} />;
  }

  return (
    <Box sx={{ padding: "2.5rem", height: "150vh" }}>
      <Box sx={{ marginBottom: 2 }}>
        <Button
          disableElevation
          variant={showAppraisal ? "contained" : "outlined"}
          onClick={() => setShowAppraisal(true)}
          sx={{
            backgroundColor: showAppraisal ? "#2C2B83" : "white",
            color: showAppraisal ? "white" : "#525252",
            border: "none",
            borderRadius: "30px",
            textTransform: "none",
            "&:hover": {
              scale: "0.9",
              bgcolor: "#E5E4FF",
              color: "#525252",
              border: "none",
            },
          }}
        >
          Appraisal
        </Button>
        <Button
          disableElevation
          variant={!showAppraisal ? "contained" : "outlined"}
          onClick={() => setShowAppraisal(false)}
          sx={{
            marginLeft: 1,
            border: "none",
            backgroundColor: !showAppraisal ? "#2C2B83" : "white",
            color: !showAppraisal ? "white" : "#525252",
            borderRadius: "30px",
            textTransform: "none",
            transition: "scale 0.1s ease-in",
            mr: "0.5rem",
            "&:hover": {
              scale: "0.9",
              bgcolor: "#E5E4FF",
              color: "#525252",
              border: "none",
            },
          }}
        >
          Personal Development Plan
        </Button>
      </Box>
      {showAppraisal ? (
        <>
          <Typography fontSize={"16px"} fontWeight={"500"} gutterBottom>
            Appraisal:
          </Typography>
          <Typography fontSize={"14px"} fontWeight={"400"} paragraph>
            A formal process of appraisals and reviews underpins Medical
            Training. This process ensures adequate supervision for you during
            training, and provides continuity between posts and different
            supervisors. The appraisal forms can be written in draft by the
            trainee and then checked and finally saved by the supervisor. End of
            attachment appraisals can only be written by supervisors.
          </Typography>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<AddCircle style={{ color: "#2C2B83" }} />}
            sx={{
              textTransform: "none",
              marginBottom: 2,
              bgcolor: "#E5E4FF",
              borderRadius: "30px",
              color: "#2C2B83",
              fontWeight: "500",
              border: "1px solid #2C2B83",
              "&:hover": {
                bgcolor: "#E5E4FF",
                color: "#2C2B83",
              },
            }}
            onClick={handleAddAppraisalClick}
          >
            Add Appraisal
          </Button>

          <Grid container spacing={2}>
            {appraisal.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "20px",
                    width: "100%", // Full width
                    cursor: "pointer",
                  }}
                  onClick={() => handleOpen(item)}
                >
                  <Grid container alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#E4E5FF",
                        borderRadius: "50%",
                        width: 32,
                        height: 32,
                        marginRight: 2,
                      }}
                    >
                      <DescriptionIcon sx={{ color: "#8787A3" }} />
                    </Box>
                    <Typography>{item.type}</Typography>
                  </Grid>
                  <IconButton
                    sx={{
                      backgroundColor: "#E5E4FF",
                      "&:hover": {
                        backgroundColor: "#D4D3FF",
                      },
                    }}
                  >
                    <CiEdit style={{ color: "#2C2B83" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#FFEBEB",
                      "&:hover": {
                        backgroundColor: "#FFD4D4",
                      },
                      marginLeft: 2,
                    }}
                  >
                    <MdDelete style={{ color: "#D32F2F" }} />
                  </IconButton>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        //----------------------------------------------content for personal button-----------------------------------//
        <Box>
          <Typography fontSize={"16px"} fontWeight={"500"} gutterBottom>
            Personal Development Plan:
          </Typography>
          <Typography fontSize={"14px"} fontWeight={"400"} paragraph>
            The Personal Development Plan is your chance to set out what you
            expect to achieve during each post and throughout the programme. It
            should be developed in conjunction with your supervisor and should
            be updated following appraisal meetings.
            <br />
            The PDP should be reviewed at the beginning of each post. You should
            identify learning objectives, outline the plan for the objectives to
            be met, and give an indication of the timescale for the objectives,
            and an idea of how evidence for the completion of the objective will
            be achieved. These should be agreed with your supervisor. Once
            objectives have been achieved, they can be ticked off. They will
            remain on the PDP to reflect your achievements throughout training.
          </Typography>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<AddCircle style={{ color: "#2C2B83" }} />}
            sx={{
              textTransform: "none",
              marginBottom: 2,
              bgcolor: "#E5E4FF",
              borderRadius: "30px",
              color: "#2C2B83",
              fontWeight: "500",
              border: "1px solid #2C2B83",
              "&:hover": {
                bgcolor: "#E5E4FF",
                color: "#2C2B83",
              },
            }}
            onClick={openModal}
          >
            Add New Plan
          </Button>
          {/* //----------------------------------------------Modal for add new plan button-----------------------------------// */}

          <Modal open={open} onClose={closeModal}>
            <Box
              sx={{
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 700,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: "20px",
              }}
            >
              <Typography fontSize={"14px"} fontWeight={"500"} component="h2">
                Personal Development Plan
              </Typography>
              <Box component="form" sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle1"
                  fontSize={"12px"}
                  fontWeight={"500"}
                >
                  <span style={{ color: "red" }}>*</span>Title
                </Typography>
                <TextField
                  fullWidth
                  margin="none"
                  required
                  rows={1}
                  InputProps={{
                    sx: {
                      bgcolor: "#f5f5f5",
                      border: "none",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={"500"}
                    >
                      What Specific Development Needs Do I have?
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      margin="none"
                      InputProps={{
                        sx: {
                          bgcolor: "#f5f5f5",
                          border: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={"500"}
                    >
                      How Will These Objectives Be Addressed?
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      margin="none"
                      InputProps={{
                        sx: {
                          bgcolor: "#f5f5f5",
                          border: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={"500"}
                    >
                      Timescale
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      margin="none"
                      InputProps={{
                        sx: {
                          bgcolor: "#f5f5f5",
                          border: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="subtitle1"
                      fontSize={"12px"}
                      fontWeight={"500"}
                    >
                      Evaluation and Outcome
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      margin="none"
                      InputProps={{
                        sx: {
                          bgcolor: "#f5f5f5",
                          border: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Box>
                <Typography
                  mt={2}
                  variant="subtitle1"
                  fontSize={"12px"}
                  fontWeight={"500"}
                >
                  Has It Been Achieved?
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={1}
                  margin="none"
                  InputProps={{
                    sx: {
                      bgcolor: "#f5f5f5",
                      border: "none",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />

                <Box mt={2}>
                  <IconButton>
                    <ShareIcon />
                  </IconButton>
                  <Box display="flex" gap={2} mt={2}>
                    <Button
                      disableElevation
                      onClick={handleClose}
                      variant="contained"
                      sx={{
                        bgcolor: "#EFEFEF",
                        color: "black",
                        borderRadius: "30px",
                        textTransform: "none",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{
                        bgcolor: "#E5E4FF",
                        color: "#2C2B83",
                        borderRadius: "30px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#2C2B83",
                          color: "white",
                        },
                      }}
                    >
                      Save To Draft
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{
                        bgcolor: "#2C2B83",
                        borderRadius: "30px",
                        textTransform: "none",
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>

          <Paper
            elevation={0}
            sx={{
              p: 2,
              bgcolor: "white",
              mt: 2,
              border: "1px solid #D5D5D5",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Show log For
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                        fontSize: "12px",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none">
                      <em>Select date</em>
                    </MenuItem>
                    <MenuItem value={10}>07 Aug, 2023 to 02 Aug, 2023</MenuItem>
                    <MenuItem value={20}>07 Aug, 2023 to 03 Aug, 2023</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Select Training Year
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                        fontSize: "12px",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none" sx={{ color: "#7E7E7E" }}>
                      <em sx={{ fontSize: "12px" }}>select</em>
                    </MenuItem>
                    <MenuItem value={10} sx={{ fontSize: "12px" }}>
                      2023
                    </MenuItem>
                    <MenuItem value={20} sx={{ fontSize: "12px" }}>
                      2024
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Select Grade
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                    fontSize: "12px",
                  }}
                >
                  <Select
                    defaultValue="none"
                    sx={{
                      height: 36,
                      ".MuiSelect-select": {
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        color: "#7E7E7E",
                        fontSize: "12px",
                      },
                    }}
                    IconComponent={(props) => (
                      <IoIosArrowDown
                        {...props}
                        style={{ color: "black", fontSize: "20px" }}
                      />
                    )}
                  >
                    <MenuItem value="none" sx={{ color: "#7E7E7E" }}>
                      <em>Grade</em>
                    </MenuItem>
                    <MenuItem value={10}>Grade 1</MenuItem>
                    <MenuItem value={20}>Grade 2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  Period Cover From
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  placeholder="DD-MM-YY"
                  sx={{
                    fontSize: "12px",
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 36,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      "& input": {
                        color: "#7E7E7E",
                      },
                    },
                    // "& input::-webkit-calendar-picker-indicator": {
                    //   display: "none",
                    // },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={{ color: "#525252", fontSize: "12px" }}>
                  To
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  placeholder="DD-MM-YY"
                  sx={{
                    fontSize: "12px",
                    height: 36,
                    bgcolor: "#EFEFEF",
                    color: "#7E7E7E",
                    borderRadius: "4px",
                    "& fieldset": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 36,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      "& input": {
                        color: "#7E7E7E",
                      },
                    },
                    // "& input::-webkit-calendar-picker-indicator": {
                    //   display: "none",
                    // },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#ffffff",
                    backgroundColor: "#2C2B83",
                    borderRadius: "10px",
                    transition: "scale 0.1s ease-in",
                    mt: 2,
                    "&:hover": {
                      scale: "0.9",
                      backgroundColor: "#2C2B83",
                    },
                  }}
                >
                  Find PDPs
                </Button>
              </Grid>
            </Grid>
          </Paper>

          {/* //--------------------------------cards----------------------------------------------// */}

          <Grid container spacing={2} mt={2}>
            {PersonalForms.map((form, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card sx={{ borderRadius: "16px" }} elevation={0}>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      Title
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontSize: "13px", mb: 1 }}
                    >
                      {form.title}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                          Date Of Modified
                        </Typography>
                        <Typography variant="body2">
                          {form.dateModified}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                          Date Of Activity
                        </Typography>
                        <Typography variant="body2">
                          {form.dateActivity}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                          Shared
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: getColor(form.shared) }}
                        >
                          {form.shared}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body2"
                          sx={{ color: "#525252", fontSize: "11px" }}
                        >
                          Achieved
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: getColor(form.achieved) }}
                        >
                          {form.achieved}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ color: "#525252", fontSize: "11px" }}>
                          Countersigned
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: getColor(form.countersigned) }}
                        >
                          {form.countersigned}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box display="flex" marginTop={2}>
                      <IconButton
                        sx={{
                          backgroundColor: "#E5E4FF",
                          "&:hover": {
                            backgroundColor: "#D4D3FF",
                          },
                        }}
                      >
                        <CiEdit style={{ color: "#2C2B83" }} />
                      </IconButton>
                      <IconButton
                        sx={{
                          backgroundColor: "#FFEBEB",
                          "&:hover": {
                            backgroundColor: "#FFD4D4",
                          },
                          marginLeft: 1,
                        }}
                      >
                        <MdDelete style={{ color: "#D32F2F" }} />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/*-------------------------------------------------Modal for Appraisals------------------------------------------------------*/}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "20px",
            }}
          >
            {selectedItem && appraisaldetails && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#E4E5FF",
                      borderRadius: "50%",
                      width: 32,
                      height: 32,
                      marginRight: 2,
                    }}
                  >
                    <DescriptionIcon sx={{ color: "#8787A3" }} />
                  </Box>
                  <Typography fontSize={"14px"} component="h2">
                    {selectedItem.type} {/* Displaying type */}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      <span style={{ color: "red" }}>*</span>Meeting Date:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.meetingDate}{" "}
                      {/* Displaying meeting date */}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      Out Of Hours Work:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.outOfHoursWork}{" "}
                      {/* Displaying out of hours work */}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      Monday:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.monday}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      Tuesday:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.tuesday}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      Wednesday:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.wednesday}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      Thursday:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.thursday}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "12px", color: "#525252" }}>
                      Friday:
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {appraisaldetails.friday}
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={{ mt: 2, fontSize: "12px", color: "#525252" }}>
                  Arrangements For Senior Review Of Admission:
                </Typography>
                <Typography
                  sx={{ mt: 1, mb: 8, fontSize: "14px", fontWeight: "500" }}
                >
                  {appraisaldetails.summary}
                </Typography>
                <Box
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#E6E6FE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "18%",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#2C2B83",
                    }}
                  >
                    Close
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Appraisal;
