import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Grid,
  Modal,
} from "@mui/material";
import styled from "@emotion/styled";
import img1 from "../../images/doctor.jpeg";
import img2 from "../../images/newlogo.png";
import ne from "../../images/alertnew.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { authService } from "../../service/index";
import FileLoader from "../../components/FileLoader/FileLoader";
import { useSnackbar } from "notistack";
// import { userService } from "../../service/index";
import OtpComponent from "../../components/otp-component/OtpComponent";

// stylefor bgimage

const StyledImg1 = styled("img")({
  height: "700px",
  marginTop: "10px",
  width: "100%",
  "@media (min-width: 3000px) and  (max-width: 4016px)": {
    height: "843px",
    marginTop: "5px",
    width: "100%",
    marginLeft: "30px",
  },
  "@media (min-width: 1100px) and  (max-width: 1535px)": {
    height: "643px",
    marginTop: "5px",
    width: "100%",
    marginLeft: "30px",
  },
  "@media (min-width: 900px) and  (max-width: 1100px)": {
    height: "500px",
    marginTop: "120px",
    width: "100%",
    marginLeft: "20px",
  },
  "@media   (max-width: 899.5px)": {
    display: "none",
  },
});

// styleforlogo

const StyledImg2 = styled("img")({
  width: "150px",
  height: "46px",
  marginTop: "80px",
});

// const StyledWall = styled("img")({
//   width: "45%",
//   position: "relative",
//   left: "400px",
//   bottom: "400px",
//   height: "343px",

//   "@media  (max-width: 900px)": {
//     width: "200px",
//     height: "150px",
//     position: "relative",
//     bottom: "550px",
//     left: "30px",
//   },
// });

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [reenteredPassword, setReenteredPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: '',
    mobile: ''
  });

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      email: email,
      password: password,
      mobile: phoneNumber
    }));
  }, [email, password, phoneNumber]);

  // {
  //   /* handlesubmit */
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!updateFormValidity) return;
  //   setLoading(true);
  //   userService.createUser(email, password, phoneNumber).then((res) => {
  //     setLoading(false);
  //     console.log("res", res);
  //     if (!res.status) {
  //       snackbar("Registration Failed", { variant: "error" });
  //       return;
  //     }
  //     snackbar("Registration Sucessfull", { variant: "success" });

  //     if (res.status) {
  //       navigate("/sign-in/");
  //       return;
  //     }
  //   });
  // };

  if (loading) {
    console.log("....loading");
    <FileLoader />;
  }

  // To check form is valid

  const updateFormValidity = useCallback(() => {
    setIsFormValid(
      emailValid && // Check your email validation condition here
      validatePassword(password) && // Check your password validation condition here
      isPasswordMatch(password, reenteredPassword) && // Check password match
      validatePhoneNumber(phoneNumber) && // Check phone number validation
      isCheckboxChecked // Check the checkbox
    );
  }, [emailValid, isCheckboxChecked, password, phoneNumber, reenteredPassword]);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  // Call the function whenever any of the validation states change
  useEffect(() => {
    updateFormValidity();
  }, [emailValid, password, reenteredPassword, phoneNumber, isCheckboxChecked, updateFormValidity]);

  const isPasswordMatch = (password, reenteredPassword) => {
    return password === reenteredPassword;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailValid(validateEmail(newEmail));
  };
  // const handleModalOpen = () => {
  //   setIsModalOpen(true);
  // };

  // const handleModalClose = () => {
  //   setIsModalOpen(false);
  // };
  const validatePassword = (password) => {
    // Check if the password is at least 6 characters long
    // Check if the password contains at least one alphabet, one number, and one special character
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsPasswordValid(validatePassword(newPassword));
  };
  const handleReenteredPasswordChange = (event) => {
    const newReenteredPassword = event.target.value;
    setReenteredPassword(newReenteredPassword);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{12}$/; // Matches a 10-digit number
    return phoneRegex.test(phoneNumber);
  };
  const handlePhoneNumberChange = (value, data, event, formattedValue) => {
    setPhoneNumber(value);
    setIsPhoneNumberValid(validatePhoneNumber(value));
  };

  const handleOTPOpen = () => {
    try {
      reSend(phoneNumber);
    } catch (err) {
      console.error(err);
    }
  };

  const reSend = async (phoneNumber) => {
    try {
      setLoading(true);
      if (!validatePhoneNumber(phoneNumber)) {
        console.log("Number\t\t", phoneNumber);
        snackbar("Number was not valid", { variant: "error" });
        return;
      }
      setOtpOpen(true);
      const forgotResponse = await authService.generateOTP(phoneNumber);
      if (!forgotResponse || !forgotResponse.status) {
        snackbar("Server error", { variant: "error" });
        console.error("Failed generate OTP api\t", forgotResponse);
        return;
      }
      if (forgotResponse.data) {
        snackbar(`OTP :\t${forgotResponse.data}`, { variant: "info" });
        return;
      }
      snackbar("Something went wrong", { variant: "error" });
      return;
    } catch (err) {
      console.error("Error in send OTP\t", err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOtpOpen(false);
  };

  const [otpOpen, setOtpOpen] = useState(false);

  return (
    <Box>
      <Grid container spacing={3}>
        {/* bgimg */}

        <Grid item md={6}>
          <StyledImg1 src={img1} alt="women" />
        </Grid>

        {/* form */}

        <Grid item md={6}>
          <Box
            sx={{
              width: "357px",

              "@media (min-width: 1300px) and  (max-width: 4016px)": {
                ml: "15vw",
              },
              "@media (min-width: 1000px) and  (max-width: 1300px)": {
                ml: "9vw",
              },
              "@media (min-width: 899.5px) and  (max-width: 1000px)": {
                ml: "7vw",
              },
              "@media (min-width: 600px) and  (max-width: 899.5px)": {
                ml: "120px",
              },
              "@media (max-width: 600px)": {
                ml: "40px",
              },
              "@media (max-width: 500px)": {
                ml: "0px",
              },
            }}
          >
            <StyledImg2 src={img2} alt="logo" />
            <Typography sx={{ mt: 4, fontWeight: "600", fontSize: "19.651px" }}>
              Register
            </Typography>
            <Typography
              sx={{
                mt: 0,
                fontSize: "11px",
                fontWeight: "400",
                "@media (min-width: 600px) and (max-width: 900px)": {
                  width: "250px",
                },
              }}
            >
              Register you login details
            </Typography>

            {/* phonefield */}
            <Typography
              sx={{ mt: 2, fontSize: "10.72px", fontWeight: "500", mb: "7px" }}
            >
              Phone number<span style={{ color: "red" }}>*</span>
            </Typography>
            <PhoneInput
              country={"in"}
              onChange={handlePhoneNumberChange}
              placeholder="Enter phone number"
              inputStyle={{
                border: "none",
                width: "100%",
                height: "40px",
                backgroundColor: isPhoneNumberValid ? "#F5F5F5" : "#FFEAE8", // Set the bgcolor dynamically
                borderRadius: "5.359px",
                paddingLeft: "100px",
              }}
              buttonStyle={{
                height: "40px",
                border: "none",
                backgroundColor: "#EBEBEB",
                width: "88px",
                paddingLeft: "20px",
                borderRadius: "6px",
              }}
            />

            {/* emailfield */}

            <Typography sx={{ mt: 2, fontSize: "10.72px", fontWeight: "500" }}>
              Email <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="Enter your email address"
              sx={{
                mt: "5px",
                color: "#C6C6CE",
                bgcolor: emailValid ? "#F5F5F5" : "#FFEAE8",
                width: "100%",
                height: "40px",
                borderRadius: "5.359px",

                "& input::placeholder": {
                  position: "relative",
                  right: "0px",
                },
              }}
              onChange={handleEmailChange}
              value={email}
              error={!emailValid}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {emailValid ? null : (
                      <IconButton disabled edge="end">
                        <img
                          src={ne}
                          alt="invalid-icon"
                          style={{
                            width: "18px",
                            height: "18px",
                            paddingRight: "20px",
                            paddingTop: "5px",
                          }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    paddingLeft: "14px",
                    paddingTop: "8px", // Adjust the left padding for cursor placement
                  },
                },
              }}
            // Add this line
            />

            {!emailValid && (
              <Typography variant="caption" color="red" ml={29}>
                Invalid email address
              </Typography>
            )}

            {/* passwordfield */}

            <Box sx={{ mt: "10px", position: "relative", right: "10px" }}>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "10.72px",
                  fontWeight: "500",
                  ml: "10px",
                }}
              >
                Password<span style={{ color: "red" }}>*</span>
              </Typography>

              <OutlinedInput
                id="outlined-adornment-password"
                onChange={handlePasswordChange}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter your password"
                sx={{
                  "& fieldset": { border: "none" },
                  width: "100%",
                  height: "40px",
                  borderRadius: "5.359px",
                  bgcolor: isPasswordValid ? "#F5F5F5" : "#FFEAE8",
                  margin: 1,
                }}
              />
            </Box>

            {/* reenterpassword */}

            <Box sx={{ mt: "10px", position: "relative", right: "10px" }}>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "10.72px",
                  fontWeight: "500",
                  ml: "10px",
                }}
              >
                Re-enter Password<span style={{ color: "red" }}>*</span>
              </Typography>

              <OutlinedInput
                id="outlined-adornment-password"
                onChange={handleReenteredPasswordChange}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter your password"
                sx={{
                  "& fieldset": { border: "none" },
                  width: "100%",
                  height: "40px",
                  borderRadius: "5.359px",
                  bgcolor: isPasswordMatch(password, reenteredPassword)
                    ? "#F5F5F5"
                    : "#FFEAE8",
                  margin: 1,
                }}
              />
            </Box>

            {/* passwordcondition */}

            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontSize: "8.932px",
                textAlign: "justify",
                wordSpacing: "-1px",
                lineHeight: "14.29px",
                fontWeight: "400",
              }}
            >
              (Password must be at least 6 characters long which can be
              alphabets,numbers <br /> or special characters, or a mix of all
              three respectively)
            </Typography>

            {/* checkbox */}

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: -2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#252525",
                      "&.Mui-checked": { color: "#EB568F" },
                      mt: 3,
                    }}
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography sx={{ fontSize: "9.83px", mt: 3 }}>
                    Accept Terms & Conditions{" "}
                  </Typography>
                }
              />
            </Box>

            {/* <Button
              variant="contained"
              onClick={handleSubmit}
              disableElevation
              sx={{
                borderRadius: "32px",
                bgcolor: "#EB568F",
                width: "100%",
                mt: 0,
                fontSize: "14px",
                textTransform: "none",
                height: "46px",
                fontWeight: "600",
                "&:hover": { bgcolor: "#EB568F" },
              }}
              disabled={!isFormValid}
            >
              Register
            </Button> */}
            <Button
              variant="contained"
              onClick={() => handleOTPOpen()}
              disableElevation
              sx={{
                borderRadius: "32px",
                bgcolor: "#EB568F",
                width: "100%",
                mt: 0,
                fontSize: "14px",
                textTransform: "none",
                height: "46px",
                fontWeight: "600",
                "&:hover": { bgcolor: "#EB568F" },
              }}
              disabled={!isFormValid}
            >
              Send OTP
            </Button>
            <Typography
              sx={{
                mt: 1,
                marginLeft: "98px",
                fontSize: "11px",
                fontWeight: "549",
                "@media (min-width: 600px) and (max-width: 900px)": {
                  ml: "0px",
                },
              }}
            >
              You have an account?
              <Link
                onClick={() => {
                  navigate("/sign-in");
                }}
                color="#EB568F"
                sx={{ ml: 1, cursor: "pointer" }}
              >
                Login
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={otpOpen}
        onClose={() => setOtpOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <OtpComponent
          mobile={phoneNumber}
          handleClose={handleClose}
          reSend={reSend}
          data={data}
        />
      </Modal>
    </Box>
  );
}

export default Register;
