const supervisorService = (httpClient) => {
  return {
    getSupervisors: async () => {
      try {
        const res = await httpClient.get("/fetch/posts/");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch supervisors" };
      }
    },
    deleteSupervisor: async (postId, supervisorId) => {
      console.log("Sending delete request with payload:", {
        postId,
        supervisorId,
      });
      try {
        const res = await httpClient.post("/remove/post/supervisor", {
          postId,
          supervisorId,
        });
        console.log("Delete response data:", res.data);
        return res.data;
      } catch (error) {
        console.error("Error in deleteSupervisor:", error);
        return { status: false, message: "Failed to delete supervisor" };
      }
    },

    addSupervisor: async (data) => {
      try {
        const res = await httpClient.post("/add/post/supervisor", data);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to add supervisor" };
      }
    },
    editSupervisor: async (postId, supervisorId, data) => {
      try {
        const res = await httpClient.post("/edit/post/supervisor", {
          postId,
          supervisorId,
          updateData: data,
        });
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to edit supervisor" };
      }
    },
  };
};

export default supervisorService;
