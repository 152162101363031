import React, { useState } from "react";
import { useSnackbar } from "notistack";
import FileLoader from "../../components/FileLoader/FileLoader";
import { useParams } from "react-router-dom";
import { BiFolderPlus } from "react-icons/bi";
import FileAddIcon from "../../components/common/icons/file-add/FileAddIcon";
import "./user-files.css";
import { Button } from "@mui/material";
import {
  Box,
  Typography,
  Grid,
  Menu,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material/";
import FileNavigation from "../files/fileNavigation";
import { recordService } from "../../service/index";
import AddIcon from "@mui/icons-material/Add";
import AddFolder from "../../components/dialogue/AddFolder";

const UserFiles = () => {
  const { id, recordId } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [AddFolderOpen, setAddFolderOpen] = React.useState(false);
  // const [progress, setProgress] = React.useState(0);
  // const [showUploadProgress, setShowUploadProgress] = React.useState(false);
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  // const locationObj = useLocation();
  const [trigger, setTrigger] = useState(false);

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const folderToggleModel = () => {
    setAddFolderOpen(!AddFolderOpen);
    handleCloseMenu();
  };

  const modalOptions = [
    {
      title: "New Folder",
      icon: <BiFolderPlus fontSize={20} />,
      action: folderToggleModel,
    },
    {
      title: "File Upload",
      icon: <FileAddIcon sx={{ fontSize: 20 }} />,
    },
  ];

  const handleAddFolder = (name) => {
    const data = {
      name,
      // parentId: `${id || ""}`,
      parentId: id && recordId ? `${recordId || ""}` : `${id || ""}`,
      idFolder: true,
    };

    recordService.createFolder(data).then((res) => {
      if (!res.status) {
        snackbar("Error creating folder", { variant: "error" });
        return;
      }
      setAddFolderOpen(false);
    });
    console.log(name);
    setTrigger(!trigger);
  };

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  // const getBackground = (path) =>
  //   locationObj.pathname.includes(path)
  //     ? { background: "#EAEEF6" }
  //     : { background: "#F8F9FB", color: "#0E1C3F" };

  // const style = {
  //   position: "absolute",
  //   top: 142,
  //   left: 32,
  //   width: 190,
  //   bgcolor: "background.paper",
  //   boxShadow: "24",
  //   borderRadius: `0px 0px 12px 12px`,
  // };

  const fileInputRef = React.useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
    console.log("here4");
    // handleCloseMenu();
  };

  const handleFileSelected = (event) => {
    setIsLoading(true);
    handleCloseMenu();
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    const files = Array.from(event.target.files);
    // Do something with the selected file
    const data = new FormData();
    // data.append("name", file.name);
    console.log("User\t", id, "Record\t", recordId);
    if (id && recordId === undefined) {
      data.append("parentyId", `${id || ""}`);
    }
    if (id && recordId) {
      data.append("parentyId", `${recordId || ""}`);
    }

    files.forEach((file) => {
      data.append("img", file);
    });
    if (id && recordId === undefined) {
      data.append("parentId", `${id || ""}`);
    }
    if (id && recordId) {
      data.append("parentId", `${recordId || ""}`);
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        // const percentComplete = Math.round(
        //   (progressEvent.loaded / (progressEvent.total || 0)) * 100
        // );

        // setProgress(percentComplete);
      },
    };

    // setShowUploadProgress(true);
    recordService.addFile(data, config).then((res) => {
      // setProgress(0);
      // setShowUploadProgress(false);
      console.log("Res\t\t\t", res);
      if (!res.status) {
        snackbar("Upload error", { variant: "error" });
        setIsLoading(false);
        return;
      }

      snackbar("Upload success", { variant: "success" });
      setTrigger(!trigger);
      setIsLoading(false);
      // props.updateParent && props.updateParent();
    });
  };

  return (
    <>
      {isLoading && <FileLoader />}
      <Grid
        container
        sx={{
          padding: "3.5% 5% 3.5% 5%",
          flexDirection: "column",
        }}
      >
        <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontStyle: "Poppins",
              fontSize: "20px",
            }}
          >
            My Files
          </Typography>
          <Box sx={{ pt: 2.7, pb: 2, px: 2.5 }}>
            <Button
              fullWidth
              sx={
                open
                  ? {
                    background: "#EB5B91",
                    color: "white",
                    textTransform: "none",
                    fontWeight: 400,
                    borderRadius: "7.5px",
                    borderTopLeftRadius: 7.5,
                    borderTopRightRadius: 7.5,
                    borderEndStartRadius: 7.5,
                    borderEndEndRadius: 7.5,
                  }
                  : {
                    background: "#EB5B91",
                    color: "white",
                    textTransform: "none",
                    fontWeight: 400,

                    borderTopLeftRadius: 7.5,
                    borderTopRightRadius: 7.5,
                    borderEndStartRadius: 7.5,
                    borderEndEndRadius: 7.5,
                  }
              }
              onClick={handleClick}
            >
              <AddIcon />
              <Typography
                sx={{
                  fontSize: 16,
                  paddingLeft: 0.5,
                }}
              >
                Create New
              </Typography>
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleCloseMenu}
              // anchorOrigin={{
              //   vertical: 'top',
              //   horizontal: 'left',
              // }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  width: "205px",
                  marginTop: "-43px",
                  boxShadow: "0px 2.25px 7.5px -0.75px rgba(0, 0, 0, 0.25)",
                  borderRadius: "7.5px",
                },
              }}
            >
              {modalOptions.map((d, index) => (
                <ListItem
                  key={index}
                  disablePadding
                // sx={{ background: theme.palette.common.white }}
                >
                  <ListItemButton
                    sx={{}}
                    onClick={() =>
                      d.action ? d.action() : handleFileButtonClick()
                    }
                  >
                    {!d.action && (
                      <input
                        id="myInput"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileSelected}
                        multiple={true}
                      />
                    )}
                    <ListItemIcon sx={{ minWidth: "35px", color: "#0E1C3F" }}>
                      {d.icon}
                    </ListItemIcon>
                    <ListItemText primary={d.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </Menu>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            // border: '2px solid red',
            height: "80vh",
          }}
        >
          <FileNavigation trigger={trigger} />
        </Grid>
      </Grid>
      <AddFolder
        open={AddFolderOpen}
        heading={"Add New Folder"}
        submitText={"Add"}
        onSubmit={(name) => {
          handleAddFolder(name);
        }}
        onClose={() => {
          folderToggleModel();
        }}
      />
    </>
  );
};

export default UserFiles;
