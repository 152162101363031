import { Box } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuthStorage } from "../../utils/storage-utils";
import Drawer from "../../components/drawer/drawer";
import Loader from "../Loader/Loader";
import Public from "../public/public";

const Template = ({ children }) => {
  const { id, pid } = useParams();
  const { userID } = useParams();
  console.log("TEMPLATE_PARAM\t\t\t", id);
  const [loading, setLoading] = useState(true);
  // const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const session = getAuthStorage();
    if (!session || !session.token) {
      setIsLoggedIn(false);
    } else {
      setUserId(session.userId);
      setIsLoggedIn(true);
      // if (location.pathname === "" || location.pathname === "/") {
      //   navigate("/dashboard");
      // }
      // console.log("LOCATION\t\t\t", location);
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {isLoggedIn ? (
        <>
          {id || pid ? (
            <Box className="full-height">
              <Drawer>
                <Box className="full-height">
                  {React.Children.map(children, (child) => {
                    return React.cloneElement(child, { userId }); // Pass userId to children
                  })}
                </Box>
              </Drawer>
            </Box>
          ) : (
            <>
              {userID ? (
                <Box className="full-height">
                  <Box className="full-height">
                    {React.Children.map(children, (child) => {
                      return React.cloneElement(child, {
                        userId,
                      }); // Pass userId to children
                    })}
                  </Box>
                </Box>
              ) : (
                <Box className="full-height">
                  <Public>
                    <Box className="full-height">
                      {React.Children.map(children, (child) => {
                        return React.cloneElement(child, {
                          userId,
                        }); // Pass userId to children
                      })}
                    </Box>
                  </Public>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <Box className="full-height">
          <Box className="full-height">
            <Public>{children}</Public>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Template;
