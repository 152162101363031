import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Grid,
  Modal,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import img1 from "../../images/doctor.jpeg";
import img2 from "../../images/newlogo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ne from "../../images/alertnew.png";
import { useNavigate } from "react-router-dom";
import { authService } from "../../service/index";
import FileLoader from "../../components/FileLoader/FileLoader";
import { IoMdClose } from "react-icons/io";
import logoBig from "../../images/smLOGO.png"
// import modalBg from "../../images/Vector.png"
import OtpComponent from "../../components/otp-component/OtpComponent";

// stylefor bgimage

// const StyledImg1 = styled("img")({
//   height: "700px",
//   marginTop: "10px",
//   width: "100%",
//   "@media (min-width: 3000px) and  (max-width: 4016px)": {
//     height: "843px",
//     marginTop: "5px",
//     width: "100%",
//     marginLeft: "30px",
//   },
//   "@media (min-width: 1100px) and  (max-width: 1535px)": {
//     height: "643px",
//     marginTop: "5px",
//     width: "100%",
//     marginLeft: "30px",
//   },
//   "@media (min-width: 900px) and  (max-width: 1100px)": {
//     height: "500px",
//     marginTop: "120px",
//     width: "100%",
//     marginLeft: "20px",
//   },
//   "@media (max-width: 899.5px)": {
//     display: "none",
//   },
// });

// styleforlogo

const StyledImg2 = styled("img")({
  width: "150px",
  height: "46px",
  marginTop: "130px",
});

function Login() {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [password, setPassword] = useState();
  const [checked, setChecked] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [open, openModal] = useState(false);
  // const [newPassword, setNewPassword] = useState(null);
  // const [validate, setValidate] = useState(null);

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      if (!validateEmail(email)) {
        console.log("Email\t\t", email);
        snackbar("Enter a valid email", { variant: "error" });
      }
      const forgotResponse = await authService.forgotPassword(email);
      if (!forgotResponse || !forgotResponse.status) {
        snackbar("Server error", { variant: "error" });
        console.error("Failed forgot api\t", forgotResponse);
        return
      }
      if (forgotResponse.data) {
        snackbar(`OTP :\t${forgotResponse.data}`, { variant: "info" });
        openModal(false);
        setOtpOpen(true);
        return
      }
      snackbar("Something went wrong", { variant: "error" });
      return
    } catch (err) {
      console.error("Error in Forgot Password\t", err);
    } finally {
      setLoading(false)
    }
  }

  const reSend = async (callBackEmail) => {
    try {
      setLoading(true);
      if (!validateEmail(callBackEmail)) {
        console.log("Email\t\t", callBackEmail);
        snackbar("Email was not valid", { variant: "error" });
        return
      }
      const forgotResponse = await authService.forgotPassword(callBackEmail);
      if (!forgotResponse || !forgotResponse.status) {
        snackbar("Server error", { variant: "error" });
        console.error("Failed forgot api\t", forgotResponse);
        return
      }
      if (forgotResponse.data) {
        snackbar(`OTP :\t${forgotResponse.data}`, { variant: "info" });
        return
      }
      snackbar("Something went wrong", { variant: "error" });
      return
    } catch (err) {
      console.error("Error in Forgot Password\t", err);
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOtpOpen(false);
  }


  const [otpOpen, setOtpOpen] = useState(false);

  // {
  //   /* handlesubmit */
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    await authService.userSignIn(email, password, checked).then(async (res) => {
      setLoading(false);
      console.log("res", res?.data);
      if (!res?.status || !res?.data) {
        snackbar(res.message, { variant: "error" });
        return;
      }
      // if (res?.data.image.length > 0) {
      //   const image = await userService.getPresignedUrl(res.data.image);
      //   console.log("Image\t\t\t",image);
      //   localStorage.setItem("ePortimage", image);
      // }
      if (res?.data) {
        navigate(`/dashboardT/${res.data.userId}`);
        return;
      }
    });
  };

  if (loading) {
    console.log("....loading");
    <FileLoader />;
  }

  // To check form is valid

  const validateForm = () => {
    return emailValid && passwordValid;
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordValid(validatePassword(newPassword));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailValid(validateEmail(newEmail));
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <Box>
      <Grid container spacing={3}>
        {/* bgimg */}

        <Grid
          item
          md={6}
          sx={{
            height: "100vh",
            // background : `url(${img1})`,
            // backgroundRepeat : 'no-repeat',
            // backgroundSize : 'contain',
            // backgroundPosition :'center center',
            // borderRadius : '1.5rem'
          }}
        >
          <img
            src={img1}
            style={{ height: "100%", padding: "1rem" }}
            alt="women"
          />
        </Grid>

        {/* form */}

        <Grid item md={6}>
          <Box
            sx={{
              width: "357px",

              "@media (min-width: 1300px) and  (max-width: 4016px)": {
                ml: "15vw",
              },
              "@media (min-width: 1000px) and  (max-width: 1300px)": {
                ml: "9vw",
              },
              "@media (min-width: 899.5px) and  (max-width: 1000px)": {
                ml: "7vw",
              },
              "@media (min-width: 600px) and  (max-width: 899.5px)": {
                ml: "120px",
              },
              "@media (max-width: 600px)": {
                ml: "40px",
              },
              "@media (max-width: 500px)": {
                ml: "0px",
              },
            }}
          >
            <StyledImg2 src={img2} alt="logo" />
            <Typography sx={{ mt: 4, fontWeight: "600" }}>Login</Typography>
            <Typography
              sx={{
                mt: 0,
                fontSize: "11px",
                fontWeight: "400",
              }}
            >
              Welcome back! Enter your email and password below to login.
            </Typography>

            {/* emailfield */}

            <Typography sx={{ mt: 2, fontSize: "10.72px", fontWeight: "500" }}>
              Email <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="Enter your email address"
              sx={{
                borderRadius: "5.359px",
                mt: "5px",
                color: "#C6C6CE",
                bgcolor: emailValid ? "#F5F5F5" : "#FFEAE8",
                width: "100%",
                height: "40px",
                "& input": {
                  ml: "15px",
                  mt: "3px",
                },
              }}
              value={email}
              onChange={handleEmailChange}
              error={!emailValid}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {emailValid ? null : (
                      <IconButton disabled edge="end">
                        <img
                          src={ne}
                          alt="invalid-icon"
                          style={{
                            width: "18px",
                            height: "18px",
                            paddingRight: "20px",
                            paddingTop: "5px",
                          }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {!emailValid && (
              <Typography variant="caption" color="red" sx={{ ml: "230px" }}>
                Invalid email address
              </Typography>
            )}

            {/* passwordfield */}

            <Box sx={{ mt: "10px", position: "relative", right: "10px" }}>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "10.72px",
                  fontWeight: "500",
                  ml: "10px",
                }}
              >
                Password<span style={{ color: "red" }}>*</span>
              </Typography>

              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Enter your password"
                sx={{
                  "& fieldset": { border: "none" },
                  width: "100%",
                  height: "40px",
                  borderRadius: "5.359px",
                  bgcolor: passwordValid ? "#F5F5F5" : "#FFEAE8",
                  margin: 1,
                }}
                value={password}
                onChange={handlePasswordChange}
                error={!passwordValid}
              />
            </Box>

            {/* checkbox */}

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: "0px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBox}
                    sx={{
                      color: "#252525",
                      "&.Mui-checked": { color: "#EB568F" },
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                    }}
                  >
                    Remember me
                  </span>
                }
              />

              <Box sx={{ position: "fixed" }}>
                <Link
                  color="#EB568F"
                  sx={{
                    fontSize: "11px",
                    fontWeight: "500",
                    marginLeft: "227px",
                    cursor: 'pointer'
                  }}
                  onClick={() => openModal(true)}
                >
                  Forgot Your Password?
                </Link>
              </Box>
            </Box>

            {/* submitButton */}

            <Button
              variant="contained"
              onClick={handleSubmit}
              disableElevation
              sx={{
                borderRadius: "32px",
                bgcolor: "#EB568F",
                width: "100%",
                mt: 4,
                fontSize: "14px",
                textTransform: "none",
                height: "46px",
                fontWeight: "600",
                "&:hover": { bgcolor: "#EB568F" },
              }}
              disabled={!emailValid || !passwordValid}
            >
              Login
            </Button>

            <Typography
              sx={{
                mt: 2,
                marginLeft: "98px",
                fontSize: "11px",
                fontWeight: "549",
              }}
            >
              Don't have an account?
              <Link
                onClick={() => {
                  navigate("/register");
                }}
                color="#EB568F"
                sx={{ ml: 1, cursor: "pointer" }}
              >
                Register
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => openModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{
            bgcolor: '#fff',
            borderRadius: '0.5rem',
            p: '1rem',
            height: '35vh',
            width: '35vw',
            backgroundImage: `linear-gradient(to right, #EB568F, white)`
          }}>
            <Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer'
              }}
                onClick={() => openModal(false)}><IoMdClose size={25} /></Box>
            </Box>
            <Box sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Box sx={{ width: '50%', mt: '-4rem' }}>
                <img src={logoBig} alt="" style={{ maxWidth: '100%' }} />
              </Box>
              <Box sx={{
                width: '50%',
                height: '70%',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                mt: '-4rem'
              }}>
                <Typography sx={{
                  fontFamily: 'Poppins',
                  fontWeight: '600',
                  color: "#000020",
                  textAlign: 'right'
                  // border: '1px solid red'
                }}>
                  Forgot Your Password?
                </Typography>
                <TextField
                  // error
                  id="outlined-error-helper-text"
                  // helperText="Incorrect entry."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    bgcolor: '#fff',
                    borderRadius: '0.2rem'
                  }}
                />
                {/* <TextField
                  error
                  id="outlined-error-helper-text"
                  defaultValue="Hello World"
                  // helperText="Incorrect entry."
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  sx={{
                    bgcolor: '#fff'
                  }}
                /> */}
                <Button
                  sx={{
                    backgroundColor: "#EB568F",
                    fontFamily: "Poppins",
                    fontSize: "14.3px",
                    fontWeight: "600",
                    color: "#fff",
                    width: "50%",
                    borderRadius: "0.5rem",
                    height: "auto",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                    },
                  }}
                  onClick={() => { handleForgotPassword() }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={otpOpen}
        onClose={() => setOtpOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <OtpComponent email={email} handleClose={handleClose} reSend={reSend} />

      </Modal>
    </Box>
  );
}

export default Login;
