const programService = (httpClient) => {
  const getPrograms = async () => {
    try {
      const res = await httpClient.get("/programs");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch programs" };
    }
  };

  const getProgramsUnderUser = async (id) => {
    try {
      const res = await httpClient.get(`/programs/${id}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch programs for the user" };
    }
  };

  const getSubscribedPrograms = async () => {
    try {
      const res = await httpClient.get("/sub/pgm/user"); // Check and confirm the endpoint
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch subscribed programs" };
    }
  };

  const getSingleProgram = async (id) => {
    try {
      const res = await httpClient.get(`/program/${id}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch program details" };
    }
  };

  const getLibraryById = async (pid) => {
    try {
      const res = await httpClient.get(`/get/lib/${pid}`);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch library details" };
    }
  };

  return {
    getPrograms,
    getProgramsUnderUser,
    getSingleProgram,
    getSubscribedPrograms,
    getLibraryById,
  };
};

export default programService;
