export const validateEmail = (email) => {
  const checkEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  if (!checkEmail) {
    return "Invalid email id.";
  }
  return null;
};

export const validatePassword = (password) => {
  const checkPassword =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@_#$%^&*])[a-zA-Z0-9!@_#$%^&*]{8,}$/.test(
      password
    );
  if (!checkPassword) {
    return "Must contain at least one number and one uppercase and lowercase letter, one special character and at least 8 or more characters.";
  }
  return null;
};

export const validateMobile = (mobile) => {
  const checkMobile = /^\d{6,10}$/gi.test(mobile);
  if (!checkMobile) {
    return "Minimum 6-10 digits required.";
  }
  return null;
};
