import React from 'react';
import { Link, Typography, Box, Stack, Button } from '@mui/material';

import big from '../../images/bigprofile.jpg'
import premium from '../../images/premium.jpg'
import phone from '../../images/phone.jpg'
import mail from '../../images/mail.png'
import round from '../../images/round.jpg'
import tank from '../../images/tankfile.jpg'
import tank2 from '../../images/filetank2.jpg'
import event from '../../images/events.jpg'
import event1 from '../../images/eventimg1.jpg'
import event2 from '../../images/eventimg2.jpg'
import event3 from '../../images/eventimg3.jpg'
import news from '../../images/news.jpg'
import news1 from '../../images/news1.jpg'
import arrow from '../../images/arrow.jpg'
import news2 from '../../images/news2.jpg'
import news3 from '../../images/news3.jpg'
import news4 from '../../images/news4.jpg'
// import Topbar from '../../components/Topbar'
// import dash from  '../../images/changedash.png'


const Dashboard = () => {
  return (
    <div style={{ background: '#F9F9FB', minHeight: '103vh' }}>
      {/* <Topbar changedash={dash}></Topbar> */}
    

      {/* inside navbar content */}
      <Box sx={{
        position: "relative",

        '@media (min-width: 1400px) and  (max-width: 1480px)': {
          right: '60px'


        },
        '@media (min-width: 900px) and  (max-width: 1400px)': {
          left: '0px'


        },
        '@media  (max-width: 900px)': {
          right:'240px'


        },
        '@media  (max-width: 700px)': {
          right:'270px'


        },
        '@media  (max-width: 640px)': {
          right:'300px'


        },
        '@media  (max-width: 540px)': {
          right:'320px'


        },


      }}>
        <Stack direction={'row'} spacing={-6} sx={{


          '@media (max-width: 1400px)': {

            flexDirection: 'column'

          },




        }}>
          <div style={{
            marginLeft: '240px', marginTop: '60px', padding: '80px',




            '@media (min-width: 900px) and  (max-width: 1450px)': {

              fontSize: '10px'

            },




          }}>

            {/* firstdiv */}


            
            <div className='first' >

            <style jsx>{`
                      .first{

                        width:524px;
                        height:242px;
                        background-color:#fff;
                        border-radius:9px;
                      }
                        
                        @media  (max-width: 550px) {
                        .first{
                          width:474px;
                        height:252px;
                        background-color:white;
                        border-radius:9px;

                        }
                    } `}</style>




              <Stack direction={'row'} >
                <Box>
                  <img src={big} alt='profilepic' width={'173px'} height={'200px'} style={{ padding: '20px' }}></img>
                </Box>
                <Box padding={'25px'}>
                  <img src={premium} alt='premiumlogo' style={{ width: '80px', height: '23' }}></img>
                  <Typography variant='h6' sx={{ fontSize: '26px', color: '#4A4A4A' }}>Charlotte</Typography>
                  <Typography variant='body2' sx={{ fontSize: '10px', color: '#232323', mt: '3px' }}>Joined Date: 20/02/2023   <span style={{ marginLeft: '25px' }}>Status: Consultant</span></Typography>
                  <Typography variant='body2' sx={{ fontSize: '10px', color: '#232323', mt: '10px' }}>Hospital Name: Gracefield Medical Center</Typography>
                  <Stack direction={'row'}>
                    <Typography variant='body2' >
                      <img src={phone} alt='phoneicon' style={{ width: '13px', height: '15px', marginTop: '14px' }} />
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: '10px', color: '#232323', mt: '14px', marginLeft: '4px' }}>+91 8078199418</Typography>
                    <Typography variant='body2' >
                      <img src={mail} alt='phoneicon' style={{ width: '16px', height: '16px', marginTop: '11px', marginLeft: '14px' }} /> </Typography>
                    <Typography variant='body2' sx={{ fontSize: '10px', color: '#232323', mt: '13px', marginLeft: '8px' }}>sales@studymedic.com</Typography>
                  </Stack>
                  <Button variant='outlined' sx={{ borderColor: '#EB568F', width: '131.235px', height: '34px', color: '#EB568F', fontSize: '9px', fontWeight: '700', lineHeight: '12px', borderRadius: '20px', mt: '13px' }}>View full profile </Button>
                </Box>
              </Stack>
            </div>
          </div>



          {/* 2ndiv totalsize  */}

          <Stack sx={{
            '@media (max-width: 1400px)': {

              flexDirection: 'row'

            },

          }}>

            <Box sx={{

              '@media (max-width: 1400px)': {

                position: 'relative', left: '368px', bottom: '200px',

              },







            }}>
              <div style={{ marginRight: '50px', marginTop: '140px', width: '302px', height: '241px', background: '#fff', borderRadius: '9px' }}>
                <Stack direction={'row'}>
                  <Box>
                    <Typography variant='h6' sx={{ fontSize: '14.14px', fontWeight: '600', padding: '15px', color: '#252525', lineHeight: '11.467px' }}>Total Size Used</Typography>
                    <img src={round} alt='storage' style={{ width: '134px', height: '134px', padding: '10px' }}>
                    </img>
                    <Typography sx={{ position: 'relative', bottom: '100px', left: '55px', width: '57px', height: '20px', fontWeight: '600', color: '#525252', fontSize: '28.29px' }}>20%
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: '500', lineHeight: '12.97px', fontSize: "8.64px", padding: '20px', position: 'relative', bottom: '30px', color: '#525252' }}>*For Free users, a content limit of 20 GB</Typography>
                  </Box>
                  <Box>
                    <div style={{ width: '15.72px', height: '15.72px', backgroundColor: '#EB568F', marginTop: '130px', position: 'relative', right: '31px' }}><Typography sx={{ marginLeft: '23px', position: 'relative', top: '3px', fontWeight: '500', fontSize: '8.64px', lineHeight: '12.97px' }}>storage<span style={{ marginLeft: '3px' }}>used</span> </Typography></div>
                    <div style={{ width: '15.72px', height: '15.72px', backgroundColor: '#D8D6D6', marginTop: '10px', position: 'relative', right: '31px' }}><Typography sx={{ marginLeft: '23px', position: 'relative', top: '3px', fontWeight: '500', fontSize: '8.64px', lineHeight: '12.97px' }}>storage<span style={{ marginLeft: '3px' }}>Free</span><span style={{ marginLeft: '3px' }}>space</span></Typography></div>
                  </Box>
                </Stack>
              </div>
            </Box>

            {/* 3rd div create file */}

          
               <Box
      sx={{
        marginLeft: '-27px',
        marginTop: '140px',
        width: '204px',
        height: '241px',
        background: '#fff',
        borderRadius: '9px',
        '@media (max-width: 1400px)': {
          position: 'relative', bottom: '200px', left: '361px'


        },
        '@media (min-width: 1400px)': {
          position: 'relative',
           bottom: '380px', left: '350px'


        },
        '@media (max-width: 550px)': {
     width:'153px',
   


        },
      }}
    >
                <Box padding={'40px'}>
                  <img src={tank} alt='fileimage' style={{ width: '61.814px', height: '54.127px' }}></img>
                  <Typography
                    sx={{ fontSize: '18.04px', fontWeight: '600', lineHeight: '14.645px', mt: '10px' }}>Created Files</Typography>
                  <Stack direction={'row'}><img src={tank2} alt='fileimage' style={{ width: '20px', height: '24px', marginTop: '10px' }}></img><Typography sx={{ color: '#525252', fontSize: '12.696px', mt: '16px', fontWeight: '500' }}>23 Files</Typography></Stack>
                  <Link href='#' sx={{ color: '#EB568F', fontSize: '12.043px', fontWeight: '500', lineHeight: '14.645px', position: 'relative', top: '20px', textDecoration: 'underline #EB568F' }}
                  >View files</Link>
                </Box>
             </Box>
          </Stack></Stack>


        {/* starting big div */}

        {/* events */}

        <Stack direction={'row'} spacing={4} sx={{

          '@media (max-width: 1400px)': {

            flexDirection: 'column'

          }



        }}>

          <Box sx={{
            '@media (max-width: 1400px)': {

              position:'relative',
              bottom:'100px'

            },
            '@media (min-width: 1400px)': {

              position:'relative',
              bottom:'300px'

            }
          }}>

            <div  className='event'>
            <style jsx>{`
                        .event {
                          width: 485px;
                          height: 500px;
                          background: #fff;
                          border-radius: 9px;
                          margin-left: 320px;
                          padding: 20px;
                          position: relative;
                          bottom: 60px;
                        }
                        @media  (max-width: 550px) {
                          .event{
                            width:440px;
                     
                      
  
                          }
                      } `}</style>

              <Stack direction={'row'}>
                <img src={event} alt='eventlogo' style={{ width: '24px', height: '24px' }}></img>
                <Typography sx={{ color: '#2C2B83', fontSize: '16px', fontWeight: '500', marginLeft: '10px' }}>Events</Typography>
              </Stack>


              <Stack direction={'row'} marginTop={'15px'} spacing={1}>
                <Button sx={{ display: 'flex', width: '140.963px', height: '37.481px', justifyContent: 'center', alignItems: ' center', borderRadius: '40.741px', background: '#E5E4FF', color: '#2C2B83', fontSize: '11.407px', fontWeight: '600' }}>Free Sessions</Button>
                <Button sx={{ display: 'flex', width: '140.963px', height: '37.481px', justifyContent: 'center', alignItems: ' center', borderRadius: '40.741px', background: '#F4F4F4', color: '#434343', fontSize: '11.407px', fontWeight: '600' }}>Free Mocks</Button>
              </Stack>

              <Box sx={{ width: '475px', border: '0.725px solid #D7D7D7', borderRadius: '3.626px', height: '118.931px', mt: '20px',
            
            '@media (max-width: 550px)': {

              width:'450px'
  
            }
  
            
            
            
            
            
            }}>
                <Stack direction={'row'} >
                  <img src={event1} alt='eventimg' style={{ width: '182.748px', height: ' 119.931px' }}></img>
                  <Box padding={'17px'}>
                    <Stack direction={'row'}>
                      <Typography sx={{ fontSize: '10px', fontWeight: '400', color: '#525252' }}>Medical</Typography>
                      <Typography sx={{ fontSize: '10px', fontWeight: '400', color: '#525252', marginLeft: '152px', marginTop: '-23px' }}><span style={{ fontSize: '30px', marginRight: '5px' }}>.</span> jan 12, 2023</Typography>
                    </Stack>
                    <Typography sx={{ fontSize: '11.603px', fontWeight: '400', marginBottom: '50px', color: "#252525" }}>Lorem ipsum dolor sit amet,<br /> consectetur adipiscing</Typography>
                    <Link href='#' sx={{ color: '#EB568F', fontSize: '11px', fontWeight: '500', position: 'relative', bottom: '41px', textDecoration: 'underline #EB568F' }}
                    >Book Now</Link>
                  </Box>
                </Stack>
              </Box>


              <Box sx={{ width: '475px', border: '0.725px solid #D7D7D7', borderRadius: '3.626px', height: '118.931px', mt: '20px',
            
            '@media (max-width: 550px)': {

              width:'450px'
  
            }
            
            
            
            }}>
                <Stack direction={'row'} >
                  <img src={event2} alt='eventimg' style={{ width: '182.748px', height: ' 119.931px' }}></img>
                  <Box padding={'17px'}>
                    <Stack direction={'row'}>
                      <Typography sx={{ fontSize: '10px', fontWeight: '400', color: '#525252' }}>Medical</Typography>
                      <Typography sx={{ fontSize: '10px', fontWeight: '400', color: '#525252', marginLeft: '152px', marginTop: '-23px' }}><span style={{ fontSize: '30px', marginRight: '5px' }}>.</span> jan 12, 2023</Typography>
                    </Stack>
                    <Typography sx={{ fontSize: '11.603px', fontWeight: '400', marginBottom: '50px', color: "#252525" }}>Lorem ipsum dolor sit amet,<br /> consectetur adipiscing</Typography>
                    <Link href='#' sx={{ color: '#EB568F', fontSize: '11px', fontWeight: '500', position: 'relative', bottom: '41px', textDecoration: 'underline #EB568F' }}
                    >Book Now</Link>
                  </Box>
                </Stack>
              </Box>



              <Box sx={{ width: '475px', border: '0.725px solid #D7D7D7', borderRadius: '3.626px', height: '118.931px', mt: '20px' ,
            
            '@media (max-width: 550px)': {

              width:'450px'
  
            }
            
            
            
            
            
            
            }}>
                <Stack direction={'row'} >
                  <img src={event3} alt='eventimg' style={{ width: '182.748px', height: ' 119.931px' }}></img>
                  <Box padding={'17px'}>
                    <Stack direction={'row'}>
                      <Typography sx={{ fontSize: '10px', fontWeight: '400', color: '#525252' }}>Medical</Typography>
                      <Typography sx={{ fontSize: '10px', fontWeight: '400', color: '#525252', marginLeft: '152px', marginTop: '-23px' }}><span style={{ fontSize: '30px', marginRight: '5px' }}>.</span> jan 12, 2023</Typography>
                    </Stack>
                    <Typography sx={{ fontSize: '11.603px', fontWeight: '400', marginBottom: '50px', color: "#252525" }}>Lorem ipsum dolor sit amet,<br /> consectetur adipiscing</Typography>
                    <Link href='#' sx={{ color: '#EB568F', fontSize: '11px', fontWeight: '500', position: 'relative', bottom: '41px', textDecoration: 'underline #EB568F' }}
                    >Book Now</Link>
                  </Box>
                </Stack>
              </Box>
            </div>

          </Box>




          {/* news last div */}


          <Box
      sx={{
        width: '485px',
        height: '500px',
        background: '#fff',
        borderRadius: '9px',
        padding: '20px',
        position: 'relative',
        bottom: '58px',
        '@media (max-width: 1400px)': {

          left:'285px',
          bottom:'110px'

        },
        '@media (min-width: 1400px)': {

          position:'relative',
          bottom:'360px'

        },
        '@media (max-width: 550px)': {

         
          width:'438px'

        }
      }}
    >


            <Stack direction={'row'}>
              <img src={news} alt='newslogo' style={{ width: '20px', height: '24px' }} ></img>
              <Typography sx={{ color: '#2C2B83', fontSize: '16px', fontWeight: '500', marginLeft: '10px' }}>News</Typography>
            </Stack>

            <Stack direction={'row'} marginTop={3}>
              <img src={news1} alt='newslogo' style={{ width: '90.159px', height: '65.41px' }} ></img>
              <Typography sx={{ fontSize: '12.37px', fontWeight: '500', marginLeft: '10px', lineHeight: '18.56px' }}>People with Alzheimer's disease more likely to develop epilepsy and vice versa</Typography>
            </Stack>


            <Stack direction={'row'}>
              <Typography sx={{ fontSize: '8.839px', fontWeight: '400', color: '#525252', position: 'relative', left: '100px', bottom: '10px' }}> jan 12, 2023</Typography>
              <img src={arrow} alt='arrouwupimage' className='arrow'></img>
              <style jsx>{`
                                    
                          .arrow {
                            width: 21.214px;
                            height: 21.214px;
                            position: relative;
                            left: 412px;
                            bottom: 22px;
                          }

                    @media  (max-width: 550px) {
                        .arrow{
                          left:372px;

                        }
                    }
                    `}</style>

            </Stack>
            <hr />

            <Stack direction={'row'} marginTop={3}>
              <img src={news2} alt='newslogo' style={{ width: '90.159px', height: '65.41px' }} ></img>
              <Typography sx={{ fontSize: '12.37px', fontWeight: '500', marginLeft: '10px', lineHeight: '18.56px' }}>People with Alzheimer's disease more likely to develop epilepsy and vice versa</Typography>
            </Stack>


            <Stack direction={'row'}>
              <Typography sx={{ fontSize: '8.839px', fontWeight: '400', color: '#525252', position: 'relative', left: '100px', bottom: '10px' }}> jan 12, 2023</Typography>
              <img src={arrow} alt='arrouwupimage' className='arrow'></img>

            </Stack>
            <hr />


            <Stack direction={'row'} marginTop={3}>
              <img src={news3} alt='newslogo' style={{ width: '90.159px', height: '65.41px' }} ></img>
              <Typography sx={{ fontSize: '12.37px', fontWeight: '500', marginLeft: '10px', lineHeight: '18.56px' }}>People with Alzheimer's disease more likely to develop epilepsy and vice versa</Typography>
            </Stack>


            <Stack direction={'row'}>
              <Typography sx={{ fontSize: '8.839px', fontWeight: '400', color: '#525252', position: 'relative', left: '100px', bottom: '10px' }}> jan 12, 2023</Typography>
              <img src={arrow} alt='arrouwupimage' className='arrow'></img>

            </Stack>
            <hr />


            <Stack direction={'row'} marginTop={3}>
              <img src={news4} alt='newslogo' style={{ width: '90.159px', height: '65.41px' }} ></img>
              <Typography sx={{ fontSize: '12.37px', fontWeight: '500', marginLeft: '10px', lineHeight: '18.56px' }}>People with Alzheimer's disease more likely to develop epilepsy and vice versa</Typography>
            </Stack>


            <Stack direction={'row'}>
              <Typography sx={{ fontSize: '8.839px', fontWeight: '400', color: '#525252', position: 'relative', left: '100px', bottom: '10px' }}> jan 12, 2023</Typography>
              <img src={arrow} alt='arrouwupimage' className='arrow'></img>

            </Stack>






          </Box>
        </Stack></Box>
        <style>
                {`
          body {
            margin: 0;
            /* Add any other body-specific styles here */
          }
        `}
            </style>
    </div>
  );
};

export default Dashboard;

