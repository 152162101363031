import React, { useEffect } from "react";
// import NavBar from './NavBar'
import {
  Button,
  Typography,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  Divider
} from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import arrowup from "../../images/arrowup.png";
import round2 from "../../images/rounds2.png";
import solutions1 from "../../images/solutions1.png";
import solutions2 from "../../images/solutions2.png";
import plus from "../../images/solPlusIcon.svg";
import plan from "../../images/planImage.png";
import tick from "../../images/tickIcon.svg";
import cross from "../../images/crossIcon.svg";
import solutions4 from "../../images/solutions4.png";
import solutions5 from "../../images/solutions5.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
// import Footer from './Footer';
import { useNavigate } from "react-router-dom";


// const theme = createTheme({
//   typography: {
//     fontFamily: "Poppins, sans-serif", // Set the desired font family here
//   },
// });

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#EB568F',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function Solutions() {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [checked, setChecked] = useState(false);

  // const handleChange = () => {
  //   setChecked((prev) => !prev);
  // };


  return (
    <>
      <Box>
        {/* LANDING */}
        <Grid
          container
          sx={{
            mt: "3.6%",
            height: {
              xs: "auto",
              sm: "auto",
              md: "93vh",
            },
            width: "100vw",
            backgroundColor: "#F5F7FC",
            position: "relative",
            // border :' 1px solid red'
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: {
                xs: "4rem 1.5rem 4rem 1.5rem",
                sm: "1.5rem",
              },
            }}
          >
            <Box
              sx={
                {

                  justifyContent: 'center',
                  textAlign: 'center'
                }
              }
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "36px",
                  color: "#13112D",
                  // border: '1px solid red',
                }}
              >
                {" "}
                StudyMEDIC
                <br />
                {/* Education with{" "} */}
                <span style={{ color: "#EB568F" }}>E-Portfolio</span>
              </Typography>
              <Typography
                sx={{
                  fontWeight: "normal",
                  fontSize: "13px",
                  color: "#000000",
                  letterSpacing: "1px",

                }}
              >
                {/* {" "}
                Join us and embark on a transformative journey towards success,
                <br />
                collaboration, and endless possibilities in the medical world. */}
                Transform Your Medical Tale with StudyMEDIC E-Portfolio!
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  mt: "2rem",
                  textAlign: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#EB568F",
                    borderRadius: "1.5rem",
                    height: "3rem",
                    fontSize: "13.31px",
                    color: "#FFF",
                    mr: "1rem",
                    padding: "0.8rem 1.2rem 0.8rem 1.2rem",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                    },
                  }}
                  onClick={() => { navigate('/clinical?join_programs=true') }}
                >
                  Join Clinical Programs
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#fff",
                    borderRadius: "1.5rem",
                    height: "3rem",
                    fontSize: "13.31px",
                    color: "#EB568F",
                    padding: "0.8rem 1.2rem 0.8rem 1.2rem",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "#2C2B83",
                      color: "#fff",
                    },
                  }}
                  onClick={() => { navigate('/clinical/') }}
                >
                  Learn More
                  <span>
                    <img
                      src={arrowup}
                      alt=""
                      style={{
                        maxHeight: "1.5rem",
                        transform: "translateY(0.2rem)",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </span>
                </Button>
              </Box>

              <Typography
                sx={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  color: "#000000",
                  mt: "2rem",

                  width: {
                    xs: "100%",
                    sm: "100%",
                    lg: "100%",
                  },
                  // letterSpacing: '1px'
                }}
              >
                {/* {" "}
                Optimize educational management, drive institutional growth, and
                transform the way students excel in the medical field. */}
                Join with us now and take the first step towards building an E-Portfolio that opens doors to new opportunities!
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5.5}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                // border : '1px solid blue',
                background: `url(${solutions1})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            ></Box>
          </Grid>

          <Box
            sx={{
              display: "flex",
              position: "absolute",
              // border: '1px solid red',
              width: "6rem",
              bottom: {
                xs: '1rem',
                sm: '1rem',
                md: '4rem',
              },
              right: {
                xs: '5%',
                sm: '5%',
                md: '50%',
              }
            }}
          >
            <img
              src={round2}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>

        <Box
          sx={{
            // CONTAINER
            // border : '1px solid red',
            overflow: "hidden",
            width: "100%",
            padding: "5% 14% 5% 14%",
          }}
        >
          {/* SECTION 2 */}
          <Box
            sx={
              {
                // border : '1px solid blue'
              }
            }
          >
            <Box>
              <Typography
                sx={{
                  color: "#EB568F",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Benefit from StudyMEDIC E Portfolio
              </Typography>
            </Box>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: 'center',
                  flexDirection: "column",
                  // border: '1px solid blue'
                }}
              >
                <Typography
                  sx={{
                    color: "#13112D",
                    fontSize: "36px",
                    fontWeight: "700",
                    lineHeight: "48px",
                  }}
                >
                  {/* Students Benefit from <br />
                  StudyMEDIC E Portfolio */}
                  How StudyMEDIC E-Portfolio <br /> Benefits You?
                </Typography>
                <Box
                  sx={{
                    mt: "1rem",
                    // border :'1px solid red',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: {
                      xs: "translateX(0)",
                      sm: "translateX(0)",
                      md: "translateX(-2.4rem)",
                    },
                    width: "100%",
                    // height: '35rem'
                  }}
                >
                  <img
                    src={solutions2}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4.5}
                sx={{
                  // border: '1px solid blue',
                  display: "flex",
                  justifyContent: {
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                  alignItems: "center",
                  flexDirection: "column",
                  mt: {
                    xs: "1.5rem",
                    sm: "1.5rem",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {/* StudyMEDIC E Portfolio is a comprehensive platform designed to
                  enhance the learning and professional development experience
                  for students in the medical field. By providing a robust set
                  of features and tools, StudyMEDIC empowers students to manage
                  their educational journey, showcase their achievements, and
                  accelerate their career growth. */}
                  At StudyMEDIC, we provide a dedicated space for trainees to log all evidence of their experiences, competencies, and courses throughout their entire medical journey. This comprehensive platform enhances the learning and professional development of aspirants by building a customized E-Portfolio that reflects their unique identity, aspirations, and accomplishments. By offering a robust set of features and tools, StudyMEDIC empowers students to showcase their achievements, and accelerate their career growth.
                </Typography>

                <Box
                  sx={{
                    mt: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      mt: "0.3rem",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        mr: "1rem",
                      }}
                    >
                      <img src={plus} alt="" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          // color: "#000000",
                          fontSize: "15.6px",
                          fontWeight: "600",
                          color: "#090914",
                        }}
                      >
                        Empower Your Medical Journey:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                          mt: "0.2rem",
                        }}
                      >
                        {/* StudyMEDIC E Portfolio equips you with a powerful
                        platform to manage your learning, track your progress,
                        and showcase your achievements. Take control of your
                        medical journey like never before!{" "} */}
                        StudyMEDIC E-Portfolio equips you with a powerful platform to manage your learning, track your progress, and showcase your achievements. We will help you to take control of your medical journey like never before!
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      mt: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        mr: "1rem",
                      }}
                    >
                      <img src={plus} alt="" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          // color: "#000000",
                          fontSize: "15.6px",
                          fontWeight: "600",
                          color: "#090914",
                        }}
                      >
                        Streamlined Learning Experience:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                          mt: "0.2rem",
                        }}
                      >
                        {/* Access all your learning materials, assessments, and
                        resources in one centralized platform. Stay organized,
                        save time, and focus on what matters most: mastering
                        your medical education. */}
                        Access all your learning materials, assessments, and resources in one centralized platform. Stay organized, save time, and focus on what matters most, helping you to stand out among others.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      mt: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        mr: "1rem",
                      }}
                    >
                      <img src={plus} alt="" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          // color: "#000000",
                          fontSize: "15.6px",
                          fontWeight: "600",
                          color: "#090914",
                        }}
                      >
                        Personalized Showcase:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                          mt: "0.2rem",
                        }}
                      >
                        {/* Build a personalized E Portfolio that reflects your
                        unique skills, experiences, and accomplishments. Stand
                        out to future employers or residency programs with a
                        comprehensive showcase that highlights your potential.{" "} */}
                        StudyMEDIC's E portfolio serves as a personalized learning space, where students can document their journey, demonstrate their competencies, and enhance their overall learning experience. It  reflects your unique skills, experiences, and accomplishments and fosters a culture of continuous improvement and serves as a powerful tool for self-assessment and professional growth, making it a valuable asset in their medical education journey.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      mt: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        mr: "1rem",
                      }}
                    >
                      <img src={plus} alt="" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          // color: "#000000",
                          fontSize: "15.6px",
                          fontWeight: "600",
                          color: "#090914",
                        }}
                      >
                        Collaboration and Networking:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                          mt: "0.2rem",
                        }}
                      >
                        {/* Connect with peers, mentors, and faculty members. Engage
                        in discussions, share knowledge, and grow together.
                        Expand your professional network and open doors to
                        exciting opportunities. */}
                        Aspirants get an opportunity to connect with peers, mentors, and faculty members and   Engage in discussions thereby sharing knowledge, and growing  together. Expand your professional network and open doors to exciting opportunities.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      mt: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        mr: "1rem",
                      }}
                    >
                      <img src={plus} alt="" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          // color: "#000000",
                          fontSize: "15.6px",
                          fontWeight: "600",
                          color: "#090914",
                        }}
                      >
                        Meaningful Feedback and Assessment:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                          mt: "0.2rem",
                        }}
                      >
                        {/* Receive valuable feedback from mentors and supervisors
                        to enhance your skills and competencies. Leverage
                        formative and summative assessments to track your
                        progress and identify areas for improvement. */}
                        Receive valuable feedback from mentors and supervisors that can help you with your skills and competencies. Well-crafted assessments will help you to track your progress and identify areas for improvement.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* SECTION 3 */}
        <Box
          sx={{
            // height: "100vh",
            width: "100%",
            position: "relative",
            // backgroundColor: '#F2F5FF'
            background: `linear-gradient(to bottom, #F2F5FF 0%, #F2F5FF 50%, #ffffff 50%, #ffffff 100%)`,
          }}
        >
          <Box
            sx={{
              //CONTAINER
              overflow: "hidden",
              width: "100%",
              padding: {
                xs: "15% 14% 15% 14%",
                sm: "15% 14% 15% 14%",
                md: "5% 14% 5% 14%",

              },

            }}
          >
            <Box
              sx={
                {
                  // border : '1px solid blue'
                }
              }
            >
              <Box>
                <Typography
                  sx={{
                    color: "#EB568F",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Benefit from StudyMEDIC E Portfolio
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // border: '1px solid blue'
                  }}
                >
                  <Typography
                    sx={{
                      color: "#13112D",
                      fontSize: "36px",
                      fontWeight: "700",
                      lineHeight: "48px",
                    }}
                  >
                    Benefits from <br />
                    StudyMEDIC E Portfolio
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4.5}
                  sx={
                    {
                      // border: '1px solid blue',
                      // display: 'flex',
                      // justifyContent: {
                      //   md: 'flex-start',
                      //   sm: 'center',
                      //   xs: 'center'
                      // },
                      // alignItems: 'flex-start',
                      // flexDirection: 'column',
                      transform: {
                        md: 'translateX(-1.4rem)'
                      }
                    }
                  }
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "13px",
                      fontWeight: "normal",
                    }}
                  >
                    By providing a robust set of features and tools, StudyMEDIC
                    empowers students to manage their educational journey,
                    showcase their achievements, and accelerate their career
                    growth.{" "}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  mt: "3rem",
                  justifyContent: "space-between",
                  // border: '1px solid red',
                  mb: '3rem',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5.5}
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "1.5rem",
                    boxShadow: "10px 10px 30px 0 rgba(17, 38, 146, 0.05)",
                    // border: '1px solid blue',
                    height: {
                      sm: '60vh',
                      xs: '60vh',
                      md: '50vh',
                    },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      // border: "1px solid red",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      pt: {
                        xs: '1.5rem',
                        sm: '1.5rem'
                      },
                      pb: {
                        xs: '1.5rem',
                        sm: '1.5rem'
                      },
                      height: {
                        xs: '50%',
                        sm: '50%',
                        md: '30%',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "80%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#090914",
                          fontSize: "15.6px",
                          fontWeight: "600",
                        }}
                      >
                        Enhanced Learning Management:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                        }}
                      >
                        Optimize educational workflows with a centralized
                        platform. Streamline content delivery, assessment
                        tracking, and progress monitoring for a seamless
                        learning experience.
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      height: "70%",
                      background: `url(${solutions4})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5.5}
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "1.5rem",
                    boxShadow: "10px 10px 30px 0 rgba(17, 38, 146, 0.05)",
                    // border: '1px solid blue',
                    mt: {
                      sm: '1.5rem',
                      xs: '1.5rem',
                      md: '0',
                    },
                    height: {
                      sm: '60vh',
                      xs: '60vh',
                      md: '50vh',
                    },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      // border: "1px solid red",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      pt: {
                        xs: '1.5rem',
                        sm: '1.5rem'
                      },
                      pb: {
                        xs: '1.5rem',
                        sm: '1.5rem'
                      },
                      height: {
                        xs: '50%',
                        sm: '50%',
                        md: '30%',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "80%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#090914",
                          fontSize: "15.6px",
                          fontWeight: "600",
                        }}
                      >
                        Institutional Branding:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#252525",
                          fontSize: "12px",
                          fontWeight: "normal",
                        }}
                      >
                        Showcase your college's excellence by empowering
                        students with a cutting-edge E Portfolio solution.
                        Highlight the achievements of your students and
                        demonstrate the quality of education provided by your
                        institution.
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      height: "70%",
                      background: `url(${solutions5})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </Grid>

              </Grid>

              <Divider sx={{ border: '1px solid #D9D9D9', mb: { xs: '2rem', sm: '2rem', md: '3rem' } }} />

              <Grid container sx={{
                justifyContent: 'space-between'
              }}>
                <Grid item xs={12} sm={12} md={2.5}>
                  <Typography sx={{
                    color: "#13112D",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}>
                    Elevate the medical education experience for students and colleges alike with StudyMEDIC
                    E Portfolio.
                  </Typography>

                </Grid>

                <Grid item xs={12} sm={12} md={3.4} sx={{ mt: { xs: '1.5rem', sm: '1.5rem', md: '0' } }}>
                  <Typography sx={{
                    color: "#090914",
                    fontSize: "15.6px",
                    fontWeight: "600",
                  }}>
                    Career Services Support:
                  </Typography>
                  <Typography sx={{
                    color: "#252525",
                    fontSize: "12px",
                    fontWeight: "normal",
                    wordSpacing: '0.35rem'
                  }}>
                    Showcase your college's excellence by empowering students with a cutting-edge E Portfolio solution. Highlight the achievements of your students and demonstrate the quality of education provided by your institution.
                  </Typography>

                </Grid>

                <Grid item xs={12} sm={12} md={3.4} sx={{ mt: { xs: '1.5rem', sm: '1.5rem', md: '0' } }}>
                  <Typography sx={{
                    color: "#090914",
                    fontSize: "15.6px",
                    fontWeight: "600",
                  }}>
                    Faculty Collaboration:
                  </Typography>
                  <Typography sx={{
                    color: "#252525",
                    fontSize: "12px",
                    fontWeight: "normal",
                    wordSpacing: '0.35rem'
                  }}>
                    Showcase your college's excellence by empowering students with a cutting-edge E Portfolio solution. Highlight the achievements of your students and demonstrate the quality of education provided by your institution.
                  </Typography>

                </Grid>

              </Grid>


            </Box>
          </Box>
        </Box>

        {/* SECTION 4 */}
        <Box
          sx={{
            // height: "100vh",
            width: "100%",
            position: "relative",
            backgroundColor: '#F2F5FF'
          }}
        >
          <Box
            sx={{
              //CONTAINER
              overflow: "hidden",
              width: "100%",
              padding: {
                xs: "15% 14% 15% 14%",
                sm: "15% 14% 15% 14%",
                md: "5% 14% 5% 14%",

              },

            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}>
                <Typography sx={{
                  fontSize: '40px',
                  color: '#191D23',
                  fontWeight: '700'
                }}>
                  Purchase Plans, Compare &
                </Typography>
                <Typography sx={{
                  fontSize: '40px',
                  color: '#EB568F',
                  fontWeight: '700'
                }}>
                  Enjoy Exclusive Benefits!
                </Typography>
                <Typography sx={{
                  fontSize: '20px',
                  color: '#191D23',
                  fontWeight: 'normal'
                }}>
                  Choose a plan that’s right for you
                </Typography>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '50%',
                  // border: '1px solid red',
                  mt: '4rem',
                }}>
                  <Typography sx={{
                    fontSize: '16px',
                    color: '#191D23',
                    fontWeight: 'normal'
                  }}>Pay Monthly</Typography>
                  <FormGroup >
                    <FormControlLabel
                      sx={{ transform: 'translateX(1rem)' }}
                      control={<IOSSwitch />}
                    />
                  </FormGroup>
                  <Typography sx={{
                    fontSize: '16px',
                    color: '#191D23',
                    fontWeight: 'normal'
                  }}>Pay Yearly</Typography>
                </Box>
                <Box sx={{
                  position: 'absolute',
                  right: { xs: '-3rem', sm: '-2rem', md: '-4rem' },
                  bottom: {
                    md: '-4rem',
                    xs: '-3rem'
                  },
                  width: {
                    md: '13rem',
                    sm: '8rem',
                    xs: '8rem'
                  }
                }}>
                  <img src={plan} alt="" style={{ width: '100%', height: '100%' }} />
                </Box>
              </Box>

              <Grid container sx={{
                justifyContent: 'space-between',
                mt: '5rem'
              }}>

                <Grid item xs={12} sm={12} md={3.6} sx={{
                  borderRadius: '0.5rem',
                  backgroundColor: '#ffffff',
                  padding: '2.5rem 1.5rem 2.5rem 1.5rem',
                  transition: 'background-color 0.3s',
                  mt: {
                    xs: '1.5rem',
                    sm: '1.5rem',
                    md: '0',
                  },
                  '&:hover': {
                    backgroundColor: '#EB568F',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                    '& .MuiButton-root': {
                      backgroundColor: '#ffffff', // Change button background to white
                      color: '#EB568F', // Change button text color to white
                    },
                  },


                }}>
                  <Box sx={{
                    // border :'1px solid red'
                  }}>
                    <Typography sx={{
                      fontSize: '22px',
                      fontWeight: '700',
                      color: '#191D23'
                    }}>Freebies</Typography>
                    <Typography sx={{
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#64748B'
                    }}>Ideal for individuals who need quick access to basic features.</Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem' }}>
                      <Typography sx={{
                        fontSize: '56px',
                        fontWeight: 'bold',
                        color: '#191D23',
                        fontFamily: 'Manrope',
                        mr: '0.5rem',
                      }}>$0</Typography>
                      <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 'normal',
                        color: '#64748B'
                      }}>/Month</Typography>

                    </Box>

                    <Box sx={{
                      mt: '1rem'
                    }}>
                      <Button fullWidth sx={{
                        textTransform: 'none',
                        border: '1px solid #EB568F',
                        color: '#EB568F',
                        fontSize: '16px',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        '&:hover': {
                          backgroundColor: '#EB568F',
                          color: '#ffffff'
                        }
                      }}>
                        Get Started Now
                      </Button>
                    </Box>

                    <Box sx={{
                      mt: '2rem'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>20,000+ of PNG & SVG graphics</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Access to 100 million stock images</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Upload custom icons and fonts</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Unlimited Sharing</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Upload graphics & video in up to 4k</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Unlimited Projects</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Instant Access to our design system</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Create teams to collaborate on designs</Typography>
                      </Box>



                    </Box>



                  </Box>



                </Grid>

                <Grid item xs={12} sm={12} md={3.6} sx={{
                  borderRadius: '0.5rem',
                  backgroundColor: '#ffffff',
                  padding: '2.5rem 1.5rem 2.5rem 1.5rem',
                  transition: 'background-color 0.3s',
                  mt: {
                    xs: '1.5rem',
                    sm: '1.5rem',
                    md: '0',
                  },
                  '&:hover': {
                    backgroundColor: '#EB568F',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                    '& .MuiButton-root': {
                      backgroundColor: '#ffffff', // Change button background to white
                      color: '#EB568F', // Change button text color to white
                    },
                  },


                }}>
                  <Box sx={{
                    // border :'1px solid red'
                  }}>
                    <Typography sx={{
                      fontSize: '22px',
                      fontWeight: '700',
                      color: '#191D23'
                    }}>Professional</Typography>
                    <Typography sx={{
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#64748B'
                    }}>Ideal for individuals who who need advanced features and tools for client work.</Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem' }}>
                      <Typography sx={{
                        fontSize: '56px',
                        fontWeight: 'bold',
                        color: '#191D23',
                        fontFamily: 'Manrope',
                        mr: '0.5rem',
                      }}>$25</Typography>
                      <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 'normal',
                        color: '#64748B'
                      }}>/Month</Typography>

                    </Box>

                    <Box sx={{
                      mt: '1rem'
                    }}>
                      <Button fullWidth sx={{
                        textTransform: 'none',
                        border: '1px solid #EB568F',
                        color: '#EB568F',
                        fontSize: '16px',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        '&:hover': {
                          backgroundColor: '#EB568F',
                          color: '#ffffff'
                        }
                      }}>
                        Get Started Now
                      </Button>
                    </Box>

                    <Box sx={{
                      mt: '2rem'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>20,000+ of PNG & SVG graphics</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Access to 100 million stock images</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Upload custom icons and fonts</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Unlimited Sharing</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Upload graphics & video in up to 4k</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Unlimited Projects</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Instant Access to our design system</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={cross} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#A0ABBB',
                          ml: '1rem',
                        }}>Create teams to collaborate on designs</Typography>
                      </Box>



                    </Box>



                  </Box>



                </Grid>

                <Grid item xs={12} sm={12} md={3.6} sx={{
                  borderRadius: '0.5rem',
                  backgroundColor: '#ffffff',
                  padding: '2.5rem 1.5rem 2.5rem 1.5rem',
                  transition: 'background-color 0.3s',
                  mt: {
                    xs: '1.5rem',
                    sm: '1.5rem',
                    md: '0',
                  },
                  '&:hover': {
                    backgroundColor: '#EB568F',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                    '& .MuiButton-root': {
                      backgroundColor: '#ffffff', // Change button background to white
                      color: '#EB568F', // Change button text color to white
                    },
                  },


                }}>
                  <Box sx={{
                    // border :'1px solid red'
                  }}>
                    <Typography sx={{
                      fontSize: '22px',
                      fontWeight: '700',
                      color: '#191D23'
                    }}>Enterprise</Typography>
                    <Typography sx={{
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#64748B'
                    }}>Ideal for businesses who need personalized services and security for large teams. </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem' }}>
                      <Typography sx={{
                        fontSize: '56px',
                        fontWeight: 'bold',
                        color: '#191D23',
                        fontFamily: 'Manrope',
                        mr: '0.5rem',
                      }}>$100</Typography>
                      <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 'normal',
                        color: '#64748B'
                      }}>/Month</Typography>

                    </Box>

                    <Box sx={{
                      mt: '1rem'
                    }}>
                      <Button fullWidth sx={{
                        textTransform: 'none',
                        border: '1px solid #EB568F',
                        color: '#EB568F',
                        fontSize: '16px',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        '&:hover': {
                          backgroundColor: '#EB568F',
                          color: '#ffffff'
                        }
                      }}>
                        Get Started Now
                      </Button>
                    </Box>

                    <Box sx={{
                      mt: '2rem'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>20,000+ of PNG & SVG graphics</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Access to 100 million stock images</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Upload custom icons and fonts</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Unlimited Sharing</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Upload graphics & video in up to 4k</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Unlimited Projects</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Instant Access to our design system</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '0.8rem' }}>
                        <img src={tick} alt="" style={{ width: '2rem' }} />
                        <Typography sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#191D23',
                          ml: '1rem',
                        }}>Create teams to collaborate on designs</Typography>
                      </Box>



                    </Box>



                  </Box>



                </Grid>





              </Grid>


            </Box>
          </Box>
        </Box>





      </Box>
    </>

  );
}

export default Solutions;
