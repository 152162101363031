
const eventService = (httpClient) => {
    return {

        getEventsByUser: async (id) => {
            try {
                const res = await httpClient.get(`/user/events/${id}`);
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch events" };
            }
        },

        getAllEvents: async (userId) => {
            try {
                const res = await httpClient.post("/public/events", { userId: userId });
                console.log("ResponseDDDDDDDDDDDDD\t\t", res, userId);
                return res.data;
            } catch (error) {
                console.log("ResponseDoooooooooooooooooDD\t\t", error, userId);
                return { status: false, message: "Failed to fetch events" };
            }
        },

        getSingleEvent: async (id) => {
            try {
                const res = await httpClient.get(`/event/${id}`);
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch event" };
            }
        },
    };
};

export default eventService;
