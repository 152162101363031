import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "./Viewer.css";

const VideoPlayer = ({ url, onClose }) => {
  const [validUrl, setValidUrl] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const checkUrlValidity = async () => {
      try {
        const isValid = await ReactPlayer.canPlay(url);
        setValidUrl(isValid);
      } catch (err) {
        setError(true);
      }
    };

    checkUrlValidity();
  }, [url]);

  const handlePlayerError = () => {
    setError(true);
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="videoWrapper">
      <div className="videoPlayer">
        {error ? (
          <div>Failed to load video</div>
        ) : validUrl ? (
          <ReactPlayer
            url={url}
            controls
            onError={handlePlayerError}
            width="98%"
            height="auto"
            className="player"
          />
        ) : (
          <div>Invalid video URL</div>
        )}
      </div>
      <button className="closeButton" onClick={handleClose}>
        X
      </button>
    </div>
  );
};

export default VideoPlayer;
