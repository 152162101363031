import React, { useRef } from "react";
import "./Viewer.css";
// import FileLoader from "../../components/FileLoader";

const ImageViewer = ({ url, onClose }) => {
  const containerRef = useRef(null);

  const handleContainerClick = (event) => {
    // Check if the clicked element is the image itself or a child of the image
    if (event.target === containerRef.current) {
      onClose();
    }
  };

  return (
    <div
      className="image-viewer-container"
      ref={containerRef}
      onClick={handleContainerClick}
    >
      <div className="image-viewer">
        <img src={url} alt="Viewer" className="image-viewer-image" />
      </div>
      <button className="closeButton" onClick={onClose}>
        X
      </button>
    </div>
  );
};

export default ImageViewer;
