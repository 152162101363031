import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as svg } from "./audio.svg";

const AUDIOIcon = (props) => {

  return (
    <SvgIcon
      component={svg}
      viewBox="0 0 60 60"
      sx={{
        fontSize: "30px",
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default AUDIOIcon;
