const maincurriculumService = (httpClient) => {
  return {
    fetchUserCurriculumList: async () => {
      try {
        const res = await httpClient.get(`/fetch/user/curriculum`);
        console.log("Fetched curriculum response:", res);
        return res.data;
      } catch (error) {
        console.error("Error fetching curriculum:", error);
        return { status: false, message: "Failed to fetch curriculum" };
      }
    },
    fetchCurriculumListDetails: async (curriculumId) => {
      try {
        console.log(`Sending POST request with curriculumId: ${curriculumId}`);
        const res = await httpClient.post(`/fetch/curriculum/details`, {
          curriculumId, // Send the curriculumId as part of the request body
        });
        console.log("API response:", res);
        return res.data;
      } catch (error) {
        console.error(`Error fetching curriculum details:`, error);
        return {
          status: false,
          message: "Failed to fetch curriculum details",
        };
      }
    },
    fetchChaptersFromCurriculum: async (curriculumId) => {
      try {
        console.log(`Sending POST request with curriculumId: ${curriculumId}`);
        const res = await httpClient.post(`/fetch/curriculum/chapter`, {
          curriculumId,
        });
        console.log("Fetched chapters response:", res);
        return res.data;
      } catch (error) {
        console.error(`Error fetching chapters:`, error);
        return {
          status: false,
          message: "Failed to fetch chapters",
        };
      }
    },
    fetchHospitals: async (curriculumId) => {
      try {
        console.log(`Sending POST request with curriculumId: ${curriculumId}`);
        const res = await httpClient.post(`/fetch/curriculum/hospitals`, {
          curriculumId,
        });
        console.log("Fetched chapters response:", res);
        return res.data;
      } catch (error) {
        console.error(`Error fetching chapters:`, error);
        return {
          status: false,
          message: "Failed to fetch chapters",
        };
      }
    },
  };
};

export default maincurriculumService;
