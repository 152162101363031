import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { maincurriculumService } from "../../service/index";
import bookicon from "../../images/bookicon.png";
import SampleVideo from "../../images/sampleClinical.mp4";
import badge from "../../images/badge2.png";
import play from "../../images/preview.png";
import chapterDefault from "../../images/thumbanilVid.png";
import AudioIco from "../../images/audio-icon.svg";
import QBIcon from "../../images/qbIcon.svg";
import VidIcon from "../../images/video-icon.svg";
import PodIcon from "../../images/pod-ico.svg";
import DocIcon from "../../images/doc-ico.svg";
import ImageIcon from "../../images/image-icon.svg";
import { icon } from "@fortawesome/fontawesome-svg-core";
import FileListTable from "./FileListTable";
const initialDocumentsandicons = [
  { icon: AudioIco, label: "Audios", count: 0 },
  { icon: QBIcon, label: "Question Bank", count: 0 },
  { icon: PodIcon, label: "Podcasts", count: 0 },
  { icon: DocIcon, label: "Documents", count: 0 },
  { icon: ImageIcon, label: "Images", count: 0 },
  { icon: VidIcon, label: "Videos", count: 0 },
];

const CurriculumProgram = ({ curriculumId }) => {
  const [documentsandicons, setDocumentsandicons] = useState(
    initialDocumentsandicons
  );
  const [curriculumDetails, setCurriculumDetails] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const handleIconClick = (label) => {
    console.log("Icon clicked:", label); // Log the label of the clicked icon
    setSelectedFileType(label);

    navigate(`/file-list/${label.toLowerCase()}`, {
      state: { name: label }, // Pass the label name as state
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const curriculumResponse =
          await maincurriculumService.fetchCurriculumListDetails(curriculumId);
        if (curriculumResponse.status) {
          setCurriculumDetails(curriculumResponse.data);
        } else {
          setError(curriculumResponse.message);
        }

        const chaptersResponse =
          await maincurriculumService.fetchChaptersFromCurriculum(curriculumId);
        if (chaptersResponse.status) {
          setChapters(chaptersResponse.data);

          const counts = chaptersResponse.data.reduce(
            (acc, chapter) => {
              acc.audios += chapter.audios.length;
              acc.videos += chapter.videos.length;
              acc.images += chapter.images.length;
              acc.questionBank += chapter.questionBank.length;
              acc.documents += chapter.documents.length;
              return acc;
            },
            { audios: 0, videos: 0, images: 0, questionBank: 0, documents: 0 }
          );

          const updatedIcons = initialDocumentsandicons.map((item) => {
            let count = 0;
            if (item.label === "Audios") count = counts.audios;
            if (item.label === "Videos") count = counts.videos;
            if (item.label === "Images") count = counts.images;
            if (item.label === "Question Bank") count = counts.questionBank;
            if (item.label === "Documents") count = counts.documents;

            return { ...item, count };
          });

          setDocumentsandicons(updatedIcons);
        } else {
          setError(chaptersResponse.message);
        }

        const hospitalsResponse = await maincurriculumService.fetchHospitals(
          curriculumId
        );
        if (hospitalsResponse.status) {
          const { hospitals, hospitalLocations } = hospitalsResponse.data;
          setHospitals(hospitals);
          setLocations(hospitalLocations);
          setFilteredHospitals(hospitals);
          setFilteredLocations(hospitalLocations);
        } else {
          console.error("Failed to fetch hospitals");
          setError("Failed to fetch hospitals data.");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [curriculumId]);

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setSelectedLocation(selectedLocation);
    const filtered = hospitals.filter(
      (hospital) => hospital.location === selectedLocation
    );
    setFilteredHospitals(filtered);
    setSelectedHospital(""); // Reset selected hospital when location changes
  };

  const handleHospitalChange = (event) => {
    const selectedHospitalId = event.target.value;
    setSelectedHospital(selectedHospitalId);
    const selectedHospital = hospitals.find(
      (hospital) => hospital._id === selectedHospitalId
    );
    if (selectedHospital) {
      setFilteredLocations([selectedHospital.location]);
      setSelectedLocation(selectedHospital.location); // Automatically select location
    }
  };

  const handleSubmit = () => {
    console.log("Selected Hospital ID:", selectedHospital);
    // You can send the selectedHospital ID to the backend here
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  const handleClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{`Error: ${error}`}</Typography>;
  }

  if (!curriculumDetails) {
    return <Typography>No curriculum details available</Typography>;
  }

  return (
    <Box sx={{ p: 5, backgroundColor: "#f0f2f5", minHeight: "100vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <img src={bookicon} alt="Back" style={{ marginRight: "8px" }} />
            <Typography
              sx={{ color: "#101010", fontSize: "16px", fontWeight: "500" }}
            >
              <span
                onClick={handleClick}
                style={{
                  color: "#525252",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Curriculum
              </span>
              {" > "} {curriculumDetails.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7.2}>
          <Paper
            elevation={0}
            sx={{ p: 3, backgroundColor: "#ffffff", borderRadius: "20px" }}
          >
            <Box position="relative">
              <video
                src={SampleVideo}
                style={{ width: "100%", borderRadius: "10px" }}
                controls
              />
              <IconButton
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                color="primary"
                aria-label="play preview"
              >
                <img src={play} style={{ width: "86px", height: "auto" }} />
              </IconButton>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                {curriculumDetails.title}
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                About Course
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "400", mt: 1 }}>
                {curriculumDetails.about || "No description available"}
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "500", mt: 2 }}>
                Hospitals:
              </Typography>
              {Array.isArray(curriculumDetails.hospitals) &&
              curriculumDetails.hospitals.length > 0 ? (
                curriculumDetails.hospitals.map((hospital, index) => (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                    sx={{ bgcolor: "#F6F6F6", p: 2, borderRadius: "10px" }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                        {hospital.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#252525",
                          fontWeight: "400",
                        }}
                      >
                        {hospital.location}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#252525",
                        fontWeight: "400",
                      }}
                    >
                      {hospital.country}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>No hospitals listed</Typography>
              )}
              <Typography sx={{ fontSize: "16px", fontWeight: "500", mt: 2 }}>
                What you'll learn
              </Typography>
              <Box sx={{ mt: 1 }}>
                {Array.isArray(curriculumDetails.uses) &&
                curriculumDetails.uses.length > 0 ? (
                  curriculumDetails.uses.map((learning, index) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      key={index}
                      mt={index === 0 ? 0 : 1}
                    >
                      <img src={badge} style={{ marginBottom: "10px" }} />
                      <Typography
                        sx={{ fontSize: "13px", fontWeight: "400", ml: "20px" }}
                      >
                        {learning}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography>No learning outcomes listed</Typography>
                )}
              </Box>
              <Typography sx={{ fontSize: "16px", fontWeight: "500", mt: 2 }}>
                Requirements
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "400", mt: 1 }}>
                {Array.isArray(curriculumDetails.requirements) &&
                curriculumDetails.requirements.length > 0
                  ? curriculumDetails.requirements.join(", ")
                  : "No requirements listed"}
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "500", mt: 2 }}>
                Skills You Will Gain
              </Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {Array.isArray(curriculumDetails.skills) &&
                curriculumDetails.skills.length > 0 ? (
                  curriculumDetails.skills.map((skill, index) => (
                    <Grid item key={index}>
                      <Button
                        disableElevation
                        variant="contained"
                        sx={{
                          bgcolor: "#F6F6F6",
                          color: "#525252",
                          borderRadius: "26px",
                          textTransform: "none",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        {skill}
                      </Button>
                    </Grid>
                  ))
                ) : (
                  <Typography>No skills listed</Typography>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
        {/* --------------------------------------------------------Preview section -------------------------- */}

        <Grid item xs={12} md={4.5}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
              height: "430px",
              overflowY: "auto",
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#D9D9D9",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#888" },
            }}
          >
            {chapters.map((chapter, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
              >
                <Box display="flex" alignItems="center">
                  <img
                    src={chapter.preview}
                    alt={chapter.title}
                    style={{
                      width: "100px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                  <Box ml={2}>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: "500", pr: "15px" }}
                    >
                      {chapter.title}
                    </Typography>
                    <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
                      {chapter.lectures.length > 0
                        ? `${chapter.lectures.length} lectures`
                        : "No lectures available"}
                      .{chapter.duration} min
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                  >
                    Preview
                  </Typography>
                  <Typography
                    sx={{ fontSize: "11px", fontWeight: "500" }}
                    ml={2}
                  >
                    {chapter.duration}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Paper>
          {/* --------------------------------------------------------Documents section audio vedio...----------------- */}

          <Paper
            elevation={0}
            sx={{
              p: 4,
              mt: 2,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              {documentsandicons.map((item, index) => (
                <Grid item key={index} xs={4}>
                  <Box
                    textAlign="center"
                    onClick={() => handleIconClick(item.label)}
                  >
                    <img
                      src={item.icon}
                      alt={item.label}
                      style={{
                        width: "46px",
                        marginBottom: "4px",
                        cursor: "pointer",
                      }}
                    />
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {item.label}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                      {item.count}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>

          {/* --------------------------------------------------------hospital selectb0x---------------- */}
          <Paper
            elevation={0}
            sx={{
              p: 3,
              mt: 2,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                marginTop: "16px",
                marginBottom: "8px",
                fontSize: "13.4px",
              }}
            >
              Location <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedLocation}
                onChange={handleLocationChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Your Location
                </MenuItem>
                {filteredLocations.map((location, index) => (
                  <MenuItem key={index} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              sx={{
                fontWeight: "500",
                marginTop: "16px",
                marginBottom: "8px",
                fontSize: "13.4px",
              }}
            >
              Hospital <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedHospital}
                onChange={handleHospitalChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Your Hospital
                </MenuItem>
                {filteredHospitals.map((hospital) => (
                  <MenuItem key={hospital._id} value={hospital._id}>
                    {hospital.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              disableElevation
              variant="contained"
              sx={{
                mt: 2,
                borderRadius: "26px",
                bgcolor: "#2C2B83",
                "&:hover": { bgcolor: "#2C2B83" },
              }}
              onClick={handleSubmit}
            >
              Start Now
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={4}>
        {selectedFileType && (
          <FileListTable
            filesToDisplay={filesToDisplay}
            heading={selectedFileType}
          />
        )}
      </Box>
    </Box>
  );
};

export default CurriculumProgram;
