const newsService = (httpClient) => {
    return {
      getNewsById: async (newsId) => {
        try {
          const res = await httpClient.get(`/news/${newsId}`);
          return res.data;
        } catch (error) {
          return { status: false, message: 'Failed to fetch news by ID' };
        }
      },
  
      getAllNews: async () => {
        try {
          const res = await httpClient.get('/news');
          return res.data;
        } catch (error) {
          return { status: false, message: 'Failed to fetch all news' };
        }
      },
  
      addNews: async (newsData) => {
        try {
          const res = await httpClient.post('/news/create', newsData);
          return res.data;
        } catch (error) {
          return { status: false, message: 'Failed to add news' };
        }
      },
  
      updateNews: async (newsId, newsData) => {
        try {
          const res = await httpClient.put(`/news/update/${newsId}`, newsData);
          return res.data;
        } catch (error) {
          return { status: false, message: 'Failed to update news' };
        }
      },
  
      deleteNews: async (newsId) => {
        try {
          const res = await httpClient.delete(`/news/delete/${newsId}`);
          return res.data;
        } catch (error) {
          return { status: false, message: 'Failed to delete news' };
        }
      },
    };
  };
  
  export default newsService;
  