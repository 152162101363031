import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Grid,
  Typography,
  Modal,
  Button,
} from "@mui/material";
// import SubTopBar from '../../components/template/SubTopBar';
import AudioIco from "../../images/audio-icon.svg";
import QBIcon from "../../images/qbIcon.svg";
import VidIcon from "../../images/video-icon.svg";
import podIcon from "../../images/pod-ico.svg";
import docIcon from "../../images/doc-ico.svg";
import ImageIcon from "../../images/image-icon.svg";
import FolderIcon from "@mui/icons-material/Folder";
import { libraryService, recordService } from "../../service/index";
import FileLoader from "../../components/FileLoader";
import {
  docFileExtensions,
  getFileExtension,
  getIcon,
  getRecordType,
  imageExtensions,
  videoExtensions,
} from "../../utils/core-utils";
import ImageViewer from "../files/ImageViewer";
import VideoPlayer from "../files/Video";
import PDFViewer from "../files/Pdf";
import FolderCardComponent from "../../components/card/FolderCardComponent";
import { handlePreviewDocuments } from "../../utils/doc-utils";

const LibFiles = (props) => {
  const { pid, id } = useParams();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [initialLoadingComplete, setInitialLoadingComplete] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  const [program, setProgram] = useState(null);
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState([]);
  const [records, setRecords] = useState([]);
  // const [folder, setFolder] = useState("");
  // const [selectedFileType, setSelectedFileType] = useState("");
  // const [fileSelection, openFileSelection] = useState(false);

  const fetchProgram = useCallback(() => {
    setInitialLoadingComplete(false);
    libraryService.getLibraryById(pid).then((res) => {
      setInitialLoadingComplete(true);
      console.log("Program:\t\t\t\t", res);
      if (!res.status || !res.data) {
        console.log("ProgERR\t\t\t", res);
        snackbar("Program fetching failed", { variant: "error" });
        return;
      }
      setProgram(res.data);
    });
  }, [pid, snackbar]);

  const fetchRecords = useCallback(() => {
    setInitialLoadingComplete(false);
    recordService.fetchRecords(id ? id : "").then((res) => {
      setInitialLoadingComplete(true);
      console.log("Records:\t\t\t\t", res);
      if (!res.status || !res.data) {
        console.log("RecERR\t\t\t", res);
        snackbar("Record fetching failed", { variant: "error" });
        return;
      }
      setRecords(res.data);
    });
  }, [id, snackbar]);

  useEffect(() => {
    if (!id) {
      fetchProgram();
    } else {
      fetchRecords();
    }
  }, [id, fetchProgram, fetchRecords]);


  if (!initialLoadingComplete) {
    <FileLoader />;
  }

  const [viewer, openViewer] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState(null);
  const [type, setType] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);

  const handleDoubleClick = async (record) => {
    try {
      setInitialLoadingComplete(false);
      console.log("Record\t\t\t", record);
      if (record.isFolder) {
        setRecords(null);
        navigate(`/user/files/${id}/${record._id}`);
      }
      if (!record.isFolder) {
        const recordType = getRecordType(record);
        console.log("RecordType\t", recordType);
        // if (!readAble.includes(recordType)) {
        //   snackbar("File can't be opened T", { variant: "error" });
        //   return;
        // } else if
        if (imageExtensions.includes(recordType.toLowerCase())) {
          const res = await recordService.generatePresignedUrl(record.img);
          console.log("UrlSigned\t\t\t", res?.url);
          if (res?.url) {
            setType("image");
            setSignedUrl(res.url);
            openViewer(true);
            return;
          }
        } else if (videoExtensions.includes(recordType.toLowerCase())) {
          const res = await recordService.generatePresignedUrl(record.img);
          console.log("UrlSigned\t\t\t", res?.url);
          if (res?.url) {
            setType("video");
            setSignedUrl(res.url);
            openViewer(true);
            return;
          }
        } else if (getFileExtension(record.name) === "pdf") {
          const res = await recordService.generatePresignedUrl(record.img);
          console.log("UrlSigned\t\t\t", res?.url);
          if (res?.url) {
            // setType("pdf");
            // setSignedUrl(res.url);
            // openViewer(true);
            // return;
            window.open(`${res.url}`, "_blank");
          }
        } else if (docFileExtensions.includes(recordType.toLowerCase())) {
          const response = await handlePreviewDocuments(record);
          console.log("Response_preview", response);
          if (!response.status) {
            snackbar("Something went wrong", { variant: "error" });
            console.log("Error\t\t", response);
            return;
          }
          window.open(`${response.url}`, "_blank");
        } else {
          console.log(
            "Record\t\t",
            record,
            recordType,
            docFileExtensions.includes(recordType.toLowerCase())
          );
          snackbar("File can't be opened", { variant: "error" });
          return;
        }
        return;
      }
    } catch (err) {
      console.log("Double_click_err\t\t", err);
      snackbar("Something went wrong", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
    }
  };

  const handleRecordClick = () => { };
  const handleRenameTrigger = () => { };

  const fetchFilesUnderRecord = async () => {
    try {
      setInitialLoadingComplete(false);
      const res = await recordService.getFolderRecordService(id);
      if (!res.status) {
        snackbar(res.message, { variant: "error" });
        return;
      }
      console.log("Folder_Records\t\t", res);
      setSelectedData(res.data.data);
    } catch (err) {
      console.log("File_Fetching_Error_Folder", err);
      snackbar("Something went wrong", { variant: "error" });
    } finally {
      setInitialLoadingComplete(true);
    }
  };

  // const handlePlusClick = (type) => {
  //   setFolder(type);
  // };

  // const handleSelectChange = (event) => {
  //   setSelectedFileType(event.target.value);
  // };

  // const handleFileSelection = () => {
  //   openFileSelection(!fileSelection);
  // };

  const handleSelected = (data, type) => {
    setSelectedData(data);
    // setFolder(type);
    console.log("types...\t\t", data, type)
  };

  return (
    <div style={{ padding: "3.5% 4% 1% 4%" }}>
      <>
        {program && selectedData.length === 0 && !id && (
          <Box
            sx={{
              // border: '1px solid red',
              width: "98%",
            }}
          >
            {/* <SubTopBar title={`My Library`} /> */}
            <Grid
              container
              gap={3}
              sx={{
                pr: "1rem",
              }}
            >
              <Grid
                item
                xs={5.5}
                md={3.8}
                lg={2.3}
                sx={{
                  // border: '1px solid blue',
                  backgroundColor: "#E4E0FF",
                  height: "12rem",
                  borderRadius: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },
                }}
                onClick={() => {
                  handleSelected(program?.audio, "audio");
                }}
              >
                <img src={AudioIco} alt="" />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14.19px",
                    fontFamily: "Poppins",
                    color: "#000020",
                    mt: "0.8rem",
                  }}
                >
                  Audio
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    mt: "0.8rem",
                  }}
                >
                  {program?.audio?.length}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                md={3.8}
                lg={2.2}
                sx={{
                  // border: '1px solid blue',
                  backgroundColor: "#CCE1FF",
                  height: "12rem",
                  borderRadius: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },
                }}
                onClick={() => {
                  handleSelected(program?.questionBank, "question");
                }}
              >
                <img src={QBIcon} alt="" />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14.19px",
                    fontFamily: "Poppins",
                    color: "#000020",
                    mt: "0.8rem",
                  }}
                >
                  Question Bank
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    mt: "0.8rem",
                  }}
                >
                  {program?.questionBank?.length}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                md={3.8}
                lg={2.2}
                sx={{
                  // border: '1px solid blue',
                  backgroundColor: "#FFD2C7",
                  height: "12rem",
                  borderRadius: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },
                }}
                onClick={() => {
                  handleSelected(program?.podcasts, "podcast");
                }}
              >
                <img src={podIcon} alt="" />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14.19px",
                    fontFamily: "Poppins",
                    color: "#000020",
                    mt: "0.8rem",
                  }}
                >
                  Podcasts
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    mt: "0.8rem",
                  }}
                >
                  {program?.podcasts?.length}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                md={3.8}
                lg={2.2}
                sx={{
                  // border: '1px solid blue',
                  backgroundColor: "#FFE1B4",
                  height: "12rem",
                  borderRadius: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },
                }}
                onClick={() => {
                  handleSelected(program?.documents, "document");
                }}
              >
                <img src={docIcon} alt="" />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14.19px",
                    fontFamily: "Poppins",
                    color: "#000020",
                    mt: "0.8rem",
                  }}
                >
                  Document
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    mt: "0.8rem",
                  }}
                >
                  {program?.documents?.length}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                md={3.8}
                lg={2.2}
                sx={{
                  // border: '1px solid blue',
                  backgroundColor: "#C3FFD4",
                  height: "12rem",
                  borderRadius: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },
                }}
                onClick={() => {
                  handleSelected(program?.images, "image");
                }}
              >
                <img src={ImageIcon} alt="" />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14.19px",
                    fontFamily: "Poppins",
                    color: "#000020",
                    mt: "0.8rem",
                  }}
                >
                  Images
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    mt: "0.8rem",
                  }}
                >
                  {program?.images?.length}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                md={3.8}
                lg={2.2}
                sx={{
                  // border: '1px solid blue',
                  backgroundColor: "#FFD2E5",
                  height: "12rem",
                  borderRadius: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                  },
                }}
                onClick={() => {
                  handleSelected(program?.video, "video");
                }}
              >
                <img src={VidIcon} alt="" />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14.19px",
                    fontFamily: "Poppins",
                    color: "#000020",
                    mt: "0.8rem",
                  }}
                >
                  Videos
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    color: "#252525",
                    mt: "0.8rem",
                  }}
                >
                  {program?.video?.length}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* ===================  ==================  ===================  ===================  ===================*/}

        {/* {selectedData.length !== 0 && !id && (
          <>
            {console.log("selected\t\t\t", selectedData)}
            <Box
              sx={{
                // border: '1px solid red',
                width: "98%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "#000020",
                    fontFamily: "Poppins",
                    mb: "2rem",
                  }}
                >
                  My Library
                </Typography>
              </Box>
              <Grid
                container
                gap={3}
                sx={{
                  pr: "1rem",
                }}
              >
                {selectedData
                  .filter(
                    (item) =>
                      item.isFolder &&
                      (item.parentId === null || item.parentId === undefined)
                  )
                  .map((item, index) => (
                    <Grid
                      item
                      xs={5.5}
                      md={3.8}
                      lg={2.3}
                      sx={{
                        // border: '1px solid blue',
                        backgroundColor: "#fff",
                        height: "12rem",
                        borderRadius: "1.5rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        cursor: "pointer",
                        padding: "1.5rem",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        overflow: "hidden",
                        "&:hover": {
                          boxShadow:
                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                        },
                      }}
                      onClick={() => {
                        navigate(`/lib/files/${pid}/${item._id}`);
                      }}
                    >
                      <img src={FolderIcon} alt="" />
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "14.19px",
                          fontFamily: "Poppins",
                          color: "#000020",
                          mt: "1.5rem",
                        }}
                      >
                        {item.name ? item.name : ""}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
              <Grid
                container
                gap={3}
                sx={{
                  pr: "1rem",
                  mt: "1.5rem",
                }}
              >
                {selectedData
                  .filter(
                    (item) =>
                      !item.isFolder &&
                      (item.parentId === null || item.parentId === undefined)
                  )
                  .map((item, index) => (
                    <Grid
                      item
                      xs={5.5}
                      md={3.8}
                      lg={2.3}
                      sx={{
                        // border: '1px solid blue',
                        backgroundColor: "#fff",
                        height: "8rem",
                        borderRadius: "1.5rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        cursor: "pointer",
                        padding: "1.5rem",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        overflow: "hidden",
                        "&:hover": {
                          boxShadow:
                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                        },
                      }}
                    >
                      <BsFileEarmark size={34} color="#2C2B83" />
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "14.19px",
                          fontFamily: "Poppins",
                          color: "#000020",
                          mt: "1.5rem",
                        }}
                      >
                        {item.name ? item.name : ""}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </>
        )} */}

        {selectedData.length !== 0 && !id && (
          <Box
            sx={{
              overflowY: "hidden",
            }}
          >
            <Grid container spacing={1.5}>
              {selectedData.map((record, i) => (
                <Grid item md={3} key={i}>
                  <FolderCardComponent
                    id={record._id}
                    record={record}
                    title={record.name}
                    //   isSelected={selectedRecords.includes(record._id)}
                    dIcon={
                      record.isFolder ? (
                        // <BiFolder color="red" fontSize={36} />
                        <FolderIcon
                          sx={{
                            fontSize: "36px",
                            color: "#2C2B83",
                          }}
                        />
                      ) : (
                        getIcon(record.isFolder, false, record)
                      )
                    }
                    clickHandler={() => handleDoubleClick(record)}
                    clickSelection={() => handleRecordClick(record._id)}
                    fetchRecords={() => fetchFilesUnderRecord(id)}
                    setInitialLoadingComplete={(val) => {
                      setInitialLoadingComplete(val);
                    }}
                    dense
                    isDelete={false}
                    isEdit={false}
                    allowShare={false}
                    allowDownload={true}
                    allowRename={false}
                    handleRenameTrigger={handleRenameTrigger}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )
        }

        {id && records && (
          <>
            {console.log("Records\t\t\t", records)}
            <Box
              sx={{
                overflowY: "hidden",
              }}
            >
              <Grid container spacing={1.5}>
                {records.map((record, i) => (
                  <Grid item md={3} key={i}>
                    <FolderCardComponent
                      id={record._id}
                      record={record}
                      title={record.name}
                      //   isSelected={selectedRecords.includes(record._id)}
                      dIcon={
                        record.isFolder ? (
                          // <BiFolder color="red" fontSize={36} />
                          <FolderIcon
                            sx={{
                              fontSize: "36px",
                              color: "#2C2B83",
                            }}
                          />
                        ) : (
                          getIcon(record.isFolder, false, record)
                        )
                      }
                      clickHandler={() => handleDoubleClick(record)}
                      clickSelection={() => handleRecordClick(record._id)}
                      fetchRecords={() => fetchFilesUnderRecord(id)}
                      setInitialLoadingComplete={(val) => {
                        setInitialLoadingComplete(val);
                      }}
                      allowShare
                      dense
                      allowDownload={true}
                      allowRename={true}
                      handleRenameTrigger={handleRenameTrigger}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}

        {signedUrl && (
          <Modal
            open={viewer}
            onClose={() => openViewer(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                //   border : '1px solid red'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "1rem",
                }}
              >
                {type === "image" && (
                  <ImageViewer
                    url={signedUrl}
                    onClose={() => openViewer(false)}
                  />
                )}
                {type === "video" && (
                  <VideoPlayer
                    url={signedUrl}
                    onClose={() => openViewer(false)}
                  />
                )}
                {type === "pdf" && (
                  <PDFViewer
                    url={signedUrl}
                    onClose={() => openViewer(false)}
                  />
                )}
              </Box>
              {type === "video" && (
                <Button
                  sx={{
                    display: "flex",
                    bgcolor: "#2C2B83",
                    color: "#ffffff",
                    textTransform: "none",
                    "&:hover": {
                      scale: "0.8",
                      bgcolor: "#2C2B83",
                    },
                  }}
                  onClick={() => openViewer(false)}
                >
                  Close Player
                </Button>
              )}
            </Box>
          </Modal>
        )}
      </>
    </div>
  );
};

export default LibFiles;
