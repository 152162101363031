const subService = (httpClient) => {
  return {
    addAppointment: async (id, data) => {
      try {
        console.log("Data\t\t\t", data);
        const res = await httpClient.post(`/subscribe/${id}`, data);
        return res.data;
      } catch (error) {
        return { status: false, message: "Booking failed" };
      }
    },

    bookEvent: async (id) => {
      try {
        const res = await httpClient.post(`/subscribe/event/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Booking failed" };
      }
    },

    getProgramByUser: async () => {
      try {
        const res = await httpClient.get("/sub/pgm/user");
        return res.data;
      } catch (error) {
        return { status: false, message: "Program Fetching Failed" };
      }
    },

    getBookedGuidance: async () => {
      try {
        const res = await httpClient.get("/get/user/guidance");
        return res.data;
      } catch (error) {
        return { status: false, message: "Guidance Fetching Failed", error: error };
      }
    },

    getBookedEvents: async () => {
      try {
        const res = await httpClient.get("/get/user/events");
        return res.data;
      } catch (error) {
        return { status: false, message: "Event Fetching Failed", error: error };
      }
    },

    getBookedProgramIds: async () => {
      try {
        const res = await httpClient.get("/get/sub/program/ids");
        return res.data;
      } catch (error) {
        return { status: false, message: "Program Fetching Failed", error: error };
      }
    },
  };
};

export default subService;
