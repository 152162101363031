const ticketService = (httpClient) => {
  return {
    fetchTickets: async () => {
      try {
        const res = await httpClient.get("/fetch/ticket/");
        console.log("Fetched tickets response:", res);
        return res.data;
      } catch (error) {
        console.error("Error fetching tickets:", error);
        return { status: false, message: "Failed to fetch tickets" };
      }
    },
    addTicket: async (data) => {
      try {
        const res = await httpClient.post("/add/ticket/main", data);
        console.log("Add ticket response:", res);
        return res.data;
      } catch (error) {
        console.error("Error adding ticket:", error);
        return { status: false, message: "Failed to add ticket" };
      }
    },
    updateTicket: async (data) => {
      try {
        const res = await httpClient.post("/edit/ticket/main", data);
        console.log("Update ticket response:", res);
        return res.data;
      } catch (error) {
        console.error("Error updating ticket:", error);
        return { status: false, message: "Failed to update ticket" };
      }
    },
    deleteTicket: async (ticketId) => {
      try {
        const res = await httpClient.post("/remove/ticket/", { ticketId });
        console.log("Delete ticket response:", res);
        return res.data;
      } catch (error) {
        console.error("Error deleting ticket:", error);
        return { status: false, message: "Failed to delete ticket" };
      }
    },
    fetchTicketDetails: async (ticketId) => {
      try {
        const res = await httpClient.post("/fetch/ticket/details", {
          ticketId,
        });
        console.log("Fetched ticket details response:", res);
        return res.data;
      } catch (error) {
        console.error("Error fetching ticket details:", error);
        return { status: false, message: "Failed to fetch ticket details" };
      }
    },
  };
};

export default ticketService;
