import { CardType } from "../enum";
import { zohoService } from "../service";
import {  getRecordType } from "./core-utils";

export const handlePreviewDocuments = async (document) => {
  //   const { enqueueSnackbar: snackbar } = useSnackbar();
  try {
    if (
      document.img === "" ||
      document.img === undefined ||
      document.img == null
    ) {
      //   snackbar("File key was not available", { variant: "error" });
      console.log("Error: File key was not available");
      return;
    }
    const type = getRecordType(document);
    if (
      type !== CardType.DOC &&
      type !== CardType.PPT &&
      type !== CardType.XLS
    ) {
      console.log("Error: File type is not supported");
      //   snackbar("File type is not supported", { variant: "error" });
      return;
    }
    if (type === CardType.DOC) {
      console.log("POutgoing\t\t", document);
      const url = await zohoService.previewDoc(document.img);
      if (url.previewUrl !== undefined) {
        return { status: true, url: url.previewUrl };
      }
      return { status: false };
    } else if (type === CardType.PPT) {
      console.log("POutgoing\t\t", document);
      const url = await zohoService.previewSlide(document.img);
      if (url.previewUrl !== undefined) {
        return { status: true, url: url.previewUrl };
      }
      return { status: false };
    } else if (type === CardType.XLS) {
      console.log("POutgoing\t\t", document);
      const url = await zohoService.previewSheet(document.img);
      if (url.previewUrl !== undefined) {
        return { status: true, url: url.previewUrl };
      }
      return { status: false };
    }
  } catch (err) {
    console.log(err);
  }
};
