import React from 'react';
import PropTypes from 'prop-types';

const CircularProgress = ({ progress }) => {
  const radius = 50;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (progress / 100) * circumference;

  return (
    <svg width="120" height="120" viewBox="0 0 120 120">
      <circle
        cx="60"
        cy="60"
        r={radius}
        fill="none"
        stroke="#F4F4FA"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="60"
        cy="60"
        r={radius}
        fill="none"
        stroke="#7171FF"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
        strokeLinecap="round"
        transform="rotate(-90 60 60)"
      />
      <text
        x="60"
        y="60"
        textAnchor="middle"
        dy=".3em"
        fontSize="20"
        fontFamily="Poppins, sans-serif"
        fontWeight="500"
        fill="#252525"
      >
        {`${progress}%`}
      </text>
    </svg>
  );
};

CircularProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default CircularProgress;
