import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
} from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReminderIcon from "@mui/icons-material/Email";
import CopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate } from "react-router-dom";
import ticketIcon from "../../images/ticketIcon.svg";
import IncompleteICon from "../../images/incompleteIconsvg.svg";
import CreateTicket from "../../components/createticket/CreateTicket";

const ticketData = [
  {
    id: "1",
    ticketedItems: "Acute Care Assessment Tool (IMT)",
    expiry: "In 24 Days",
    expiryDate: "01/05/2024",
    createdDate: "02/04/2024",
    assessorEmail: "1234@gmail.com",
    loginCode: "28487hf45",
    fpci: "ACAT / IMT",
    assessor:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    id: "2",
    ticketedItems: "Acute Care Assessment Tool (IMT)",
    expiry: "In 24 Days",
    expiryDate: "01/05/2024",
    createdDate: "02/04/2024",
    assessorEmail: "1234@gmail.com",
    loginCode: "18487hf45",
    fpci: "ACAT / IMT",
    assessor:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const TicketModalComponent = ({ selectedTicket, handleClose }) => {
  const [selected, setSelected] = useState("tickets");

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        padding: "1.5rem",
        width: {
          lg: 480,
          md: "90vw",
        },
        height: {
          lg: 450,
          md: "90vh",
        },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box>
        <img
          src={selected === "tickets" ? ticketIcon : IncompleteICon}
          alt="no"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "85%",
          mt: "1rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Created Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.createdDate}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Expiry Date
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.expiryDate}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "1rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#525252",
            fontSize: "12px",
          }}
        >
          Assessor
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#101010",
            fontSize: "14px",
          }}
        >
          {selectedTicket.assessor}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "85%",
          mt: "1rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Assessor Email
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.assessorEmail}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#525252",
              fontSize: "12px",
            }}
          >
            Login Code
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "medium",
              color: "#101010",
              fontSize: "14px",
            }}
          >
            {selectedTicket.loginCode}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "1rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#525252",
            fontSize: "12px",
          }}
        >
          Form / Procedure / Curriculum Item
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "medium",
            color: "#101010",
            fontSize: "14px",
          }}
        >
          {selectedTicket.fpci}
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: "#E6E6FE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "18%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "600",
            color: "#2C2B83",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          Close
        </Typography>
      </Box>
    </Box>
  );
};

const ExternalAssessment = () => {
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [createTicket, OpenCreateTicket] = useState(false);
  const navigate = useNavigate();
  const handleSelectTicket = (item) => {
    setSelectedTicket(item); // Set selected ticket data
    setTicketModalOpen(true); // Open the modal
  };
  const handleCloseModal = () => {
    setTicketModalOpen(false); // Close the modal
    setSelectedTicket(null); // Clear selected ticket data
  };
  return (
    <Box p={"2.5rem"} height={"100vh"}>
      <Typography fontWeight="500" gutterBottom fontSize="16px">
        <span
          style={{ color: "#525252", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          Supervisor's Report
        </span>{" "}
        &gt; Request External Assessment
      </Typography>

      <Typography fontSize={"14px"} fontWeight={"400"} gutterBottom>
        To Request an assessment from a person who does not have supervise
        access to the account on e-Portfolio, select the request new assessment
        below. This will generate a unique code that the assessor can use to
        login to e-Portfolio and submit the assessment.
      </Typography>
      <Button
        startIcon={<AddCircleIcon />}
        onClick={() => OpenCreateTicket(true)}
        sx={{
          mt: 2,
          mb: 2,
          textTransform: "none",
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#ffffff",
          backgroundColor: "#2C2B83",
          borderRadius: "1rem",
          transition: "scale 0.1s ease-in",
          mr: "0.5rem",
          "&:hover": {
            scale: "0.9",
            backgroundColor: "#2C2B83",
          },
        }}
      >
        New Assessment Form
      </Button>

      {/* ------------------------------------------------modal for createticket----------------------------------------- */}
      <Modal
        open={createTicket}
        onClose={() => OpenCreateTicket(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 99999,
        }}
      >
        <CreateTicket />
      </Modal>

      <Grid container spacing={2}>
        {ticketData.map((item) => (
          <Grid item xs={12} sm={6} key={item.id}>
            <Card
              variant="outlined"
              sx={{
                border: "0.5px solid #AAAAAA",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
                borderRadius: "1.2rem",
                "&:hover": {
                  border: "0.5px solid #2C2B83",
                },
                cursor: "pointer",
              }}
              onClick={() => handleSelectTicket(item)}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Box
                    sx={{
                      bgcolor: "#EFEFEF",
                      borderRadius: "10px",
                      height: "40px",
                      width: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    <img src={ticketIcon} alt="ticket icon" />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#525252",
                      }}
                    >
                      Expiry
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {item.expiry}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#525252",
                      }}
                    >
                      Ticketed Items
                    </Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                      {item.ticketedItems}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={2}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#525252",
                    }}
                  >
                    Assessor
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {item.assessor}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-start" gap={1} mt={3}>
                  <Button
                    startIcon={<DeleteIcon />}
                    sx={{
                      textTransform: "none",
                      fontSize: "12.52px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      color: "#E32222",
                      backgroundColor: "#FFECEC",
                      borderRadius: "1.5rem",
                      mr: "0.5rem",
                      "&:hover": {
                        backgroundColor: "#E32222",
                        color: "#ffffff",
                      },
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    startIcon={<ReminderIcon />}
                    sx={{
                      textTransform: "none",
                      fontSize: "12.52px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      color: "#2C2B83",
                      backgroundColor: "#E5E4FF",
                      borderRadius: "1.5rem",
                      mr: "0.5rem",
                      "&:hover": {
                        backgroundColor: "#2C2B83",
                        color: "#ffffff",
                      },
                    }}
                  >
                    Send Reminder
                  </Button>
                  <Button
                    startIcon={<CopyIcon />}
                    sx={{
                      textTransform: "none",
                      fontSize: "12.52px",
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      color: "#2C2B83",
                      backgroundColor: "#E5E4FF",
                      borderRadius: "1.5rem",
                      "&:hover": {
                        backgroundColor: "#2C2B83",
                        color: "#ffffff",
                      },
                    }}
                  >
                    Copy
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={ticketModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="ticket-modal-title"
        aria-describedby="ticket-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TicketModalComponent
          selectedTicket={selectedTicket}
          handleClose={handleCloseModal}
        />
      </Modal>
    </Box>
  );
};

export default ExternalAssessment;
