import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import Close from "../../images/otpClose.png";
import BG from "../../images/otpModalbg.png";
import { authService } from "../../service";
import { useSnackbar } from "notistack";
import { validatePassword } from "../../utils/validate-utils";
import FileLoader from "../FileLoader/FileLoader";
import { useNavigate } from "react-router-dom";

const OtpComponent = ({ email, mobile, handleClose, reSend, data = null }) => {
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState(["", "", "", ""]);
  const refs = [useRef(), useRef(), useRef(), useRef()];
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (index, e) => {
    const { value } = e.target;
    if (isNaN(value)) return; // Allow only numeric input

    const newOtp = [...OTP];
    newOtp[index] = value.slice(-1); // Limit input to one character
    setOTP(newOtp);

    // Move to the next input field
    if (value !== "" && index < 3) {
      refs[index + 1].current.focus();
    }
  };
  // eslint-disable-next-line
  const [userId, setUserId] = useState(null);
  // eslint-disable-next-line
  const [verified, setVerified] = useState(false);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const completeOtp = OTP.join("");
      if (!email && !mobile) {
        console.error("Bad request");
        return;
      }
      let verifyReponse;
      if (email && !mobile) {
        console.log("here1");
        verifyReponse = await authService.verifyOTP(email, completeOtp);
      }
      if (mobile && !email) {
        console.log("here2");
        verifyReponse = await authService.verifyOTPMobile(
          mobile,
          completeOtp,
          data ? data : ""
        );
      }

      // console.log("Verfiy_Response\t\t", verifyReponse);
      if (!verifyReponse || !verifyReponse.status) {
        snackbar(verifyReponse.message, { variant: "error" });
        console.error("Failed verifiy api\t", verifyReponse);
        return;
      }

      if (verifyReponse.data) {
        snackbar("OTP Verified Successfully", { variant: "success" });
        if (!data) {
          setUserId(verifyReponse.data);
          setVerified(true);
          return;
        }
        snackbar("Please login again", { variant: "success" });
        navigate("/sign-in");
        return;
      }
      snackbar("Failed to veify OTP", { variant: "error" });
      console.log("Failed OTP\t", verifyReponse);
      return;
    } catch (err) {
      console.error("Error in Forgot Password\t", err);
    } finally {
      setLoading(false);
    }
  };

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleChangePassword = async () => {
    try {
      setLoading(true);
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      const test = validatePassword(password);
      console.log("here\t\t", test);
      if (validatePassword(password)) {
        setError(test);
        return;
      } else {
        setError("");
        if (!userId) {
          console.error("No user Id found");
          return;
        }
        const res = await authService.resetPassword(userId, password);
        if (!res || !res.status) {
          snackbar("Failed to reset the password", { variant: "error" });
          return;
        }
        snackbar("Password was reset", { variant: "success" });
        handleClose();
        return;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const completeOtp = OTP.join('');
  //     console.log('Submitted OTP:', completeOtp);

  //     // You can pass this completeOtp to a function or API call here
  // };
  return !loading ? (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "1rem",
          width: "50%",
          height: "70%",
          padding: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => handleClose()}
        >
          <img src={Close} alt="" style={{ width: "2rem" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            // border :'1px solid red'
            mt: "-1rem",
          }}
        >
          {!verified && (
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "24px",
                      color: "#000020",
                    }}
                  >
                    Enter verification code
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "#252525",
                    }}
                  >
                    {email && (
                      <>
                        We have just sent a verification code to <br /> {email}
                      </>
                    )}
                    {mobile && (
                      <>
                        We have just sent a verification code to <br /> {mobile}
                      </>
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // border :'1px solid red'
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        mt: "2rem",
                        mb: "2rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {OTP.map((digit, index) => (
                        <Grid item xs={1.9} key={index}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            inputProps={{
                              maxLength: 1,
                              style: { textAlign: "center" },
                            }}
                            value={digit}
                            onChange={(e) => handleChange(index, e)}
                            inputRef={refs[index]}
                            sx={{
                              backgroundColor: "#F2F2F2",
                              "& .MuiOutlinedInput-root": {
                                fieldset: {
                                  borderColor: "transparent", // Remove border on hover
                                },
                              },
                              "& .Mui-focused fieldset": {
                                borderColor: "transparent", // Remove border on focus
                              },
                              borderRadius: "0.8rem",
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#EB568F",
                          fontFamily: "Poppins",
                          fontSize: "12.51px",
                          fontWeight: "600",
                          color: "#fff",
                          width: "50%",
                          // borderRadius: "0.5rem",
                          height: "auto",
                          textTransform: "none",
                          borderRadius: "1.8rem",
                          "&:hover": {
                            backgroundColor: "#2C2B83",
                          },
                        }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Verify
                      </Button>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "13px",
                          color: "#EB568F",
                          mt: "1.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          email && !mobile ? reSend(email) : reSend(mobile);
                        }}
                      >
                        Resend the code again
                      </Typography>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          )}

          {verified && (
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "24px",
                      color: "#000020",
                    }}
                  >
                    Reset the Password
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // border :'1px solid red'
                  }}
                >
                  <form onSubmit={handleChangePassword}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      maxWidth={300}
                      margin="auto"
                    >
                      <TextField
                        label="New Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        required
                        sx={{
                          mt: "2rem",
                        }}
                      />
                      <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        margin="normal"
                        required
                        error={error !== ""}
                        helperText={error}
                        sx={{
                          mt: "2rem",
                        }}
                      />
                      <Button
                        type="submit"
                        sx={{
                          backgroundColor: "#EB568F",
                          fontFamily: "Poppins",
                          fontSize: "12.51px",
                          fontWeight: "600",
                          color: "#fff",
                          width: "100%",
                          // borderRadius: "0.5rem",
                          height: "2.5rem",
                          textTransform: "none",
                          borderRadius: "1.8rem",
                          mt: "1rem",
                          "&:hover": {
                            backgroundColor: "#2C2B83",
                          },
                        }}
                        onClick={() => {
                          handleChangePassword();
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              width: "50%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // background: `url(${BG})`,
              // backgroundSize: 'contain',
              // backgroundPosition: 'center center',
              // backgroundRepeat: 'no-repeat'
            }}
          >
            <img src={BG} alt="" style={{ maxHeight: "70%", width: "auto" }} />
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <FileLoader />
  );
};

export default OtpComponent;
