import { Grid, Typography, Box, Button } from '@mui/material';
import React, { useState, useRef } from "react";
import { userService } from "../../service/index";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import upload from "../../images/uploadpic-ico.svg";
import DropZone from "../../components/common/drop-zone/drop-Zone";



const ProfileUpload = ({ handleClose, handleFileSelected, isModal = true }) => {

    const [file, setFile] = useState(null);
    const { id } = useParams();
    const { enqueueSnackbar: snackbar } = useSnackbar();
    // const [user, setUser] = useState(null);
    const [avatar, setAvatar] = useState(null);
    // const [submitting, setSubmitting] = useState(false);

    const fileInputRef = useRef(null);

    const handleFileSelection = (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }
        // console.log("Event\t\t\t",event);
        const selectedFile = event.target.files[0];
        // handleFileSelected(event);
        setFile(selectedFile);
        setAvatar(URL.createObjectURL(selectedFile));
    };

    const handleFileButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleUpload = () => {
        // setSubmitting(true);
        const data = new FormData();
        data.append("userImage", file);
        console.log("UploadData\t\t\t", file);

        userService.updateUser(data, id).then((res) => {
            // setSubmitting(false);
            console.log("UploadREs\t\t\t", res);
            if (!res.status) {
                snackbar("Upload failed", { variant: "error" });
                return;
            }
            snackbar("Upload Successful", { variant: "success" });
            handleProfileChange();
            handleClose();
        });
    };

    const handleProfileChange = async () => {
        // await userService.refreshToken(id).then((res) => {
        //   if (!res.status || !res.data) {
        //     setIsLoading(false);
        //     snackbar("Upload Failed, Please login again", { variant: "error" });
        //     return;
        //   }
        //   console.log("Profile_Data\t\t\t", res.data);
        //   setIsLoading(false);
        // });
    };

    return (
        <Grid
            container
            // xs={11}
            // sm={11}
            // md={12}
            sx={{
                // border: "1px solid red",
                backgroundColor: "#fff",
                borderRadius: "1rem",
                height: "auto",
                width: "100%",
                overflow: "hidden",
                // padding: isModal ? "2.5rem 0 3rem 0" : "0 0 0 0",
            }}
        >
            <Grid
                item
                sx={{
                    // border: "1px solid blue",
                    // borderRadius: "1rem",
                    width: "100%",
                    height: "100%",
                    // padding: "1rem",
                }}
            >
                <Grid
                    container
                    sx={{
                        //   border: "1px solid green",
                        height: "100%",
                        display: "flex",
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                            // border: "1px solid yellow",
                            width: "100%",
                            transform: 'translateY(0rem)'
                        }}
                    >
                        <Box
                            className="Modalimage"
                            sx={{
                                width: "7rem",
                                height: "7rem",
                                background: avatar
                                    ? `url(${avatar})`
                                    : `url(${localStorage.getItem("ePortImage")})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                borderRadius: "50%",
                                border: "1px solid #AFAFAF",
                            }}

                        >{console.log("Avatar\t\t\t", avatar)}</Box>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={7}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            padding: "1rem 0 1rem 0",
                            // alignItems : 'center'
                        }}
                    >
                        {isModal && <Box sx={{
                            mb: '1.5rem'
                        }}>
                            <Typography
                                sx={{
                                    fontStyle: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#000020",
                                }}
                            >
                                Profile Photo
                            </Typography>
                            <Typography
                                sx={{
                                    fontStyle: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                    color: "#252525",
                                }}
                            >
                                This will be displayed on your profile.
                            </Typography>
                        </Box>}

                        <DropZone>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "10rem",
                                    border: "2px solid #AFAFAF",
                                    borderRadius: "0.5rem",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // border : '2px solid red'
                                }}
                            >
                                <img src={upload} alt="" />
                                <Typography
                                    sx={{
                                        fontSize: "10px",
                                        fontStyle: "Poppins",
                                        mt: "1rem",
                                        display: "flex",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#EB568F",
                                            fontStyle: "Poppins",
                                            fontSize: "10px",
                                            mr: "5px",
                                            cursor: "pointer",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={handleFileButtonClick}
                                    >
                                        Click to upload
                                    </Typography>{" "}
                                    <input
                                        id="myInput"
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileSelection}
                                    />
                                    or drag and drop
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "10px",
                                        fontStyle: "Poppins",
                                    }}
                                >
                                    PNG, JPG (max 800x4000)
                                </Typography>
                            </Box>
                        </DropZone>

                        {isModal && <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                mt: '1.5rem',
                                // border : '1px solid red'
                            }}
                        >
                            <Button
                                sx={{
                                    backgroundColor: "#EBEBEB",
                                    fontFamily: "Poppins",
                                    fontSize: "11px",
                                    fontWeight: "600",
                                    color: "#000",
                                    width: "48%",
                                    borderRadius: "0.5rem",
                                    height: "3rem",
                                    "&:hover": {
                                        backgroundColor: "#2C2B83",
                                        color: "#fff",
                                    },
                                    textTransform: "none",
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "#EB568F",
                                    fontFamily: "Poppins",
                                    fontSize: "11px",
                                    fontWeight: "600",
                                    color: "#fff",
                                    width: "48%",
                                    borderRadius: "0.5rem",
                                    height: "3rem",
                                    "&:hover": {
                                        backgroundColor: "#2C2B83",
                                    },
                                    textTransform: "none",
                                }}
                                onClick={handleUpload}
                            >
                                Save
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default ProfileUpload