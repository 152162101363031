import { setAuthStorage, setSessionStorage } from "../utils/storage-utils";

const authService = (httpClient) => ({
  adminSignIn: async (email, password) => {
    try {
      const res = await httpClient.post("/user/auth/sign-in", {
        username: email,
        password,
      });
      return res.data;
    } catch (err) {
      return { status: false, message: "Sign up failed try again!" };
    }
  },

  userSignIn: async (email, password, checked) => {
    try {
      const res = await httpClient.post("/signIn", {
        username: email,
        password,
        rememberFlag: checked,
      });
      console.log("userSignIn\t\t\t", res);
      if (res.data.data.expiresIn === "1h") {
        setSessionStorage(res.data.data);
      } else {
        setAuthStorage(res.data.data);
      }
      return res.data;
    } catch (err) {
      return { status: false, message: err.response.data.message };
    }
  },
  forgotPassword: async (email) => {
    try {
      const res = await httpClient.post("/forgot/password", {
        email,
      });
      return res.data;
    } catch (err) {
      return { status: false, message: "Failed to rest password!" };
    }
  },
  verifyOTP: async (email,otp,data) => {
    try {
      const res = await httpClient.post("/verify/forgot/password", {
        email,
        otp,
        data
      });
      return res.data;
    } catch (err) {
      return { status: false, message: "Failed to rest password!" };
    }
  },
  resetPassword: async (userId,password) => {
    try {
      const res = await httpClient.post("/change/forgot/password", {
        userId,
        password
      });
      return res.data;
    } catch (err) {
      return { status: false, message: "Failed to rest password!" };
    }
  },
  verifyOTPMobile: async (mobile,otp,data) => {
    try {
      const res = await httpClient.post("/verify/otp/mobile", {
        mobile,
        otp,
        data
      });
      return res.data;
    } catch (err) {
      console.error(err)
      return { status: false, message: "Failed to verify OTP!" };
    }
  },
  generateOTP: async (mobile) => {
    try {
      const res = await httpClient.post("/send/otp", {
        mobile,
      });
      return res.data;
    } catch (err) {
      return { status: false, message: "Failed to generate OTP!" };
    }
  },
});

export default authService;
