import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Grid,
  useTheme,
} from "@mui/material";
import {
  Link as LinkIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import DOCIcon from "../../images/docIcon.png";
import PDFIcon from "../../images/docIcon.png";
import AudioIcon from "../../images/docIcon.png";
import VedioIcon from "../../images/docIcon.png";
import PPTIcon from "../../images/docIcon.png";
import XLSIcon from "../../images/docIcon.png";
import bookicon from "../../images/bookicon.png";

const Audios = [
  {
    fileName: "Pharmacology and Medication Administration",
    size: "22MB",
    uploadedDate: "11/08/2023",
    modifiedDate: "22/09/2023",
    fileType: "doc",
  },
  {
    fileName: "Patient Assessment and Vital Signs Monitoring",
    size: "22MB",
    uploadedDate: "11/08/2023",
    modifiedDate: "22/09/2023",
    fileType: "pdf",
  },
];

const QuestionBank = [
  {
    fileName: "Heart Sound Analysis",
    size: "10MB",
    uploadedDate: "05/07/2023",
    modifiedDate: "20/09/2023",
    fileType: "mp3",
  },
  {
    fileName: "Lung Sound Analysis",
    size: "8MB",
    uploadedDate: "10/07/2023",
    modifiedDate: "22/09/2023",
    fileType: "mp3",
  },
];

const Podcasts = [
  {
    fileName: "Cardiac Examination",
    size: "120MB",
    uploadedDate: "15/06/2023",
    modifiedDate: "25/09/2023",
    fileType: "mp4",
  },
  {
    fileName: "Abdominal Examination",
    size: "150MB",
    uploadedDate: "20/06/2023",
    modifiedDate: "28/09/2023",
    fileType: "mp4",
  },
];

const Documents = [
  {
    fileName: "Clinical Guidelines",
    size: "5MB",
    uploadedDate: "01/09/2023",
    modifiedDate: "22/09/2023",
    fileType: "ppt",
  },
  {
    fileName: "Lab Report Template",
    size: "2MB",
    uploadedDate: "02/09/2023",
    modifiedDate: "24/09/2023",
    fileType: "xls",
  },
];

const Images = [
  {
    fileName: "Clinical Guidelines",
    size: "5MB",
    uploadedDate: "01/09/2023",
    modifiedDate: "22/09/2023",
    fileType: "ppt",
  },
  {
    fileName: "Lab Report Template",
    size: "2MB",
    uploadedDate: "02/09/2023",
    modifiedDate: "24/09/2023",
    fileType: "xls",
  },
];

const Videos = [
  {
    fileName: "Clinical Guidelines",
    size: "5MB",
    uploadedDate: "01/09/2023",
    modifiedDate: "22/09/2023",
    fileType: "ppt",
  },
  {
    fileName: "Lab Report Template",
    size: "2MB",
    uploadedDate: "02/09/2023",
    modifiedDate: "24/09/2023",
    fileType: "xls",
  },
];

// Similar constants for other file types...

const getFileIcon = (fileType) => {
  switch (fileType) {
    case "doc":
      return <img src={DOCIcon} alt="Doc Icon" />;
    case "pdf":
      return <img src={PDFIcon} alt="PDF Icon" />;
    case "mp3":
      return <img src={AudioIcon} alt="Audio Icon" />;
    case "mp4":
      return <img src={VedioIcon} alt="Video Icon" />;
    case "ppt":
      return <img src={PPTIcon} alt="PPT Icon" />;
    case "xls":
      return <img src={XLSIcon} alt="XLS Icon" />;
    default:
      return null;
  }
};

const allFiles = [
  ...Audios,
  ...QuestionBank,
  ...Podcasts,
  ...Documents,
  ...Images,
  ...Videos,
];

const FileListTable = () => {
  const location = useLocation();
  const { name: heading } = location.state || {};
  const theme = useTheme();

  useEffect(() => {
    console.log("Received heading:", heading);
  }, [heading]);

  return (
    <Box p={"4%"} pt={"6%"} sx={{ backgroundColor: "#f6f6fa" }}>
      <Typography
        variant="h6"
        sx={{ mb: 3, fontSize: "16px", color: "#525252" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={bookicon} style={{ marginRight: "10px" }} />{" "}
          {/* Add your icon here */}E - Learning &gt; MRCP Clinical Program &gt;
          <span style={{ color: "black" }}>{heading}</span>
        </Box>
      </Typography>

      {/* Table Heading Section */}
      <Paper elevation={0} sx={{ mb: 1, borderRadius: "10px", p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "500", color: "#525252" }}
            >
              File Name
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "500", color: "#525252" }}
            >
              Size
            </Typography>
          </Grid>
          <Grid item xs={2.5} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "500", color: "#525252" }}
            >
              Uploaded Date
            </Typography>
          </Grid>
          <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "500", color: "#525252" }}
            >
              Modified Date
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              align="center"
              sx={{ fontSize: "14px", fontWeight: "500", color: "#525252" }}
            >
              Actions
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Table Rows */}
      {allFiles.map((file, index) => (
        <Paper
          elevation={0}
          sx={{ mb: 1, borderRadius: "10px", p: 2 }}
          key={index}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {getFileIcon(file.fileType)}
                <Typography sx={{ ml: 2, fontSize: "13px", fontWeight: "500" }}>
                  {file.fileName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                {file.size}
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                {file.uploadedDate}
              </Typography>
            </Grid>
            <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                {file.modifiedDate}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: "#E5E4FF", // Light purple
                  borderRadius: "50%",
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#D1C4E9", // Slightly darker purple on hover
                  },
                }}
              >
                <LinkIcon sx={{ color: "#2C2B83" }} />{" "}
                {/* Dark purple color for the icon */}
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "#E5E4FF", // Light purple
                  borderRadius: "50%",
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#C5CAE9", // Slightly darker purple on hover
                  },
                }}
              >
                <EditIcon sx={{ color: "#2C2B83" }} />{" "}
                {/* Dark purple color for the icon */}
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "#FFECEC", // Light pink
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor: "#F8BBD0", // Slightly darker pink on hover
                  },
                }}
              >
                <DeleteIcon sx={{ color: "#E32222" }} />{" "}
                {/* Red color for the icon */}
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default FileListTable;
