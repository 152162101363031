import React, { Component } from "react";
import Slider from "react-slick";
import { Card, Typography, Grid, Box, Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import home7 from "../../images/home7.png";
import home8 from "../../images/home8.png";
import home9 from "../../images/home9.png";
import home10 from "../../images/home10.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const customSlides = [
  {
    imageSrc: home7,
    title: "Visualize Your Progress",
    description:
      "Our E-Portfolio empowers you to easily track your growth and progress over time . Utilizing visual representations and other interactive features, aspirants can showcase their educational milestones, clinical experiences, research projects, and other achievements. We truly highlight your skills and accomplishments, ensuring your progress and growth at the first place.",
  },
  {
    imageSrc: home8,
    title: "Personalized Showcase",
    description:
      "We guarantee unique e-portfolios that reflect your identity, individualized experiences, and your passion for a career in  Medicine. With the support of customizable templates and user-friendly interface, we ensure a personalized showcase of your journey, making it easier for others to understand your strengths, interests, and expertise.",
  },
  {
    imageSrc: home9,
    title: "Documentation",
    description:
      "We enable you to go beyond the constraints of traditional paper portfolios and offer you a centralized platform for organizing and documenting your accomplishments.",
  },
  {
    imageSrc: home10,
    title: "Collaboration and Networking",
    description:
      "StudyMEDIC uplifts you with an opportunity to connect and collaborate with a vibrant community of medical professionals. Make use of the chance to engage in discussions, seek advice, and share insights with peers and experts from across the globe.",
  },
];

export default class Responsive extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef(); // Create a ref for the Slider component
  }

  next() {
    this.slider.current.slickNext();
  }

  previous() {
    this.slider.current.slickPrev();
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3.23,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider ref={this.slider} {...settings}>
          {customSlides.map((slide, index) => (
            <div key={index}>
              <Card
                sx={{
                  width: "17rem",
                  height: "24rem",
                }}
              >
                <Grid ml={"25px"} mt={"25px"}>
                  <img
                    src={slide.imageSrc}
                    alt="Custom Slide"
                    style={{ width: "67px", height: "67px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "15.602px",
                      fontWeight: "600",
                      mt: "10px",
                      textAlign: "left",
                      color: "#090914",
                    }}
                  >
                    {slide.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12.258px",
                      fontWeight: "400",
                      mt: "10px",
                      textAlign: "justify",
                      paddingRight: "30px",
                      color: "#252525",
                    }}
                  >
                    {slide.description}
                  </Typography>
                </Grid>
              </Card>
            </div>
          ))}
        </Slider>
        <Box sx={{ mt: "1.5rem" }}>
          <Button
            className="button"
            sx={{
              height: "3rem",
              width: "3rem",
              borderRadius: "50%",
              backgroundColor: "#fff",
              mr: "1rem",
              "&:hover": {
                backgroundColor: "#EB568F",
                color: "#fff",
              },
            }}
            onClick={() => this.previous()}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            className="button"
            sx={{
              height: "3rem",
              width: "3rem",
              borderRadius: "50%",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#EB568F",
                color: "#fff",
              },
            }}
            onClick={() => this.next()}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </div>
    );
  }
}
